import { DomComponent } from "../core/dom.component";
import classNames from 'classnames';

export default class CategoryCardComponent extends DomComponent {
  constructor(app, element, settings) {
    super(app, element, 'c-category-card');

    this.indexController = this.app.getController('index');
    this.dialogController = this.app.getController('dialog');

    this.clickHandler = this.clickHandler.bind(this);

    this.init();

  }

  init() {
    this.category = this.getJSON('category');

    if(!this.hasClass(this.getSelector('--cover', ''))) {
      if (this.category) {
        this.findChild(this.getSelector('__button')).then(element => {
          element.onclick = this.clickHandler;
        });
      }
    }
  }

  clickHandler(e) {
    e.preventDefault();
    const list = this.category.elements.map(element => {
      return {
        label: element.title,
        image: element.image,
        value: element.url
      }
    });
    const content = {
      title: this.category.title ||  "Best Category",
      primaryAction: {
        label: "Shop All",
        href: this.category.shopAllUrl
      },
      items: list
    }
    this.dialogController.context(content, {
      refElement: this.domElement,
      dismissOnScroll: true
    });
  }

}