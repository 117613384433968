import { DomComponent } from "../core/dom.component";

export default class ImageGalleryComponent extends DomComponent {
  constructor(app, element) {
    super(app, element, "c-image-gallery");
    this.imageGalleryService = this.app.imageGalleryService;
    this.init();
  }

  init() {  
    console.log(FB);

    FB.getLoginStatus(function (response) {
        console.log(response);
        if (response.status === 'connected') {
            var accessToken = response.authResponse.accessToken;
            console.log('inside login: ' + accessToken);
        }
    });
    console.log('outside login: ' + FB.getAccessToken());
  }
}
