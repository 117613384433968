import { BaseController } from "../core/base.controller";

import ReactDOM from "react-dom";
import React,{Suspense,lazy} from 'react';
const  PartnerStoreCategoryProducts =lazy(()=> import("../components/focus/partner-storelocation-products"));
const SelectStore  =lazy(()=> import("../components/focus/select.store.component"));
const ChangeStore  =lazy(()=> import("../components/focus/change.store.component"));
const SelectStoreCheckout  =lazy(()=> import("../components/focus/select.store.checkout.component"));
export class FocusStoreController extends BaseController {
    constructor(app) {
        super(app);
    }
    appOnInit(){
        if(super.appOnInit) super.appOnInit();

        this.app.findChild('#select-store').then( element => {
            ReactDOM.render(<Suspense fallback={null}><SelectStore app={this.app} /></Suspense>, element);
        }).catch( reason => this.handleSelectorError(reason));
        this.app.findChild('#change-store-popup').then( element => {
            ReactDOM.render(<Suspense fallback={null}><ChangeStore app={this.app} /></Suspense>, element);
        }).catch( reason => this.handleSelectorError(reason));
            this.app.findChild('#select-store-checkout').then( element => {
                ReactDOM.render(<Suspense fallback={null}><SelectStoreCheckout app={this.app} /></Suspense>, element);
            }).catch( reason => this.handleSelectorError(reason));
    
            this.app.findChild('#partner-storelocation-products__list').then( element => {
                const sortby = JSON.parse(element.getAttribute("data-sortoption"));
                ReactDOM.render(<Suspense fallback={null}><PartnerStoreCategoryProducts sortby={sortby} app={this.app} /></Suspense>, element);
            }).catch( reason => this.handleSelectorError(reason));
 
    }
}