import React, { Component } from 'react';
import classNames from 'classnames';
import BaseReactComponent from '../core/base-react.component';

export class ActionListComponent extends BaseReactComponent {
  constructor(props, context) {
    super(props, context);

    this.renderStore = {
      actionList: null
    }
    this.state = {
      listItems: props.listItems
    };
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.theme = this.props.theme;
  }

  render() {

    this.renderStore.componentClass = classNames(
      "c-action-list",
      { ['c-action-list--' + this.theme]: this.theme }
    )

    if (this.state.listItems) {
      this.renderStore.actionList = this.state.listItems.map((item, index) => {
        return <li className="c-action-list__item" key={index}>
          <a href={item.value} className="c-action-list__item-wrapper">
            {item.image &&
              <div className="c-action-list__item-preview">
                <img src={item.image} alt={item.label} />
              </div>}
            <div className="c-action-list__item-info">
              <span className="c-action-list__item-title">{item.label}</span>
            </div>
          </a>
        </li>
      });
    }

    return (
      <div className={this.renderStore.componentClass}>
        <div className="c-action-list__container">
          <ul className="c-action-list__items">
            {this.renderStore.actionList}
          </ul>
        </div>
      </div>
    )
  }

}