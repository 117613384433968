import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <span className="ltl-close-popup"></span>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const CartShipppingMethodPopUp = () => {
  const [open, setOpen] = useState(false);

  const closeClick = () => {
    setOpen(false);
  };

  return (
    <>
      <span class="modal-tooltip" onClick={() => setOpen(true)}>
        ?
      </span>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={closeClick}
        aria-labelledby="max-width-dialog-title"
        open={open}
        scroll="paper"
      >
        <div className="cart_ship_meth_dialog">
          <DialogTitle id="max-width-dialog-title" onClose={closeClick}>
            <h1 className="h4 ltl-title">Residential Liftgate Notice</h1>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              <p className="h6 ltl-message">
                {window.mageConfig &&
                  window.mageConfig.settings.ltlShippingInfo.alert_message}
              </p>
            </Typography>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};
export default CartShipppingMethodPopUp;
