import { DomComponent } from "../core/dom.component";
import Swiper from "swiper";
import { ViewportController } from "../controllers/viewport.controller";
export default class BannerSliderComponent extends DomComponent {
  constructor(app, element) {
    super(app, element, "c-banner-slider");
    this.init();
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.viewportController = this.app.getController("viewport");

    this.viewportBreakpointChangeHandler =
      this.viewportBreakpointChangeHandler.bind(this);
    this.viewportBreakpointChangeProxyListener =
      this.viewportController.addEventListener(
        ViewportController.EVENT_BREAKPOINT_CHANGE,
        this.viewportBreakpointChangeHandler
      );
    this.viewportBreakpointChangeHandler();
  }

  init() {
    // this.findChild(this.getSelector("__slider")).then((element) => {
    //   this.findChildren(this.getSelector("__slider-item")).then((slides) => {
    //     if (slides && slides.length == 1) {
    //       this.findChild(this.getSelector("__slider-pagination")).then(
    //         (pagination) => {
    //           pagination.style.display = "none";
    //         }
    //       );

    //       return;
    //     }

    this.swiper = new Swiper(this.domElement, {
      slidesPerView: 1,
      touchRatio: 0.2,
      mousewheel: {
        invert: true,
        forceToAxis: true,
      },
      hashNavigation: {
        watchState: true,
      },
      pagination: {
        el: this.getSelector("__slider-pagination"),
        clickable: true,
        bulletClass: "c-banner-slider__slider-pagination-bullet",
        bulletActiveClass: "c-banner-slider__slider-pagination-bullet--active",
      },
    });

    //   });
    // });
  }
  viewportBreakpointChangeHandler() {
    if (!this.viewportController) return;
    if (this.viewportController.isDesktop && this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    } else {
      if (this.swiper) {
        this.init();
      }
    }
  }
}
