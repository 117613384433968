import { DomComponent } from "../core/dom.component";
import FileUploadComponent from "./file-upload.component";
import ReactDOM from "react-dom";
import React from "react";

export default class TaxExemptionFileComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-tax-exemption-file');
        this.accountService = this.app.accountService;
        this.i18nService = this.app.i18nService;
        this.i18nScopeKey = 'TAX_EXEMPTION_FILE';
        this.fileUpload;
        this.fileUploadSettings = {
            uploadFileLabel: this.i18nService.translate([this.i18nScopeKey, 'UPLOAD_FILE_LABEL'])
        }
        this.init();
    }

    init() {
        this.findChild(this.getSelector('__upload')).then(element => {
            this.fileUpload = ReactDOM.render(<FileUploadComponent app={this.app} onChange={this.fileChangeHandler.bind(this)} onRemove={this.fileRemoveHandler.bind(this)} settings={this.fileUploadSettings} />, element);
        });
    }

    fileChangeHandler(e) {
        this.fileUpload.setState({
            isProcessing: true
        });
        this.accountService.uploadTaxExemption().then( result => {
            this.fileUpload.setState({
                isProcessing: false
            });
        }).catch( reason => {
            //todo notification
            this.fileUpload.setState({
                isProcessing: false
            });
        });
    }

    fileRemoveHandler(e) {
        this.fileUpload.setState({
            isProcessing: true
        });
        this.accountService.removeTaxExemption().then( result => {
            this.fileUpload.reset();
        }).catch( reason => {
            //todo notification
            this.fileUpload.setState({
                isProcessing: false
            });
        });
    }
}