import BaseService from "../core/base.service";
import ReactGA from "react-ga4";
import AccountService from "./account.service";

export default class GAService4 extends BaseService {
  constructor(app) {
    super(app);
    this.dimensions = {};
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.accountService = this.app.accountService;
    this.cartService = this.app.cartService;
    this.mageService = this.app.mageService;
    this.categoryService = this.app.categoryService;
    
    ReactGA.initialize(this.app.settings.ga4MeasurementId);

    // // Initialize Google Analytics
    // window.dataLayer = window.dataLayer || [];
    // function gtag() {
    //   window.dataLayer.push(arguments);
    // }
    // gtag("js", new Date());
    // gtag("config", this.app.settings.gaTrackingId); // Replace with your Measurement ID

    this.checkIfAnalyticsLoaded().then((result) => {
      console.trace("Google Analytics 4 injected");
    });
  }

  async viewItem(items) {
    var item = await  this.categoryService.getBrandAndCategoryData(items[0].item_id)
    items[0].item_brand = item[0].brand;
    items[0] = {...items[0] ,...this.getCategoryData(item[0])}
    // gtag('event', 'view_item', {
    //   items
    // });
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "view_item",
      ecommerce: items
    });
  }

  getCategoryData(item){
    const {category_l1,category_l2,category_l3,category_l4} = item;
    return  {
      item_category:  category_l1,
      item_category2: category_l2,
      item_category3: category_l3,
      item_category4: category_l4,
    }
  }

  async addToCart(products) {
    var item = await  this.categoryService.getBrandAndCategoryData(products[0].item_id)
    
    products[0].item_brand = item[0].brand;
    products[0] = {...products[0] ,...this.getCategoryData(item[0])}
    const productInfo = {
      currency: "USD",
      value: (products[0].price * products[0].quantity),
      items: products
    };
    // gtag('event', 'add_to_cart', productInfo);
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: productInfo
    });

  }

  userData(shipping_address) {
    const {email,telephone, firstname,lastname, street,city, region,country_id, postcode    } = shipping_address;
    let payload = {
      'event': 'User_Data',
      'Email':email,
      'Phone': telephone, 
      'First_name': firstname, 
      'Last_name': lastname,
      'Street': street, 
      'City': city, 
      'Region': region, 
      'Country': country_id, 
      'Postal_code': postcode 
    }
    dataLayer.push(payload);
  }

  purchase(products, transaction) {
    if (!this.mageService.trackEnabled) return null;
    try {
      let purchasePayload = {
        transaction_id: transaction.id,
        value: JSON.parse(transaction.revenue),
        currency: "USD",
        items: [],
        ...transaction,
      };
      if (purchasePayload.tax)
        purchasePayload.tax = JSON.parse(purchasePayload.tax);
      if (purchasePayload.shipping)
        purchasePayload.shipping = JSON.parse(purchasePayload.shipping);

      purchasePayload.items = products.map((product) => {
        let categoryData = product?.category.split("/")
        let item = {
          item_id: product.id,
          item_name: product.name,
          item_brand:product.brand,
          item_category:   categoryData[1],
          item_category2:  categoryData[2],
          item_category3:  categoryData[3],
          item_category4:  categoryData[4],
          ...product,
          price: JSON.parse(product.price),
        };
        delete item.id; delete item.name; 
        delete item.brand; delete item.category;
        return item;
      });

      // window.gtag("event", "purchase", purchasePayload);
      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
        event: "purchase",
        ecommerce: purchasePayload
      });
    } catch (err) {
      console.error("GA4 Purchase Error :", err);
    }
  }



  getProductsFromCart() {
    // if (!this.mageService.trackEnabled) return Promise.reject(null);
    return new Promise((resolve, reject) => {
      if (this.cartService.cart && this.cartService.cart.items) {
        let products = [];
        let skus = "";
        this.cartService.cart.items.forEach((product) => {
          skus = (!skus ? skus : skus + ",") + product.sku;
          products.push({
            item_id: product.sku,
            item_name: product.name,
            price: product.price,
            quantity: product.qty
          });
        });

        this.categoryService.getBrandAndCategoryData(skus).then( items => {
          items.map( (item,index) => {
            products[index].item_brand = item.brand;
            products[index] = {...products[index] ,...this.getCategoryData(item)};
          })
          resolve(products);

        }).catch( err => console.error(err))

      } else {
        reject({ message: 'Empty Cart' })
      }
    });
  }

  beginCheckout() {
    this.getProductsFromCart().then(products => {
      // gtag('event', 'begin_checkout', {
      //   items: products,
      //   currency: "USD",
      //   value: this.cartService?.cart?.totals?.grand_total
      // });
      dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          currency: "USD",
          value: this.cartService?.cart?.totals?.grand_total,
          items: products
        }
      });
    }).catch(reason => {
      console.error("Begin Checkout error:",reason)
    });
  }


  checkIfAnalyticsLoaded() {
    return new Promise((resolve, reject) => {
      let timeStart = Date.now();
      const TIMEOUT = 5000;

      let _isLoaded = function () {
        if (Date.now() - timeStart > TIMEOUT) {
          reject("Timeout. Google analytics 4 not injected!");
          return;
        }
        if (window.dataLayer) {
          resolve(window.dataLayer);
          return;
        } else {
          setTimeout(_isLoaded, 500);
        }
      };

      _isLoaded();
    });
  }
}
