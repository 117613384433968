import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';

export default class InlineNotificationComponent extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);
        
        const notification = props.notification || {}
        this.state = {
            theme: props.theme || notification.theme,
            title: props.title || notification.title,
            description: props.description || notification.description,
            content: props.content,
            info: props.info || notification.info
        }
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.dialogController = this.app.getController('dialog');
    }

    componentDidMount() {
        if(this.state.title === undefined) {
            let title = "";
            switch(this.state.theme) {
                case 'danger':
                case 'error':
                    title = 'Error';
                break;
                case 'info':
                    title = "Info";
                break;
                case "success":
                    title = "Success";
                break;
            }
            this.setState({title: title});
        }
    }

    componentWillReceiveProps(nextprops){
        if(this.state.description !== nextprops.description){
            this.setState({description:nextprops.description})
        } 
    }
    
    render() {
        let className = classNames(
            'c-inline-notification',
            {
                ['c-inline-notification--'+this.state.theme]: this.state.theme
            }
        )
        let description;
        if(Array.isArray(this.state.description)) {
            const uniqueDescription = _.uniq(this.state.description);
            description = "<ul>";
            uniqueDescription.forEach( item => description += `<li>${item}</li>`);
            description += "</ul>";
        }else{
            description = this.props.description;
        }

        return (
            <div className={className}>
                <div className="c-inline-notification__container">
                    <div className="c-inline-notification__content">
                        <div className="c-inline-notification__header">
                            <div className="c-inline-notification__header-info" dangerouslySetInnerHTML={{__html: this.state.title}}></div>
                            <div className="c-inline-notification__header-icon"></div>
                        </div>
                        {!this.state.content && <div className="c-inline-notification__body" dangerouslySetInnerHTML={{__html: description}}></div>}
                        {this.state.content && <div className="c-inline-notification__body">{this.state.content}</div>}
                        <div className="c-inline-notification__footer"  dangerouslySetInnerHTML={{__html: this.state.info}}></div>
                    </div>
                    {/* {this.props.hasCloseButton && <span className="c-inline-notification__close"><a onClick={(e) => this.close()} className="c-button c-button--icon"><i className="icon__fsd icon__fsd--close-primary"></i></a></span>} */}
                </div>
            </div>
        )
    }

    close() {
        if(this.dialog) this.dialogController.dismiss(null, this.dialog.dialogRefId);
    }
}