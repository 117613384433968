import EventDispatcher from "./classes/EventDispatcher";
import BaseApp from "./base.app";

export class BaseController extends EventDispatcher {
  constructor(app, settings) {
    super();
    this.app = app;
    this.settings = settings;
    this.declarationList = [];
    this.declarationDictionary = {};

    this.superInit();
  }

  superInit() {
    if (this.app) {
      if (this.app.isReady) {
        this.appReadyHandler();
      } else {
        this.app.addEventListener(
          BaseApp.EVENT_APP_INIT,
          this.appReadyHandler.bind(this)
        );
      }
    }
  }

  appReadyHandler() {
    if (this.appOnInit) this.appOnInit();
    if (this.settings && this.settings.declarations) {
      this.initDeclarations(this.settings.declarations).catch(reason =>
        this.handleSelectorError(reason)
      );
    }
  }

  createDomComponent(componentFactory, element, ...args) {
    return new componentFactory(this.app, element);
  }

  getDomComponent(name, asList = false) {
    if (!this.declarationList)
      Promise.reject(new Error("Undefined decleration list"));

    return new Promise((resolve, reject) => {

      if(this.declarationDictionary[name]) {
        resolve(this.declarationDictionary[name]);
        return;
      } 

      console.log("buraya gelmemesi lazım ama => ", name, this.declarationDictionary, this.declarationDictionary[name]);

      let components = this.declarationList.filter(
        instance => instance._declarationName == name
      );
      if (components && components.length) {
        asList ? resolve(components) : resolve(components[0]);
      } else {
        reject(
          new Error(
            "Couldn't found any component that has declaration name called " +
              name
          )
        );
      }
    });
  }

  initDeclarations(declarations) {
    if (!declarations) Promise.reject(new Error("Undefined declerations"));

    return new Promise((resolve, reject) => {
      let instance, uiId;
      declarations.forEach(declaration => {
        if (declaration.decorator && declaration.decorator.selector) {
          this.app
            .findChildren(declaration.decorator.selector)
            .then(elements => {
              elements.forEach(element => {
                uiId =
                  element.getAttribute("name") || _.uniqueId(declaration.name);
                instance = this.createDomComponent(declaration, element);
                instance._uiId = uiId;
                instance._declarationName = declaration.name;
                if (this.declarationDictionary[uiId]) {
                  throw new Error(
                    `This instance name (${uiId}) has been already used`
                  );
                }
                this.declarationDictionary[uiId] = instance;
                this.declarationList.push(instance);
              });
              resolve(this.declarationList);
              setTimeout(()=>{
                if (this.onMounted) this.onMounted();
              }, 10);
            })
            .catch(reason => reject(reason));
        }
      });
    });
  }

  handleSelectorError(error) {
    if (this.app.environment.name != "production") {
      console.log(error);
    }
  }

  sendCommand(command, params) {
    if (params) params = this.app.parseJSON(params);
    this.dispatchEvent({
      type: BaseController.EVENT_SEND_COMMAND,
      command: command,
      params: params
    });
  }
}

BaseController.EVENT_SEND_COMMAND = "send-command";
