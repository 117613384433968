import { DomComponent } from "../core/dom.component";
import validate from "validate.js";

export default class FormComponent extends DomComponent {
  constructor(app, element, settings) {
    super(app, element, "c-form");
    this.dialogController = this.app.getController("dialog");
    this.constraints = {};
    this.inputs = [];
    this.enabledInputs = [];
    this.disabledInputs = [];
    this.validateInputs = [];
    this.errors = [];
    this.value = null;
    this.defaultSettings = {
      hasCaptcha: false,
      showCaptchaTerms: false,
      showErrorMessages: true,
      onError: null,
    };
    if (settings)
      this.settings = {
        ...this.defaultSettings,
        ..._.pick(settings, Object.keys(this.defaultSettings)),
      };

    this.captchaId = null;

    this.captchaTermsTemplate = `
            <div class="c-form__section">
                <div class="c-form__section-content">
                    <div class="c-captcha-terms c-captcha-terms--{{theme}}">
                        <div class="c-captcha-terms__container">
                            <p>This site is protected by reCAPTCHA and the Google
                                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        `;

    this.submitClickHandler = this.submitClickHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
    this.captchaDone = this.submit.bind(this);
    // this.mstsPopup = this.mstsPopup.bind(this);
    this.init();
  }

  init() {
    this.findChild(this.getSelector(' [href="#submit"]'))
      .then((element) => (element.onclick = this.submitClickHandler))
      .catch((error) => this.handleSelectorError(error));

    this.findChild(this.getSelector(' [href="#expandToggle"]'))
      .then(
        (element) =>
          (element.onclick = this.expandToggleHandler.bind(this, element))
      )
      .catch((error) => this.handleSelectorError(error));
    const query = window.location.href.split("?");
    
    if (
      window.location.pathname.includes("/customer/account/create") &&
      query[0]
    ) {
      const params = new URLSearchParams(query[1]);
      if (params.get("checkout")) {
        document.getElementById("apply_msts").checked = true;
        // document.getElementsByClassName('apply-msts')[0].classList.add('d-none');
      } else {
        this.msts = true;
      }
      if(params.get("referral")){
          let newElement = document.createElement('input');
          newElement.type ="hidden";
          newElement.name = "create_referral_account";
          newElement.value = 1;
          this.findChild(".c-form__container").then((element) => {
              this.appendChild(newElement,element);
          });
      }
    }
    this.findChild("#group-id")
      .then((element) => {
        element.onchange = (e) => {
          const label = element.options[element.selectedIndex].text;
          this.findChild(".apply-msts").then((element) => {
            if (["Home Use (Consumer)"].includes(label)) {
              document.getElementById("apply_msts").checked = false;
              element.classList.add("d-none");
            } else {
              document.getElementById("apply_msts").checked = this.msts
                ? false
                : true;
              element.classList.remove("d-none");
            }
          });
          // this.mstsPopup(e.target.checked);
        };
      })
      .catch((err) => this.handleSelectorError(err));
    this.domElement.onsubmit = this.formSubmitHandler;

    let settings = this.getJSON("settings");
    if (settings)
      this.settings = {
        ...this.defaultSettings,
        ..._.pick(settings, Object.keys(this.defaultSettings)),
      };
    if (this.settings) {
      if (
        this.settings.hasCaptcha == true &&
        this.settings.showCaptchaTerms == true
      ) {
        if (!settings.theme) settings.theme == "dark";
        this.findChild(" .c-form__container").then((element) => {
          this.findChild(" .c-captcha-terms").catch((err) => {
            this.appendChild(
              _.template(this.captchaTermsTemplate)({ theme: settings.theme }),
              element
            );
          });
        });
      }
    }

    this.setValidations();
    this.findChildren(".c-password-match")
      .then((elements) => {
        elements.forEach((element) => {
          this.password = "";
          this.password_confirmation = "";
          element.onkeyup = (e) => {
            this[e.target.name] = e.target.value;
            let index = e.target.name === "password_confirmation" ? 1 : 0;
            this.getBorderValue(index, e.target.name, true);
            this.getBorderValue(index, e.target.name);
            if (index === 0) {
              this.getBorderValue(1, "password_confirmation", true);
              this.getBorderValue(1, "password_confirmation");
            }
          };
        });
      })
      .catch((err) => this.handleSelectorError(err));
  }
  getBorderValue(index, name, displayborder) {
    // console.log("display border ", displayborder);
    // console.log("index ", index);
    const value = this[name];
    // console.log("value value ", value);
    // if (this.state.signup[keyname] || this.state.signup[keyname] === "") {
    // const passwordcheck = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,16})/;

    const passwordcheck = /((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,16})/;
    console.log("passwordcheck ", passwordcheck.test(value.trim()));
    const valueLength = value.length;

    let message = `${
      !valueLength
        ? "Strength: No Password"
        : valueLength >= 8 && passwordcheck.test(value.trim())
        ? "Strength: OK"
        : "Password must include lower case,upper case, numbers and special characters"
    } <span>i</span>`;
    // console.log("message ", message);
    let border = `5px solid ${
      !valueLength
        ? "#FF5600"
        : valueLength >= 8 && passwordcheck.test(value)
        ? "#50B32E"
        : "#FFBF3D"
    }`;

    if (
      name === "password_confirmation" &&
      valueLength >= 8 &&
      passwordcheck.test(value)
    ) {
      let isMatch = this.password === this.password_confirmation;

      message = `${
        isMatch ? "Password Matched" : "Password not Matched"
      }  <span>i</span>`;
      border = isMatch ? border : " 5px solid #FFBF3D";
    }

    //     return displayborder ? border : message;
    // }
    // return null;
    if (displayborder) {
      document.getElementsByClassName("password-message")[
        index
      ].innerHTML = message;
      // console.log(document.getElementsByName(name)[0]);
      document.getElementsByClassName("c-password-match")[
        index
      ].style.borderBottom = border;
      //  element.style.borderBottom = border;
    }
  }
  // mstsPopup(isChecked){
  //     console.log(isChecked);

  //     if(isChecked){
  //        ReactDOM.render(<MSTSPOPUP app={this.app}/>, document.querySelector('.l-main__dialog'));
  //     }
  // }
  setValidations() {
    this.findChildren([
      this.getSelector(" .c-form__group input"),
      this.getSelector(" .c-form__group select"),
    ])
      .then((inputs) => {
        this.constraints = {};
        this.value = null;
        this.inputs = Array.from(inputs).filter((input) =>
          input.getAttribute("name")
        );
        this.enabledInputs = this.inputs.filter((input) => !input.disabled);
        this.disabledInputs = this.inputs.filter((input) => input.disabled);
        this.validateInputs = this.enabledInputs.filter((input) => {
          let validateOptions = input.getAttribute("validate");
          if (validateOptions) {
            validateOptions = this.parseJSON(validateOptions);
            if (typeof validateOptions === "string")
              validateOptions = JSON.parse(validateOptions);
            this.constraints[input.getAttribute("name")] = validateOptions;
            return input;
          }
        });
      })
      .catch((error) => this.handleSelectorError(error));
  }

  update() {
    this.setValidations();
  }

  expandToggleHandler(el, e) {
    e.preventDefault();
    e.stopPropagation();
    const expandSettings = this.getJSON("expand-settings", el);
    if (expandSettings && expandSettings.targetSelector) {
      this.findChild(expandSettings.targetSelector).then((element) => {
        this.toggleClass(expandSettings.targetExpandClass, element);
      });
    }
  }

  submitClickHandler(e) {
    console.log("password ", this.constraints);

    this.setValue().then((value) => {
      if (this.validate(validate(value, this.constraints))) {
        if (this.settings && this.settings.hasCaptcha == true) {
          this.validateCaptcha()
            .then((result) => {
              this.submit();
            })
            .catch((error) => {});
        } else {
          this.submit();
        }
      } else {
        if (this.settings.onError) this.settings.onError(this.errors);
      }
    });
  }

  submit() {
    if (this.domElement.hasAttribute("action")) {
      this.domElement.submit();
    } else {
      this.dispatchEvent(FormComponent.EVENT_SUBMIT);
    }
  }

  setValue() {
    return new Promise((resolve, reject) => {
      this.findChild(this.getSelector("__container")).then((element) => {
        let extraValues = {};
        this.findChildren(
          this.getSelector(" :not(input):not(select)[name][value]")
        )
          .then((extraInputs) => {
            extraInputs.forEach((input) => {
              extraValues[input.getAttribute("name")] = input.getAttribute(
                "value"
              );
            });
            this.value = this.filterEnabledInputs({
              ...validate.collectFormValues(element),
              ...extraValues,
            });
            resolve(this.value);
          })
          .catch((reason) => {
            this.value = this.filterEnabledInputs(
              validate.collectFormValues(element)
            );
            resolve(this.value);
          });
      });
    });
  }

  filterEnabledInputs(value) {
    if (!this.disabledInputs || this.disabledInputs.length == 0) return value;
    const cloneValue = { ...value };
    this.disabledInputs.forEach((input) => {
      delete cloneValue[input.getAttribute("name")];
    });
    return cloneValue;
  }

  clearErrors() {
    if (this.errors)
      while (this.errors.length > 0) {
        let error = this.errors.pop();
        let inputType =
          error.input.tagName == "SELECT"
            ? "select"
            : error.input.getAttribute("type");
        this.removeClass(
          "has-error",
          inputType == "select" ? error.input.parentNode : error.input
        );
        if (error.helperText) error.helperText.destroy();
      }
  }

  validateCaptcha() {
    return new Promise((resolve, reject) => {
      window.grecaptcha
        .execute(this.app.settings.reCaptcha.siteKey, { action: "captchaForm" })
        .then((token) => {
          if (token && token.length > 0) {
            let input = document.createElement("input");
            input.setAttribute("name", "g-recaptcha-response");
            input.setAttribute("value", token);
            input.setAttribute("type", "hidden");
            this.domElement.appendChild(input);
            this.setValue().then((result) => {
              if (this.validate(validate(result, this.constraints))) {
                resolve(result);
              } else {
                reject(new Error("Invalid Form"));
              }
            });
          } else {
            reject(new Error("Invalid recaptcha token"));
          }
        });
    });
  }

  validate(errors) {
    this.clearErrors();
    if (errors) {
      this.errors = [];
      let inputError;
      let helperText;
      let inputType;
      let inputParent;

      this.validateInputs.forEach((input) => {
        inputError = errors[input.getAttribute("name")];
        inputType =
          input.tagName == "SELECT" ? "select" : input.getAttribute("type");
        inputParent =
          inputType == "select"
            ? input.parentNode.parentNode
            : input.parentNode;

        if (inputError) {
          if (this.settings.showErrorMessages) {
            helperText = this.createDomComponent(
              this.app,
              "div",
              {
                className: "c-form__helper-text c-form__helper-text--error",
              },
              inputParent,
              inputType == "select"
                ? input.parentNode.nextSibling
                : input.nextSibling
            );
            helperText.html(
              `<span class="c-form__helper-text-description">${_.capitalize(
                inputError.join(",").replace("Login[", "").replace("]", "")
              )}</span>`
            );
            this.addClass(
              "has-error",
              inputType == "select" ? input.parentNode : input
            );
          } else {
            helperText = null;
          }
          this.errors.push({
            input: input,
            helperText: helperText,
            messages: inputError,
          });
        }
      });
      return false;
    } else {
      return true;
    }
  }

  clear() {
    if (this.domElement.tagName == "FORM") {
      this.domElement.reset();
    } else {
      this.fillForm(null);
    }
    this.clearErrors();
    this.findChild(this.getSelector(' [href="#submit"]'))
      .then((button) => (button.onclick = this.submitClickHandler))
      .catch((error) => this.handleSelectorError(error));
  }

  reset() {
    this.clear();
  }

  fillForm(value) {
    let name;
    if (this.inputs)
      this.inputs.forEach((input) => {
        name = input.getAttribute("name");
        input.value = value ? value[name] : "";
      });
  }

  formSubmitHandler(e) {
    e.preventDefault();
    return false;
  }
}

FormComponent.EVENT_SUBMIT = "submit";
