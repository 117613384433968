import { DomComponent } from "../core/dom.component";
import Swiper from "swiper";

export default class MultiProductSliderComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-multi-product-slider');
        this.init();
    }

    init() {
        this.findChild(this.getSelector('__container')).then( element => {
            this.findChildren(this.getSelector('__slide')).then( slides => {
                this.swiper = new Swiper(element, {
                    spaceBetween: 20,
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                    touchRatio: 0.2,
                    mousewheel: {
                        invert: true,
                        forceToAxis: true
                    },
                    scrollbar: {
                        el: this.getSelector('__scrollbar'),
                        hide: true,
                        draggable: true,
                    },
                    // slideToClickedSlide: true,
                    breakpoints: {
                        [this.app.settings.breakpoints.lg.min]: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 20,
                        },
                        [this.app.settings.breakpoints.md.min]: {
                            freeMode: true,
                            slidesPerView: 2,
                            // slidesPerGroup: 2,
                            spaceBetween: 10,
                        },
                        [this.app.settings.breakpoints.sm.min]: {
                            slidesPerView: 'auto',
                            freeMode: true,
                            spaceBetween: 10,
                        },
                        [this.app.settings.breakpoints.xs.min]: {
                            slidesPerView: 'auto',
                            freeMode: true,
                            spaceBetween: 10,
                        }
                    },
                    navigation: {
                        nextEl: this.querySelector(this.getSelector('__nav-next')),
                        prevEl: this.querySelector(this.getSelector('__nav-prev')),
                        disabledClass: this.getSelector('__nav-item--disabled', ''),
                        hiddenClass: this.getSelector('__nav-item--hidden', '')
                    },
                });
    
                setTimeout(()=> this.app.trigger('resize'), 50);
            });
        });
    }
}