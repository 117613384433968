import { DomComponent } from "../core/dom.component";
import Swiper from "swiper";

export default class TestimonialSliderComponent extends DomComponent {
  constructor(app, element) {
    super(app, element, "c-testimonial-slider");

    this.autoplaySpeed = 6000;
    this.init();
  }

  init() {
    this.initSwiper();
    this.domElement.onmouseover = this.hoverClickHandler.bind(this);
    this.domElement.onclick = this.hoverClickHandler.bind(this);
    this.domElement.onmouseout = this.hoverOutHandler.bind(this);
  }

  initSwiper() {
    this.findChild(this.getSelector("__content")).then((element) => {
      this.findChildren(this.getSelector("__items")).then((slides) => {
        this.swiper = new Swiper(element, {
          loop: true,
          autoplay: {
            // delay: this.autoplaySpeed,
            disableOnInteraction: false,
          },
          touchRatio: 0.2,
          mousewheel: {
            invert: true,
            forceToAxis: true,
          },

          pagination: {
            el: ".c-testimonial-slider__pagination",
            clickable: true,
          },
          breakpoints: {
            // when window width is >= 280px
            280: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          },
        });
      });
    });
  }

  hoverClickHandler() {
    this.swiper.autoplay.stop();
  }

  hoverOutHandler() {
    this.swiper.autoplay.start();
  }
}
