import { BaseController } from "../core/base.controller";
import ProductViewerComponent from "../components/product-viewer.component";
import ProductShopBoxComponent from "../components/product-shop-box.component";
import CartService from "../services/cart.service";
import ReviewEditorComponent from "../components/review-editor.component";
import ExpandableListBlock from "../components/expandable-list-block.component";
import ProductStickyShopBar from "../components/product-sticky-shop-bar.component";
import ReactDOM from "react-dom";
import React,{lazy,Suspense} from 'react';
import UnbxdService from "../services/unbxd.service";
const ProductDetailRecommendationsComponent = lazy(()=>import("../components/product-detail-recommendations.component"));

export class ProductController extends BaseController {

    constructor(app) {
        super(app);
        this.shopBox = null;
        this.viewer = null;
        this.otherProducts = null;
        this.reviewEditor = null;
        this.expandableBlocks = [];
        this.productStickyShopBar = null;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.gaService = this.app.gaService;
        this.cartService = this.app.cartService;
        this.unbxdService = this.app.unbxdService;
        this.unbxdRecommendationService = this.app.unbxdRecommendationService;
        this.productGroups = this.app.settings.productGroups;
        document.addEventListener('unbxd-pdp',()=>this.productDetailUnbxd());
        this.app.findChild('.p-product-detail .c-product-viewer').then( element => {
            this.viewer = new ProductViewerComponent(this.app, element);
            this.app.findChild('.c-product-shop-sticky-bar').then(stickyElement => {    
                this.productStickyShopBar = new ProductStickyShopBar(this.app, stickyElement, {product: this.viewer.product});
            }).catch(reason => this.handleSelectorError(reason));
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChild('.p-product-detail .c-product-shop-box').then( element => {
            this.shopBox = new ProductShopBoxComponent(this.app, element);
            if(this.unbxdService.isReady) {
                this.trackProduct();
            }else{
                this.unbxdService.addEventListener(UnbxdService.EVENT_READY, this.trackProduct.bind(this));
            }
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChild('.p-product-detail .c-review-editor').then( element => {
            this.reviewEditor = new ReviewEditorComponent(this.app, element);
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChildren('.p-product-detail .c-expandable-list-block').then( elements => {
            this.expandableBlocks = [];
            let expandableBlock;
            elements.forEach( (element, index) => {
                expandableBlock = new ExpandableListBlock(this.app, element, {id: index, onStatusChange: this.expandedListBlockStatusChangeHandler.bind(this)});
                expandableBlock.collapse();
                this.expandableBlocks.push(expandableBlock);
            });
            if(this.expandableBlocks.length > 0) this.expandableBlocks[0].expand();

        }).catch( reason => this.handleSelectorError(reason));

        this.cartChangeHandlerProxyListener = this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartServiceChangeHandler.bind(this));
        this.cartServiceChangeHandler();

        this.app.findChild('#unbxd-recommendation--product-detail').then( element => {
            ReactDOM.render(<Suspense fallback={null}><ProductDetailRecommendationsComponent app={this.app} product={this.viewer.product} /></Suspense>, element);
        }).catch( reason => this.handleSelectorError(reason));
    }
    productDetailUnbxd(){
        this.app.findChild('#unbxd-recommendation--product-detail').then( element => {
            ReactDOM.render(<Suspense fallback={null}><ProductDetailRecommendationsComponent app={this.app} product={window.productItem.item} /></Suspense>, element);
        }).catch( reason => this.handleSelectorError(reason));
    }
    cartServiceChangeHandler(e) {
        if(this.shopBox && this.cartService && this.cartService.cart && Array.isArray(this.cartService.cart.items)) {
            let relatedCartItem;
            if(this.shopBox.product) relatedCartItem = this.cartService.cart.items.find( cartItem =>  this.shopBox.product.sku == cartItem['sku'] );
            if(relatedCartItem) {
                relatedCartItem.product = this.shopBox.product;
                this.shopBox.setRelatedCartItem(relatedCartItem);
                if(this.viewer) this.viewer.setRelatedCartItem(relatedCartItem);
            }else{
                this.shopBox.setRelatedCartItem(null);
                if(this.viewer) this.viewer.setRelatedCartItem(null);
            }
        }
    }

    expandedListBlockStatusChangeHandler(expandedBlock) {
        // this.expandableBlocks.forEach( block => {
        //     if(block.id == expandedBlock.id && expandedBlock.isExpanded) {

        //     }else{
        //         block.collapse();
        //     }
        // });
        this.shopBox.updateTopOffset(true);
    }

    trackProduct() {
        if(this.shopBox && this.shopBox.product){
          if(this.unbxdService.isReady) this.unbxdService.fireProductView(this.shopBox.product);
          if(this.shopBox.product.tax_class_name) {
            const categories = this.shopBox.product.category && this.shopBox.product.category.split('/');
            if(categories && categories[2]) {
              if(this.productGroups.some( item => item.name === this.shopBox.product.tax_class_name)) {
                this.gaService.set('contentGroup1', categories[2]);
              }else{
                this.gaService.set('contentGroup2', categories[2]);
              }
            }
          }
        }
    }
}