import { DomComponent } from "../core/dom.component";
import classNames from "classnames";
import { LayoutController } from "../controllers/layout.controller";
import CartService from "../services/cart.service";
import AccountService from "../services/account.service";
import I18nService from "../services/i18n.service";
import ReactDOM from "react-dom";
import React, { lazy, Suspense } from "react";
import { LoadingController } from "../controllers/loading.controller";
import { ViewportController } from "../controllers/viewport.controller";
import MenuController from "../controllers/menu.controller";
import HeaderSignsComponent from "./header-signs.component";
import CartNotificationComponent from "./cart-notification.component";
import SmallCartNotification from "./small-cart-notification.component";
// const HeaderSignsComponent = lazy(()=>import("./header-signs.component"));
// const CartNotificationComponent = lazy(()=>import("./cart-notification.component"));

export default class HeaderComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-header');
        this.routerController = this.app.getController('router');
        this.loadingController = this.app.getController('loading');
        this.dialogController = this.app.getController('dialog');
        this.layoutController = this.app.getController('layout');
        this.viewportController = this.app.getController('viewport');
        this.productsController = this.app.getController('products');
        this.menuController = this.app.getController('menu');
        this.accountService = this.app.accountService;
        this.i18nService = this.app.i18nService;
        this.cartService = this.app.cartService;
        this.user = null;
        this.isShrink = false;
        this.searchBarIsActive = true;
        this.searchBarEnabled = false;
        this.searchBarActiveOnScroll = true;
        this.hasSearchBar = false;
        this.hasSearchBarOnMobile = false;
        this.overlayIsOpen = false;
        this.drawerIsOpen = false;
        this.cartButton = null;
        this.contentElement = null;
        this.container = null;
        this.hamburgerButton = null;
        this.cartNotificationDialog = null;
        this.mobileClone = null;
        this.cartRoutePath = this.app.environment.route.cart;
        this.cartIsProcessing = false;
        this.i18nData = null;
        this.isAutosuggested = false;
        this.isNoResult = false;
        this.shouldShowHistory = false;
        this.isBrandHidden = false;
        this.isProductsHidden = false;
        this.isNoShadow = false;
        this.accountMenus = [];
        this.accountMenuTitles = [];
        this.closeAccountMenuButtons = [];
        this.menuSigns = [];

        this._clickCount = 0;
        this._cartInitialized = false;

        this.loginContainerElement = null;
        this.guestLoginTemplate = `
            <span class="c-header__login-action c-header__login-action--desktop"><a href="{{SIGN_IN_URL}}">{{SIGN_IN_LABEL}}</a> <div class="c-header__vertical-seperator"></div> <a href="{{SIGN_UP_URL}}">{{SIGN_UP_LABEL}}</a></span>
            <span class="c-header__login-action c-header__login-action--mobile"> <a href="{{SIGN_IN_URL}}">{{SIGN_IN_MOBILE_LABEL}}</a></span>
            <div class="c-header__vertical-seperator"></div>
        `;

        this.withPartnerLoginTemplate = `
            <span class="c-header__login-action c-header__login-action--desktop"><a href="{{SIGN_IN_URL}}">{{SIGN_IN_LABEL}}</a> <div class="c-header__vertical-seperator"></div> <a href="{{SIGN_UP_URL}}">{{SIGN_UP_LABEL}}</a> <span class="pl-2 pr-2">or</span> <a href="{{SIGN_IN_PARTNER_URL}}">{{SIGN_IN_PARTNER_LABEL}}</a></span>
            <span class="c-header__login-action c-header__login-action--mobile"> <a href="{{SIGN_IN_URL}}">{{SIGN_IN_MOBILE_LABEL}}</a>  <span class="pl-1 pr-1">or</span> <a href="{{SIGN_IN_PARTNER_URL}}">{{SIGN_IN_MOBILE_PARTNER_LABEL}}</a> </span>
            <div class="c-header__vertical-seperator"></div>
        `;

        this.customerLoginTemplate = `
            <div class="c-header__user-menu">
                <div class="c-header__user-menu-action-wrapper">
                    <span class="c-header__user-menu-welcome-text">{{HELLO}}</span>
                    <a href="{{ACCOUNT_URL}}" class="c-header__user-menu-action">
                        <span class="c-header__user-menu-action-btn c-header__user-menu-action-btn--desktop">{{name}}</span>
                        <span class="c-header__user-menu-action-btn c-header__user-menu-action-btn--mobile"><i class="icon__fsd icon__fsd--user-primary"></i></span>
                    </a>
                </div>
                <div class="c-header__vertical-seperator"></div>
            </div>
        `;

        this.init();
    }

    get mobileMenuIsActive() { return this.menuController && this.menuController.mobileMenuIsActive }

    init() {
        this.hasSearchBar = !this.app.hasClass('no-search-bar') && this.hasClass(this.getSelector('--with-search-bar', ''));
        this.hasSearchBarOnMobile = this.hasClass(this.getSelector('--with-search-bar-mobile', ''));
        this.isShrink = document.getElementById('change-store-popup') || document.getElementsByClassName('p-checkout').length || document.getElementsByClassName('p-checkout-result').length ? true : false;
        this.findChild(this.getSelector('__login')).then(element => {
            this.loginContainerElement = element;
            this.renderLogin();
        }).catch(err => this.handleSelectorError(err));

        this.findChild(this.getSelector('__menu-bar')).then(element => {
            this.topOffset = element.offsetTop;
        });
        this.findChild(this.getSelector('__cart-action')).then(element => {
            this.cartButton = element;
            // this.cartButton.onclick = this.cartButtonClickHandler.bind(this);
        });
        this.findChild(this.getSelector('__container')).then(element => {
            this.container = element;
        });
        this.findChild(this.getSelector('__content')).then(element => {
            this.contentElement = element;
        });
        this.findChild(this.getSelector('__hamburger')).then(element => {
            this.hamburgerButton = element;
            this.hamburgerButton.onclick = this.hamburgerButtonClickHandler.bind(this);
        });

        this.findChild(this.getSelector(' .c-header')).then(element => {
            this.wrapperRef = element;
        }).catch(err => this.handleSelectorError(err));
        // let cartclick = document.getElementsByClassName('c-header__cart');
        this.findChildren(this.getSelector('__cart')).then(elements => {
            elements.forEach(element => {
                element.onclick = this.cartButtonClickHandler.bind(this);
            });
            // element.onclick=this.cartButtonClickHandler.bind(this);
        });
        this.findChild(this.getSelector('__mobile-clone')).then(element => {
            this.mobileClone = element;
        });


        this.findChildren(this.getSelector('__menu-bar-signs')).then(elements => {
            elements.forEach(element => {
                this.menuSigns.push(ReactDOM.render(<Suspense fallback={null}><HeaderSignsComponent app={this.app} /></Suspense>, element));
            });
        });

        // this.layoutController.addEventListener(LayoutController.EVENT_SCROLL_DIRECTION_CHANGE, this.layoutScrollDirectionChangeHandler.bind(this));
        this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(ViewportController.EVENT_BREAKPOINT_CHANGE, this.viewportBreakPointChangeHandler.bind(this));
        this.layoutDrawerChangeProxyListener = this.layoutController.addEventListener(LayoutController.EVENT_DRAWER_CHANGE, this.layoutDrawerChangeHandler.bind(this));

        this.findChild(this.getSelector('__cart-action-icon')).then(element => {
            this.loadingController.addEventListener(LoadingController.EVENT_CHANGE, (event) => {
                this.cartIsProcessing = this.loadingController.getLoadingStatus('cart');
                this.cartIsProcessing ? this.addClass('icon__fsd--processing-dark', element) : this.removeClass('icon__fsd--processing-dark', element);
                if (this.cartIsProcessing) this.updateCartInfo(0);
            });
        });

        this.cartChangeHandlerProxyListener = this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartChangeHandler.bind(this));
        this.accountService.addEventListener(AccountService.EVENT_USER_CHANGE, this.accountUserChangeHandler.bind(this));
        this.menuController.addEventListener(MenuController.EVENT_MOBILE_MENU_STATUS_CHANGE, this.setHamburgerMenuClass.bind(this));

        this.clickHandlerProxy = this.outsideClickListener.bind(this);
        this.updateCartInfo(0);
        // this.setComponentClass();
        this.viewportBreakPointChangeHandler();
        this.layoutDrawerChangeHandler();

    }

    renderLogin() {
        if (!this.loginContainerElement) return;
        if (this.user) {
            this.render(this.customerLoginTemplate, {
                HELLO: this.i18nService.translate('HEADER.LOGIN.HELLO'),
                ACCOUNT_URL: this.i18nService.translate('HEADER.LOGIN.ACCOUNT_URL'),
                name: this.decodeURIComponent(this.user['firstname'], true)
            }, this.loginContainerElement);
        } else {
            let hasPartner = this.i18nService.translate('HEADER.LOGIN.SIGN_IN_PARTNER_URL', null, null, true).length > 0;
            if (hasPartner) {
                this.render(this.withPartnerLoginTemplate, {
                    SIGN_IN_URL: this.i18nService.translate('HEADER.LOGIN.SIGN_IN_URL'),
                    SIGN_IN_LABEL: this.i18nService.translate('HEADER.LOGIN.SIGN_IN_LABEL'),
                    SIGN_IN_PARTNER_LABEL: this.i18nService.translate('HEADER.LOGIN.SIGN_IN_PARTNER_LABEL'),
                    SIGN_UP_URL: this.i18nService.translate('HEADER.LOGIN.SIGN_UP_URL'),
                    SIGN_UP_LABEL: this.i18nService.translate('HEADER.LOGIN.SIGN_UP_LABEL'),
                    SIGN_IN_MOBILE_LABEL: this.i18nService.translate('HEADER.LOGIN.SIGN_IN_MOBILE_LABEL'),
                    SIGN_IN_MOBILE_PARTNER_LABEL: this.i18nService.translate('HEADER.LOGIN.SIGN_IN_MOBILE_PARTNER_LABEL'),
                    SIGN_IN_PARTNER_URL: this.i18nService.translate('HEADER.LOGIN.SIGN_IN_PARTNER_URL'),
                }, this.loginContainerElement);
            } else {
                this.render(this.guestLoginTemplate, {
                    SIGN_IN_URL: this.i18nService.translate('HEADER.LOGIN.SIGN_IN_URL'),
                    SIGN_IN_LABEL: this.i18nService.translate('HEADER.LOGIN.SIGN_IN_LABEL'),
                    SIGN_IN_PARTNER_LABEL: this.i18nService.translate('HEADER.LOGIN.SIGN_IN_PARTNER_LABEL'),
                    SIGN_UP_URL: this.i18nService.translate('HEADER.LOGIN.SIGN_UP_URL'),
                    SIGN_UP_LABEL: this.i18nService.translate('HEADER.LOGIN.SIGN_UP_LABEL'),
                    SIGN_IN_MOBILE_LABEL: this.i18nService.translate('HEADER.LOGIN.SIGN_IN_MOBILE_LABEL'),
                }, this.loginContainerElement);
            }

        }
    }

    showOverlay() {
        this.overlayIsOpen = true;
        this.setComponentClass();
        this._clickCount = 0;
        // document.addEventListener('click', this.clickHandlerProxy);
        this.viewportController.addGestureEvent('tap', this.clickHandlerProxy);
        this._ignoreFirstClick = false;
    }

    hideOverlay() {
        this.overlayIsOpen = false;
        this.setComponentClass();
        // document.removeEventListener('click', this.clickHandlerProxy);
        this.viewportController.removeGestureEvent('tap', this.clickHandlerProxy);
    }

    scrollHandler(event) {
        //triggered from layout controller
        if (!document.getElementById('change-store-popup') && !document.getElementsByClassName('p-checkout').length && !document.getElementsByClassName('p-checkout-result').length) {
            if (this.layoutController.getScrollHeight() - this.layoutController.getHeight() < 130) {
                this.isShrink = false;
                return;
            }
            let isShrink = this.layoutController.getScrollTop() > this.topOffset;
            if (isShrink == this.isShrink) return;
            this.isShrink = isShrink;
            this.setComponentClass();
            this.dispatchEvent(HeaderComponent.EVENT_SHRINK);
        }
    }

    layoutScrollDirectionChangeHandler(event) {
        // this.searchBarIsActive = this.searchBarActiveOnScroll && this.searchBarEnabled && this.layoutController.getScrollDirection() < 0;
        this.setComponentClass();
        setTimeout(() => {
            this.dispatchEvent(HeaderComponent.EVENT_SEARCH_BAR_ACTIVE);
        }, 300);
    }

    enableSearchBar(enabled) {
        this.viewportBreakPointChangeHandler(enabled);
    }

    viewportBreakPointChangeHandler(forceToSearchBarEnabled = true) {
        this.searchBarEnabled = forceToSearchBarEnabled && (this.viewportController.isMobile && this.hasSearchBarOnMobile) || this.hasSearchBar;
        this.setComponentClass();
    }

    layoutDrawerChangeHandler() {
        this.drawerIsOpen = this.layoutController.drawer.left.isOpen || this.layoutController.drawer.right.isOpen;
        this.setComponentClass();
    }

    setComponentClass() {
        let className = classNames(
            this.getComponentClass(),
            {
                [this.getSelector('--autocomplete-expanded', '')]: this.isAutosuggested,
                [this.getSelector('--no-result-expanded', '')]: this.isNoResult,
                [this.getSelector('--history-expanded', '')]: this.shouldShowHistory,
                // [this.getSelector('--autocomplete-no-brand', '')]: this.isBrandHidden,
                [this.getSelector('--autocomplete-no-product', '')]: this.isProductsHidden,
                [this.getSelector('--no-border', '')]: this.drawerIsOpen,
                [this.getSelector('--overlay', '')]: this.overlayIsOpen,
                [this.getSelector('--shrink', '')]: this.isShrink,// && !this.viewportController.isMobile,
                [this.getSelector('--with-search-bar', '')]: this.searchBarEnabled,
                [this.getSelector('--no-shadow', '')]: this.isNoShadow,
                [this.getSelector('--search-bar-is-active', '')]: this.searchBarEnabled && (!this.isShrink || this.searchBarIsActive)
            }
        )
        this.setClass(className)

        setTimeout(() => {
            if (this.dialogController.dialogs) {
                this.dialogController.dialogs.forEach(dialog => {
                    if (dialog.popper) dialog.popper.update();
                });
            }
        }, 100);
    }

    outsideClickListener(event) {
        this.findChild(this.getSelector('__overlay-content')).then(element => {
            if (!element.contains(event.target)) {
                if (this._clickCount > 0) this.dispatchEvent({
                    type: HeaderComponent.EVENT_OUTSIDE_CLICK,
                    target: this,
                    originalEvent: event
                });
                this._clickCount++;

            }
        });
    }

    cartChangeHandler(e) {
        if (this.cartService && this.cartService.cart) {
            if (this._cartInitialized) {
                if (this.cartService.lastCartAction == 'remove') {
                    this.dialogController.notify({
                        theme: "success",
                        text: this.i18nService.translate('CART_NOTIFICATION.CART_REMOVE_SUCCESS_MESSAGE')
                    }).then(res => {
                        console.log(res, 'asdsadas');
                    });
                } else {
                    if (this.cartService.lastCartAction) this.showCartNotification(true);
                }
            }
            this.updateCartInfo();
            if (!this._cartInitialized) this._cartInitialized = true;
        }
    }

    accountUserChangeHandler(e) {
        this.user = this.accountService.user;
        this.renderLogin();
    }

    hideSmallCart = () => {
        this.app.findChild(".small-cart_container")
         .then((element) => {
              ReactDOM.render(null,element);
         })
         .catch((reason) => this.handleSelectorError(reason));    
    }

    showCartNotification(isAdded) {
        if (this.user && this.user.is_partner_store) return;

        if (!this.cartButton) return;
        let self = this;

        // Open Small Cart Popup on second and subsequent Added-Item. 
        if(isAdded && this.cartService?.cart?.totals?.items_qty > 1){
            this.app.findChild(".small-cart_container")
            .then((element) => {
                ReactDOM.render(
                    <SmallCartNotification hideSmallCart={this.hideSmallCart} app={this.app} cart={this.cartService.cart}/>,
                    element
                );
            })
            .catch((reason) => this.handleSelectorError(reason));
            
            setTimeout(this.hideSmallCart, 5000);// 5 sedonds delay
            return true
        }
        
        this.findChildren(this.getSelector('__cart-action-icon')).then(elements => {
            const element = Array.from(elements).find(item => item.offsetHeight > 0);
            this.dialogController.notify((container) => this.createCartNotification(container, isAdded), {
                onInit(dialog) {
                    self.cartNotificationDialog = dialog;
                },
                backdrop: true,
                backdropAction: 'dismiss',

                dialogClass: 'c-dialog-box__content--fullheight',
                popper: {
                    reference: element,
                    options: {
                        positionFixed: true,
                        eventsEnabled: false,

                        placement: 'bottom-end',
                        modifiers: {
                            offset: {
                                offset: `0,10`
                            },
                            arrow: {
                                element: '.c-mini-cart-notification__arrow'
                            }
                        }
                    }
                }
            }).then(result => this.destroyCartNotification()).catch(reason => this.destroyCartNotification());
        });
    }

    destroyCartNotification() {
        this.cartNotificationDialog = null;
    }

    createCartNotification(container, isAdded) {
        ReactDOM.render(<CartNotificationComponent isGuestUser={this.user ? false : true} isAdded={isAdded} app={this.app} cart={this.cartService.cart} i18n={this.i18nData ? this.i18nData.cartNotification : null} />, container);
    }

    cartButtonClickHandler(e) {

        if (!document.getElementsByClassName('p-checkout').length && !document.getElementById('change-store-popup')) {
            e.preventDefault();
            this.showCartNotification();
        }

        // if(!this.cartService || this.cartIsProcessing ) {
        //     e.preventDefault();
        //     return;
        // }
        // if(this.cartService.isEmpty || !this._cartInitialized) {
        //     e.preventDefault();
        //     this.showCartNotification();
        // }
        // this.cartService.isEmpty ? this.showCartNotification() : this.routerController.href(this.cartRoutePath, true);
    }

    updateCartInfo(count) {
        // this.findChild(this.getSelector('__cart-price')).then( element => {
        //     if(this.cartService.isEmpty || isNaN(this.cartService.cart.totals['subtotal'])) {
        //         this.html('$0', element);
        //     }else {
        //         this.html(this.i18nService.format(this.cartService.cart.totals['subtotal'], ['number', 'currency']), element);
        //     }
        // });
        this.findChildren(this.getSelector('__cart-action-badge')).then(elements => {
            Array.from(elements).forEach(element => {
                if (this.cartService.isEmpty) {
                    element.style.display = 'none';
                } else {
                    element.style.display = 'block';
                    const html = FsdApp.cartService.cart.totals.redirectTrue ? '!' : 
                       parseInt(FsdApp.cartService.cart.totals.items_qty).toString();
                    (html === '!') ?  this.addClass('bg-warning',element) : this.removeClass('bg-warning',element); 
                    this.html(html, element);
                 }
            })
        });
    }

    hamburgerButtonClickHandler(e) {
        this.toggleMobileMenu();
    }

    accountMenuHandler() {
        this.accountMenus.forEach(menu => {
            menu.classList.contains('c-header__menu-bar-account-menu--expanded') ? this.closeAccountMenu(menu) : this.openAccountMenu(menu);
        });
    }

    setHamburgerMenuClass() {
        this.mobileMenuIsActive ? this.addClass('c-header__hamburger--active', this.hamburgerButton) : this.removeClass('c-header__hamburger--active', this.hamburgerButton);
    }

    openMobileMenu() {
        this.menuController.openMobileMenu();
    }

    closeMobileMenu() {
        this.menuController.closeMobileMenu();
    }

    toggleMobileMenu() {
        this.mobileMenuIsActive ? this.closeMobileMenu() : this.openMobileMenu();
    }
}

HeaderComponent.EVENT_SHRINK = "shrink";
HeaderComponent.EVENT_SEARCH_BAR_ACTIVE = "search-bar-active";
HeaderComponent.EVENT_OUTSIDE_CLICK = "outside-click";