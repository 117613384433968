import { BaseApiService } from "./base-api.service";

export default class GraphqlService extends BaseApiService {

    constructor(app) {
        super(app);


        this.http.defaults.baseURL = '/graphql';
    }

    appOnInit() {
        if (super.appOnInit) super.appOnInit();
    }
    graphql(data) {
        let url = '';
        return new Promise((resolve, reject) => {
            let store;
            if(this.app.settings.isPartnerStore){
                store = {headers:{ Store : "focus_website_store_view"}}
            } 
            
            this.http.post(url, data,store).then((response) => {
                resolve(response.data.data);
            }).catch(err => {
                reject(err);
            })
        });
    }

}