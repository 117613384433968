import { DomComponent } from "../core/dom.component";
import classNames from "classnames";
import { LayoutController } from "../controllers/layout.controller";
import { ViewportController } from "../controllers/viewport.controller";

export default class BrandNavigationComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-brand-navigation');

        this.layoutController = this.app.getController('layout');
        this.viewportController = this.app.getController('viewport');
        this.routerController = this.app.getController('router');

        this.i18nService = this.app.i18nService;

        this.isShrink = false;
        
        this.sticky = null;
        this.i18nData = null;
        
        this.init();
    }

    init() {
        this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(ViewportController.EVENT_BREAKPOINT_CHANGE, this.viewportBreakPointChangeHandler.bind(this));
        this.viewportBreakPointChangeHandler();
    }

    shrink() {
        if(this.isShrink) return;
        this.isShrink = true;
        this.setComponentClass();
        this.findChild(this.getSelector('__selectbox select')).then( element => {
            this.selectbox = element;
            this.selectbox.onchange = this.selectboxChangeHandler.bind(this)
        })
    }

    grow() {
        if(!this.isShrink) return;
        this.isShrink = false;
        this.setComponentClass();
        this.findChild(this.getSelector('__selectbox select')).then( element => element.onchange = null)
    }

    selectboxChangeHandler(e) {
        if(this.selectbox) this.routerController.href(this.selectbox.value, true);
    }

    scrollHandler(event) {
        //triggered from layout controller
        if(this.layoutController.getScrollHeight() - this.layoutController.getHeight() < 135) {
            this.isShrink = false;
            return;
        }
        let isShrink = this.layoutController.getScrollTop() > this.topOffset;
        if(isShrink == this.isShrink) return;
        this.isShrink = isShrink;
        this.setComponentClass();
        this.dispatchEvent(BrandNavigationComponent.EVENT_SHRINK);
    }

    layoutScrollDirectionChangeHandler(event){
        // event.target.getScrollDirection() < 0;
        this.setComponentClass();
    }

    viewportBreakPointChangeHandler(forceToSearchBarEnabled = true) {
        this.isMobile = this.viewportController.isMobile;
        this.setComponentClass();
    }

    setComponentClass(){
        let className = classNames(
            this.getComponentClass(),
            {
                [this.getSelector('--shrink', '')]: this.isShrink
            }
        )
        this.setClass(className)
    }
}

BrandNavigationComponent.EVENT_SHRINK = "shrink";