
import { BaseApiService } from "./base-api.service";

export default class NewsletterService extends BaseApiService {
    constructor(app) {
        super(app);             
    }

    appOnInit() {
        super.appOnInit();
        this.loadingController = this.app.getController('loading');
        this.mageService = this.app.mageService;
    }

    subscribe(postData){
        return new Promise((resolve, reject) => {
            let data = {email:postData.email};
            // let data = new FormData();
            // data.append('email', postData.email);
            // data.append('form_key', this.mageService.formKey);
            this.loadingController.showLoading('newsletter');          
            //this.setHeaders('Content-Type', "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2));
            this.post('/newsletter/subscriber/email',data,null).then(result => {
                this.loadingController.hideLoading('newsletter');
                resolve(result);
            }).catch(reason => {
                this.loadingController.hideLoading('newsletter');
                reject(reason);
            });
        });
    }
}