import { BaseApiService } from "./base-api.service";
import jsonp from "jsonp";

export default class UnbxdRecommendationService extends BaseApiService {  
    constructor(app) {
        super(app);
   // this.specialStockType = "Special Order";
    }
    appOnInit() {
        super.appOnInit();
        this.dialogController = this.app.getController('dialog');

    }
    handleError(error, reject) {
        if(error && error.message) {
            this.dialogController.notify({
                theme: 'danger',
                text: error.message
            }).then(result => {}).catch( reason => {});
        }
        if(reject) reject(error);
    }

    isSpecialPriceAvailable(product) {
        let isSpecialPriceAvailable = false;
        let today = new Date();
        if(product.special_price) {
          let specialPriceToDate = product.special_price_to_date ? new Date(product.special_price_to_date) : null;
          let specialPriceFromDate = product.special_price_from_date ? new Date(product.special_price_from_date) : null;
          isSpecialPriceAvailable = specialPriceToDate && specialPriceFromDate && today >= specialPriceFromDate && today <= specialPriceToDate;
        } 
        return isSpecialPriceAvailable;
    }
    addSpecialPriceAvailableToProduct(products) {
        if(products && products.length > 0) {
            products = products.map(product => {
                let isSpecialPriceAvailable = this.isSpecialPriceAvailable(product);
                return {...product, isSpecialPriceAvailable: isSpecialPriceAvailable}
            })
        }
        return products;
    }
    
    getRecommendations(page,productId,cartItems){

        return new Promise((resolve, reject) => {
            this.http.get("/fsd/search/getFeaturedProductSkus").then((result) => {
                console.log("getRecommendations",result.data[0]);
                resolve(result.data[0]);
              })
              .catch((error) => {
                reject(error);
              });
          });
         
    }
    
}