import { DomComponent } from "../core/dom.component";
import Swiper from "swiper";

export default class PreviewImageSliderComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-preview-image-slider');
        this.init();
    }

    init() {
        let self = this;
        this.findChildren(this.getSelector('__slide')).then( slides => {
            if(slides && slides.length > 1) {
                this.swiper = new Swiper(this.getSelector('__container'), {
                    pagination: {
                        el: this.getSelector('__pagination'),
                        clickable: true
                    },
                });
            }
        });
        
        this.findChild(this.getSelector('__content')).then( element => element.onclick = function(e) {
            self.dispatchEvent('click');
        });
    }

    destroy() {
        if(this.swiper) this.swiper.destroy();
    }

}