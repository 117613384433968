import { BaseController } from "../core/base.controller";
import BannerSliderComponent from "../components/banner-slider.component";
import CategoryNavigationComponent from "../components/category-navigation.component";
import TeamSliderComponent from "../components/team-slider.component";
import LeftPanelSubCategory from "../components/left-panel-sub-category";
import ReactDOM from "react-dom";
import React, { lazy, Suspense } from "react";
import RecysRecommendation from "./../components/recys-recommendation";
import CategoryCardComponent from "../components/category-card.component";
import ImageGalleryComponent from "../components/image-gallery.component";
const HomePageRecommendationsComponent = lazy(() =>
  import("../components/home-page-recommendations.component")
);
const FeatureBrandsLogo = lazy(() =>
  import("../components/home-feature-brands.component")
);

const ProductComparison = lazy(() =>
  import("../components/product-comparison.component")
);

const HomeBanner = lazy(() =>
  import("../components/home-banner.component")
);

const QuickOrderPage = lazy(() =>
  import("../components/quick-order.component")
);

const SaveListView = lazy(() =>
import("../components/save-list-view.component")
);

const IFrameComponent = lazy(() =>
import("../components/iframe.component")
);
export class IndexController extends BaseController {
  constructor(app) {
    super(app);
    this.bannerSlider = null;
    this.categoryNavigation = null;
    this.categoryCards = [];
    this.imageGallery = null;
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();

    this.unbxdRecommendationService = this.app.unbxdRecommendationService;
    this.unbxdService = this.app.unbxdService;

    // this.app
    //   .findChild(".c-banner-slider")
    //   .then((element) => {
    //     this.bannerSlider = new BannerSliderComponent(this.app, element);
    //   })
    //   .catch((reason) => this.handleSelectorError(reason));
    
    this.app
    .findChild(".desktop-view")
    .then((element) => {
      ReactDOM.render(
        <Suspense fallback={null}>
          <HomeBanner app={this.app} />
        </Suspense>,
        element
      );
    })
    .catch((reason) => this.handleSelectorError(reason));
    
    this.app
      .findChildren(".c-category-navigation")
      .then((elements) => {
        elements.forEach((element) => {
          console.trace("element ", element);
          this.categoryNavigation = new CategoryNavigationComponent(
            this.app,
            element
          );
        });
      })
      .catch((reason) => this.handleSelectorError(reason));

    this.app
      .findChild(".c-team-slider")
      .then((element) => {
        this.teamSlider = new TeamSliderComponent(this.app, element);
      })
      .catch((reason) => this.handleSelectorError(reason));

    this.app
      .findChild("#fsd-recommendation--home-page")
      .then((element) => {
        ReactDOM.render(
          <Suspense fallback={null}>
            <HomePageRecommendationsComponent app={this.app} />
          </Suspense>,
          element
        );
      })
      .catch((reason) => this.handleSelectorError(reason));

    this.app
      .findChild("#category-page")
      .then((element) => {
        ReactDOM.render(
          <LeftPanelSubCategory
            app={this.app}
            categoryId={element.getAttribute("data-categoryid")}
          />,
          element
        );
      })
      .catch((reason) => this.handleSelectorError(reason));

    this.app
      .findChildren(".fsd-recommendations-recsys-for-customer-group")
      .then((elements) => {
        elements.forEach((element) => {
          ReactDOM.render(
            <RecysRecommendation
              limit={element.getAttribute("data-limit")}
              title={element.getAttribute("data-title")}
              type={"trending"}
              app={this.app}
            />,
            element
          );
        });
      })
      .catch((reason) => this.handleSelectorError(reason));

    this.app
      .findChild("#featurebrands")
      .then((element) => {
        ReactDOM.render(
          <Suspense fallback={null}>
            <FeatureBrandsLogo app={this.app} />
          </Suspense>,
          element
        );
      })
      .catch((reason) => this.handleSelectorError(reason));

    this.app
      .findChildren(".c-category-card")
      .then((elements) => {
        elements.forEach((element) => {
          const categoryCard = new CategoryCardComponent(this.app, element);
          this.categoryCards.push(categoryCard);
        });
      })
      .catch((err) => this.handleSelectorError(err));

    this.app
      .findChild(".c-image-gallery")
      .then((element) => {
        this.imageGallery = new ImageGalleryComponent(this.app, element);
      })
      .catch((reason) => this.handleSelectorError(reason));

      // product-comparison page
      this.app
      .findChild("#product-comparison-table")
      .then((element) => {
        ReactDOM.render(
          <Suspense fallback={null}>
            <ProductComparison app={this.app} />
          </Suspense>,
          element
        );
      })
      .catch((reason) => this.handleSelectorError(reason));

    this.app
      .findChild(".saved-list-content")
      .then((element) => {
        ReactDOM.render(
          <Suspense fallback={null}>
            <SaveListView app={this.app} />
            </Suspense>,
          element
        );
      })
      .catch((reason) => this.handleSelectorError(reason));

    this.app
      .findChild("#quick-order-section")
      .then((element) => {
        ReactDOM.render(
          <Suspense fallback={null}>
            <QuickOrderPage app={this.app} />
          </Suspense>,
          element
        );
      })
      .catch((reason) => this.handleSelectorError(reason));

      
    
    this.app
    .findChild(".iframe_container")
    .then((element) => {
      ReactDOM.render(
        <Suspense fallback={null}>
          <IFrameComponent app={this.app} />
        </Suspense>,
        element
      );
    })
    .catch((reason) => this.handleSelectorError(reason));

    // this.app.findChild('__action-list').then(element => {
    //     let listItems = [
    //         {
    //             label: "item 1",
    //             value: "/item1.html"
    //         },
    //         {
    //             label: "item 2",
    //             value: "/item2.html"
    //         },
    //         {
    //             label: "item 3",
    //             value: "/item3.html"
    //         }
    //     ]
    //     this.actionList = ReactDOM.render(<ActionListComponent app={this.app} theme={'flat'} listItems={listItems} />, element)
    // });
  }
}

IndexController.EVENT_CONTROLLER_READY = "controller-ready";
