const isPartnerStore = document.getElementById('change-store-popup') ? true : false;
let gaTrackingId = "UA-481417-10";
let ga4MeasurementId = "G-YE7BQBFB4S";
let isStaging = true;
if(window.location.hostname === "www.foodservicedirect.com" || window.location.hostname === "partners.foodservicedirect.com"){
  gaTrackingId = "UA-481417-2";
  ga4MeasurementId = "G-K7ZV1KDLVZ";
  isStaging = false;
}
export default {

  settings: {
    isPartnerStore,
    environment: 'production',
    isStaging,
    breakpoints: {
      xs: { min: 0, max: 575, breakpointName: "extrasmall", className: "phone" },
      sm: { min: 576, max: 767, breakpointName: "small", className: "phone" },
      md: { min: 768, max: 991, breakpointName: "medium", className: "tablet" },
      lg: { min: 992, max: 1199, breakpointName: "large", className: "desktop" },
      xl: { min: 1200, max: 1299, breakpointName: "xlarge", className: "wide-desktop" },
      xxl: { min: 1300, max: Infinity, breakpointName: "xxlarge", className: "wide-desktop" }
    },
    geocodeOptions:{
      apiKey:' AIzaSyAhyh9KtOG6u5-OF-lzNx6S3d3_Tj9DGWQ',
      language:'en',
      enableDebug:isStaging
    },
    breakpointClasses: ["phone", "tablet", "desktop"],
    i18n: {
      defaultLocale: {
        code: "en",
        name: "English",
        culture: "en-US",
        country: "US",
        currency: 'USD'
      },
      availableLocales: [
        {
          code: "en",
          name: "English",
          culture: "en-US",
          country: "US",
          currency: 'USD',
          defaultFormats: {
            number: {
              currency: {
                style: 'currency',
                currency: 'USD'
              }
            }
          },
        }
      ]
    },
    gaTrackingId,
    ga4MeasurementId,
    storeOptions: {
      namespace: 'fsd',
      storages: ['cookie', 'local', 'session'], // default: `['local', 'cookie', 'session', 'memory']`
      expireDays: 14,
      keyDelimiter: '.'
    },
    excludedStates: ['AK', 'HI', 'PR', 'AE', 'AA', 'AP', 'AS', 'GU', 'FM', 'MH', 'MP', 'PW','VI'],
    minSearchCharCount: 2,
    materialUI: {
      disableRipple: true
    },
    reCaptcha: {
      // siteKey: '6LdICIAUAAAAAPL5k-Xp883uQWLXClX_wwYLN3am',
      siteKey: '6LfJfIcUAAAAACHHjvRdKq11KFAddsGE5KeQ3l5K',
      // secretKey: '6LdICIAUAAAAAOyYyNE07Sye9030FbKwDM6fnL5r'
      secretKey: '6LfJfIcUAAAAAFm9msLZz5ILD8Xrnsf2GWIPoBmH'
    },
    applePay: {
      marchantId: 'merchant.com.foodservicedirect',
      validateApplePayMerchantUrl: 'apple-pay/validate-marchant',
      latestVersion: 5,
      requestDefaults: {
        countryCode: 'US',
        currencyCode: 'USD',
        supportedNetworks: ['amex', 'cartesBancaires', 'chinaUnionPay', 'discover', 'eftpos', 'electron', 'elo', 'interac', 'jcb', 'mada', 'maestro', 'masterCard', 'privateLabel', 'visa', 'vPay'],
        merchantCapabilities: ['supports3DS'],
      }
    },
    paypal: {
      environment: 'sandbox',
      clientId: ''
    },
    numberstepper: {
      min: 1,
      max: 999
    },
    productGroups: [
      { name: "Food Items", type: "FOOD"},
      { name: "Foods", type: "FOOD"},
      { name: "Food", type: "FOOD"},
      { name: "Non Food", type: "NON-FOOD"},
      { name: "Non Food Items", type: "NON-FOOD"},
      { name: "Non Food Products", type: "NON-FOOD"},
      { name: "Nonfood", type: "NON-FOOD"},
      { name: "Non Food Item", type: "NON-FOOD"}
    ]
  }
}