import { DomComponent } from "../core/dom.component";
import classNames from "classnames";
import ReactDOM from "react-dom";
import React, { lazy, Suspense } from "react";
const MailSubBarComponent =lazy(()=>import("./mail-sub-bar.component"));
const FooterColumns =lazy(()=>import("./footer.columns.component"));

export default class FooterComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-footer');
        this.layoutController = this.app.getController('layout');
        this.viewportController = this.app.getController('viewport');

        this.mailSubBar;

        this.init();
    }
    init() {
        this.findChild(this.getSelector('__mail-sub-bar')).then( element => {
            const mailSubBarSettings = this.getJSON('mail-sub-bar-settings');
            this.mailSubBar = ReactDOM.render(<Suspense fallback={null}><MailSubBarComponent settings={mailSubBarSettings} app={this.app}/></Suspense>, element);
        });
        this.findChild(this.getSelector('__container')).then( element => {
             ReactDOM.render(<Suspense fallback={null}><FooterColumns app={this.app}/></Suspense>, element);
        });
        this.findChild(this.getSelector('__etc-list')).then( element => {
            ReactDOM.render(<Suspense fallback={null}><FooterColumns bottom={true} app={this.app}/></Suspense>, element);
        });
    }
}