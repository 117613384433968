import BaseReactComponent from "../core/base-react.component";
import React from "react";

export default class SmallCartNotification extends BaseReactComponent {
  constructor(props, context) {
    super(props, context, {});
    this.state = {};
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.gaService = this.app.gaService;
    this.accountService = this.app.accountService;
    this.cartService = this.app.cartService;
    this.unbxdSearchService = this.app.unbxdSearchService;
    this.recSysService = this.app.recSysService;
  }

  render() {
    let item = this.props?.cart?.items[this.props?.cart?.items?.length-1];
    return (
      <div className="small-cart_wrapper container">
        <div className="small-cart_box">
          <i
            className="icon__fsd icon__fsd--close_dark"
            href="javascript:void(0)"
            onClick={() => this.props.hideSmallCart()}
            // onClick={(e) => this.removeItemHandler(item)}
          ></i>
          <div className="small-cart_content">
            <h1>One New Item Added!</h1>
            <div className="c-mini-cart-notification__product-bar">
              <div className="c-mini-cart-notification__product-avatar">
                <img
                  className="c-mini-cart-notification__product-thumb"
                  src={item?.image}
                  alt={item?.name}
                />
              </div>
              <span className="c-mini-cart-notification__product-name">
                <span 
                className="c-mini-cart-notification__product-name-text"
                dangerouslySetInnerHTML={{ __html: item["name"] }}
                ></span>
                <span className="c-mini-cart-notification__actions justify-content-between mt-3">
                  <span className="c-mini-cart-notification__cart-sold-by-label">
                    Sold By:
                    <span className="mb-2 c-mini-cart-notification__cart-sold-by-vendor-label">
                      {JSON.parse(`${item?.options}`).map( option => option.value)}
                    </span>
                  </span>
                  <span className="small-cart_content_price">
                    {this.i18nService.format(
                      parseFloat(item?.price),
                      ["number", "currency"]
                    )}
                  </span>
                </span>
                <div class=" d-flex justify-content-between small-cart_content_subtotal">
                  <span>
                    Sub Total
                  </span>
                  <span>
                    {this.i18nService.format(
                      parseFloat(this.props?.cart?.totals["subtotal"]),
                      ["number", "currency"]
                    )}
                  </span>
                </div>
              </span>
            </div>

            <div class="row">
              <a
                href="/checkout"
                className="c-mini-cart-notification__action-primary c-button c-button--primary col mr-3"
              >
                Checkout
              </a>
              <a
                href="/checkout/cart"
                className="c-mini-cart-notification__action-primary small-cart_btn_right   c-button col"
              >
                Go to Cart
              </a>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
