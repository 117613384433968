import { DomComponent } from "../core/dom.component";

export default class ListViewSettingsComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-list-view-settings');

        this.sortSelect = null;
        this.catalogSidebar = null;
        
        this.init();
    }

    get catalogSidebarIsOpen() { return this.productsController.catalogSidebarDrawerIsOpen }

    init() {
        this.routerController = this.app.getController('router');
        this.layoutController = this.app.getController('layout');
        this.productsController = this.app.getController('products');
        this.searchController = this.app.getController('search');

        this.findChild(this.getSelector(' a')).then( element => element.onclick = this.actionClickHandler.bind(this, element));
        this.findChild(this.getSelector('__sort-selectbox select')).then( element => {
            this.sortSelect = element;
            this.sortSelect.onchange = this.sortSelectChangeHandler.bind(this);
        });
    }

    actionClickHandler(anchor, e) {
        e.preventDefault();
        e.stopPropagation();
        switch(anchor.getAttribute('href')) {
            case '#show-filters':
                this.catalogSidebarIsOpen ? this.closeCatalogSidebar() : this.openCatalogSidebar();
            break;
            case '#show-search-filters':
                this.searchController.searchSidebarDrawerIsOpen ? this.searchController.closeSearchSidebarDrawer() : this.searchController.openSearchSidebarDrawer();
            break;
        }
    }

    openCatalogSidebar() {
        this.productsController.openCatalogSidebar();
    }

    closeCatalogSidebar() {
        this.productsController.closeCatalogSidebar();
    }

    sortSelectChangeHandler(e) {
        if(e.target.name === "partner-sortby") return true;
        if(this.routerController.queryParams.get('q') && this.routerController.queryParams.get('q').length > 0) return;
        let valueParts = this.sortSelect.value.split(':');
        let sortBy = valueParts[0];
        let sortType = valueParts[1];
        let params = { 'product_list_order': sortBy };
        params['product_list_dir'] = sortType;
        this.routerController.navigate(null, {queryParams: params, preserveQueryParams: true});
    }

}