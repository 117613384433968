import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import BaseReactComponent from '../core/base-react.component';
import { DomComponent } from '../core/dom.component';
import { Button } from './button.component';

export class AddToCartButtonComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.component = ReactDOM.render(<AddToCartButton app={this.app} currentProps={this.getAttributes()}/>, this.domElement);
    }
}

AddToCartButtonComponent.decorator = {
    selector: "fsd-add-to-cart"
}

export class AddToCartButton extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);

        this.i18nScopeKey = 'ADD_TO_CART_BUTTON';
        const is_saleable= this.props.currentProps.product.is_saleable;
        const title = this.props.currentProps.product.is_saleable ? this.props.currentProps.title : "Notify me when in Stock";
        this.state = {
            title,
            is_saleable,
            product: this.props.currentProps.product
        };
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.cartService = this.app.cartService;
        this.i18nService = this.app.i18nService;
    }

    render(){
        return <Button 
                    app={this.app}
                    // label={this.i18nService.translate([this.i18nKey, 'TITLE_LABEL'])}
                    label={this.state.title}
                    onClick={this.addToCartButtonHandler.bind(this)}
                    size="sm"
                />
    }

    addToCartButtonHandler(){
        if(this.state.is_saleable){
            this.cartService.cartLabel = "Reorder";
            this.cartService.addToCart(this.state.product, 1, null);
        }
            
        else
            this.cartService.notifyApi(this.state.product.sku);
    }
}