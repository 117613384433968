import { BaseApiService } from "./base-api.service";
import CartService from "./cart.service";
import GraphqlService from "./base-graphql.service";

export default class MissingProductsService extends BaseApiService {
    constructor(app) {
        super(app);
        this.list = null;
        this.missingProductsPastOrder = null;
        this.missingProductsTrendingNow = null;
        this.AllSkus = null;
        this.getProductsData = this.getProductsData.bind(this)
    }
    appOnInit() {
        super.appOnInit();
        this.loadingController = this.app.getController('loading');
    }

    getProductsData(sku){
        const http = new GraphqlService(this.app);
        return new Promise( (resolve, reject) => {
            http.graphql({
                query:`{
                    products :product_details(
                      skus_comma_separated:"${sku}"
                    ){
                        sku
                        image
                        price
                        name
                        mp_price
                        min_sale_qty
                        product_type
                        qty
                        url
                        vendor_id
                        vendor_name
                        shipping_temp
                        shipping_type
                        ships_in
                        product_review{
                            review_count
                            rating_avg
                        }

                        
                    }
                  }`
            }).then(res=>{
                console.log("actualResult==>",res)
                resolve(res);
            }).catch((err)=> {
                console.error("detError",err);
                reject(err);
            })
        });
    }

    getMissingProducts(){
        return new Promise( (resolve, reject) => {
            this.get('/personalized-recommendations/getAllSkus').then( result => {
                console.log("Result",result)
                this.AllSkus = result[0]
                // this.getProductsData(result[0].past_order_skus).then( result1 => {
                    this.missingProductsPastOrder = result[0].past_order_skus.items
                //     console.log("Result1",result1)
                //     this.dispatchEvent(MissingProductsService.EVENT_CHANGE);
                //     // resolve(result1)
                // })
                // this.getProductsData(result[0].trending_now_skus).then( result2 => {
                    this.missingProductsTrendingNow = result[0].trending_now_skus.items
                //     console.log("Result2",result2)
                    this.dispatchEvent(MissingProductsService.EVENT_CHANGE);
                //     resolve(result2)
                // })
            }).catch((err)=> {
                console.error("error1",err);
                reject(err);
            })
        })
    }
}
MissingProductsService.EVENT_CHANGE = 'items-changed';
MissingProductsService.EVENT_REFRESH = 'refresh-cart';