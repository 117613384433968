import { BaseController } from "../core/base.controller";
import ProductCardComponent from "../components/product-card.component";
import CartService from "../services/cart.service";
import CatalogSidebarComponent from "../components/catalog-sidebar.component";
import ListViewSettingsComponent from "../components/list-view-settings";
import MultiProductSliderComponent from "../components/multi-product-slider.component";
import ReactDOM from "react-dom";
import React, { lazy, Suspense } from "react";
const CatalogSidebarDrawerComponent = lazy(()=>import("../components/catalog-sidebar-drawer.component"));

export class ProductsController extends BaseController {
    constructor(app) {
        super(app);
        this.cards = [];
        this.listView =false;
        this.productListCards = [];
        this.multiProductSliders = [];
        this.catalogSidebar = null;
        this.catalogSidebarTemplate = null;
        this.catalogSidebarDrawer = null;
        this.catalogSidebarDrawerGesture = null;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.cartService = this.app.cartService;
        this.viewportController = this.app.getController('viewport');
        this.loadingController = this.app.getController('loading');
        this.dialogController = this.app.getController('dialog');
        this.routerController = this.app.getController('router');
        this.searchController = this.app.getController('search');
        // if(sessionStorage.getItem('listView') && document.getElementsByClassName('modes_button').length){
        //     this.view(sessionStorage.getItem('listView'))
        // }
        this.update();

    }
    view (list){
        // if(!this.app.settings.isPartnerStore){
        //     sessionStorage.setItem('listView',list);
        // }
        const addRemove = list ? 'add' : 'remove';
        document.getElementsByClassName('changelist')[0].classList[addRemove]('list');
        document.getElementsByClassName('modes_button')[0].classList.remove('active');
        document.getElementsByClassName('modes_button')[1].classList.remove('active');
        document.getElementsByClassName('modes_button')[list ? 0 : 1].classList.add('active');
        var item=document.querySelectorAll('.partner-products__list-item .c-product-card');
        for (var i=0;i<item.length;i++){
            item[i].classList[addRemove]('c-product-card--shrink');
        } 
    }
    update(){
        
        if(document.getElementsByClassName('modes_button').length){
            document.getElementsByClassName('modes_button')[0].onclick=(element)=>{
                console.log('test');
                // console.error('test');
                this.view(true);
                //this.setComponentClass();
            };
         
                document.getElementsByClassName('modes_button')[1].onclick=(element)=>{
                    // console.error('test1');
                    this.view(false);
                    //this.setComponentClass();
                 };
        }
        
       
        this.app.findChildren('.c-product-card').then( elements => {
            let productCard;
            elements.forEach( element => {
                const json = element.getAttribute('data-load');
                if(!json)
                    this.cards.push(new ProductCardComponent(this.app, element));
            });
            this.cartChangeHandlerProxyListener = this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartServiceChangeHandler.bind(this));
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChildren('.c-multi-product-slider').then( elements => {
     
            elements.forEach( element => {
                const json = element.getAttribute('data-load');
                if(!json)
                this.multiProductSliders.push(new MultiProductSliderComponent(this.app, element));
            })
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChild('.c-catalog-sidebar').then( element => {
            let isRefreshDisabled = this.routerController.queryParams.get('q') && this.routerController.queryParams.get('q').length > 0 && this.searchController.searchService.searchType == 'readonly';
            this.catalogSidebarTemplate = element.outerHTML;
            this.catalogSidebar = new CatalogSidebarComponent(this.app, element, {isRefreshDisabled: isRefreshDisabled});
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChild('.c-list-view-settings').then( element => {
            this.listViewSettings = new ListViewSettingsComponent(this.app, element);
        }).catch( reason => this.handleSelectorError(reason));
    }

    cartServiceChangeHandler(e) {
        if(this.cards && this.cartService && this.cartService.cart && Array.isArray(this.cartService.cart.items)) {
            let relatedCartItem;
            this.cards.forEach( card => {
                if(card.product) relatedCartItem = this.cartService.cart.items.find( cartItem =>  card.product.sku == cartItem['sku'] );
                if(relatedCartItem) {
                    relatedCartItem.product = card.product;
                    card.setRelatedCartItem(relatedCartItem);
                }else{
                    card.setRelatedCartItem(null);
                }
            });
        }
    }

    openCatalogSidebar() {
        if(!this.catalogSidebar) return;
        this.catalogSidebarDrawerIsOpen = true;
        this.dialogController.drawer((container) => {
            this.catalogSidebarDrawer = ReactDOM.render(<Suspense fallback={null}><CatalogSidebarDrawerComponent app={this.app} html={this.catalogSidebarTemplate}/></Suspense>, container); 
            
            // this.catalogSidebarDrawerGesture = new Hammer(container);
            // this.catalogSidebarDrawerGesture.on('panleft', this.catalogSideBarGestureHandler.bind(this));

        }, {dialogRefId: 'catalogSideBarDrawer' }).catch( reason => this.catalogSidebarDrawerIsOpen = false);
    }

    closeCatalogSidebar() {
        this.catalogSidebarDrawerIsOpen = false;
        this.dialogController.dismiss(null, 'catalogSideBarDrawer');
        
        if(this.catalogSidebarDrawerGesture){
            this.catalogSidebarDrawerGesture.destroy();
            this.catalogSidebarDrawerGesture = null;
        }     

        if(this.catalogSidebarDrawer) {
            this.catalogSidebarDrawer = null;
        }
    }


    catalogSideBarGestureHandler(e) {
        if(e && e.velocityX <= -0.175) this.closeCatalogSidebar();
        
    }
}
ProductsController.EVENT_CLICK = "change-view";