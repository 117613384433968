import { BaseApiService } from "./base-api.service";
import CartService from "./cart.service";
import AccountService from "./account.service";
import { AuthorizenetService } from "./authorizenet.service";
import MageService from "./mage.service";
import React, { Component } from 'react';
//import { ApplePayService } from "./apple-pay.service";
import { PaypalService } from "./paypal.service";
import { StoreCreditService } from "./store-credit.service";
import CustomerService from "./customer.service";
import Cookies from "universal-cookie";

let cookies = new Cookies();
export default class CheckoutService extends BaseApiService {

    constructor(app, isCheckoutPage) {
        super(app);

        this._isCheckoutPage = isCheckoutPage;
        this._userInited = false;
        this.cart = null;
        this.cartId = null;

        this.currentShippingAddress;
        this.sameAsShipping = true;
        this.currentBillingAddress;
        this.currentPaymentMethod;
        this.currentCreditCard;
        this.currentBraintreeCard;
        this.currentShippingMethod;
        this.checkoutConfig;
        this.showplaceorder = false;
        this.availableAddressList = [];
        this.availableShippingMethods;
        this.availablePaymentMethods = null;
        this.availableCreditCardList = [];
        this.availableBraintreeCardList = [];
        this.availableRegions = [];
        this.stateOptionList = [];
        this.willSavedCreditCardList = [];
        this.willSavedAddressList = [];
        this.defaultZipCode = cookies.get("selectedPostalCode") ? cookies.get("selectedPostalCode") : null;
        this.isSaveCard = true;
        this.excludedStates = this.app.settings.excludedStates;
        this.delivery_notes = '';
        this.braintreeNonce = null;
        this.braintreePaypalData = null;
        this.shipsToPayload = null;
        this.upsAddressType = null;
        this.isCartORCheckoutPage = this.isCartORCheckoutPage.bind(this);
        this.isCartPage = this.isCartPage.bind(this);
        this.isCheckoutPage = this.isCheckoutPage.bind(this);
        
    }

    appOnInit() {
        if (super.appOnInit) super.appOnInit();
        this.cartService = this.app.cartService;
        this.accountService = this.app.accountService;

        this.mageService = this.app.mageService;
        this.partnerService = this.app.partnerService;
        this.i18nService = this.app.i18nService;
        this.gaService = this.app.gaService;
        this.gaService4 = this.app.gaService4;
        this.authorizenetService = new AuthorizenetService(this.app);
  //      this.applePayService = new ApplePayService(this.app);
        this.paypalService = new PaypalService(this.app);
        this.storeCreditService = new StoreCreditService(this.app);
        this.loadingController = this.app.getController('loading');
        this.dialogController = this.app.getController('dialog');
        this.routerController = this.app.getController('router');
        this.customerService = this.app.customerService;
        this.braintreeService = this.app.braintreeService;
        this.cart = this.cartService.cart || {};
        this.cartId = this.cartService.cartId;
        this.hasOnlyFreeShippingMethod = false;

        this.cartChangeHandlerProxyListener = this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartChangeHandler.bind(this));

        this.authorizenetService.setEnvironment(this.app.environment.authorizeNet);
        if (this.mageService.settings) {
            this.mageServiceReadyHandler();
        } else {
            this.mageService.addEventListener(MageService.EVENT_READY, this.mageServiceReadyHandler.bind(this));
        }

        if (this.accountService.user) {
            this.authenticationUserChangeHandler();
        } else {
            this.accountService.addEventListener(AccountService.EVENT_USER_CHANGE, this.authenticationUserChangeHandler.bind(this));
        }
        this.cartService.addEventListener(CartService.SET_PAYPAL_DATA, this.setPaypalData.bind(this));
        this.cartService.addEventListener(CartService.SET_APPLEPAY_DATA, this.setApplePayData.bind(this));

        this.customerService.addEventListener(CustomerService.EVENT_REFRESH, () => this.setAvailableAddressList(this.accountService.user.addresses, true));
    }
    setPaypalData() {
        // this.selectCurrentPaymentMethod({ code: 'paypal_express' }, { additional_data: this.cartService.paypalData });
        console.trace("this.cartService",this.cartService)
        this.selectCurrentPaymentMethod({code:"braintree_paypal"},{
            braintreePayapalNonceToken: this.cartService.paypalData.braintreePayapalNonceToken,
        });
    }
    setApplePayData(){
        if(cookies.get("applepay_nonce")){
            let additional_data = {
                payment_method_nonce: cookies.get("applepay_nonce"),
            };
            this.selectCurrentPaymentMethod({code:"braintree_applepay"},{
                additional_data,
                applePayNonce: additional_data.payment_method_nonce,
            });
        }
    }
    initUser() {
        let isCheckout = typeof this._isCheckoutPage === 'function' ? this._isCheckoutPage() : this._isCheckoutPage;

        if (!this.accountService.user || this._userInited || !isCheckout) return;
        this._userInited = true;
        this.setAvailableAddressList(this.accountService.user.addresses);
        // this.setAvailableCardList(this.accountService.user.cards);
        this.setAvailableBraintreeCardList(this.accountService.user.braintree_cards)
        // this.setSameAsShipping(this.accountService.user.default_billing ? this.accountService.user.default_billing == this.accountService.user.default_shipping);
    }

    mageServiceReadyHandler() {
        if (this.mageService.settings) {
            this.authorizenetService.setEnvironment(this.mageService.settings.authorizeNet);
            // this.applePayService.setEnvironment(this.mageService.settings.applePay);
            this.paypalService.setEnvironment(this.mageService.settings.paypal);
            this.braintreeService.setEnvironment(this.mageService.settings.paypal);
            this.storeCreditService.setEnvironment(this.mageService.settings.storeCredit);
            this.hasOnlyFreeShippingMethod = this.mageService.settings.cart.hide_other_shipping_methods;
            this.defaultZipCode = this.mageService.settings.cart.defaultZipCode || this.defaultZipCode;
        }
    }
    setFormAddress(address, isValid, billing,callFromAddressPopup) {
        console.log('00000',address)
        if (this[`add${billing ? 'billing' : 'shipping'}`] && address === this[`add${billing ? 'billing' : 'shipping'}`].address && isValid === this[`add${billing ? 'billing' : 'shipping'}`].isValid) return;
        console.trace('11111',this.addshipping);
        if (isValid) {
            console.log("0000 valid post code", address.postcode)
            if(callFromAddressPopup) this.cartService.setCookies(address.postcode)
            // if (!cookies.get("selectedPostalCode") || address.postcode !== cookies.get("selectedPostalCode")) {
            //     cookies.set("selectedPostalCode", address.postcode, {
            //         path: "/",
            //     });
            // }
            this.cartService.getCart();
        }
        if (address) {
            this[`add${billing ? 'billing' : 'shipping'}`] = { address, isValid };
            // if(!billing && isValid){
            //     this.setShippingInformationForPaypal().then(res=>{

            //     }); 
            // }

            // if(!billing&&isValid){
            //     console.error('success2');
            //     this.upsAddress(address).then(result=>{
            //         console.error('resultups',result);

            //         this.upsAddressData = result.response_data.candidate_address;
            //         this.dispatchEvent(CheckoutService.SHOW_UPS_POPUP);
            //     });
            // }
        }
        else
        this[`add${billing ? 'billing' : 'shipping'}`] = undefined;
        this.dispatchEvent(CheckoutService.SHOW_PLACEORDER_BUTTON);
    }
    getShippingAddressFromQuote() {
        let url = this.accountService.isGuestUser ? `/guest-carts/${this.cartId}/billing-address` : '/carts/mine/billing-address';
        return new Promise((resolve, reject) => {
            this.get(url).then(result => {
                resolve(result);
            });
        });
    }

    upsAddress(payload) {

        if (!payload) {
            const selectAddress = this.addshipping ? { ...this.addshipping.address } : { ...this.currentShippingAddress };
            payload = {
                "address_information": {
                    "street": [selectAddress.street, selectAddress.street1],
                    "city": selectAddress.city,
                    "region_code": selectAddress.region_code,
                    "country_code": "US",
                    "postcode": selectAddress.postcode
                }
            };
            this.selectedAddress = {...payload.address_information};
        }
        this.loadingController.showLoading('checkout');
        return new Promise((resolve, reject) => {
            const matchAddress = this.addshipping ? { ...this.addshipping.address } : { ...this.currentShippingAddress };

            fetch('/rest/V1/ups/address/validate', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(payload)
            }).then(res => res.json()).then(result => {
                if (result) {
                    if (result && result.response_data) {
                        if (result.response_data.address_indicator) {
                            const { candidate_address,address_type } = result.response_data;
                            let response_candidate_address={candidate_address};
                            if(address_type) response_candidate_address.address_type = address_type;
                          let placeorder= {placeorder:true};

                            switch (result.response_data.address_indicator) {
                                
                                case 'nocandidate': 
                                    resolve(placeorder);
                                    break;
                                case 'valid':
                                    try{

                                    
                                    let isNotSameAsUPS = false;
                                    if (candidate_address[0].region_code !== matchAddress.region_code) isNotSameAsUPS = true;
                                    if (candidate_address[0].postcode !== matchAddress.postcode) isNotSameAsUPS = true;
                                    if (_.upperCase(candidate_address[0].city) !== _.upperCase(matchAddress.city)) isNotSameAsUPS = true;
                                    if (_.upperCase(candidate_address[0].street[0]) !== _.upperCase(matchAddress.street)) isNotSameAsUPS = true;
                                    if (_.upperCase(candidate_address[0].street[1]) !== _.upperCase(matchAddress.street1)) isNotSameAsUPS = true;
                                    if(isNotSameAsUPS){
                                        resolve(response_candidate_address);
                                    }else{
                                        if(candidate_address?.length) resolve(response_candidate_address);
                                        else resolve(placeorder)
                                    }
                                }catch(ex){
                                    console.trace(ex);
                                }
                                        break;
                                case 'ambiguous': 
                                    resolve(response_candidate_address);
                                    break;
                            }

                        }
                        resolve(result);
                    }
                    resolve({placeorder:true});
                    //this.upsAddressData = result.response_data.candidate_address;
                    //this.dispatchEvent(CheckoutService.SHOW_UPS_POPUP);

                }

                this.loadingController.hideLoading('checkout');
            }).catch((err) => {
                reject(err);
                this.loadingController.hideLoading('checkout');
            })
        })



    }
    setCreditCardForm(newCard) {         
        console.log(newCard, 'newCard');

        this.newCard = newCard;
        if (newCard && newCard.isValid) {

            // this.selectCurrentCreditCard(newCard.creditCard);
        }
        if (newCard && newCard.creditCard && Object.keys(newCard.creditCard).length) {
            this.dispatchEvent(CheckoutService.VALIDATE_FORM);
        }
        this.dispatchEvent(CheckoutService.SHOW_PLACEORDER_BUTTON);

    }
    login() {
        return new Promise((resolve, reject) => {
            this.post(`/`, payload).then(result => {
                if (result) {
                    resolve(result);
                }
            }).catch(reason => {

            });
        });
    }
    setAvailableAddressList(addressList, refresh) {
        //     let changeshippingAddress = this.availableAddressList !== addressList;
        this.availableAddressList = addressList;
        console.error(this.availableAddressList, 'available');

        if (refresh) {
            this.selectCurrentShippingAddress(this.availableAddressList.find(address => address['default_shipping'] != null) || this.availableAddressList[0]);
            this.selectCurrentBillingAddress(this.availableAddressList.find(address => address['default_billing'] != null) || this.availableAddressList[0]);
        } else if (this.availableAddressList) {
            if (!this.currentShippingAddress) this.selectCurrentShippingAddress(this.availableAddressList.find(address => address['default_shipping'] != null) || this.availableAddressList[0],true);
            if (!this.currentBillingAddress) this.selectCurrentBillingAddress(this.availableAddressList.find(address => address['default_billing'] != null) || this.availableAddressList[0]);
        }
        this.dispatchEvent(CheckoutService.EVENT_AVAILABLE_ADDRESS_LIST_CHANGE);
    }

    setAvailableCardList(cards) {
        if (cards) {
            this.availableCreditCardList = cards.map(card => {
                return {
                    label: card.label,
                    cardNumber: null,
                    year: card.cc_exp_year && card.cc_exp_year.length == 4 ? card.cc_exp_year.substr(2, 3) : card.cc_exp_year,
                    month: card.cc_exp_month,
                    type: card.cc_type,
                    ...card
                }
            });
            if (this.availableCreditCardList) {
                if (!this.currentCreditCard) this.selectCurrentCreditCard(this.availableCreditCardList.find(card => card['default_card'] != null) || this.availableCreditCardList[0]);
            }
            this.dispatchEvent(CheckoutService.EVENT_AVAILABLE_CREDIT_CARD_LIST_CHANGE);
        } else {
            this.availableCreditCardList = null;
        }
    }

    setAvailableBraintreeCardList(braintree_cards) {
        if (braintree_cards.length) {
            this.availableBraintreeCardList = braintree_cards.map(card => {
                return {
                    label: card.label,
                    cardNumber: null,
                    year: card.cc_exp_year && card.cc_exp_year.length == 4 ? card.cc_exp_year.substr(2, 3) : card.cc_exp_year,
                    month: card.cc_exp_month,
                    type: card.cc_type,
                    ...card
                }
            });
            if (this.availableBraintreeCardList) {
                if (!this.currentBraintreeCard) this.selectCurrentBranitreeCard(this.availableBraintreeCardList.find(card => card['default_card'] != null) || this.availableBraintreeCardList[0]);
                // if (!this.currentBraintreeCard) {
                //     this.currentBraintreeCard = this.availableBraintreeCardList.find(card => card['default_card'] != null) || this.availableBraintreeCardList[0];
                // }
            }
            this.dispatchEvent(CheckoutService.EVENT_AVAILABLE_BRAINTREE_CARD_LIST_CHANGE);
        } else {
            this.availableBraintreeCardList = null;
        }
    }

    setAvailablePaymentMethods(paymentMethods) {
        this.availablePaymentMethods = [...paymentMethods];
        if (this.availablePaymentMethods) { // this is for selecting default selected-payment method
            const queryString = window.location.search;
            let defaultPaymentMethodCode = 'braintree';
            if ( queryString && queryString === "?paypal") {
                defaultPaymentMethodCode = 'braintree_paypal'
            }
            let defaultPaymentMethod = this.availablePaymentMethods?.find(method => method['code'] === defaultPaymentMethodCode)
            if (!this.currentPaymentMethod) this.selectCurrentPaymentMethod(defaultPaymentMethod)
        }
        this.dispatchEvent(CheckoutService.EVENT_AVAILABLE_PAYMENT_METHODS_CHANGE);
    }

    appendAddress(address, target) {
        if (!address) return;
        if (!this.availableAddressList) this.availableAddressList = [   ];
        let isExistAddress = this.availableAddressList.some(item => !item.id && item.uiID == address.uiID);

        if (isExistAddress) {
            this.availableAddressList = this.availableAddressList.map(item => {
                return item.uiID == address.uiID ? { ...item, ...address } : item;
            });
            if (target == 'shipping' || (target == 'billing' && (this.currentBillingAddress && this.currentShippingAddress) && (address.uiID == this.currentBillingAddress.uiID && address.uiID == this.currentShippingAddress.uiID))) this.selectCurrentShippingAddress(address);
        } else {
            this.availableAddressList.push(address);
            if (this.availableAddressList.length == 1) {
                this.selectCurrentShippingAddress(address);
                this.selectCurrentBillingAddress(address);
            } else {
                if (target == 'shipping') this.selectCurrentShippingAddress(address);
                if (target == 'billing') this.selectCurrentBillingAddress(address);
            }
        }

        this.dispatchEvent(CheckoutService.EVENT_AVAILABLE_ADDRESS_LIST_CHANGE);
    }
    
    appendCreditCard(creditCard) {
        if (!this.availableCreditCardList) this.availableCreditCardList = [];

        let isExistCreditCard = this.availableCreditCardList.some(item => (!item.id && (item.uiID == creditCard.uiID)));
        if (isExistCreditCard) {
            this.availableCreditCardList = this.availableCreditCardList.map(item => {
                return item.uiID == creditCard.uiID ? { ...item, ...creditCard } : item;
            });
        } else {
            this.availableCreditCardList.push(creditCard);
            this.selectCurrentCreditCard(creditCard);
        }

        this.willSavedCreditCardList = this.availableCreditCardList.filter(item => item.saveForLater === true);
        this.dispatchEvent(CheckoutService.EVENT_AVAILABLE_CREDIT_CARD_LIST_CHANGE);
    }

    async selectCurrentShippingAddress(address,isSetAvailableAddressList)  {
        if (this.currentShippingAddress == address) return false;
        if (!address || ( this.isCartPage() && cookies.get("selectedPostalCode") ) ) {
            address = {};
            address.postcode = cookies.get("selectedPostalCode") ? cookies.get("selectedPostalCode") : this.defaultZipCode;
            address.country_id = this.i18nService.currentLocale.country;
        }
        if (!address.email && this.accountService.user) address.email = this.accountService.user.email;
        if (!address.email && this.accountService.guestEmail) address.email = this.accountService.guestEmail;
        this.currentShippingAddress = address;

        if(this.availableAddressList && isSetAvailableAddressList){
            if( !this.isCheckoutPage() && cookies.get("selectedPostalCode") && cookies.get("selectedAdressType")) return
            
            await this.upsAddress().then((response) => {
                let addressType ;
                if (response.placeorder) {
                    addressType = "res";
                } else if (response.candidate_address) {
                    if(response.address_type) addressType = response.address_type;
                    else addressType = response.candidate_address[0].address_type;
                }
                this.shipsToPayload = {postcode:address?.postcode};
                this.cartService.setAddressTypeCookie(addressType === "none" ? "res" : addressType);
            }).catch(error => {
                 console.error("upsAddress Error : ",error);
            })  
        }

        if (this.sameAsShipping) this.selectCurrentBillingAddress(this.currentShippingAddress);
        this.dispatchEvent(CheckoutService.EVENT_SHIPPING_ADDRESS_CHANGE);

        this.getEstimatedShippingMethods();
    }

    selectCurrentBillingAddress(address) {

        if (this.currentBillingAddress == address) return;
        if (!address) {
            address = {};
            address.postcode = this.defaultZipCode;
            address.country_id = this.i18nService.currentLocale.country;
        }
        this.currentBillingAddress = address;
        this.dispatchEvent(CheckoutService.EVENT_BILLING_ADDRESS_CHANGE);
    }

    selectCurrentPaymentMethod(paymentMethod, params) {
        console.trace(paymentMethod,"selectCurrentPaymentMethod1",params)
        if (this.currentPaymentMethod == paymentMethod) return;

        if (paymentMethod?.code === 'paypal_express' || paymentMethod?.code === 'braintree_paypal' || paymentMethod?.code === 'braintree_applepay' || paymentMethod?.code === "msts_invoiceme") {
            this.dispatchEvent(CheckoutService.VALIDATE_FORM);
        }
        console.trace(paymentMethod,"selectCurrentPaymentMethod2",params)
        let newParams = params || {};
        if (paymentMethod?.code === 'braintree_applepay' && params) {
            console.log('set paypal data');
            this.applePayData = { ...params };
        }
        if (paymentMethod?.code === 'paypal_express' && params) {
            console.log('set paypal data');
            this.payPalData = { ...params };
        } else if (paymentMethod?.code === 'paypal_express' && this.payPalData && !params) {
            console.log('set paypal data1');
            newParams = { ...this.payPalData };
        } else if(paymentMethod?.code === 'braintree_paypal' && params ){
            this.braintreePaypalData = { ...params };
        }
        // if(paymentMethod && paymentMethod?.code === 'paypal_express' && this.paypalData && this.paypalData.additional_data){
        //     newParams.additional_data ={...this.paypalData.additional_data};

        // }
        // if(!this.paypalData && this.currentPaymentMethod && this.currentPaymentMethod?.code === 'paypal_express' && this.currentPaymentMethod.additional_data){
        //     this.paypalData = {...this.currentPaymentMethod};
        // }
        this.currentPaymentMethod = { ...paymentMethod, ...newParams };
        console.trace(this.currentPaymentMethod,"selectCurrentPaymentMethod4",newParams)
    
        this.dispatchEvent(CheckoutService.EVENT_PAYMENT_METHOD_CHANGE);
        if (paymentMethod?.code === "paypal_express" || paymentMethod?.code === "braintree_paypal" || paymentMethod?.code === "braintree_applepay" ) {
            this.dispatchEvent(CheckoutService.SHOW_PLACEORDER_BUTTON);
        }

    }

    selectCurrentPaymentMethodByCode(code, params, showPaypal) {
        const existPaymentMethod = this.availablePaymentMethods?.find(item => item.code == code);
        this.selectCurrentPaymentMethod(existPaymentMethod, params);

        if (showPaypal) {
            this.dispatchEvent()
        }
    }

    setPaymentAdditonalData(addtionalData, paymentMethod, forceToSelectPaymentMethod) {
        if (forceToSelectPaymentMethod) this.selectCurrentPaymentMethod(paymentMethod);
        if (!paymentMethod) paymentMethod = this.currentPaymentMethod;
        if (!paymentMethod) return;

        paymentMethod.additional_data = addtionalData;
        this.dispatchEvent(CheckoutService.EVENT_PAYMENT_ADDITIONAL_DATA_CHANGE);
    }
    saveCard(isSaveCard) {
        this.isSaveCard = isSaveCard;
    }
    selectCurrentCreditCard(card) {
        if (this.currentCreditCard == card) return;
        this.currentCreditCard = card;
        if (!this.currentPaymentMethod) this.selectCurrentPaymentMethod(this.getPaymentMethod('authnetcim'), { "code": "authnetcim", "title": "Credit Card" });
        if (this.currentCreditCard && this.currentPaymentMethod && this.currentPaymentMethod.code == "authnetcim") {
            this.currentPaymentMethod.rawData = this.currentCreditCard;
            if (this.currentCreditCard.hash) {
                this.setPaymentAdditonalData({
                    card_id: this.currentCreditCard.hash
                }, this.currentPaymentMethod);
            } else {
                this.setPaymentAdditonalData(this.currentCreditCard.additional_data, this.currentPaymentMethod);
            }
        }
        this.dispatchEvent(CheckoutService.EVENT_CREDIT_CARD_CHANGE);
    }

    selectCurrentBranitreeCard(card) {
        if (card && this.currentBraintreeCard == card) return;
        if(card) this.currentBraintreeCard = card;
        const queryString = window.location.search;
        if ( !card || !this.currentPaymentMethod) this.selectCurrentPaymentMethod(this.getPaymentMethod('braintree'), { "code": "braintree", "title": "Credit Card" });
        if (this.currentBraintreeCard && this.currentPaymentMethod && this.currentPaymentMethod.code == "braintree") {
            this.currentPaymentMethod.rawData = this.currentBraintreeCard;
            if (this.currentBraintreeCard.hash) {
                this.braintreeService.createBraintreeCardNonceByHash(this.currentBraintreeCard.hash)
                .then( response => {
                    this.setPaymentAdditonalData({
                        payment_method_nonce: response.createBraintreeCreditCardNonceTokenByHash.paymentMethodNoncetoken,
                        public_hash:this.currentBraintreeCard.hash,
                        currentBraintreeCard:this.currentBraintreeCard
                    }, this.currentPaymentMethod);
                })
            } else {
                this.setPaymentAdditonalData(this.currentBraintreeCard.additional_data, this.currentPaymentMethod);
            }
        }
        this.dispatchEvent(CheckoutService.EVENT_BRAINTREE_CARD_CHANGE);
    }

    selectCurrentShippingMethod(shippingMethod,clicked,selectedShippingMethod) {
        
        // console.trace('available shipping method',this.availableShippingMethods);
        if(this.cartService.multipleShippingVendors.length){
            let multipleShippingMethods = this.availableShippingMethods.filter(availableMethod=> availableMethod.carrier_code === "rbmultipleshipping");
            let nonMultipleMethods = [];
            let shippingMethodSplit = null;
            let selectedShippingMethodList = [];
                if(selectedShippingMethod || clicked){
                     let selectedMultipleShippingMethod = clicked ? this.selectedMultipleShipMethForClicked : selectedShippingMethod;
                     shippingMethodSplit=selectedMultipleShippingMethod.replace('rbmultipleshipping_','').split(':');
                     this.availableShippingMethods.map(availableMethod=> {
                         if(shippingMethodSplit.includes(`${availableMethod.carrier_code}_${availableMethod.method_code}`)){
                            selectedShippingMethodList.push(availableMethod.carrier_title);
                         }
                     })  
                }
                this.cartService.multipleShippingVendors.map(multipleShippingMethod=>{
                    nonMultipleMethods.push(this.availableShippingMethods.find(availableMethod=> {
                        if(availableMethod.carrier_title=== multipleShippingMethod){
                            if(clicked && shippingMethod.carrier_title === availableMethod.carrier_title && shippingMethod.method_code === availableMethod.method_code){
                                return true;
                            }else if(clicked && shippingMethod.carrier_title !== multipleShippingMethod){
                                return selectedShippingMethodList.includes(multipleShippingMethod) && shippingMethodSplit.includes(`${availableMethod.carrier_code}_${availableMethod.method_code}`);
                            }else if(!clicked && !selectedShippingMethod){
                                return true;
                            }else if(selectedShippingMethod){
                                if(multipleShippingMethod.length === shippingMethodSplit.length){
                                    return shippingMethodSplit.includes(`${availableMethod.carrier_code}_${availableMethod.method_code}`)
                                }else{
                                    if(selectedShippingMethodList.includes(multipleShippingMethod) && shippingMethodSplit.includes(`${availableMethod.carrier_code}_${availableMethod.method_code}`)){
                                        return true;
                                    }else if(!selectedShippingMethodList.includes(multipleShippingMethod)){
                                        return true;
                                    }else{
                                        return false;
                                    }
                                }
                            }

                        }else{
                            return false; 
                        }
                        }                        
                        ));
                })
            console.trace(nonMultipleMethods,'nonMultipleMethods')
           let selectMultipleShippingMethod =  multipleShippingMethods.find(multipleShippingMethodObj=>{
                const {method_code} = {...multipleShippingMethodObj};
                const codeArrays = method_code.split(':');
            
                console.trace(nonMultipleMethods,'nonMultipleMethods');
                let matchMulitpleMethods = nonMultipleMethods.filter(nonMultipleMethod=> {
                   return codeArrays.includes(`${nonMultipleMethod.carrier_code}_${nonMultipleMethod.method_code}`)
                });
                console.trace(codeArrays,matchMulitpleMethods,'selectMultipleShippingMethod');
                return codeArrays.length === matchMulitpleMethods.length;
            })
            this.currentShippingMethod = selectMultipleShippingMethod;
            this.selectedMultipleShipMethForClicked = "rbmultipleshipping_" + selectMultipleShippingMethod.method_code;
            this.cartService.selectedShippingMethod = "rbmultipleshipping_" + selectMultipleShippingMethod.method_code;
        }else if(selectedShippingMethod){
            this.currentShippingMethod = this.availableShippingMethods.find(availableMethod=>selectedShippingMethod === `${availableMethod.carrier_code}_${availableMethod.method_code}`);
        }else{
            this.currentShippingMethod = shippingMethod;
        }
        this.dispatchEvent(CheckoutService.EVENT_SHIPPING_METHOD_CHANGE);
        this.loadingController.showLoading('cart');
        this.setShippingInformation().then(result => {
            this.loadingController.hideLoading('cart');
        }).catch(reason => {
            this.loadingController.hideLoading('cart');
        });
    }

    setSameAsShipping(sameAsShipping = false) {
        this.sameAsShipping = sameAsShipping;
        if (this.sameAsShipping) this.selectCurrentBillingAddress(this.currentShippingAddress);
        this.dispatchEvent(CheckoutService.EVENT_SAME_AS_SHIPPING_CHANGE);
    }

    setAvailableShippingMethods(shippingMethods) {
        if (this.hasOnlyFreeShippingMethod) {
            console.log(shippingMethods, 'shippingMethods111');
            let filteredShippingMethods = shippingMethods.filter(s => s.method_code === 'freeshipping');
            if (filteredShippingMethods && filteredShippingMethods.length) {
                this.availableShippingMethods = filteredShippingMethods;
            } else {
                this.availableShippingMethods = shippingMethods;
            }
        } else {
            this.availableShippingMethods = shippingMethods;
        }

        this.dispatchEvent(CheckoutService.EVENT_AVAILABLE_SHIPPING_METHODS_CHANGE);

        if (this.availableShippingMethods && this.availableShippingMethods.length) {
            let currentShippingMethod = this.availableShippingMethods[0]
            if (this.currentShippingMethod) {
                let existShippingMethod = this.availableShippingMethods.find(item => item.method_code == this.currentShippingMethod.method_code);
                if (existShippingMethod) currentShippingMethod = existShippingMethod;
            }
                try{
                    this.selectCurrentShippingMethod(currentShippingMethod,null,this.cartService.selectedShippingMethod);

                }
                catch(ex){
                    console.trace(ex);
                }
        }
        if (!this.placeOrderaftershipping)
            this.loadingController.hideLoading('checkout');
    }

    setCheckoutConfig(data) {
        this.loadingController.hideLoading('checkout');
        this.checkoutConfig = { ...this.checkoutConfig, ...data };
        this.dispatchEvent(CheckoutService.CHECKOUT_CONFIG_CHANGE);
    }
    getStateNameByCode(code) {
        if(!code) return null;
        return this.stateOptionList.find(obj => code === obj.value).label;
    }
    getPaypalAuth(paypalData) {
        return new Promise((resolve, reject) => {
            // fetch('/rest/V1/checkout/returnfrompaypal', {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Accept": "application/json"
            //     },
            //     body: JSON.stringify(paypalData)
            // }).then(res => res.json()).then(result => {
            //     if (result) {
            //         //this.upsAddressData = result.response_data.candidate_address;
            //         //this.dispatchEvent(CheckoutService.SHOW_UPS_POPUP);
            //         console.error("getPaypalAuth",result);
            //         resolve(result);
            //     }

            // }).catch((err) => {
            //     reject(err);
            // })
            this.post(`/checkout/returnfrompaypal`, paypalData).then(result => {
                console.error("getPaypalAuth",result);
                resolve(result);
            }).catch(error => {
                reject(error);
            });
        });
    }
    getStates(countryCode = 'US') {
        return new Promise((resolve, reject) => {
            this.get(`${this.getEndpoint('directory')}/countries/${countryCode}`).then(result => {
                if (result && result.available_regions) {
                    let regions = [];
                    if (this.excludedStates && this.excludedStates.length) {
                        regions = result.available_regions.filter(i => !this.excludedStates.includes(i.code));
                    } else {
                        regions = result.available_regions;
                    }
                    this.availableRegions = regions;
                    this.stateOptionList = regions.map(item => {
                        return { value: item.code, label: item.name, id: item.id };
                    });
                    this.dispatchEvent(CheckoutService.GET_STATE);
                    resolve(regions);
                }
            }).catch(reason => {
                reject(reason);
            })
        });
    }
    isCartPage(){
        const path = window.location.pathname;
        return path === "/checkout/cart/" || path === "/checkout/cart";
    }
    isCheckoutPage(){
        const path = window.location.pathname;
        return path === "/checkout" || path === "/checkout/";
    }
    
    isCartORCheckoutPage(){
        if(this.isCheckoutPage() || this.isCartPage()) return true
        else return false
    }
    
    getEstimatedShippingMethods() {
        if(!this.isCartORCheckoutPage()) return
        // if(!this.app.getController('checkout')._isCheckoutPage) return;
        if (!this.placeOrderaftershipping)
            this.loadingController.showLoading('checkout');
            console.error(this.currentShippingAddress);

            
        if(this.shipsToPayload?.address_type){
            this.dispatchEvent(CheckoutService.EVENT_ZIP_CHANGE);
            this.loadingController.showLoading('wishlist');
        } 
        console.warn("this.currentShippingAddress>>",this.currentShippingAddress)
        if(!this.shipsToPayload?.postcode && this.currentShippingAddress.postcode === null) {
            this.loadingController.hideLoading('checkout');
            this.loadingController.hideLoading('wishlist');
            return
        }

        if (this.currentShippingAddress.hasOwnProperty("id") && this.currentShippingAddress.id) {
            this.getEstimatedShippingMethodsByAddressId().then(response => {
                this.setAvailableShippingMethods(response);
            }).catch(reason => {
                this.setAvailableShippingMethods(null);
                this.loadingController.hideLoading('checkout');
            });
        } else {
            this.getEstimatedShippingMethodsByAddress().then(response => {
                this.setAvailableShippingMethods(response);
            }).catch(reason => {
                console.log('setAvailableShippingMethods111', reason);

                this.setAvailableShippingMethods(null);
                this.loadingController.hideLoading('checkout');
            });
        }
    }

    getEstimatedShippingMethodsByAddress() {
        if (!this.currentShippingAddress) return;

        if(this.addshipping?.address) this.currentShippingAddress.postcode = this.addshipping?.address?.postcode;

        let shippingInformation = this.createAddressPayload(this.currentShippingAddress);
        let payload = {};
        // if(this.accountService.user && this.accountService.user.is_corporate_user ==='0' && this.partnerService.storeInfo){
        //     const shippingAddress = this.partnerService.storeInfo.store_addresses.find(store => store.is_current_active_store)
        //     payload = {
        //         address :{city: shippingAddress.city,
        //         company: "embitel",
        //         country_id:shippingAddress.country_id,
        //        // email: this.partnerService.storeInfo.email,
        //         firstname: shippingAddress.firstname,
        //         lastname: shippingAddress.lastname,
        //         postcode: shippingAddress.postcode,
        //         region_code: shippingAddress.region_code,
        //         telephone: shippingAddress.telephone,street:[shippingAddress.street]}
        //     }
        // }else{

        shippingInformation.extension_attributes = {
            "address_type": this.shipsToPayload?.address_type ? this.shipsToPayload.address_type : cookies.get("selectedAdressType") ? cookies.get("selectedAdressType") : "bus"
        }
        if(this.shipsToPayload?.postcode) shippingInformation.postcode = this.shipsToPayload.postcode;

        if(this.upsAddressType) shippingInformation.extension_attributes = {
            address_type:this.upsAddressType
        } 
        
        if(this.app.settings.isPartnerStore){
            shippingInformation.extension_attributes.address_type = "bus";
        }

        payload = {
            address: shippingInformation
        }
        //}


        return new Promise((resolve, reject) => {
            this.post(`${this.getEndpoint('carts')}/${this.cartService.getCartId()}/estimate-shipping-methods`, payload).then(result => {
                if (result) {
                    resolve(result);
                }
            }).catch(reason => this.handleError(reason, reject));
        });
    }

    getEstimatedShippingMethodsByAddressId(id) {
        if (!this.currentShippingAddress) return;
        let addressId;
        if(this.shipsToPayload?.postcode) {
            addressId = this.availableAddressList.find(address => address['postcode'] === this.shipsToPayload.postcode)?.id
        }
        let payload = {
            addressId: addressId ? addressId : this.currentShippingAddress.id,
            postCode: this.shipsToPayload?.postcode ? this.shipsToPayload?.postcode : this.currentShippingAddress.postcode,
            address_type: this.shipsToPayload?.address_type ? this.shipsToPayload.address_type : cookies.get("selectedAdressType") ? cookies.get("selectedAdressType") : "bus"
        };
        
        if(this.upsAddressType) payload.address_type = this.upsAddressType;

        if(this.app.settings.isPartnerStore){
            payload.address_type = "bus";
        }
        return new Promise((resolve, reject) => {
            this.post(`fsd/${this.getEndpoint('carts')}/${this.cartService.getCartId()}/estimate-shipping-methods-by-address-id`, payload).then(result => {
                if (result) {
                    resolve(result);
                }
            }).catch(reason => {
                if (this.app.environment.name === "development") {
                    resolve([{ "carrier_code": "tablerate", "method_code": "bestway", "carrier_title": "Table Rate", "method_title": "Table Rate", "amount": 1339, "base_amount": 1339, "available": true, "error_message": "", "price_excl_tax": 1339, "price_incl_tax": 1339 }]);
                } else {
                    this.handleError(reason, reject)
                }
            });
        });
    }
    setShippingInformationForPaypal() {


        const address = { postcode: this.defaultZipCode, country_id: this.i18nService.currentLocale.country };

        let shippingInformation = this.createAddressPayload(address);
        let billingInformation = this.createAddressPayload(this.currentBillingAddress);
        //billingInformation.saveInAddressBook = this.currentBillingAddress.hasOwnProperty("id") ? 0 : 1;   

        let payload = {
            addressInformation: {
                shippingAddress: shippingInformation,
                billingAddress: billingInformation,
                shippingCarrierCode: this.currentShippingMethod.carrier_code,
                shippingMethodCode: this.currentShippingMethod.method_code
            }
        }
        return new Promise((resolve, reject) => {
            this.post(`${this.getEndpoint('carts')}/${this.cartService.getCartId()}/shipping-information`, payload).then(result => {
                console.log(result, 'sadsadas');
                if (result) {
                    resolve(result);
                }
            })
        });
    }
    setShippingInformationForAddressForm(address) {
        let shippingInformation = this.createAddressPayload(address || this.addshipping.address);
        //  shippingInformation.saveInAddressBook = this.sameAsShipping ? 0 : (this.currentShippingAddress.hasOwnProperty("id") ? 0 : 1);


        let billingInformation = this.createAddressPayload(this.currentBillingAddress);
        //billingInformation.saveInAddressBook = this.currentBillingAddress.hasOwnProperty("id") ? 0 : 1;   

        let payload = {
            addressInformation: {
                shippingAddress: shippingInformation,
                billingAddress: billingInformation,
                shippingCarrierCode: this.currentShippingMethod.carrier_code,
                shippingMethodCode: this.currentShippingMethod.method_code
            }
        }
        return new Promise((resolve, reject) => {
            this.post(`${this.getEndpoint('carts')}/${this.cartService.getCartId()}/shipping-information`, payload).then(result => {
                if (result) {
                    if (result.payment_methods) {
                        let availablePaymentMethods = [];
                        let existPaymentMethod;
                        let afterpay = {};
                        result.payment_methods.forEach((item,index) => {
                            if(item.code === "afterpay"){
                                afterpay = {...this.getPaymentMethod(item.code, item)};
                            }else{
                                existPaymentMethod = this.getPaymentMethod(item.code, item);
                                if (existPaymentMethod) availablePaymentMethods.push(existPaymentMethod);
                            }
                        });
                        if(afterpay){
                            availablePaymentMethods.push(afterpay);
                        }
                        this.setAvailablePaymentMethods(availablePaymentMethods.length ? availablePaymentMethods : null);
                    }
                    if (result.totals) this.cartService.updateTotals(result.totals);
                    resolve(result);
                }
            }).catch(reason => {
                this.handleError(reason);
                reject(reason);
            });
        });
    }
    setShippingInformation() {
        console.log(this.currentShippingAddress, 'current shipping address');
        console.log(this.currentBillingAddress, 'current billing address');
        if (!this.currentShippingAddress || !this.currentBillingAddress) return;
        
        if(this.shipsToPayload?.postcode) this.currentShippingAddress.postcode = this.shipsToPayload.postcode;
        if(this.sameAsShipping && this.shipsToPayload?.postcode) this.currentBillingAddress.postcode = this.shipsToPayload.postcode;

        let shippingInformation = this.createAddressPayload(this.currentShippingAddress);
        shippingInformation.saveInAddressBook = this.sameAsShipping ? 0 : (this.currentShippingAddress.hasOwnProperty("id") ? 0 : 1);

        let billingInformation = this.createAddressPayload(this.currentBillingAddress);
        billingInformation.saveInAddressBook = this.currentBillingAddress.hasOwnProperty("id") ? 0 : 1;

        let payload = {
            addressInformation: {
                shippingAddress: shippingInformation,
                billingAddress: billingInformation,
                shippingCarrierCode: this.currentShippingMethod.carrier_code,
                shippingMethodCode: this.currentShippingMethod.method_code
            }
        }
        this.cartService.setCookies(this.currentShippingAddress.postcode)
        if(this.shipsToPayload?.address_type){
            this.cartService.setAddressTypeCookie(this.shipsToPayload.address_type)
            this.loadingController.hideLoading('wishlist');
        }
        this.shipsToPayload = null;
        // if (!cookies.get("selectedPostalCode") || this.currentShippingAddress.postcode !== cookies.get("selectedPostalCode")) {
        //     cookies.set("selectedPostalCode", this.currentShippingAddress.postcode, {
        //         path: "/",
        //     });
        // }
        //  this.cartService.getCart();

        console.log("cookie checkout selected zip code", cookies.get("selectedPostalCode"))

        return new Promise((resolve, reject) => {
            this.post(`${this.getEndpoint('carts')}/${this.cartService.getCartId()}/shipping-information`, payload).then(result => {
                if (result) {
                    this.updateAddress = false;
                    if (this.newCard && this.placeOrderaftershipping) {
                        this.newCard.submit();
                    }
                    else if (this.placeOrderaftershipping) {
                        this.placeOrder();
                    }
                    // let staticResult = {"payment_methods":[{"code":"authnetcim","title":"Credit Card"}, {"code":"paypal","title":"Paypal"}, {"code":"applepay","title":"Apple Pay"}]};
                    if (result.payment_methods) {
                        let availablePaymentMethods = [];
                        let existPaymentMethod;

                        let afterpay = {};
                        result.payment_methods.forEach((item,index) => {
                            if(item.code === "afterpay"){
                                afterpay = {...this.getPaymentMethod(item.code, item)};
                            }else{
                                existPaymentMethod = this.getPaymentMethod(item.code, item);
                                if (existPaymentMethod) availablePaymentMethods.push(existPaymentMethod);
                            }
                        });
                        if(afterpay){
                            availablePaymentMethods.push(afterpay);
                        }
                        this.setAvailablePaymentMethods(availablePaymentMethods.length ? availablePaymentMethods : null);
                    }
                    if (result.totals) this.cartService.updateTotals(result.totals);
                    resolve(result);
                }
            }).catch(reason => {
                this.handleError(reason);
                reject(reason);
            });
        });
    }

    cartChangeHandler() {
        let previousTotal = this.cart && this.cart.totals ? parseInt(this.cart.totals.grand_total) : 0;
        this.cart = this.cartService.cart;
        this.cartId = this.cartService.cartId;
        if (this.cart) {
            this.initUser();
            if (this.cartService.cart.totals.grand_total <= 0) this.selectCurrentPaymentMethod(this.getPaymentMethod('free'));
            console.trace('redirecttrue',this.cartService.cart.totals);
        let isCheckout = this.app.getController('checkout').isCheckoutPage;
                
            if(this.cartService.cart.totals.redirectTrue && isCheckout){
                window.location.href = this.app.environment.route.cart;
            }
            if (previousTotal != parseInt(this.cart.totals.grand_total) || 
            (parseFloat(this.cart.totals.customerbalance) < 0 && parseInt(this.cart.totals.grand_total) === 0 && !this.availableShippingMethods) ||
            (this.cart.totals.coupon_code && parseFloat(this.cart.totals.discount_amount) < 0 && parseInt(this.cart.totals.grand_total) === 0 && !this.availableShippingMethods)
            ) {
                if (this.currentShippingAddress) {
                    if (this.accountService.isGuestUser || this._userInited) this.getEstimatedShippingMethods();
                } else {
                    this.selectCurrentShippingAddress({
                        postcode: cookies.get("selectedPostalCode") ? cookies.get("selectedPostalCode") : this.currentShippingAddress && this.currentShippingAddress.postcode ? this.currentShippingAddress.postcode : this.defaultZipCode,
                        country_id: this.currentShippingAddress && this.currentShippingAddress.country_id ? this.currentShippingAddress.country_id : this.i18nService.currentLocale.country
                    });
                }
            }
        }
    }

    authenticationUserChangeHandler() {
        if (this.cart) {
            this.initUser();
        }
    }
    setGift(is_gift_order) {
        this.is_gift_order = is_gift_order;
    }
    dispatchCreditCard(cardData) {
        return new Promise((resolve, reject) => {
            let secureData = {
                authData: {
                    clientKey: this.authorizenetService.clientKey,
                    apiLoginID: this.authorizenetService.apiLoginID
                },
                cardData: cardData
            }
            //Todo: Remove mock data
            // if(!cardData) {
            //     cardData = {
            //         cardNumber: '1234123412341234',
            //         month: '12',
            //         year: '19',
            //         cardCode: '333',
            //         zip: '10033',
            //         fullName: 'Abdullah Karadeniz'
            //     }
            // }
            Accept.dispatchData(secureData, function (response) {
                console.error(response);

                if (response.messages.resultCode === "Error") {
                    var i = 0;
                    let errors = [];

                    if (response.messages.message && Array.isArray(response.messages.message)) {
                        while (i < response.messages.message.length) {
                            // errors.push(response.messages.message[i].code + ": " + response.messages.message[i].text);
                            errors.push(response.messages.message[i].text);
                            i = i + 1;
                        }
                    } else {
                        errors.push(response.messages.message);
                    }
                    reject(errors);
                } else {
                    resolve({ opaqueData: response.opaqueData, cardData: cardData });
                }
            });
        });
    }

    dispatchApplePay() {
        console.log("dispatch apple pay ", this.cartService.cart);
        this.applePayService.createSession({
            total: { label: 'Food Service Direct', amount: this.cartService.cart.totals.grand_total }
        });
    }
    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {

            return ['(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return phoneNumberString;
    }
    confirmPlaceOrder(signup) {
        this.loadingController.showLoading("checkout")
        this.signup = signup;
        this.dispatchEvent(CheckoutService.VALIDATE_FORM);
        if (this.addbilling && this.addbilling.isValid) {
            this.selectCurrentBillingAddress(this.addbilling.address);
        }
        if (this.addshipping && this.addshipping.isValid) {
            this.placeOrderaftershipping = true;
            this.currentShippingAddress = undefined;
            //if(this.selectCurrentShippingAddress(this.addshipping.address))
            this.selectCurrentShippingAddress(this.addshipping.address);

        } else if (this.newCard) {
            this.placeOrderaftershipping = true;
            this.newCard.submit();
        } else {

            this.placeOrder();
        }
    }
    braintreeSubmit(){
        let postalCode;
        if(this.addshipping) postalCode = this.addshipping.address.postcode;
        else postalCode = this.currentShippingAddress.postcode;
        this.braintreeService.getNonce({
            ...this.newCard.creditCard,
            postalCode
        })
        .then((response) => {
            this.braintreeNonce = response.creditCards[0].nonce;
            this.placeOrder();
        })
        .catch((err) => {
            this.loadingController.hideLoading("checkout");
            this.dialogController.notify({
                theme: "danger",
                text: err?.details?.originalError?.error?.message
            });
            console.error("getClientErr===>", err);
        });
    }
    setPONumber(po_number) {
        this.po_number = po_number;
    }
    setDeliveryNotes(delivery_notes) {
        this.delivery_notes = delivery_notes;
    }
    validateCaptcha(){
        return new Promise((resolve, reject) => {
            if(window.location.href.includes('gcaptcha')) resolve(true);
            else{
            window.grecaptcha
              .execute(this.app.settings.reCaptcha.siteKey, { action: "captchaForm" })
              .then((token) => {
                if (token && token.length > 0) {
                    this.grecaptchaToken = token;
                    resolve(this.grecaptchaToken);
                }
            })
            }
        });   
    }
    sendGiftService() {
        return new Promise((resolve, reject) => {
            if (!this.is_gift_order && !this.po_number && !this.delivery_notes) {
                resolve(true);
            } else {
                this.post(`order/additional-information`, {delivery_notes:this.delivery_notes , quote_id: this.cartId, is_gift_order: this.is_gift_order || 0, po_number: this.po_number }).then(result => {
                    resolve(result);
                }).catch(err => {
                    resolve(err);
                });
            }
        });
    }
    getMSTSTermsId() {
        return new Promise((resolve, reject) => {
            fetch('/v20180807/terms_and_conditions', { headers: { "Authorization": "Bearer 8b041906-da32-49f9-b4de-af28d7447b29" } }).then(res => {
                resolve(res);
            }).catch(err => reject(err));
        });
    }
    postMstsForm(payload) {
        return new Promise((resolve, reject) => {
            this.post('/msts/createbuyer', payload).then((res) => {
                if (res[0] && res[0].message) {
                    reject(res[0].message);

                } else {
                    resolve(res);
                }
            }).catch(err => reject(err))
        })
    }
    postMstsOrderId(payload) {
        return new Promise((resolve, reject) => {
            this.post('/msts/updateorderstatus', payload).then((res) => {
                resolve(res);
            })
        })
    }
    getMSTSClientId() {
        return new Promise((resolve, reject) => {
            this.get('/msts/generatereferenceid').then(res => {
                if (res[0].total_transacted_amount === false) {
                    delete res[0].total_transacted_amount;
                }
                if (res[0].first_transacted_date === false) {
                    delete res[0].first_transacted_date;
                }
                if (res[0].current_transaction_amount === false) {
                    delete res[0].current_transaction_amount;
                }

                resolve(res[0]);
            }).catch(error => reject(error));
        });
    }
    afterPayPlaceOrder(payload){
        let isFsdUrl = this.accountService.isGuestUser ? 'fsd/' : ''
        this.post(`${isFsdUrl+this.getEndpoint('carts')}/${this.cartService.getCartId()}/set-payment-information`, payload).then(result => {
        this.post('/afterpay/checkout',{
            "cartId": this.cartId, // lengthy string if guest user
            "redirectPath": {
                "confirmPath": "afterpay/payment/capture",
                "cancelPath": "afterpay/payment/capture"
            }
        }).then(res=>{
            window.location.href = res.afterpay_redirect_checkout_url;
            });
        });
    }
    checkCardExpiration(expirationMonth, expirationYear) {
        // Get the current date
        const currentDate = new Date();
        // Calculate the last day of the expiration month
        const lastDayOfExpirationMonth = new Date(expirationYear, expirationMonth, 0).getDate();
        // Set the expiration date to the last day of the month
        const expiration = new Date(expirationYear, expirationMonth - 1, lastDayOfExpirationMonth);
        // Compare the expiration date with the current date
        if (expiration < currentDate) {
          this.loadingController.hideLoading('checkout');
          this.dialogController.notify({
            theme: "danger",
            text: 'Card has expired. Please use a valid card.'
          });
          return true;
        }
        // Return true if the card is valid
        return false;
    }
      
    placeOrder(showDialog = true, signup) {
        this.placeOrderaftershipping = false;
        if (!this.currentBillingAddress) return;
        
        let billingInformation = this.createAddressPayload(this.currentBillingAddress);
        let shippingInformation = this.createAddressPayload(this.currentShippingAddress);
        billingInformation.saveInAddressBook = this.currentBillingAddress.hasOwnProperty("id") ? 0 : 1;
        billingInformation.email = billingInformation.email || shippingInformation.email;
        if (this.accountService.user) {
            billingInformation.customer_id = this.accountService.user.id;
        }
        
        let paymentMethod = {
            method: this.currentPaymentMethod.code,
            additional_data: this.currentPaymentMethod.additional_data
        }
        
        if(paymentMethod.method === "braintree"){
            console.error("this.braintreeNonce",this.braintreeNonce);
            if(this.accountService.user){
                if(this.newCard){ 
                    paymentMethod.additional_data = {
                      payment_method_nonce : this.braintreeNonce
                   }
                }else {
                    const {cc_exp_month,cc_exp_year} = paymentMethod.additional_data.currentBraintreeCard;
                    try {
                        let isExpiredCard = this.checkCardExpiration(cc_exp_month,cc_exp_year);
                        if(isExpiredCard) return false
                    } catch (error) {
                      console.error(error.message);
                    }
                    paymentMethod.method = "braintree_cc_vault";
                }
                // delete paymentMethod.additional_data.currentBraintreeCard;
                paymentMethod.additional_data = {
                    payment_method_nonce:paymentMethod.additional_data.payment_method_nonce,
                    public_hash: paymentMethod.additional_data.public_hash
                };
                paymentMethod.additional_data.is_active_payment_token_enabler = true;
            }else{
                paymentMethod.additional_data = {
                    payment_method_nonce : this.braintreeNonce
                }
            } 
        }
        if(paymentMethod.method === "braintree_paypal") paymentMethod.additional_data = {
                payment_method_nonce : this.currentPaymentMethod.braintreePayapalNonceToken
        }

        if (this.po_number && paymentMethod.method === "msts_invoiceme") {
            paymentMethod.additional_data = paymentMethod.additional_data || {};
            paymentMethod.additional_data.invoiceme_po_number = this.po_number;
        }
        if (paymentMethod.method === "authnetcim") {
            paymentMethod.additional_data = { ...paymentMethod.additional_data, save: this.accountService.isGuestUser ? false : this.isSaveCard };
        } else if (paymentMethod.method === "paypal_express" || paymentMethod.method === "braintree_paypal" || paymentMethod.method === 'braintree_applepay') {
            billingInformation = { ...billingInformation, ...this.createAddressPayload(this.currentShippingAddress) };
        }
        
        let payload = {
            paymentMethod: paymentMethod,
            email: billingInformation.email,
            billing_address: billingInformation,
            deviceInfo : this.app.deviceInfo
        }
        if(paymentMethod.method === "trevipay_magento"){
            payload.requestSource="frontend_checkout";
        }
        if(this.accountService.isGuestUser && Object.keys(this.signup)){
            payload = {...payload,...this.signup};
        }
        
        if(payload.paymentMethod?.additional_data) payload.paymentMethod.additional_data.device_data =  this.braintreeService.deviceData;

        this.loadingController.showLoading('checkout');
        this.sendGiftService().then(res => {
            return new Promise((resolve, reject) => {
                this.validateCaptcha().then(token=>{
                payload.googleReCaptchaClientToken = token;
                if(paymentMethod.method === "afterpay"){
                    this.afterPayPlaceOrder(payload);
                    return true;
                }
                else{
                this.post(`fsd-${this.getEndpoint('carts')}/${this.cartService.getCartId()}/payment-information`, payload).then(result => {
                    if(result[0] && result[0].status=="failed"){
                        this.dialogController.notify({
                            theme: 'danger',
                            text: result[0].message,
                            timeout:4000
                        }).then(result => { }).catch(reason => { });
                        if(result[0].urlPath){
                            window.location.reload();
                        }                    
                    }
                    else if (result[0]) {
                        
                        localStorage.removeItem('shipping-address');
                        this.cartService.clearApplePayCookie();
                        let params = { 'order_id': result[0].orderId };
                        // this.gaService4.purchase(result[0].ecommerce.purchase.products,result[0].ecommerce.purchase.actionField)
                        if (paymentMethod.method === "msts_invoiceme" && (this.accountService.user && !this.accountService.user.msts_im_status)) {
                            this.postMstsOrderId(params).then(res => {
                                window.location.href = this.app.environment.route.success;
                            })
                        }
                        // else if (Object.keys(this.signup).length) {
                        //     let user = {
                        //         'userInfo': {
                        //             ...this.signup,
                        //             order_id: result[0].orderId
                        //         }
                        //     }
                            // this.customerService.createUser(user).then(result => {
                            //     window.location.href = this.app.environment.route.success;
                            // }).catch(reason => {
                            //     window.location.href = this.app.environment.route.success;
                            // });
                        //} 
                        else {
                            window.location.href = this.app.environment.route.success;
                            //this.routerController.navigate(this.app.environment.route.success, {queryParams: params, preserveQueryParams: false});       
                        }
                    } 
                }).catch(reason => {
                    if(paymentMethod.method === "braintree_applepay"){
                        this.dispatchEvent(CheckoutService.SHOW_APPLEPAY_ERROR);
                    }
                    this.handleError(reason, reject, {
                        showDialog: showDialog,
                        name: "payment-information"
                    });
                });
                }
                });
            });
        });

    }

    handleError(error, reject, event) {
        this.loadingController.hideLoading('checkout');
        const showDialog = !event || event.showDialog;
        if (error && showDialog) {
            this.dialogController.notify({
                theme: 'danger',
                text: error ? error.message : "Unkonwn error, please try again"
            }).then(result => { }).catch(reason => { });
        }
        if (reject) reject(error);
        if (event) this.dispatchEvent({ type: CheckoutService.EVENT_ERROR, error: error, event: event });
    }

    // Helpers
    createAddressPayload(address) {
        let payload = {
            country_id: address.country_id || this.i18nService.currentLocale.country,
            postcode: address.postcode || this.defaultZipCode
        };
        if (this.updateAddress && address.id) payload.customer_address_id = address.id;
        if(address.postcode && address.id && !address.region_code){
            payload.customer_address_id = address.id;
            const getStateObject = this.stateOptionList.find(state=>this.app.validateService.isZipCode(address.postcode,state.value))
            if(getStateObject){
                payload.region_code = getStateObject.value;
            }
        }
        if (address.city) payload.city = address.city;
        if (address.company) payload.company = address.company;
        if (address.email) payload.email = address.email;
        if (address.firstname) payload.firstname = address.firstname;
        if (address.lastname) payload.lastname = address.lastname;
        if (address.region_code) payload.region_code = address.region_code;
        if (address.region) payload.region = address.region_code;
        if (address.street) payload.street = [address.street.replace("  "," ")];
        if (address.street1) payload.street = [...payload.street, address.street1.replace("  "," ")]
        if (address.telephone) payload.telephone = address.telephone;

        return payload;
    }

    getPaymentMethod(code, dynamicData) {
        return {
            free: {
                ...dynamicData,
                code: 'free',
                icon: 'assets/images/icon/payment-credit-card.svg',
                description: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'FREE', 'DESCRIPTION'], null, null, true),
                ctaLabel: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'FREE', 'CTA_LABEL'])
            },
            authnetcim: {
                ...dynamicData,
                code: 'authnetcim',
                icon: 'assets/images/icon/payment-credit-card.svg',
                description: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'AUTHNETCIM', 'DESCRIPTION'], null, null, true),
                ctaLabel: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'AUTHNETCIM', 'CTA_LABEL'])
            },
            paypal: {
                ...dynamicData,
                code: 'paypal',
                icon: 'assets/images/icon/payment-paypal.svg',
                description: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'PAYPAL', 'DESCRIPTION'], null, null, true),
                ctaLabel: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'PAYPAL', 'CTA_LABEL'])
            },
            msts_invoiceme: {
                ...dynamicData,
                code: 'msts_invoiceme'
            },
            trevipay_magento: {
                ...dynamicData,
                code: 'trevipay_magento'
            },
            afterpay: {
                ...dynamicData,
                code: 'afterpay'
            },
            paypal_express: {
                ...dynamicData,
                code: 'paypal_express',
                icon: 'assets/images/icon/payment-paypal.svg',
                description: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'PAYPAL', 'DESCRIPTION'], null, null, true),
                ctaLabel: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'PAYPAL', 'CTA_LABEL'])
            },
            applepay: {
                ...dynamicData,
                code: 'applepay',
                icon: 'assets/images/icon/payment-apple.svg',
                description: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'APPLE_PAY', 'DESCRIPTION'], null, null, true),
                ctaLabel: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'APPLE_PAY', 'CTA_LABEL'])
            },
            braintree: {
                ...dynamicData,
                code: 'braintree',
                icon: 'assets/images/icon/payment-credit-card.svg',
                description: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'BRAINTREE', 'DESCRIPTION'], null, null, true),
                ctaLabel: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'BRAINTREE', 'CTA_LABEL'])
            },
            braintree_paypal: {
                ...dynamicData,
                code: 'braintree_paypal',
                icon: 'assets/images/icon/payment-paypal.svg',
                description: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'PAYPAL', 'DESCRIPTION'], null, null, true),
                ctaLabel: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'PAYPAL', 'CTA_LABEL'])
            },
            braintree_applepay: {
                ...dynamicData,
                code: 'braintree_applepay',
                icon: 'assets/images/icon/payment-apple.svg',
                description: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'APPLE_PAY', 'DESCRIPTION'], null, null, true),
                ctaLabel: this.i18nService.translate(['CHECKOUT', 'PAYMENT_METHODS', 'APPLE_PAY', 'CTA_LABEL'])
            }
        }[code]
    }

    getFormattedAddress(address, uiKey = "address-") {
        if (!address) return address;

        let title = address.address_name || address.firstname + " " + address.lastname;
        if (address.company) title += ", " + address.company;

        let line1; let line2; let line3; let description;
        line1 = [];
        if (address.street) Array.isArray(address.street) ? line1.concat(address.street) : line1.push(address.street);

        line2 = [];
        if (address.postcode) line2.push(address.postcode);
        if (address.region_code) line2.push(address.region_code);
        if (address.city) line2.push(address.city);

        line3 = [];
        if (address.telephone) line3.push("Phone: " + address.telephone);

        description = [];
        if (line1) description.push(<li key={uiKey + address.uiKey + 'line-1'}>{line1.join(', ')}</li>);
        if (line2) description.push(<li key={uiKey + address.uiKey + 'line-2'}>{line2.join(', ')}</li>);
        if (line3) description.push(<li key={uiKey + address.uiKey + 'line-3'}>{line3.join(', ')}</li>);

        return { title: title, description: description }
    }

    getFormattedCreditCard(creditCard, uiKey = "credit-card-") {
        if (!creditCard) return creditCard;

        let title = creditCard.label || creditCard.fullName;

        let line1; let description;
        line1 = [];
        line1.push("Expires on: " + (creditCard.month || '--') + "/" + (creditCard.year || '--'));

        description = [];
        if (line1) description.push(<li key={uiKey + creditCard.uiKey + 'line-1'}>{line1.join(', ')}</li>);

        return { title: title, description: description }
    }

    getFormattedPaymentMethod(paymentMethod) {
        if (!paymentMethod || !paymentMethod.rawData) return null;

        let formattedPaymentMethod;
        switch (paymentMethod.code) {
            case "authnetcim":
                formattedPaymentMethod = this.getFormattedCreditCard(paymentMethod.rawData);
                break;
        }
        return formattedPaymentMethod;
    }

    getCreditCardType(key) {
        return {
            'AE': "amex",
            'american-express': 'amex',
            'VI': 'visa',
            'MC': 'mastercard',
            'DI': 'discover',
            'JCB': 'jcb',
            'SM': 'switch-maestro',
            'DN': 'dinersclub',
            'SO': 'solo',
            'MI': 'maestro',
            'MD': 'maestro',
            'OT': 'unknown'
        }[key] || key
    }

    emailCheckOnOtherFieldsHandler(){
        this.dispatchEvent(CheckoutService.EMAIL_CHECK_ON_OTHER_FIELDS);
    }
    customerTypeCheckOnOtherFieldsHandler(){
        this.dispatchEvent(CheckoutService.CUSTOMER_TYPE_CHECK_ON_OTHER_FIELDS);
    }
}

CheckoutService.EVENT_ERROR = 'error';
CheckoutService.EVENT_ZIP_CHANGE = 'zip-change';
CheckoutService.EVENT_CONFIG_CHANGE = 'config-change';
CheckoutService.EVENT_SHIPPING_ADDRESS_CHANGE = 'shipping-address-change';
CheckoutService.EVENT_BILLING_ADDRESS_CHANGE = 'billing-address-change';
CheckoutService.EVENT_SAME_AS_SHIPPING_CHANGE = 'same-as-shipping-change';
CheckoutService.EVENT_PAYMENT_METHOD_CHANGE = 'payment-method-change';
CheckoutService.EVENT_PAYMENT_ADDITIONAL_DATA_CHANGE = "payment-addtional-data-change";
CheckoutService.EVENT_CREDIT_CARD_CHANGE = 'credit-card-change';
CheckoutService.EVENT_BRAINTREE_CARD_CHANGE = 'braintree-card-change';
CheckoutService.EVENT_SHIPPING_METHOD_CHANGE = 'shipping-method-change';
CheckoutService.EVENT_AVAILABLE_SHIPPING_METHODS_CHANGE = 'available-shipping-methods-change';
CheckoutService.EVENT_AVAILABLE_PAYMENT_METHODS_CHANGE = 'available-payment-methods-change';
CheckoutService.EVENT_AVAILABLE_ADDRESS_LIST_CHANGE = 'available-address-list-change';
CheckoutService.EVENT_AVAILABLE_CREDIT_CARD_LIST_CHANGE = 'available-credit-card-list-change';
CheckoutService.EVENT_AVAILABLE_BRAINTREE_CARD_LIST_CHANGE = 'available-braintree-list-change';
CheckoutService.EVENT_AVAILABLE_REGION_LIST_CHANGE = 'available-region-list-change';
CheckoutService.CHECKOUT_CONFIG_CHANGE = 'checkout-config-change';
CheckoutService.GET_STATE = 'get-state';
CheckoutService.SHOW_PLACEORDER_BUTTON = 'show-place-order';
CheckoutService.SHOW_UPS_POPUP = "show-ups-popup";
CheckoutService.SET_PAYPAL_DATA = 'set-paypal-data';
CheckoutService.VALIDATE_FORM = 'validate-form';
CheckoutService.ADDRESS_UPDATE = 'ups-address-update';
CheckoutService.SHOW_APPLEPAY_ERROR = "show-applepay-error";
CheckoutService.EMAIL_CHECK_ON_OTHER_FIELDS = "email-check-on-other-fields";
CheckoutService.CUSTOMER_TYPE_CHECK_ON_OTHER_FIELDS = "customer-type-check-on-other-fields";
