import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';
import { LayoutController } from '../controllers/layout.controller';
import { DomComponent } from "../core/dom.component";
import ReactDOM from "react-dom";

export default class PillGroupComponent extends DomComponent {
  constructor(app, element) {
    super(app, element);
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.component = ReactDOM.render(<PillGroup app={this.app} />, this.domElement);
  }
}

PillGroupComponent.decorator = {
  selector: 'fsd-pill-group'
}

export class PillGroup extends BaseReactComponent {
  constructor(props, context) {
    super(props, context);

    this.i18nScopeKey = 'PILL_GROUP';

    this.renderStore = {
      pills: null,
      showMoreButton: null,
      clearFiltersButton: null,
      productsLabel: null
    }

    this.state = {
      pills: null,
      showMore: false
    }

  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.categoryService = this.app.categoryService;

    this.pillShowLimit = 3;
  }

  componentDidMount() {
    this.setPills(this.props.pills, this.props.pageInfo);
  }

  render() {

    if (this.state.pills) {

      this.renderStore.pills = this.state.pills.map((item, index) => {
        if (this.state.showMore || index < this.pillShowLimit) {
          return <li key={index} className="c-pill-group__item">
            <button className="c-pill-button" onClick={this.removeFilterHandler.bind(this, item)}>
              <div className="c-pill-button__container">
                <div className="c-pill-button__caption">
                  <div className="c-pill-button__caption-title">{item.title}</div>
                  <div className="c-pill-button__caption-info">{item.label}</div>
                </div>
                <div className="c-pill-button__close"><i className="icon__fsd icon__fsd--close-white"></i></div>
              </div>
            </button>
          </li>
        }
      });

      if (this.state.pills.length > this.pillShowLimit) {
        this.renderStore.showMoreButton = <li onClick={this.showHideMoreHandler.bind(this)} className="c-pill-group__item c-pill-group__item--show-more">
          <span>{this.state.showMore ? this.i18nService.translate([this.i18nScopeKey, "MORE_BUTTON_LESS_TITLE"]) : `+${this.state.pills.length - this.pillShowLimit} ${this.i18nService.translate([this.i18nScopeKey, "MORE_BUTTON_MORE_TITLE"])}`}</span>
        </li>
      }

      if (this.state.pills.length > 0) {
        this.renderStore.clearFiltersButton = <li className="c-pill-group__item c-pill-group__item--action">
          <button onClick={this.clearAllClickHandler.bind(this)} className="c-button c-button--link">{this.i18nService.translate([this.i18nScopeKey, "CLEAR_ALL_FILTERS_TEXT"])}</button>
        </li>

        if(this.state.pageInfo) {
          this.renderStore.productsLabel = <li className="c-pill-group__item c-pill-group__item--info">
            <span dangerouslySetInnerHTML={{__html:this.i18nService.translate([this.i18nScopeKey, 'SHOWING_LABEL_TEXT'], { currentProductsCount: this.state.pageInfo.currentProductsCount, totalProductsCount: this.state.pageInfo.totalProductsCount })}}></span>
          </li>
        }        
      }
    }

    return (
      <div className="c-pill-group">
        <div className="c-pill-group__container">
          <ul className="c-pill-group__items">
            {this.renderStore.pills}
            {this.renderStore.showMoreButton}
            {this.renderStore.clearFiltersButton}
            {this.renderStore.productsLabel}
          </ul>
        </div>
      </div>
    )
  }

  removeFilterHandler(item) {
    this.categoryService.removeFilter(item);
  }

  clearAllClickHandler() {
    this.categoryService.removeAllFilters();
  }

  showHideMoreHandler() {
    this.setState({
      showMore: !this.state.showMore
    });
  }

  setPills(pills, pageInfo) {
    this.setState({
      pills: pills,
      pageInfo: pageInfo
    })
  }

}