import BaseService from "../core/base.service";

export class AuthorizenetService extends BaseService {

    constructor(app) {
        super(app);

        this.acceptjs = null;
        this.clientKey = null;
        this.apiLoginID = null;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.host = this.app.environment.api.host;
        this.accountService = this.app.accountService;
    }

    setEnvironment(env) {
        if(env) {
            if(env.acceptjs) this.acceptjs = env.acceptjs;
            if(env.clientKey) this.clientKey = env.clientKey;
            if(env.apiLoginID) this.apiLoginID = env.apiLoginID;
        }
    }
    
    post(url, data) {
        return this.http.post(url, data);
    }

    getEndpoint(key) {
        let endpoint = this.app.environment.endpoint[key];
        if(!endpoint) return null;
        return endpoint;
    }
}