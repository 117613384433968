import { BaseController } from "../core/base.controller";
import AccountInformationFormComponent from "../components/account-information-form.component";
import TaxExemptionFileComponent from "../components/tax-exemption-file";
import OrderDetailComponent from "../components/order-detail";

import ReactDOM from 'react-dom';
import React from 'react';
import { Suspense } from "react";
const  OrderStatusPage = React.lazy(()=>import("../components/order-status"));
export class AccountController extends BaseController {

    constructor(app) {
        super(app);
        this.accountInformationForm;
        this.taxExemptionFile;
        this.orderList = [];
        this.orderStatus;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.accountService = this.app.accountService;
        this.customerService = this.app.customerService;
        this.dialogController = this.app.getController('dialog');
        this.init();
    }

    init() {
        this.app.findChild('.p-account .c-account-information-form').then( element => {
            this.accountInformationForm = new AccountInformationFormComponent(this.app, element);
        }).catch( error => this.handleSelectorError(error));

        this.app.findChild('.p-account .c-tax-exemption-file').then( element => {
            this.taxExemptionFile = new TaxExemptionFileComponent(this.app, element);
        }).catch( error => this.handleSelectorError(error));

        this.app.findChildren('.p-account .p-account__orders-list-item .c-order-detail').then( elements => {
            let orderDetail;
            this.orderList = [];
            elements.forEach( element => {
                orderDetail = new OrderDetailComponent(this.app, element);
                this.orderList.push(orderDetail);
            });
        }).catch( error => this.handleSelectorError(error));

        this.app.findChildren('.p-account .c-credit-card-add-select [href="#removeCreditCard"]').then( buttons => {
            buttons.forEach(button => {
                if(button.getAttribute("hash")){
                    button.onclick = this.removeCreditCard.bind(this, button.getAttribute("hash"));
                }                
            });
        }).catch( error => this.handleSelectorError(error));
        this.app.findChild('#order-status').then(element => {
            ReactDOM.render(
               <Suspense fallback={null}> 
                    <OrderStatusPage app={this.app}/>
               </Suspense>,element);
        }).catch( error => this.handleSelectorError(error));
    }

    removeCreditCard(hash){
        this.customerService.removeCreditCard(hash).then(response => {
            // console.trace("1response",response);
            if(response?.status === false) {
                this.dialogController.notify({
                    theme: "danger",
                    text: response?.message
                });
                return true
            }
            location.reload(true);              
        });
    }

}