import React from "react";
import BaseReactComponent from "../core/base-react.component";
import braintree from "braintree-web";
import BraintreeService from "../services/braintree.service";

export const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
};
import Cookies from "universal-cookie";

let cookies = new Cookies();
export default class BraintreeApplepayButton extends BaseReactComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      applePayInstance: null,
    };

    this.startPayment = this.startPayment.bind(this);
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.checkoutController = this.app.getController("checkout");
    this.checkoutService = this.checkoutController.checkoutService;
    this.dialogController = this.app.getController("dialog");
    this.i18nService = this.app.i18nService;
    this.cartService = this.app.cartService;
    this.braintreeService = this.app.braintreeService;
    this.braintreeService.addEventListener(
      BraintreeService.EVENT_SETUP_APPLEPAY,
      this.setUpApplePay.bind(this)
    );
  }

  componentDidMount() {
    appendScript("https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js");
    appendScript("https://js.braintreegateway.com/web/3.94.0/js/client.min.js");
    this.braintreeService.getApplepayClientToken();

    if (!window.ApplePaySession) {
      console.error("Device doest not support apple pay");
    } else console.error("Device supports apple pay");

    const applePayButton = document.getElementById("applePayButton");
    applePayButton.addEventListener("click", this.startPayment);
  }

  componentWillUnmount() {
    const applePayButton = document.getElementById("applePayButton");
    applePayButton.removeEventListener("click", this.startPayment);
  }

  setUpApplePay() {
    braintree.client.create(
      {
        authorization: this.braintreeService.applepayClientToken,
      },
      this.clientDidCreate.bind(this)
    );
  }

  clientDidCreate(err, clientInstance) {
    if (err) {
      console.error(err);
      return;
    }
    // continue with Braintree setup
    console.error("cc clientInstance", clientInstance);
    braintree.applePay.create(
      {
        client: clientInstance,
        displayName: "Your Company Name",
        paymentRequest: {
          total: {
            label: "Your Company Name",
            amount: "10.00",
          },
        },
      },
      this.applePayDidCreate.bind(this)
    );
  }

  applePayDidCreate(err, applePayInstance) {
    if (err) {
      console.error(err);
      return;
    }
    // continue with Apple Pay setup
    console.error("cc applePayInstance", applePayInstance);
    this.setState({ applePayInstance });
  }

  startApplePaySession(applePayInstance) {
    const { totals } = this.props.cart;
    let applepaytotals;
    if (this.checkoutService?.cart?.totals?.customerbalance) {
      applepaytotals = {
        ...totals,
        grand_total:
          totals.grand_total > 0
            ? totals.grand_total
            : totals.grand_total + Math.abs(this.checkoutService?.cart?.totals?.customerbalance),
      };
    }
    applepaytotals = this.checkoutService?.cart?.totals?.customerbalance
      ? applepaytotals
      : totals;
    let amount = this.cartService.setGrandTotal(applepaytotals);

    console.trace("amount>>>", amount);

    let paymentRequest = applePayInstance.createPaymentRequest({
      total: {
        label: "FoodServiceDirect",
        amount,
      },
      // We recommend collecting billing address information, at minimum
      // billing postal code, and passing that billing postal code with
      // all Apple Pay transactions as a best practice.
      requiredBillingContactFields: ["postalAddress"],
    });
    const session = new ApplePaySession(3, paymentRequest);
    // Handle Apple Pay session events
    session.onvalidatemerchant = (event) => {
      console.trace("onvalidatemerchantEvent", event);
      // Handle merchant validation
      const validationURL = event.validationURL;
      // Perform server-side validation with the validationURL
      applePayInstance.performValidation(
        {
          validationURL: validationURL,
          displayName: "FoodServiceDirect",
        },
        function (validationErr, validationData) {
          if (validationErr) {
            console.error(validationErr);
            session.abort();
            return;
          }
          console.trace("validationData", validationData);
          session.completeMerchantValidation(validationData);
        }
      );

      // Once the validation is complete, call completeMerchantValidation
      // session.completeMerchantValidation(/* completeMerchantValidationResult */);
    };

    session.onpaymentauthorized = (event) => {
      console.trace("onpaymentauthorizedEvent", event);
      // Handle payment authorization
      const payment = event.payment;
      // Send payment.token.paymentData to your server for transaction processing
      applePayInstance.tokenize(
        {
          token: event.payment.token,
        },
        (tokenizeErr, tokenizedPayload) => {
          if (tokenizeErr) {
            session.completePayment(ApplePaySession.STATUS_FAILURE);
            return;
          }
          // Send the tokenizedPayload to your server here!

          console.trace(
            "tokenizedPayload",
            tokenizedPayload,
            tokenizedPayload.nonce
          );
          // Once the transaction is complete, call completePayment
          // to close the Apple Pay sheet
          session.completePayment(ApplePaySession.STATUS_SUCCESS);

          if (this.props.cartDrawer) {
            cookies.set("applepay_nonce", tokenizedPayload.nonce, {
              path: "/",
            });
            if (this.checkoutService?.cart?.totals?.customerbalance && totals?.grand_total === 0) {
              this.cartService.removeStoreCredit(true);
            }
            window.location.href = this.app.environment.route.checkout;
          } else {
            let additional_data = {
              payment_method_nonce: tokenizedPayload.nonce,
            };
            this.checkoutService.selectCurrentPaymentMethodByCode(
              "braintree_applepay",
              {
                additional_data,
                applePayNonce: additional_data.payment_method_nonce,
              }
            );
          }
        }
      );
    };
    // Begin the Apple Pay session
    session.begin();
  }

  startPayment() {
    if (!this.props.cartDrawer && this.checkoutService.addshipping) {
      if (!this.checkoutService.addshipping.isValid) {
        this.dialogController.notify({
          theme: "danger",
          text: "Invalid Address",
        });
        return null;
      }
    }
    this.startApplePaySession(this.state.applePayInstance);
  }

  render() {
    return (
      <>
        <div>
          Continue to your ApplePay account. You’ll still be able to review your
          order before it is submitted.
        </div>
        <div
          className={
            this.props.cartDrawer
              ? "applepay_cartdrawer_container"
              : "applepay_container"
          }
        >
          <apple-pay-button
            buttonstyle="black"
            type="buy"
            id="applePayButton"
          ></apple-pay-button>
        </div>
      </>
    );
  }
}
