import BaseReactComponent from '../core/base-react.component';
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import NumberStepper from './number-stepper-2.component';
import { LoadingController } from '../controllers/loading.controller';

export default class CartItemTable extends BaseReactComponent {

    constructor(props, context) {
        super(props, context);
        this.i18nScopeKey = "CART_ITEM_TABLE";

        this.renderStore = {
            savedDataBoxList: null,
            savedDataBoxClass: null
        }

        this.quantityOptions = this.setOptions();
        this.state = {
            loading: null,
        }
    }

    appOnInit() {
        if (super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
        this.wishListService = this.app.wishlistService;
        this.dialogController = this.app.getController('dialog');
        this.cartService = this.app.cartService;
        this.loadingController = this.app.getController('loading');
    }

    loadingChangeHandler() {
        if (this.state.loading && !this.loadingController.getLoadingStatus('remove-cart')) {
            this.setState({ loading: null });
        }
    }
    displayOption(options) {
        if (!options) return null;
        options = JSON.parse(`${options}`);
        if (options.length)
            return options.map((option, k) => option.label === "Sold By" ? null : <div className="mb-2" key={k}>{option.label} - {option.value}</div>)
        return null;
    }
    componentDidMount() {
        this.loadingController.addEventListener(LoadingController.EVENT_CHANGE, this.loadingChangeHandler.bind(this));
        let error = false;
        if (!this.props.saveforLater && this.props.items && this.props.items.length) {
            this.props.items.map(({ qty, available_stock_qty, is_in_stock }) => {
                if (is_in_stock && qty > available_stock_qty) {
                    error = true;
                }
            })
        }
        if (error) {
            this.dialogController.notify({
                theme: 'danger',
                text: "Some of your item(s) doesn't have requested quantity, please reduce quantity requested.",
                timeout: 5000

            }).then(result => { }).catch(reason => { });
        }
    }
    render() {
        const { saveforLater, isGuestUser,missingProduct ,cartItems} = this.props;
        if (!this.i18nService) return null;
        if (this.props.items) {
            this.renderStore.savedDataBoxList = this.props.items.map((item) => {
                if(missingProduct){
                    if(!item.Qty) item.Qty = 1;
                    var currentItem = cartItems?.find(({ sku }) => sku === item.sku);
                    var isAdded = currentItem?.sku;
                    // console.error(currentItem,isAdded, "missingProduct",cartItems)
                }
                console.log(item, "AAAAA")
                let error = false;
                if (!saveforLater && !item.is_in_stock) {
                    localStorage.setItem('removeoos', true);
                }
                if (item.is_in_stock && !saveforLater && item.qty > item.available_stock_qty) {
                    error = true;
                }
                const actions = <Fragment>
                    <a className={`c-cart-item__product-action ${item.sku === this.state.loading ? 'loading' : ''} ${saveforLater ? '' : 'text-danger'}`}
                        onClick={e => this.removeItemHandler(e, item)} href="javascript:void(0)">{saveforLater ? 'Remove from list'
                            :
                            <>
                                {/* <span className="c-cart-item__remove">{this.i18nService.translate([this.i18nScopeKey, 'REMOVE_LABEL'])}</span> */}
                                <span className="c-cart-item__remove"></span>

                                {/* <span className="c-cart-item__remove--processing"></span> */}
                            </>
                        }
                    </a>
                    {/* {!saveforLater && !isGuestUser && <a className="c-cart-item__product-action" onClick={e => this.saveforLater(e, item)} href="javascript:void(0)">{this.i18nService.translate([this.i18nScopeKey, 'SAVE_FOR_LATER'])}</a>} */}
                </Fragment>
                const isDiscounted = parseFloat(item.discount_amount) > 0;
                const uniqueKey = `cart-item-${item.item_id}`;
                this.renderStore.savedDataBoxClass = classNames(
                    'c-cart-item',
                    {
                        'c-cart-item c-cart-item--has-discount': isDiscounted,
                        'c-wishlist': saveforLater,
                        'c-cart-item--is_out-stock': saveforLater ? false : !item.is_in_stock ? true : false
                    }
                );

                return <div key={uniqueKey} className={this.renderStore.savedDataBoxClass}>
                    <div className="c-cart-item__container">
                        <div className="c-cart-item__content">
                            <div className="c-cart-item__avatar">
                                <img src={item.image} alt={item.name} />
                            </div>

                            {item.shipping_type &&
                                <div class="c-cart-item_free-shipping">
                                    <img alt="free-shipping" src={this.app.settings.baseUrl + "assets/images/bg/free_shipping.png"} />
                                </div>
                            }
                            <div className="c-cart-item__bar">
                                <div className="c-cart-item__product">
                                    <div className="c-cart-item__product-title">
                                        <a href={item.url} className="c-cart-item__item-title">{item.name}</a>
                                    </div>
                                    {item.sku  &&
                                        <div className="c-cart-item__product-shipping-info d-flex align-items-center">
                                            {/* {item.ships_in &&
                                                <span className="c-cart-item__item-ship-info ">{item.ships_in}</span>} */}
                                            {item.sku && <span className="c-cart-item__item-sku-info ">FSD SKU ID: #{item.sku}</span>}
                                            {/* {item.shipping_temp === 'Frozen' || item.shipping_temp === 'Refrigerated' ?
                                                <i
                                                    class={`icon__fsd icon__fsd--ships-frozen-no-border ${item.shipping_temp === 'Refrigerated' ? 'c-cart-item__refrigerated' : ''} c-cart-item__forzen c-cart-item__forzen-mobile`}></i>
                                                : ''} */}
                                        </div> }
                                    {item.delivery_days_data  ? 
                                    <p className="c-cart-item__product-deliverydates-info mt-2">
                                        {this.cartService.displayDeliveryDates(item.delivery_days_data)}
                                    </p>: 
                                    (item.stock_type === "Stocked" || item.stock_type === "On Request" || item.stock_type ===  "Special Order") && <div class="c-product-card__ship-info-text mt-3" style={{fontSize:"13px"}}>
                                        Ready to ship within <span class="c-product-card__ship-info-day" 
                                        style={{color:item.stock_type ===  "Special Order" ? "#ff7324" :""}}
                                        >{this.cartService.getShipInDays(item.ships_in_days)}</span>
                                    </div>
                                    }
                                    {/* {item.stock_type === "Special Order" &&
                                        <div className="c-cart-item__product-special-order-shipin">
                                          Ready to ship in {this.cartService.getShipInDays(item.ships_in_days , item.ships_in)}
                                        </div> 
                                    }     */}
                                    <div className="mt-2 save-list-sold-txt">{item.options ? this.displayOption(item.options) : item.vendor_name ? `Sold By - ${item.vendor_name}` : ''}</div>
                                    {/* Shipping and frozen block */}
                                    <div className = "icon__fsd-container">
                                         {/* ${item.shipping_temp === 'Refrigerated' ? 'c-cart-item__refrigerated' : ''} */}
                                        <div class={` ${item.shipping_temp === 'Frozen' || item.shipping_temp === 'Refrigerated' || item.shipping_temp === 'Ships Refrigerated (May - September)' ? 'c-cart-item__forzen-show' : 'c-cart-item__forzen-hide'}`}>
                                            <i className="icon__fsd icon__fsd--ships-frozen-no-border c-cart-item__forzen"></i>
                                            <span>{item.shipping_temp === 'Frozen' ? 'Ships Frozen' : item.shipping_temp === 'Ships Refrigerated (May - September)' ? item.shipping_temp : 'Ships Refrigerated'  }</span>
                                        </div>
                                            
                                        {/* <i class={`icon__fsd icon__fsd--ships-frozen-no-border c-cart-item__forzen ${item.shipping_temp === 'Refrigerated' ? 'c-cart-item__refrigerated' : ''} ${item.shipping_temp === 'Frozen' || item.shipping_temp === 'Refrigerated' ? 'c-cart-item__forzen-show' : 'c-cart-item__forzen-hide'}`}></i> */}
                                        {item.shipping_type === "Included in Treshold" ?
                                            <Fragment>
                                                {saveforLater ? '' :
                                                <span style={{ visibility: 'hidden' }} className="d-flex flex-md-column mt-2">
                                                    <span className="qualify_ships_free-content">Qualified for Free Shiping</span>
                                                    <span className="qualify_ships_free"></span>
                                                </span>}
                                            </Fragment>
                                            : 
                                            !saveforLater &&
                                                <div class={`${item.shipping_type === 'Ships Free' ? 'c-cart-item__shipsFree-show' : 'c-cart-item__shipsFree-hide'}`}>
                                                    <i className="icon__fsd icon__fsd--shipsFree c-cart-item__shipsFree"></i>
                                                    <span>
                                                        Free Shipping 
                                                        {/* on orders over $750 */}
                                                    </span> 
                                                </div>
                                                
                                            // <i class={`icon__fsd icon__fsd--shipsFree c-cart-item__shipsFree ${item.shipping_type === 'Ships Free' || this.props.vendorName !== "FoodServiceDirect.com" ? 'c-cart-item__shipsFree-show' : 'c-cart-item__shipsFree-hide'}`}></i>
                                            }

                                            {/* Free shipping for savefor and missing product list  */}

                                            {saveforLater  && 
                                                <div class={`${item.shipping_type === 'Ships Free' ? 'c-cart-item__shipsFree-show' : 'c-cart-item__shipsFree-hide'}`}>
                                                    <i className="icon__fsd icon__fsd--shipsFree c-cart-item__shipsFree"></i>
                                                    <span>
                                                        Free Shipping 
                                                    </span> 
                                                </div>
                                            }

                                    </div>
                                    {item.stock_type === "Special Order" &&
                                        <div className="c-cart-item__product-special-order-tag">
                                          <i className="icon__fsd icon__fsd--SpecialOrder"></i>
                                          <div className="msg_box">
                                            <h5>Special Order</h5>
                                            <p>   
                                                This is a special order item. Special order items are purchased specifically for the customer and can take 3-4 weeks before they are available to ship. Once your order is processing with the supplier we will be unable to cancel the order. Special ordering this product does not guarantee it will be fulfilled, some orders may be cancelled due to supply chain challenges.
                                            </p>
                                           </div>
                                        </div> 
                                    }  

                                    {saveforLater  && 
                                    <div className="c-cart-item__product-actions sfl-action">
                                            {actions}
                                    </div>}

                                    {!saveforLater && !isGuestUser && 
                                    <a className="c-cart-item__product-action" onClick={e => this.saveforLater(e, item)} href="javascript:void(0)">{this.i18nService.translate([this.i18nScopeKey, 'SAVE_FOR_LATER'])}</a>}
                                        
                                    
                                    

                                </div>

                                {/* <div className="icon__fsd-container">
                                    <div class={`${item.shipping_temp === 'Refrigerated' ? 'c-cart-item__refrigerated' : ''} ${item.shipping_temp === 'Frozen' || item.shipping_temp === 'Refrigerated' ? 'c-cart-item__forzen-show' : 'c-cart-item__forzen-hide'}`}>
                                        <i className="icon__fsd icon__fsd--ships-frozen-no-border-new"></i>
                                        <span>Ships Frozen</span>
                                    </div>

                                    <div class={`${item.shipping_type === 'Ships Free' || this.props.vendorName !== "FoodServiceDirect.com" ? 'c-cart-item__shipsFree-show' : 'c-cart-item__shipsFree-hide'}`}>
                                        <i className="icon__fsd icon__fsd--shipsFree-new"></i>
                                        <span>Free Shipping</span>
                                    </div>
                                </div> */}

                                
                                {saveforLater ? 
                                    <div className="price_number_tepper_container">
                                        <div className="d-md-flex flex-column align-items-center">
                                                    <NumberStepper app={this.app} saveForLater={true} sku={item.sku} changeQty={!this.props.saveforLater} qty={item.qty} {...this.props.app.settings.numberstepper} available_stock_qty={item.available_stock_qty} onQtyChange={(qty) => this.qtyChangeHandler(qty, item)} />
                                                    {/* {!saveforLater && <div className="d-none d-md-block c-cart-item__price-per">{this.i18nService.format(item.price, ['number', 'currency'])} per {item.sold_as}</div>} */}
                                                    {error && <div style={{ fontSize: '11px' }} className="d-none d-md-block text-danger">Ops, available qty is {item.available_stock_qty}</div>}
                                        </div>
                                        {!missingProduct && <div className="c-cart-item__price-total">
                                                {!item.is_in_stock && !saveforLater ? <div className="d-flex align-items-center"><i className="icon__fsd icon__fsd--out-of-stock" style={{ fontSize: '32px' }}></i><span className="pl-2 text-danger">Out of Stock</span></div> :
                                                    <>
                                                        <div className="c-cart-item__price-total-group flex-md-column">
                                                            <span className="c-cart-item__price-total-value">{this.i18nService.format(saveforLater ? item.row_total * item.qty : item.row_total, ['number', 'currency'])}</span>
                                                            <span className="c-cart-item__price-total-discounted">{this.i18nService.format(item.row_total_with_discount ? parseFloat(item.row_total_with_discount.replace(",", "")) : 0, ['number', 'currency'])}</span>
                                                        </div>
                                                        <div className="c-cart-item__price-total-discount-description">
                                                            <p>{this.i18nService.translate([this.i18nScopeKey, 'SAVE_LABEL'])} {this.i18nService.format(item.discount_amount, ['number', 'currency'])}</p>
                                                        </div>
                                                    </>
                                                }
                                        </div>}
                                        {missingProduct && <p className="missing-products-item-percase">
                                        {this.i18nService.format(item.price , ['number', 'currency'])} per case</p>}
                                    </div> : 
                                    <>

                                           <div className="stepper__container d-md-flex  flex-column align-items-center">
                                                        <NumberStepper app={this.app} itemOptions={JSON.parse(item.options)} sku={item.sku} changeQty={!this.props.saveforLater} qty={item.qty} {...this.props.app.settings.numberstepper} available_stock_qty={item.available_stock_qty} onQtyChange={(qty) => this.qtyChangeHandler(qty, item)} />
                                                        {/* {!saveforLater && <div className="d-none d-md-block c-cart-item__price-per">{this.i18nService.format(item.price, ['number', 'currency'])} per {item.sold_as}</div>} */}
                                                        {error && <div style={{ fontSize: '11px' }} className="d-none d-md-block text-danger">Ops, available qty is {item.available_stock_qty}</div>}
                                            </div>
                                            <div className={`c-cart-item__price-total ${isDiscounted && 'disc_val_div_alignment'}`}>
                                                    {!item.is_in_stock && !saveforLater ? <div className="c-cart-item_oos_div d-flex align-items-center mr-lg-4"><i className="icon__fsd icon__fsd--out-of-stock" style={{ fontSize: '32px' }}></i><span className="pl-2 text-danger">Out of Stock</span></div> :
                                                        <>
                                                            <div className="c-cart-item__price-total-group flex-md-column">
                                                                <span className="c-cart-item__price-total-value">{this.i18nService.format(saveforLater ? item.row_total * item.qty : item.price, ['number', 'currency'])}</span>
                                                                <span className="c-cart-item__price-total-discounted">{this.i18nService.format(item.row_total_with_discount ? parseFloat(item.row_total_with_discount.replace(",", "")) : 0, ['number', 'currency'])}</span>
                                                            </div>
                                                            <div className="c-cart-item__price-total-discount-description">
                                                                <p>{this.i18nService.translate([this.i18nScopeKey, 'SAVE_LABEL'])} {this.i18nService.format(item.discount_amount, ['number', 'currency'])}</p>
                                                            </div>
                                                        </>
                                                    }
                                            </div>
                                            <div className="c-cart-item__price-row-total">
                                                <span className="total-text">Total</span>
                                                <span>{this.i18nService.format(item.row_total, ['number', 'currency'])}</span>
                                            </div>

                                            
                                    </>
                                  }
                                
                                
                                {/* { !saveforLater && 
                                    <div className="c-cart-item__product-actions">
                                            {actions}
                                    </div>
                                } */}

                                {/* { saveforLater  && 
                                    <div className="add_to_cart_btn">
                                        Add to Cart
                                    </div>
                                } */}

                                {/* <div className="c-cart-item__price"> */}

                                    {/* <div className="c-cart-item__price-quantity"> */}
                                        {/* TODO: add number stepper component */}
                                        {/* <div className="c-number-stepper">
                                                    <div className="c-number-stepper__container">
                                                        <span className="c-number-stepper__decrease">
                                                            <i className="icon__fsd icon__fsd--quantity-minus"></i>
                                                        </span>
                                                        <div className="c-number-stepper__input">
                                                            <input type="number" value={item.qty} min="1"/>
                                                        </div>
                                                        <span className="c-number-stepper__increase">
                                                            <i className="icon__fsd icon__fsd--quantity-plus"></i>
                                                        </span>
                                                    </div>
                                                </div> */}
                                        {/* <div className="d-md-flex flex-column align-items-center">
                                            <NumberStepper app={this.app} sku={item.sku} changeQty={!this.props.saveforLater} qty={item.qty} {...this.props.app.settings.numberstepper} available_stock_qty={item.available_stock_qty} onQtyChange={(qty) => this.qtyChangeHandler(qty, item)} />
                                            {!saveforLater && <div className="d-none d-md-block c-cart-item__price-per">{this.i18nService.format(item.price, ['number', 'currency'])} per {item.sold_as}</div>}
                                            {error && <div style={{ fontSize: '11px' }} className="d-none d-md-block text-danger">Ops, available qty is {item.available_stock_qty}</div>}

                                        </div> */}
                                        {!missingProduct && saveforLater && 
                                        <button onClick={(e) => this.addtoCart(e, item)} className={`c-button c-button--primary c-button--full-width add_to_cart_btn ${!item?.is_addtocart? 'oos-btn':''}`}>
                                            {item?.is_addtocart ? 'Add to Cart' : 'Out of Stock'}
                                        </button>
                                        }
                                        {missingProduct &&  
                                            <div className="c-cart-item__price-total-group missing-products-item"
                                            style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}
                                            >
                                                <div className="missing-products-item_prices">
                                                 <span className="c-cart-item__price-total-value">{this.i18nService.format(saveforLater ? item.price * item.Qty : item.row_total, ['number', 'currency'])}</span>
                                                 <span className="c-cart-item__price-total-discounted">{this.i18nService.format(item.row_total_with_discount ? parseFloat(item.row_total_with_discount.replace(",", "")) : 0, ['number', 'currency'])}</span>
                                                </div>
                                                <div>
                                                    {isAdded ?
                                                    <div style={{float: 'right',height: 'unset',width: 'unset',boxShadow: 'none'}} 
                                                    className={`c-product-card--added-to-cart ${this.state.loading === item.sku && 'c-product-card--disabled c-product-card--process'}`}>
                                                     <button onClick={() => this.missingProductRemoveFromCart(currentItem)} 
                                                     class={`c-product-card__add-button c-button c-button--icon ${this.state.loading === item.sku && 'c-button--processing'}`}></button>
                                                    </div>
                                                    :
                                                    <div style={{float: 'right',height: 'unset',width: 'unset',boxShadow: 'none'}} 
                                                    className={`${this.state.loading === item.sku && 'c-product-card--disabled c-product-card--process'}`}
                                                    >
                                                     <button onClick={() => this.missingProductaddToCart(item)} class={`c-product-card__add-button c-button c-button--icon ${this.state.loading === item.sku && 'c-button--processing'} `}></button>
                                                    </div>
                                                    }
                                                </div>    
                                            </div>
                                        }
                                        {/* <Select 
                                                    onChange={(e) => this.qtyChangeHandler(e.target.state.value, item)}
                                                    app={this.app}
                                                    name="group_id"
                                                    value={item.qty}
                                                    options={this.quantityOptions}
                                                /> */}
                                    {/* </div> */}
                                    {/* <div className="c-cart-item__price-total">
                                        {!item.is_in_stock && !saveforLater ? <div className="d-flex align-items-center"><i className="icon__fsd icon__fsd--out-of-stock" style={{ fontSize: '32px' }}></i><span className="pl-2 text-danger">Out of Stock</span></div> :
                                            <>
                                                <div className="c-cart-item__price-total-group flex-md-column">
                                                    <span className="c-cart-item__price-total-value">{this.i18nService.format(saveforLater ? item.row_total * item.qty : item.row_total, ['number', 'currency'])}</span>
                                                    <span className="c-cart-item__price-total-discounted">{this.i18nService.format(item.row_total_with_discount ? parseFloat(item.row_total_with_discount.replace(",", "")) : 0, ['number', 'currency'])}</span>
                                                </div>
                                                <div className="c-cart-item__price-total-discount-description">
                                                    <p>{this.i18nService.translate([this.i18nScopeKey, 'SAVE_LABEL'])} {this.i18nService.format(item.discount_amount, ['number', 'currency'])}</p>
                                                </div>
                                            </>
                                        }
                                    </div> */}

                                {/* </div> */}
                                {error && <div className="d-flex d-md-none mt-3 text-danger">
                                    Ops, available qty is {item.available_stock_qty}
                                </div>}
                                {!saveforLater && 
                                <div className={`c-cart-item__bar_remove d-flex ${isDiscounted && 'disc_delete_icon_alignment'}`}>
                                    {saveforLater ?
                                        <a className="c-cart-item__product-action c-cart-item__product-action--remove" onClick={e => this.removeItemHandler(e, item)} href="javascript:void(0)">{this.i18nService.translate([this.i18nScopeKey, 'REMOVE_LABEL'])}</a>
                                        :

                                        actions

                                    }
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            });
        }

        return <div className="page__section-content p-cart-detail__cart-items">
            {this.renderStore.savedDataBoxList}
        </div>;
    }

    setOptions(limit = 10) {
        let options = [];
        for (let i = 1; i <= limit; i++) {
            options.push({
                label: i,
                value: i
            });
        }
        return options;
    }
    addtoCart(e, item) {
        if(!item.is_addtocart) {
            window.FsdApp.getController("dialog").notify({
                text: 'Item is out of stock',
                theme: "danger",
            });
            return false
        }
        if(item.qty < item.min_sale_qty ){
            window.FsdApp.getController("dialog").notify({
                text: `The fewest you may purchase is ${item.min_sale_qty}.`,
                theme: "danger",
            });
            return false
        }
        this.wishListService.wishlistApi('post', 'addtocart', { "itemData": { "item_id": item.wishlist_item_id, "qty": this.state[item.item_id] || item.qty, vendor_id: item.vendor_id } })
    }
    missingProductaddToCart(item) {
        this.setState({ loading: item.sku });
        this.cartService.addToCart(item, item.Qty).then( (res) => {
            console.log("Item Added",err)
            this.setState({ loading: null });
        }).catch( err => {
            console.log("EROOR",err)
            this.setState({ loading: null });
        })
    }
    missingProductRemoveFromCart(item) {
        this.setState({ loading: item.sku });
        this.cartService.removeFromCart(item.item_id).then( (res) => {
            console.log("Item removed",err)
            this.setState({ loading: null });
        }).catch( err => {
            this.setState({ loading: null });
            console.log("EROOR",err)
        })
    }
    removeItemHandler(e, item) {
        if (this.props.saveforLater) {
            this.wishListService.wishlistApi('put', `remove/${item.wishlist_item_id}`);
        }
        else if (this.props.onRemoveItemClick) {
            this.setState({ loading: item.sku });
            this.props.onRemoveItemClick(item);
        }
    }
    async saveforLater(e, item) {
     await this.wishListService.wishlistApi('post', 'additem', { vendor_id: item.extension_attributes ? item.extension_attributes.vendor_id : null, product_id: item.product_id, qty: item.qty, item_id: item.item_id, config_option: item.config_option });
    
       this.dialogController.notify({
            theme: 'success',
            text: "Product added save for later successfully",
            timeout: 3000

        }).then(result => { }).catch(reason => { });
     
    
    }
    
    missProdqtyChangeHandler(qty, item) {
        if (isNaN(qty)) return;
        item.Qty = qty;
        if (this.props.saveforLater) {
            this.setState({ [item.sku]: item.Qty });
        }
    }

    qtyChangeHandler(qty, item) {
        if (isNaN(qty)) return;
        item.qty = qty;
        if (this.props.saveforLater) {
            this.setState({ [item.item_id]: item.qty });
        }
        else if (this.props.onItemQtyChange) {
            this.props.onItemQtyChange(item);
        }
    }
}