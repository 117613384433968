import BaseReactComponent from "../core/base-react.component";
import React, { Component, Fragment } from "react";
import classNames from "classnames";
import I18nService from "../services/i18n.service";
import { ViewportController } from "../controllers/viewport.controller";
import CartService from "../services/cart.service";
import NumberStepper from "./number-stepper-2.component";
import CartRecommendationsComponent from "./cart-recommendations.component";
import { LoadingController } from "../controllers/loading.controller";
import initAfterpay from "./afterpay.component";
import { PaypalBraintreeButton } from "./paypal-braintree-button.component";
import { PaypalButton } from "./paypal-button.component";
import BraintreeApplepayButton from "./braintree-applepay-button.component";

export default class CartNotificationComponent extends BaseReactComponent {
  constructor(props, context) {
    super(props, context);

    this.i18nScopeKey = "CART_NOTIFICATION";
    this.cartItem = null;

    this.state = {
      isLoading: true,
      isLoadingRemove: null,
      isMobile: false,
      cart: props.cart,
      isShipsFree: false,
      isAdded: props.isAdded,
      isOutofStock: false,
    };
    this.keyDownProxy = this.keydownHandler.bind(this);
    this.payaplCheckoutRedirectHandler = this.payaplCheckoutRedirectHandler.bind(this);
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.dialogController = this.app.getController("dialog");
    this.viewportController = this.app.getController("viewport");
    this.loadingController = this.app.getController("loading");
    this.layoutController = this.app.getController("layout");

    this.cartService = this.app.cartService;
    this.wishListService = this.app.wishlistService;
    this.cartService.addEventListener(
      CartService.EVENT_CHANGE,
      this.cartChangeHandler.bind(this)
    );
    this.loadingController.addEventListener(
      LoadingController.EVENT_CHANGE,
      this.loadingChangeHandler.bind(this)
    );
  }
  payaplCheckoutRedirectHandler(){
    window.location.href = this.app.environment.route.checkout+"?paypal"
  }
  loadingChangeHandler() {
    if (
      this.state.isLoadingRemove &&
      !this.loadingController.getLoadingStatus("remove-cart")
    ) {
      this.setState({ isLoadingRemove: null });
    }
  }
  cartChangeHandler() {
    if (this.cartService.cart.items && !this.cartService.cart.items.length) {
      this.close();
    }
    this.setState({
      cart: this.cartService.cart,
      cartItem: this.cartService.cart.items,
      ...this.getShipsFreeFlag(this.cartService.cart.items),
    });
  }
  getShipsFreeFlag(items) {
    let obj = {};
    items.map((item) => {
      if (item.shipping_type === "Ships Free") {
        obj.isShipsFree = this.state.isAdded
          ? "Free shipping on eligible items applied"
          : "Some of your items ship free!";
      }
      if (!item.is_in_stock) {
        obj.isOutofStock = true;
      }
    });
    return obj;
  }
  saveforLater(e, item) {
    this.wishListService.wishlistApi("post", "additem", {
      product_id: item.product_id,
      config_option: item.config_option,
      item_id: item.item_id,
      qty: item.qty,
      vendor_id: item.extension_attributes
        ? item.extension_attributes.vendor_id
        : null,
    });
  }
  getLastItem() {
    if (this.state.cart.items) {
      let items = [...this.state.cart.items];
      if (this.cartService.lastSku) {
        items = items.filter((item) =>
          this.cartService.lastSku.includes(item.sku)
        );
      } else {
        items = [this.state.cart.items[this.state.cart.items.length - 1]];
      }

      return items;
    } else {
      return null;
    }
  }
  componentDidMount() {
    this.afterpayInitiated = false;
    if (this.state.cart) {
      const cartItem = [...this.state.cart.items];
      this.setState(
        {
          cartItem,

          lastItem: this.getLastItem(),
          ...this.getShipsFreeFlag(this.state.cart.items),
          //: this.state.cart.items
        },
        () => {
          if (this.state.isOutofStock) {
            localStorage.setItem("removeoos", true);
          }
        }
      );

      // this.setState({
      //     lastItem: this.state.cart.items ? this.state.cart.items[this.state.cart.items.length - 1] : null
      // });
    }

    this.viewportBreakpointChangeProxyListener =
      this.viewportController.addEventListener(
        ViewportController.EVENT_BREAKPOINT_CHANGE,
        this.viewportBreakpointChangeHandler.bind(this)
      );
    this.viewportBreakpointChangeHandler();

    this.gestureManager = new Hammer(this.wrapperRef);
    this.gestureManager.on("swipe", this.close.bind(this));
    document.getElementsByTagName("html")[0].style.overflowY = "hidden";
    document.addEventListener("keydown", this.keyDownProxy);
  }

  componentWillUnmount() {
    this.gestureManager.destroy();
    document.getElementsByTagName("html")[0].style.overflowY = "auto";
    document.removeEventListener("keydown", this.keyDownProxy);
  }
  displayOption(options) {
    if (!options) return null;
    options = JSON.parse(`${options}`);
    if (options.length)
      // return options.map((option,k)=><span className="mb-2" key={k}>{option.label} - {option.value}</span>)
      return options.map((option, k) => (
        <>
          <span
            className="mb-2 c-mini-cart-notification__cart-sold-by-label"
            key={k}
          >
            {option.label}:
            <span className="mb-2 c-mini-cart-notification__cart-sold-by-vendor-label">
              {option.value}
            </span>
          </span>
        </>
      ));
    return null;
  }
  componentDidUpdate() {
    if (
      !this.afterpayInitiated &&
      document.getElementById("afterpay-minicart")
    ) {
      initAfterpay("afterpay-minicart", this.layoutController,this.dialogController);
      this.afterpayInitiated = true;
    }
  }
  render() {
    if (!this.i18nService) return null;
    let product, description;
    const { isAdded } = this.state;
    const { isShipsFree, isOutofStock } = this.state;
    let footer = [
      {
        label: "SUB_TOTAL_LABEL",
        key: "subtotal_with_discount",
        class: "",
      },
      // {
      //   label: "ESTIMATED_TAX_LABEL",
      //   key: "tax_amount",
      //   class: "",
      // },
      // {
      //   label: "ESTIMATED_SHIPPING_LABEL",
      //   key: "shipping_amount",
      //   class: "",
      // },
    ];
    let notificationClassName = classNames("c-mini-cart-notification", {
      //'c-mini-cart-notification--empty': !this.cartService || this.cartService.isEmpty
    });
    const keyName = this.state.isAdded ? "lastItem" : "cartItem";
    if (this.state[keyName]) {
      console.log("hello item" , this.state[keyName])
      product = (
        <div className="c-mini-cart-notification__product">
          {this.state[keyName].map((item, i) => (
            <div className="c-mini-cart-notification__product-bar">
              <div className="c-mini-cart-notification__product-avatar">
              {
                <img
                  className="c-mini-cart-notification__product-thumb"
                  src={item.image}
                  alt={item.name}
                />
              }
              </div>
              <span className="c-mini-cart-notification__product-name">
                <span
                  className="c-mini-cart-notification__product-name-text"
                  dangerouslySetInnerHTML={{ __html: item["name"] }}
                ></span>
                {isAdded || !item.is_in_stock ? (
                  ""
                ) : (
                    item.stock_type === "Special Order" ?
                    <div className="c-cart-item__product-special-order-shipin">
                      Ready to ship in {this.cartService.getShipInDays(item.ships_in_days)}
                    </div> 
                    :
                    <div className="c-mini-cart-notification__product-shipsin-days">
                    {item.ships_in}
                    </div>
                )}

                <div>
                  {/* {isAdded ? (
                    <span className="c-mini-cart-notification__product-qty">
                      Quantity: {item.qty}
                    </span>
                  ) : (
                    ""
                )} */}

                  {!item.is_in_stock ? (
                    <div className="d-flex align-items-center">
                      <i
                        className="icon__fsd icon__fsd--out-of-stock"
                        style={{ fontSize: "32px" }}
                      ></i>
                      <span className="pl-2 text-danger">
                        Out of Stock
                        <div>
                          {this.props.isGuestUser ? (
                            ""
                          ) : (
                            <a
                              className="c-cart-item__product-action"
                              onClick={(e) => this.saveforLater(e, item)}
                              href="javascript:void(0)"
                            >
                              Save for Later
                            </a>
                          )}
                        </div>
                      </span>
                    </div>
                  ) : (
                    <span className="c-mini-cart-notification__product-each">
                      Quantity: {item.qty} (
                      {this.i18nService.format(item["price"] || 0, [
                        "number",
                        "currency",
                      ])}{" "}
                      {item.sold_as})
                    </span>
                  )}
                </div>

                <span className="c-mini-cart-notification__actions  flex-column align-items-start">
                  {this.displayOption(item.options)}
                </span>
                <span className="c-mini-cart-notification__actions">
                  {isAdded || !item.is_in_stock ? (
                    ""
                  ) : (
                    <span className="mr-3">
                      <NumberStepper
                        sku={item.sku}
                        qty={item.qty}
                        available_stock_qty={item.available_stock_qty}
                        {...this.props.app.settings.numberstepper}
                        onQtyChange={(qty) => this.qtyChangeHandler(qty, item)}
                      />{" "}
                    </span>
                  )}

                  {/* {isAdded ? (
                    <span className="c-mini-cart-notification__product-qty">
                      Quantity: {item.qty}
                    </span>
                  ) : (
                    ""
                  )} */}
                  {/* {!item.is_in_stock ? (
                    <div className="d-flex align-items-center">
                      <i
                        className="icon__fsd icon__fsd--out-of-stock"
                        style={{ fontSize: "32px" }}
                      ></i>
                      <span className="pl-2 text-danger">
                        Out of Stock
                        <div>
                          {this.props.isGuestUser ? (
                            ""
                          ) : (
                            <a
                              className="c-cart-item__product-action"
                              onClick={(e) => this.saveforLater(e, item)}
                              href="javascript:void(0)"
                            >
                              Save for Later
                            </a>
                          )}
                        </div>
                      </span>
                    </div>
                  ) : (
                    <span className="c-mini-cart-notification__product-each">
                      (
                      {this.i18nService.format(item["price"] || 0, [
                        "number",
                        "currency",
                      ])}{" "}
                      {item.sold_as})
                    </span>
                  )} */}
                  <span style={{ paddingBottom: "10px" }}>
                    {!item.is_in_stock ? (
                      ""
                    ) : (
                      <span className="c-mini-cart-notification__product-price c-mini-cart-notification__price-label">
                        {this.i18nService.format(
                          item["row_total_with_discount"].replace(",", "") || 0,
                          ["number", "currency"]
                        )}
                      </span>
                    )}
                    {this.state.isLoadingRemove !== item.item_id ? (
                      <i
                        className="c-mini-cart-notification__actions--trash icon__fsd icon__fsd--trash_secondary"
                        onClick={(e) => this.removeItemHandler(item)}
                        href="javascript:void(0)"
                        style={{ bottom: "8px" }}
                      ></i>
                    ) : (
                      <i className="c-mini-cart-notification__actions--trash icon__fsd icon__fsd--loading-dark"></i>
                    )}
                  </span>
                </span>
                {/* <span>
                  {!item.is_in_stock ? (
                    ""
                  ) : (
                    <span className="c-mini-cart-notification__product-price c-mini-cart-notification__price-label">
                      {this.i18nService.format(
                        item["row_total_with_discount"].replace(",", "") || 0,
                        ["number", "currency"]
                      )}
                    </span>
                  )}
                  {this.state.isLoadingRemove !== item.item_id ? (
                    <i
                      className="c-mini-cart-notification__actions--trash icon__fsd icon__fsd--trash_secondary"
                      onClick={(e) => this.removeItemHandler(item)}
                      href="javascript:void(0)"
                    ></i>
                  ) : (
                    <i className="c-mini-cart-notification__actions--trash icon__fsd icon__fsd--loading-dark"></i>
                  )}
                </span> */}
              </span>
            </div>
          ))}
        </div>
      );
    }

    if (this.cartService.isFreeShippingAvailable) {
      if (
        this.cartService.remainingAmountForFreeShipping &&
        this.cartService.remainingAmountForFreeShipping > 0
      ) {
        if (this.cartService.isFreeShippingAvailable) {
          description = (
            <p className="c-mini-cart-notification__notification-description">
              {this.i18nService.translate(
                [this.i18nScopeKey, "NOTIFICATION_DESCRIPTION"],
                {
                  remainingAmount:
                    this.cartService.remainingAmountForFreeShipping,
                }
              )}
            </p>
          );
        } else {
          description = (
            <p className="c-mini-cart-notification__notification-description c-mini-cart-notification__notification-description--success">
              {this.i18nService.translate([
                this.i18nScopeKey,
                "NOTIFICATION_DESCRIPTION_FREE_SHIPPING",
              ])}
            </p>
          );
        }
      }
    } else {
      description = null;
    }
    let refrallAmount;
    if (this.state.cart && this.state.cart.totals) {
      refrallAmount = this.state.cart.totals.aw_raf;
      if (this.state.cart.totals.total_segments) {
        this.state.cart.totals.total_segments.map(({ code, value }) => {
          if (code === "aw_raf" && value) {
            refrallAmount = Math.abs(value);
          }
        });
      }
    }
    return (
      <div ref={(r) => (this.wrapperRef = r)} className={notificationClassName}>
        <div className="c-mini-cart-notification__container">
          <div
            className={`c-mini-cart-notification__caption ${
              isAdded ? "text-center" : "text-left"
            }`}
          >
            <span className="c-mini-cart-notification__caption-title">
              {isAdded
                ? this.i18nService.translate([this.i18nScopeKey, "TITLE"])
                : "Your Cart"}
            </span>
            <span className="c-mini-cart-notification__caption-close">
              <a
                onClick={this.close.bind(this)}
                className="c-button c-button--icon"
              >
                <i className="icon__fsd icon__fsd--close-gray"></i>
              </a>
            </span>
          </div>
          <div className="c-mini-cart-notification__content">
            <div
              className={`c-mini-cart-notification__notification ${
                !isAdded && isOutofStock ? "bg-danger" : ""
              }`}
            >
              {!isAdded && isOutofStock ? (
                <span className="c-mini-cart-notification__notification-title">
                  Some items in your cart are now unavailable
                </span>
              ) : (
                <Fragment>
                  <span className="c-mini-cart-notification__notification-title">
                    {this.i18nService.translate([
                      this.i18nScopeKey,
                      "NOTIFICATION_TITLE",
                    ])}
                  </span>
                  <p className="c-mini-cart-notification__notification-description c-mini-cart-notification__notification-description--success">
                    {this.i18nService.translate([
                      this.i18nScopeKey,
                      "NOTIFICATION_DESCRIPTION_FREE_SHIPPING",
                    ])}
                  </p>
                </Fragment>
              )}
            </div>
            {product}
          </div>
          {this.state.cart &&
          this.state[keyName] &&
          this.state[keyName].length ? (
            <div className="c-mini-cart-notification__footer">
              {footer.map((sum) => (
                <div
                  className={`${sum.class} d-flex justify-content-between mb-4 c-mini-cart-notification__footer--summary`}
                >
                  <span className="c-mini-cart-notification__footer--summary--primary--text">
                    {this.i18nService.translate([this.i18nScopeKey, sum.label])}
                    {sum.key === "subtotal_with_discount" ? (
                      <span
                        className="c-mini-cart-notification__footer-items pl-1 c-pointer c-mini-cart-notification__footer--summary--secondary--text"
                        onClick={() => this.setState({ isAdded: false })}
                      >{`(${parseInt(
                        this.state.cart.totals.items_qty
                      )} ${this.state.cart.totals.items_qty  > 1 ? 'item(s)' : 'item'} in the cart)`}</span>
                    ) : (
                      ""
                    )}
                  </span>
                  <span>
                    {sum.key === "subtotal_with_discount" &&
                      parseFloat(
                        this.state.cart.totals["subtotal_with_discount"]
                      ) < parseFloat(this.state.cart.totals["subtotal"]) && (
                        <span className="c-order-summary-box__item-price-old">
                          {this.i18nService.format(
                            parseFloat(this.state.cart.totals["subtotal"]),
                            ["number", "currency"]
                          )}
                        </span>
                      )}
                    {this.i18nService.format(
                      this.state.cart && this.state.cart.totals
                        ? parseFloat(this.state.cart.totals[sum.key])
                        : "",
                      ["number", "currency"]
                    )}
                  </span>
                </div>
              ))}
              <div class="c-mini-cart-notification__footer--summary c-mini-cart-notification__footer--shipping--msg ">
                <span>Shipping will be calculated at the check-out</span>
              </div>
              {isShipsFree ? (
                <div className="c-mini-cart-notification__footer--summary--shipsfree mb-3">
                  {isShipsFree}
                </div>
              ) : (
                ""
              )}
              {refrallAmount && (
                <div
                  className={`d-flex justify-content-between mb-4 c-mini-cart-notification__footer--summary`}
                >
                  <span className="text-success">Referral Rewards</span>
                  <span className="text-success">
                    {this.i18nService.format(refrallAmount, [
                      "number",
                      "currency",
                    ])}
                  </span>
                </div>
              )}
              {/* <div
                className={`c-mini-cart-notification__footer--summary-total d-flex justify-content-between mb-4 c-mini-cart-notification__footer--summary`}
              >
                <span className="total-text">
                  {this.i18nService.translate([
                    this.i18nScopeKey,
                    "TOTAL_LABEL",
                  ])}
                </span>
                <span className="total-currency-text">
                  {this.i18nService.format(
                    this.state.cart && this.state.cart.totals
                      ? this.cartService.setGrandTotal(this.state.cart.totals)
                      : "",
                    ["number", "currency"]
                  )}
                </span>
              </div> */}
              {/* <div className="c-mini-cart-notification__subtotal">
                            <div className="c-mini-cart-notification__subtotal-heading">
                                <span className="c-mini-cart-notification__subtotal-heading-title">{this.i18nService.translate([this.i18nScopeKey, 'SUB_TOTAL_LABEL'])}</span>
                                <span className="c-mini-cart-notification__subtotal-heading-item-count">({this.i18nService.translate([this.i18nScopeKey, 'SUB_TOTAL_COUNT'], {count: parseInt(this.state.cart.totals['items_qty']).toString() })})</span>
                            </div>
                            <span className="c-mini-cart-notification__subtotal-price">{this.i18nService.format((this.state.cart && this.state.cart.totals) ? parseFloat(this.state.cart.totals['subtotal']) : '', ['number', 'currency'])}</span>
                        </div> */}
              <div></div>
              <div className="c-mini-cart-notification__action">
                {this.state.cart.totals.redirectTrue && (
                  <div
                    style={{ fontSize: "17px" }}
                    className="text-danger my-3 text-center"
                  >
                    Important message in your cart
                  </div>
                )}
                {this.state.cart.totals.redirectTrue ? null : (
                  <>
                    <a
                      href={this.app.environment.route.checkout}
                      className={`c-mini-cart-notification__action-primary c-button btn-green c-button--full-width ${
                        this.state.cart.totals.redirectTrue
                          ? "c-button--disabled"
                          : ""
                      }`}
                    >
                      Proceed to Checkout
                      {/* {this.i18nService.translate([
                      this.i18nScopeKey,
                      "CHECKOUT_LABEL",
                    ])} */}
                    </a>
                    {this.state.cart.isAfterpayPaymentAvailable && this.state.cart.totals.grand_total <= 2000 &&
                      <button
                        data-afterpay-entry-point="entryPoint"
                        id="afterpay-minicart"
                        className="c-button--full-width mb-3 checkout-afterpay-button d-flex justify-content-center align-items-center py-3"
                      >
                        <img
                          src="https://mcstaging4.foodservicedirect.net/images/logo/afterpay.png"
                          alt="Checkout with Afterpay"
                        />
                        <span className="afterpayTXT">Checkout</span>
                      </button>
                    }
                       <PaypalBraintreeButton color={"silver"} app={this.app} /> {/* TODO: will do later  */}
                       
                       {window.ApplePaySession && <BraintreeApplepayButton cartDrawer={true} app={this.app} cart={this.state.cart}/>}
                      {/* <button
                        style={{height:"50px"}}
                        onClick={this.payaplCheckoutRedirectHandler}
                        className="c-button--full-width mb-3 paypal-static-btn checkout-afterpay-button d-flex justify-content-center align-items-center py-3"
                      >
                        <i className="icon__fsd icon__fsd--paypal-btn"></i>
                        <span className="afterpayTXT">Checkout</span>
                      </button> */}
                  </>
                )}
                <a
                  href={this.app.environment.route.cart}
                  className="c-mini-cart-notification__action-primary c-button c-button--outline c-button--full-width"
                >
                  {this.i18nService.translate([
                    this.i18nScopeKey,
                    "GO_TO_SHOPPING_CART_LABEL",
                  ])}
                </a>
                <a
                  onClick={this.close.bind(this)}
                  href="#"
                  className="c-mini-cart-notification__action-secondary c-button c-button--full-width c-button--link c--button-continue-shopping"
                >
                  <i className="c-mini-cart-notification__actions--continueshopping icon__fsd icon__fsd--shopping_secondary"></i>
                  {this.i18nService.translate([
                    this.i18nScopeKey,
                    "CONTINUE_SHOPPING_LABEL",
                  ])}
                </a>
                <CartRecommendationsComponent
                  app={this.app}
                  cartItems={this.state.cartItem}
                  elementId="slider-recys"
                  isUnbxd={false}
                  isSlider={true}
                />
                {/* <CartRecommendationsComponent
                  app={this.app}
                  cartItems={this.state.cartItem}
                  elementId="slider-unbxd"
                  isUnbxd={true}
                  limit={2}
                /> */}
              </div>
            </div>
          ) : (
            <div className="m-5 px-md-5 px-4 c-mini-cart-notification__action">
              <div className="mb-3 c-mini-cart-notification__no-data-title">
                You have <strong>no items</strong> in your shopping cart.
              </div>
              <a
                onClick={this.close.bind(this)}
                href="#"
                className="c-mini-cart-notification__action-primary c-button c-button--outline c-button--full-width"
              >
                {this.i18nService.translate([
                  this.i18nScopeKey,
                  "CONTINUE_SHOPPING_LABEL",
                ])}
              </a>
              <a
                href={this.app.environment.route.cart}
                className="c-mini-cart-notification__action-primary c-button c-button--outline c-button--full-width mt-3"
              >
                View Saved Items
              </a>
            </div>
          )}
          {/* {this.state.cartItem && this.state.cartItem.length===1 ? 
                        <CartRecommendationsComponent  app={this.app} />
                        : ''
                    } */}
          <div className="c-mini-cart-notification__no-data">
            <div
              className="c-mini-cart-notification__no-data-info"
              dangerouslySetInnerHTML={{
                __html: this.i18nService.translate([
                  this.i18nScopeKey,
                  "EMPTY_CART_MESSAGE",
                ]),
              }}
            ></div>
            {this.cartService.isFreeShippingAvailable && (
              <div className="c-mini-cart-notification__no-data-footer">
                <p>
                  {this.i18nService.translate(
                    [this.i18nScopeKey, "FREE_SHIPPING_INFO"],
                    {
                      freeShippingAmount:
                        this.cartService.requiredAmountForFreeShipping,
                    }
                  )}
                </p>
              </div>
            )}
          </div>
          <span className="c-mini-cart-notification__arrow"></span>
        </div>
      </div>
    );
  }

  viewportBreakpointChangeHandler() {
    this.setState({
      isMobile: this.viewportController.isMobile,
    });
  }
  qtyChangeHandler(qty, item) {
    if (item) {
      item.qty = qty;
      this.cartService.updateItemQty(item, true);
    }
    //
  }

  close(e) {
    if (e) e.preventDefault();
    this.dialogController.dismiss();
  }
  onChangeCart() {
    console.error("test");
  }
  async removeItemHandler(item) {
    this.setState({ isLoadingRemove: item.item_id });
    const result = await this.cartService.removeFromCart(item.item_id, true);
    if (result.totals && this.state.isAdded) {
      this.close();
    }
  }
  keydownHandler(evt) {
    evt = evt || window.event;
    var isEscape = false;
    if ("key" in evt) {
      isEscape = evt.key == "Escape" || evt.key == "Esc";
    } else {
      isEscape = evt.keyCode == 27;
    }
    if (isEscape) {
      this.close();
    }
  }
}
