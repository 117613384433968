import { BaseController } from "../core/base.controller";
import MainMenuComponent from "../components/main-menu.component";
import ReactDOM from 'react-dom';
import React, { lazy, Suspense } from 'react';
import HeaderComponent from "../components/header.component";
import CategoryService from "../services/category.service";
import { LayoutController } from "./layout.controller";
import MageService from "../services/mage.service";
import DrawerPanelComponent from "./../components/drawer-panel.component";
import ManufacturerBrandsExplorer from "../components/manufacturer-brands-explorer.component";
import CategoryExplorerComponent  from "../components/category-explorer.component";

// const ManufacturerBrandsExplorer=lazy(()=>import( "../components/manufacturer-brands-explorer.component"));
// const CategoryExplorerComponent=lazy(()=>import( "../components/category-explorer.component"));

// const DrawerPanelComponent =lazy(()=>import("../components/drawer-panel.component"));

export default class MenuController extends BaseController {
    constructor(app) {
        super(app);
        this.mobileMenuIsActive = false;
        this.mobileMenuDrawer = null;
        this.drawerStatusProxy = this.drawerStatusHandler.bind(this);
        this.mobileMenuActionHandler = this.mobileMenuActionHandler.bind(this);
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.categoryService = this.app.categoryService;
        this.manufacturerBrandsService = this.app.manufacturerBrandsService;
        this.mageService = this.app.mageService;
        this.routerController = this.app.getController('router');
        this.layoutController = this.app.getController('layout');
        this.layoutController.addEventListener(LayoutController.EVENT_DRAWER_CLOSE, this.drawerStatusProxy);
        this.layoutController.addEventListener(LayoutController.EVENT_DRAWER_OPEN, this.drawerStatusProxy);
        
        this.app.findChild('.c-main-menu').then( element => {
            this.mainMenu = this.createDomComponent(MainMenuComponent, element);
            this.mainMenu.addEventListener(MainMenuComponent.EVENT_SELECT, this.mainMenuSelectHandler, this);
            if(this.layoutController) {
                this.layoutController.header.addEventListener(HeaderComponent.EVENT_OUTSIDE_CLICK, this.headerOutsideClickHandler, this);
            }
        });

        this.mageService.addEventListener(MageService.EVENT_READY, this.mageServiceReadyHandler.bind(this));
        this.categoryService.addEventListener(CategoryService.EVENT_CATEGORIES_CHANGE, this.categoriesChangeHandler.bind(this));
        this.setMobileMenu();
        
    }

    mageServiceReadyHandler() {
        setTimeout(()=>{
            this.setMobileMenu();
        }, 100);
    }

    categoriesChangeHandler() {
        this.setMobileMenu();
    }

    setMobileMenu() {
        if(this.mainMenu && this.mainMenu.items && this.categoryService.categories && this.categoryService.categories.length && !this.mobileMenuDrawer) {

            let mainMenuItem = this.mainMenu.items.find( item => item.action == 'shop-by-category');
            let subItems;
            let section = {
                name: "section-category-explorer",
                title: mainMenuItem ? mainMenuItem.label : null,
                items: this.getMenuItemsFromCategories(this.categoryService.categories),
                actions: []
            }

            if(this.mageService && this.mageService.settings && this.mageService.settings.menuItems) {
                this.mageService.settings.menuItems.forEach(menuItem => {
                    section.actions.push({
                        label: menuItem.name,
                        action: "href",
                        actionParams: {
                            model: "menu",
                            data: menuItem,
                        }
                    });
                });
            }else{
                mainMenuItem = this.mainMenu.items.find( item => item.action == 'manufacturer-and-brands');
                if(mainMenuItem) {
                    section.actions.push({
                        label: mainMenuItem.label,
                        action: 'href',
                        actionParams: {
                            model: "menu",
                            data: null,
                            name: mainMenuItem.action,
                            title: mainMenuItem.label,
                            items: null,
                            url: '/brand'
                        }
                    })
                }
                
                let otherItems = this.mainMenu.items.filter( item => item.action == null);
                otherItems.forEach( menuItem => {
                    section.actions.push({
                        label: menuItem.label,
                        action: "href",
                        highlighted: menuItem.isHighlighted,
                        actionParams: {
                            ...menuItem,
                            url: menuItem.key
                        }
                    });
                });
            }

            if(section){
                let self = this;
                this.layoutController.setDrawer('left', function(container) {
                   self.mobileMenuDrawer =  ReactDOM.render(<DrawerPanelComponent app={self.app} onAction={self.mobileMenuActionHandler} sections={[section]} />, container);
                });
            }
        }
    }

    mainMenuSelectHandler(e) {
        if(this.mainMenu.selectedItem && this.mainMenu.selectedItem.hasMore){
            this.layoutController.showOverlay('content', false);
            this.app.findChild('.c-header__overlay-content').then( element => ReactDOM.unmountComponentAtNode(element));
            switch(this.mainMenu.selectedItem.action) {
                case "shop-by-category":
                    this.layoutController.showOverlay('header', false);
                    this.app.findChild('.c-header__overlay-content').then(element => ReactDOM.render(<CategoryExplorerComponent app={this.app}/>, element));
                break;
                case "manufacturer-and-brands":
                    this.layoutController.showOverlay('header', false);
                    this.app.findChild('.c-header__overlay-content').then( element => ReactDOM.render(<ManufacturerBrandsExplorer app={this.app}/>, element));
                break;
            }
        }else{
            this.layoutController.hideOverlay('content');
            this.layoutController.hideOverlay('header');
            this.app.findChild('.c-header__overlay-content').then( element => ReactDOM.unmountComponentAtNode(element));
        }
    }

    mobileMenuActionHandler(action, params) {
        switch(action) {
            case "new-section":
                if(params.model === "category") {
                    if(params.items && params.items.length > 0) {
                        
                        this.mobileMenuDrawer.addSection({
                            name: params.name,
                            title: params.title,
                            showAllLink: params.data ? params.data.url : null,
                            items: params.items
                        }, null, true)
                    }else{
                        if(params.data && params.data.url) this.routerController.href(params.data.url);
                        this.closeMobileMenu();
                    }
                }
            break;
            case "href":
                if(params.url) this.routerController.href(params.url);
                this.closeMobileMenu();
            break;
        }
    }

    drawerStatusHandler(e) {
        this.mobileMenuIsActive = this.layoutController.drawer.left.isOpen;
        if(this.mobileMenuDrawer && !this.mobileMenuIsActive) this.mobileMenuDrawer.gotoHome();
        this.dispatchEvent(MenuController.EVENT_MOBILE_MENU_STATUS_CHANGE);
    }

    openMobileMenu() {
        this.layoutController.openDrawer("left");
    }

    closeMobileMenu() {
        this.layoutController.closeDrawer("left");
    }

    headerOutsideClickHandler(e) {
        if(this.mainMenu && this.mainMenu.domElement) {
            if(!this.mainMenu.domElement.contains(e.originalEvent.target)) {
                if(this.mainMenu.selectedItem) this.mainMenu.setSelectedItem(null);                
            }
        }
    }

    getMenuItemsFromCategories(categories) {
        return categories.map( category => {
            const items = this.getMenuItemsFromCategories(category.items)
            return {
                label: category.name,
                image: category.previewImage || `${this.app.settings.baseUrl}assets/images/placeholder-images/category-preview.svg`,
                action: "new-section",
                actionParams: {
                    model: "category",
                    data: category,
                    name: "section-" + category.id,
                    title: category.name,
                    showAllLink: category.url,
                    items: items
                }
            }
        });
    }
}

MenuController.EVENT_MOBILE_MENU_STATUS_CHANGE = "mobile-menu-status-change";