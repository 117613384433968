import { BaseApiService } from "./base-api.service";
import CartService from "./cart.service";


export default class WishListService extends BaseApiService {
    constructor(app) {
        super(app);
        this.list = null;
    }
    appOnInit() {
        super.appOnInit();
        this.loadingController = this.app.getController('loading');
    }
    wishlistApi(method,url,data=null){
        this.loadingController.showLoading('wishlist');
        return new Promise( (resolve, reject) => {
        this[method](`/wishlist/${url}`,data,null).then( result => {
            this.loadingController.hideLoading('wishlist');
            this.list = result[0];
            this.dispatchEvent(WishListService.EVENT_CHANGE);
            if(method==='post'){
                this.dispatchEvent(WishListService.EVENT_REFRESH);
            }
            resolve(result);
        }).catch((err)=>{
            this.loadingController.hideLoading('wishlist');
            console.error(err);
            reject(err);
        })
        })
    }
}
WishListService.EVENT_CHANGE = 'list-changed';
WishListService.EVENT_REFRESH = 'refresh-cart';