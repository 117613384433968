import { BaseApiService } from "./base-api.service";
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'

export default class PipeService extends BaseApiService {
    
    constructor(app, dictionary, locale, defaultFormats) {
        super(app);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
    }

    phoneNumber(value, country, formatType = 'national') {
        if(!value) return value;
        if(!country) country = this.i18nService.currentLocale.country;
        try {
            let phoneNumber = parsePhoneNumberFromString(value, country);
            if(!phoneNumber) return value;
            switch(formatType) {
                case 'national':
                default:
                    return phoneNumber.formatNational();
                break;
                case 'international':
                    return phoneNumber.formatInternational();
                break;
                case 'uri':
                    return phoneNumber.getURI();
                break;
            }
        }catch(error) {
            return value;
        }
    }
    
}