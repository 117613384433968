import { BaseApiService } from "./base-api.service";

export default class PatnerService extends BaseApiService {
    constructor(app) {
        super(app);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.storeInfo = null;
        this.mageService = this.app.mageService;
        this.accountService = this.app.accountService;
        
    }
    getStoresInfo(total=true) {
        return new Promise((resolve, reject) => {
            this.get(`/fsd/customer/store${total ? '?total=false' : '' }`, null, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then(result=> {
                if (result) {
                    this.storeInfo=result;
                    
                    this.dispatchEvent(PatnerService.EVENT_STORE_CHANGE);
                    resolve(result);
                }
            }).catch(reason => {
                window.location = '/customer/account/login/';
                reject(reason);
            });
        });
    }
    getCategoryProducts(page,pageSize,sortby){
        const store_location_id = localStorage.getItem('store_location_id');
        
        return new Promise((resolve, reject) => {
            this.http.get(`storelocation/products?store_location_id=${store_location_id}&page=${page}&pageSize=${pageSize}&sortby=${sortby}`
            ,{headers: {'X-Requested-With': 'XMLHttpRequest'}}).then(result=> {
                if (result) {
                    resolve(result.data[0]);
                }
            }).catch(reason => {
                reject(reason);
            });
        });
    }
    switchStoreForm({store_location_id}){
        localStorage.setItem('store_location_id',store_location_id);
        var f = document.createElement("form");
        f.setAttribute('method',"post");
        f.setAttribute('action',"/storelocation/products/storelocationpost");
        var key = document.createElement("input"); //input element, text
        key.setAttribute('type',"hidden");
        key.setAttribute('name',"form_key");
        key.setAttribute('value',this.mageService.formKey);
        var i = document.createElement("input"); //input element, text
        i.setAttribute('type',"hidden");
        i.setAttribute('name',"store_location_id");
        i.setAttribute('value',store_location_id);
        var customerid = document.createElement("input"); //input element, text
        customerid.setAttribute('type',"hidden");
        customerid.setAttribute('name',"customer_id");
        customerid.setAttribute('value',this.app.accountService.user.id);
        var s = document.createElement("input"); //input element, Submit button
        s.setAttribute('type',"submit");
        f.appendChild(i);
        f.appendChild(s);
        f.appendChild(customerid);
        f.appendChild(key);
        document.getElementsByTagName('body')[0].appendChild(f);
        f.submit();
    }
    switchStore({store_location_id}) {
        localStorage.setItem('store_location_id',store_location_id);
        return new Promise((resolve, reject) => {
            this.http.post(`/storelocation/switch/store`, {store_location_id},{headers: {'X-Requested-With': 'XMLHttpRequest'}}).then(result=> {
                if (result) {
                    resolve(result);
                }
            }).catch(reason => {
                reject(reason);
            });
        });
    }
}    
PatnerService.EVENT_STORE_CHANGE= 'store-change';