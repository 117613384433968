import BaseService from "../core/base.service";

export default class JUService extends BaseService {

    constructor(app){
        super(app);
        this.isReady = false;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.mageService = this.app.mageService;

        this.checkIfJuappLoaded().then( result => {
            window.juapp=window.juapp||function(){(window.juapp.q=window.juapp.q||[]).push(arguments)};
            this.isReady = true;
            if(window.dataLayer) this.sendOrder(dataLayer.find( item => item.event == "transaction"));
            this.dispatchEvent(JUService.EVENT_READY);
        }).catch(err=>{
            console.log(err);
        });
    }

    sendCartItemAdd(item){
        if(!this.isReady) return;
        juapp(
            'cartItemAdd', 
            {
                productid: item.id,
                sku: item.sku,
                name: item.name,
                quantity: item.quantity,
                price: item.price
            }
        );
    }

    sendCartItemsAndCart(cart){
        if(!this.isReady) return;
        if(!this.mageService.trackEnabled) return;
        const cartItems = cart.items.map((item) => {
            return {
                productid: item.item_id, sku: item.sku, quantity: item.qty, price: item.price, name: item.name
            }
        });
        juapp(
            'cartItems',
            cartItems
        );
        juapp(
            'cart',
            {
                total: cart.grand_total,
                subtotal: cart.subtotal,
                tax: cart.tax_amount,
                shipping: cart.shipping_amount,
                currency:'USD'
              }
        );
    }

    sendOrder(transaction){
        if(!this.isReady) return;
        if(!this.mageService.trackEnabled) return;
        if(transaction) {
            const order = transaction.ecommerce.purchase.actionField;
            juapp(
                'order',
                order.id,
                {
                  total: order.revenue,
                  tax: order.tax,
                  shipping: order.shipping,
                  currency:'USD'
                }
            );
            const products = transaction.ecommerce.purchase.products;
            products.forEach(product => {
                juapp(
                    'orderItem',
                    product.id,
                    {
                      name: product.name,
                      quantity: product.quantity,
                      price: product.price
                    }
                );
            });
        }else{
            console.log("Can't find transaction item : ", dataLayer);
        }
    }

    checkIfJuappLoaded() {
        return new Promise((resolve, reject) => {
          let timeStart = Date.now();
          const TIMEOUT = 3000;
      
        let _isLoaded = function() {
          if (Date.now() - timeStart > TIMEOUT) {
            reject('Timeout. Juapp not injected!');
            return;
          }
          if (window.juapp && typeof juapp === 'function') {
            resolve(window.juapp);
            return;
          } else {
            setTimeout(_isLoaded, 500);
          }
        };
      
        _isLoaded();
        });
    }
}

JUService.EVENT_READY= "ready";