import { DomComponent } from "../core/dom.component";
import { ViewportController } from "../controllers/viewport.controller";
import classNames from 'classnames';
import HeaderComponent from "./header.component";
import CategoryNavListComponent from "./category-nav-list.component";
import ChoiceBoxComponent from "./choice-box";

export default class CatalogSidebarComponent extends DomComponent {
    constructor(app, element, settings) {
        super(app, element, 'c-catalog-sidebar');
        this.viewportController = this.app.getController('viewport');
        this.dialogController = this.app.getController('dialog');
        this.routerController = this.app.getController('router');
        this.isAccordionMode = false;
        this.isMultiExpandable = false;
        this.categoryNavList = null;
        this.settings = settings || {};
        this.isRefreshDisabled = this.settings.isRefreshDisabled;
        this.sections = [];
        this.inputOnChange = this.inputChangeHandler.bind(this);
        this.filterForm = null;

        this.unbxdService = this.app.unbxdService;
        this.init();
    }

    init() {
        if (this.isRefreshDisabled) return;
        this.findChildren(this.getSelector('__section')).then(elements => {
            elements.forEach(element => this.sections.push({
                element: element,
                name: element.getAttribute('section-name'),
                type: element.getAttribute('section-type'),
                expanded: false
            }));

            let categorySection = this.sections.find(section => section.name == 'categories');
            if (categorySection) this.categoryNavList = new CategoryNavListComponent(this.app, categorySection.element);
            this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(ViewportController.EVENT_BREAKPOINT_CHANGE, this.breakpointChangeHandler.bind(this));
            this.setAccordion('filters');
        });

        this.findChild(this.getSelector(' form[filter-form]')).then(formElement => {
            this.filterForm = formElement;
            this.findChildren([this.getSelector(' input[type="checkbox"]'), this.getSelector(' input[type="radio"]')]).then(inputs => {
                inputs.forEach(input => {
                    input.onchange = this.inputOnChange;
                });
            });

            let choiceBox;
            this.findChildren(this.getSelector(' .c-choice-box')).then(choiceElements => {
                choiceElements.forEach(choiceElement => {
                    choiceBox = new ChoiceBoxComponent(this.app, choiceElement, {
                        // maxChoiceCount: 10,
                        // maxItemCount: 1,
                        // fuseOptions: {
                        //     shouldSort: true,
                        //     includeMatches: true,
                        //     threshold: 0.3,
                        //     distance: 50
                        // }
                    });
                    choiceBox.addEventListener(ChoiceBoxComponent.EVENT_CHANGE, this.inputOnChange);
                });
            });
        });

        this.sendFacets();
        // this.sticky = new hcSticky(this.domElement, {
        //     stickTo: '.page__content',
        //     stickyClass: this.getSelector('--sticky', ''),
        //     followScroll: true,
        //     top: 0,
        //     bottomEnd: 25
        // });
        // this.app.getController('layout').header.addEventListener(HeaderComponent.EVENT_SHRINK, (event)=>{
        //     if(event.target) {
        //         this.updateTopOffset(event.target.isShrink);
        //     }
        // });
    }

    setClassName() {
        let className = classNames(
            this.getComponentClass(),
            {
                [this.getSelector('--accordion', '')]: this.isAccordionMode
            }
        )
        this.setClass(className);
    }

    updateTopOffset(byHeader = false) {
        let offsetTop = 0;
        if (byHeader) {
            this.app.findChild('.c-header__content-bar').then(element => {
                offsetTop = element.offsetHeight + (this.app.getController('layout').header.searchBarEnabled ? 65 : 0);
                this.sticky.update({
                    top: offsetTop
                });
            });
        } else {
            this.sticky.update({
                top: offsetTop
            });
        }
    }

    setAccordion(defaultExpandedSectionName = null) {
        this.isAccordionMode = this.viewportController.isMobile;
        if (this.isAccordionMode) {
            this.sections.forEach(section => {
                this.findChild(this.getSelector('__section-caption'), section.element).then(element => {
                    element.onclick = this.sectionClickHandler.bind(this, section);
                });
            });
            if (defaultExpandedSectionName) this.expandSection(this.sections.find(section => section.name == defaultExpandedSectionName));
        } else {
            this.sections.forEach(section => {
                this.findChild(this.getSelector('__section-caption'), section.element).then(element => {
                    element.onclick = null;
                });
            });
            this.dialogController.close();
        }
        this.setClassName();
    }

    sectionClickHandler(targetSection) {
        this.expandSection(targetSection);
    }

    expandSection(targetSection) {
        if (!targetSection) return;
        if (this.isMultiExpandable) {
            this.toggleClass(this.getSelector('__section--expanded'), targetSection.element);
        } else {
            let className;
            this.sections.forEach(section => {
                section.expanded = targetSection.expanded ? false : targetSection == section;
                className = classNames(
                    this.getSelector('__section', ''),
                    this.getSelector('__section' + '--' + section.name, ''),
                    {
                        [this.getSelector('__section' + '--' + section.type, '')]: section.type,
                        [this.getSelector('__section--expanded', '')]: section.expanded
                    }
                )
                this.setClass(className, section.element);
            });
        }
    }

    breakpointChangeHandler() {
        this.setAccordion();
    }

    inputChangeHandler(e) {
        if (this.filterForm) this.filterForm.submit();
    }

    sendFacets() {
        let queryParams = new URLSearchParams(window.location.search);
        let facets = {};

        for (const [key, value] of queryParams.entries()) {

            let facetName = key;
            switch (facetName) {
                case 'brands':
                    facetName = 'brand';
                case 'price':
                    facetName = 'priceRange'
            }

            let facetValue = value;
            facets = { ...facets, [facetName]: facetValue }

        }

        if (this.unbxdService.isReady) {
            this.unbxdService.fireFacets('page', facets, true);
        }
    }

}