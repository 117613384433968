import BaseReactComponent from "../core/base-react.component";
import React from "react";
import Rating from "./rating.react";
import AccountService from "../services/account.service";
import CartService from "../services/cart.service";
export default class RecysRecommendation extends BaseReactComponent {
  constructor(props, context) {
    super(props, context, {
      defaultProps: {
          hasCart: true
      }
    });
    this.state = {
      response: null,
      isLoading: true,
      loading:null,
      images: {},
      title: props.title,
      defaultImage:
        "https://www.foodservicedirect.com/media/catalog/product/placeholder/default/foodservicedirect-product-th-placelolder_1.png",
    };
    this.limit = props.limit;
    this.type = props.type;
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.gaService = this.app.gaService;
    this.accountService = this.app.accountService;
    this.cartService = this.app.cartService;
    this.unbxdSearchService = this.app.unbxdSearchService;
    this.page = "HOME";
    this.label = "Trending - Home";
    this.recSysService = this.app.recSysService;
    this.accountService.addEventListener(AccountService.EVENT_READY, () =>
      this.getProducts()
    );
    if (this.accountService.isReady) this.getProducts();
  }

  getCurrentPrice(mp_price , mp_special_price,  mp_special_from_date , mp_special_to_date ){
    let todayDate =  new Date();
    let from_date =  new Date(mp_special_from_date);
    let to_date =  new Date(mp_special_to_date);
    
    if (mp_special_price == null){
      return (
        this.i18nService.format(mp_price, [
          "number",
          "currency",
        ])
      )
    }
    else if(mp_special_price && mp_special_from_date ==null && mp_special_to_date == null){
      return (
        this.i18nService.format(mp_special_price, [
          "number",
          "currency",
        ])
      )
    }

    else if (mp_special_price && 
      todayDate >=  from_date && 
      todayDate<= to_date){
        return (
          this.i18nService.format(mp_special_price, [
            "number",
            "currency",
          ])
        )
    }
    else{
      return (
        this.i18nService.format(mp_price, [
          "number",
          "currency",
        ])
      )
    }
   }

   getOldPrice(mp_price , mp_special_price,  mp_special_from_date , mp_special_to_date){
    let todayDate =  new Date();
    let from_date =  new Date(mp_special_from_date);
    let to_date =  new Date(mp_special_to_date);

    if(mp_special_price && mp_special_from_date == null && mp_special_to_date == null){
      return <span className="c-product-card__price-old">{this.i18nService.format(mp_price, [
        "number",
        "currency",
      ])}</span>
     }
 
     else if (mp_special_price && todayDate >= from_date && todayDate <= to_date){
       return <span className="c-product-card__price-old">{this.i18nService.format(mp_price, [
        "number",
        "currency",
      ])}</span>
     }
 
   }
  getShipInDays (ships_in_days){
    if (ships_in_days >= 21 && ships_in_days <= 30){
       return "3-4 week(s)"
    }
    else if (ships_in_days > 30){
      return "6-7 week(s)"
    }

    else {
      return `${ships_in_days} day(s)${ships_in_days === "1" ? "*" : ""}`
    }

  }

  componentDidMount() {
    this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartChangeHandler.bind(this));
  }

  cartChangeHandler() {
    this.setState({
      cart: this.currentProps.hasCart ? this.cartService.cart : null    
    }, () => {
        // if(this.cartService.cart) this.juService.sendCartItemsAndCart(this.cartService.cart);
    })
  }

  render() {
    let products = null;
    if (this.state.response && this.state.response.Recommendations) {
      products = this.state.response.Recommendations.slice(0,10)
      .filter(product => product.is_in_stock)
      .map((product, index) => {
      if(this.state.cart && this.state.cart.items.length ){
        var currentItem = this.state.cart.items?.find(({ sku }) => sku === product.sku);
        var isAdded = currentItem?.sku;
      }  
      const {mp_special_price,mp_special_from_date,mp_special_to_date,mp_price,liquidation_expiry_date,  new_arrivals_expiry_date , liquidation , new_arrivals }  =   product;

      const getDateArrivals = new_arrivals_expiry_date == null ? null : new_arrivals_expiry_date.split(' ')[0];
      const getDateClearance = liquidation_expiry_date == null ? null : liquidation_expiry_date.split(' ')[0];
      const showClearance = this.unbxdSearchService.isShowClearanceTag(liquidation , getDateClearance)
      const showArrival = this.unbxdSearchService.isShowArrivalTag(new_arrivals , getDateArrivals)

      const currentPrice  =  this.unbxdSearchService.getCurrentPrice(mp_special_price,mp_special_from_date,mp_special_to_date,mp_price);
      const oldPrice  =  this.unbxdSearchService.getOldPrice(mp_special_price,mp_special_from_date,mp_special_to_date,mp_price);

         return (
          <div
            key={product.uniqueId}
            className="p-index__featured-product-item page__section-card-list-item col-xl-4 col-lg-5 col-md-10"
          >
            <div
              className="c-product-card"
              // json-product={`{'vendor_id':'${product.vendor_id}','sku': '${product.uniqueId}','label':'${this.label}'}`}
            >
              <div className="c-product-card__container">
                <div className="c-product-card__preview">
                  <div
                    href={`${product.productUrl}`}
                    onClick={this.productClickHandler.bind(
                      this,
                      product,
                      index
                    )}
                    className="c-product-card__preview-slider"
                    data-test-id={`home-trendingNow-link-productPreview${index}`}
                  >
                    <div className="c-preview-image-slider">
                      <div className="c-preview-image-slider__container swiper-container">
                        <div className="c-preview-image-slider__content swiper-wrapper">
                          <div className="c-preview-image-slider__slide swiper-slide">
                            <img
                              alt={product.title}
                              onError={this.addDefaultProductImage}
                              src={product.image || this.state.defaultImage}
                              data-test-id={`home-trendingNow-img-productPreview${index}`}
                            />
                          </div>
                        </div>
                        <div className="c-preview-image-slider__pagination swiper-pagination" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="c-product-card__content">
                  <div class="c-product-card_additional-flags">
                  {showClearance && (
                        <div class="c-product-card_liquidation-flag">
                          <span data-test-id={`home-trendingNow-text-productClearance${index}`}>CLEARANCE</span>
                        </div>
                      )}
                      {showArrival && (
                        <div class="c-product-card_newarrivals-flag">
                          <span  data-test-id={`home-trendingNow-text-productNewarrivals${index}`}>NEW ARRIVALS</span>
                        </div>
                      )}
                    {product.shipping_type === "Ships Free" ? (
                      <div class="c-product-card_free-shipping-flag">
                        {" "}
                        <span data-test-id={`home-trendingNow-text-productFreeshipping${index}`}>FREE SHIPPING</span>{" "}
                      </div>
                    ) : null}
                    {product?.rebate_eligibility === "yes" &&
                        <div class="c-product-card_out-of-stock-flag bg-reb">
                              <span>REBATE-ELIGIBLE</span>
                        </div>
                    }
                  </div>
                  <div className="c-product-card__title">
                    <a
                      href={`${product.productUrl}`}
                      onClick={this.productClickHandler.bind(
                        this,
                        product,
                        index
                      )}
                      data-test-id={`home-trendingNow-img-productTitle${index}`}
                    >
                      {product.title}
                    </a>
                  </div>
                  <div className="c-product-card__ship-info">
                  { product.stock_type === "Special Order" ? 
                      <span className="c-product-card__ship-info-text">
                        Ready to ship within{" "}
                      <span className="c-product-card__special-order-ship-info-text"
                       data-test-id={`home-trendingNow-text-productShipInDays${index}`}
                      >
                      {this.getShipInDays(product.ships_in_days)}
                     </span>
                    </span> : <span className="c-product-card__ship-info-text">
                    Ready to ship within{" "}
                     <span className="c-product-card__ship-info-day"
                      data-test-id={`home-trendingNow-text-productShipInDays${index}`}
                     >
                     {this.getShipInDays(product.ships_in_days)}
                    </span>
                    </span>  }
                    {/* {!product.ships_in && (
                      <span className="c-product-card__ship-info-no-shipping-badge">
                        {this.i18nService.translate([this.i18nScopeKey, 'NO_SHIPPING'])}
                      </span>
                    )} */}
                  </div>
                  {product.product_review &&
                    typeof product.product_review === "object" &&
                    product.product_review.review_count && (
                      <div className="d-flex">
                        <Rating
                          size={"medium"}
                          rating={product.product_review.rating_avg}
                        />
                        <span className="c-product-shop-box__review-count" 
                        data-test-id={`home-trendingNow-text-productReviewCount${index}`}
                        >
                          ({product.product_review.review_count})
                        </span>
                      </div>
                    )}
                  {/* <div className="c-product-card__sold-by">
                    <span className="c-product-card__sold-by-text">
                      Sold By:
                    </span>
                    <span className="c-product-card__sold-by-vendor-name">
                      FoodServiceDirect.com
                    </span>
                  </div> */}
                  <div className="c-product-card__footer">
                    <div className="c-product-card__footer-left">
                      <div className="c-product-card__price">
                        <span className="c-product-card__price-current" 
                        data-test-id={`home-trendingNow-text-productPriceCurrent${index}`}
                        >
                          {this.i18nService.format(currentPrice, [
                            "number",
                            "currency",
                          ])}
                          <span class="c-product-card__sold-as">/{product.sold_as}</span>
                        </span> 
                        {oldPrice ? <span className="c-product-card__price-old"
                        data-test-id={`home-trendingNow-text-productPriceOld${index}`}
                        >
                              {this.i18nService.format(oldPrice, [
                                "number",
                                "currency",
                              ])}
                            </span> : null} 
                      </div>
                    </div>
                    <div className="c-product-card__footer-right">
                      {product.product_type === "simple" ?
                            isAdded ?
                            <div className={`c-product-card--added-to-cart ${this.state.loading === product.sku && 'c-product-card--disabled c-product-card--process'}`}>
                                <button onClick={() => this.removeFromCartHandler(currentItem)} 
                                data-test-id={`home-trendingNow-btn-productRemovefromCart${index}`}
                                class={`c-product-card__add-button c-button c-button--icon ${this.state.loading === product.sku && 'c-button--processing'}`}></button>
                            </div>
                            :
                            <div className={`${this.state.loading === product.sku && 'c-product-card--disabled c-product-card--process'}`}>
                                <button onClick={() => this.addToCartHandler(product)}
                                data-test-id={`home-trendingNow-btn-productAddtoCart${index}`}
                                class={`c-product-card__add-button c-button c-button--icon ${this.state.loading === product.sku && 'c-button--processing'} `}></button>
                            </div>
                          :
                          <a className="c-product-card__add-button pdp-link-btn" data-test-id={`home-trendingNow-btn-productAddtoCartLink${index}`} href={product.productUrl}></a>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <span>
        <a
          id="current-product-deals"
          style={{ position: "relative", left: 0 + "px", top: -140 + "px" }}
        ></a>
        <div className="page__section-caption">
          <div className="page__section-caption-title page__section-caption-title--fsd-title">
            <h3>{this.state.title}</h3>
            {!this.state.response && (
              <div className="c-loading-spinner ml-1"></div>
            )}
          </div>
          {/* <div className="page__section-caption-action">
                  <a href="">{this.i18nService.translate([this.i18nScopeKey, 'VIEW_ALL_LABEL'])}</a>
              </div> */}
        </div>
        <div className="page__section-content">
          <div className="p-index__featured-product-items page__section-card-list row row--col-3"
          data-test-id="home-trendingNow-text-allItems"
          >
            {products}
          </div>
        </div>
      </span>
    );
  }

  addDefaultProductImage(ev) {
    ev.target.src =
      "https://www.foodservicedirect.com/media/catalog/product/placeholder/default/foodservicedirect-product-th-placelolder_1.png";
  }

  productClickHandler(product, index, e) {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${product.productUrl}`;
    this.gaService.clickOnWidgets(this.label);
  }
  getProducts() {
    this.recSysService
      .getProductsTrending(this.limit, this.type)
      .then((res) => {
        this.setState(
          {
            isLoading: false,
            response: { Recommendations: res.products },
          },
          () => {
            this.app.getController("products").update();
          }
        );
      });
  }
  
  addToCartHandler(item) {
    this.setState({ loading: item.sku });
    this.cartService.addToCart(item, 1).then( (res) => {
        console.log("Item Added",err)
        this.setState({ loading: null });
    }).catch( err => {
        console.log("EROOR",err)
        this.setState({ loading: null });
    })
  }
  removeFromCartHandler(item) {
    this.setState({ loading: item.sku });
    this.cartService.removeFromCart(item.item_id).then( (res) => {
        console.log("Item removed",err)
        this.setState({ loading: null });
    }).catch( err => {
        this.setState({ loading: null });
        console.log("EROOR",err)
    })
  }


}
