import GraphqlService from "./base-graphql.service";
import CartService from "./cart.service";

export default class RecSys extends GraphqlService {
  constructor(app) {
    super(app);
  }
  appOnInit() {
    super.appOnInit();
    this.cartService = this.app.cartService;
    this.accountService = this.app.accountService;
  }
  addCartListner(limit) {
    this.cartService.addEventListener(CartService.EVENT_CHANGE, () =>
      this.getProducts(null,limit)
    );
  }
  getProducts(items,limit,fromCartPage) {
    console.trace("recSys Service items ", items);
    if (items) {
      this.graphql(this.getProductsQuery(items,limit))
        .then((res) => {
          console.log("recSys Service res ", res);

          this.faqData = res;
          this.dispatchEvent(RecSys.EVENT_CHANGE);
        })
        .catch((err) => {
          this.faqData = false;
          this.dispatchEvent(RecSys.EVENT_CHANGE);
        });
    } else if (this.cartService.cart && this.cartService.cart.items.length) {
      // if (this.cart === this.cartService.cart.items.length) return;
      this.cart = this.cartService.cart.items.length;
      this.graphql(this.getProductsQuery(this.cartService.cart.items,limit))
        .then((res) => {
          if(fromCartPage) this.cartPageFaqData = res;
          else this.faqData = res;
          this.dispatchEvent(RecSys.EVENT_CHANGE);
        })
        .catch((err) => {
          this.faqData = false;
          this.dispatchEvent(RecSys.EVENT_CHANGE);
        });
    }
  }
  getProductsQuery(items,limit,recysType) {
    limit = limit || 3;
    let sku = "";
    if(items){
      items.map((item) => {
        sku = (!sku ? sku : sku + "&") + "sku=" + item.sku;
      });
    }else if(recysType){
      sku = `recsys=${recysType}`;
     
      if(this.accountService.user && this.accountService.user.group_id){
         sku = `${sku}&customer-type=${this.accountService.user.group_id}`;
      }
    }
    // products : recommended_products(skus_query:"${sku}"){
    return {
      query: `{
                products : recommended_products(skus_query:"${sku}",limit:${limit}){
                 title : name 
                    sku
                    is_in_stock
                    uniqueId :sku
                    image
                    vendor_name
                    isSpecialPriceAvailable:special_price
                    special_price
                    mp_price
                    mp_special_price
                    mp_special_from_date
                    mp_special_to_date
                    price :final_price
                    ships_in
                    productUrl :url
                    liquidation
                    liquidation_expiry_date
                    new_arrivals
                    new_arrivals_expiry_date
                    rebate_eligibility
                    shipping_type
                    product_type
                    product_review{
                      review_count
                      rating_avg
                  }
                  vendor_id
                  stock_type
                  sold_as
                  ships_in_days
            }
          }`,
    };
  }
  getProductsTrending(limit,recysType){
    return new Promise((resolve,reject)=>{
      this.graphql(this.getProductsQuery(null,limit,recysType)).then((res)=>{
          resolve(res);
      }).catch(err=>reject(err));
    })
    
  }

}
RecSys.EVENT_CHANGE = "data-change";
