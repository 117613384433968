import { DomComponent } from "../core/dom.component";
import PreviewImageSliderComponent from "./preview-image-slider.component";
import classNames from "classnames";
import { ViewportController } from "../controllers/viewport.controller";
import { LoadingController } from "../controllers/loading.controller";
import NumberStepperComponent from "./number-stepper.component";

export default class ProductCardComponent extends DomComponent {
  constructor(app, element) {
    super(app, element, "c-product-card");
    this.product = null;
    this.list = false;
    this.isAvailableForCart = true;
    this.isAvailableForShipping = false;
    this.isAddedToCart = false;
    this.isAddedToList = false;
    this.relatedCartItem = null;
    this.isDisabled = false;
    this.isProcessing = false;
    this.isAvailableForShrink = false;
    this.isAlwaysShrink = false;
    this.isShrink = false;
    this.cartService = this.app.cartService;
    this.addButton = null;
    this.quantityStepper;
    this.quantity = 1;
    this.init();
  }

  init() {
    this.productsController = this.app.getController("products");
    this.viewportController = this.app.getController("viewport");
    this.loadingController = this.app.getController("loading");

    this.unbxdService = this.app.unbxdService;

    this.loadingController.addEventListener(
      LoadingController.EVENT_CHANGE,
      this.loadingChangeHandler.bind(this)
    );
    this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(
      ViewportController.EVENT_BREAKPOINT_CHANGE,
      this.viewportBreakpointChangeHandler.bind(this)
    );

    this.isAlwaysShrink = this.hasClass(this.getSelector("--shrink", ""));

    this.product = this.getJSON("product");
    if(this.product.default_vendor_data && this.product.default_vendor_data[0]){
        this.product.vendor_id = this.product.default_vendor_data[0].vendor_id;
    }
    this.findChild(this.getSelector("__add-button")).then((element) => {
      this.addButton = element;
      this.addButton.onclick = this.addToCartClickHandler.bind(this);
    });
    this.closest(".p-products__list-item")
      .then((productListItem) => {
        this.isAvailableForShrink = true;
      })
      .catch((reason) => this.handleSelectorError(reason));

    this.findChild(".c-preview-image-slider").then((element) => {
      this.previewImageSlider = new PreviewImageSliderComponent(
        this.app,
        element
      );
      this.previewImageSlider.addEventListener(
        "click",
        this.previewImageSliderClickHandler,
        this
      );
    });

    this.findChild(".c-product-card__title a").then((element) => {
      element.title = element.innerText;
      element.onclick = this.productClickHandler.bind(this);
    });
    this.findChild(this.getSelector(" .c-number-stepper"))
      .then((element) => {
        this.quantityStepper = new NumberStepperComponent(this.app, element);
        this.quantityStepper.addEventListener(
          NumberStepperComponent.EVENT_CHANGE,
          this.quantityChangeHandler.bind(this)
        );
      })
      .catch((reason) => this.handleSelectorError(reason));

    this.findChildren(this.getSelector(" [href]")).then((elements) => {
      elements.forEach((element) => {
        element.onclick = this.actionClickHandler.bind(this, element);
      });
    });
    this.findChild(".c-product-card__preview-slider").then((element) => {
      element.onclick = this.productClickHandler.bind(this);
    });

    // this.setComponentClass();
  }

  viewportBreakpointChangeHandler() {
    this.setComponentClass();
  }

  quantityChangeHandler(e) {
    this.quantity = this.quantityStepper.value;
  }
  productClickHandler() {
    // this.unbxdService.fireProductClick(this.product);
  }
  actionClickHandler(target, e) {
    if (!this.product) return;
    switch (target.getAttribute("href")) {
      case "#add-to-cart":
        e.preventDefault();
        e.stopPropagation();
        this.loadingController.addLoading("add-to-cart");
        this.cartService.addToCart(this.product, this.quantity);
        if (this.quantityStepper) this.quantityStepper.setValue(1);
        break;
      default:
        break;
    }
  }
  setComponentClass() {
    if (
      document.getElementsByClassName("modes_button").length &&
      !this.viewportController.isMobile
    ) {
      var current = document.querySelector(".modes_button.active");
      this.isShrink =
        current && current.getAttribute("data-mode") === "list" ? true : false;
    } else {
      this.isShrink =
        this.isAlwaysShrink ||
        (this.isAvailableForShrink && this.viewportController.isPhone);
    }

    if (this.product) {
      this.isAvailableForCart = this.product.is_salable != false;
      this.isAvailableForShipping =
        this.product.is_available_for_shipping != false;
    }
    let className = classNames(this.getComponentClass(), {
      [this.getSelector("--disabled", "")]: this.isDisabled,
      [this.getSelector("--not-available-for-shipping", "")]: !this
        .isAvailableForShipping,
      [this.getSelector("--added-to-list", "")]: this.isAddedToList,
      [this.getSelector("--added-to-cart", "")]: this.isAddedToCart,
      [this.getSelector("--not-available-for-cart", "")]: !this
        .isAvailableForCart,
      [this.getSelector("--shrink", "")]: this.isShrink,
      [this.getSelector("--processing", "")]: this.isProcessing,
    });
    if (this.isProcessing) {
      this.addClass("c-button--processing", this.addButton);
    } else {
      this.removeClass("c-button--processing", this.addButton);
    }
    this.setClass(className);
  }

  setRelatedCartItem(cartItem) {
    console.log("cartItem ", cartItem);
    if (!this.product) return;
    this.relatedCartItem = cartItem;
    this.isAddedToCart =
      this.relatedCartItem && this.relatedCartItem["sku"] == this.product.sku;
    this.isProcessing = false;
    this.setComponentClass();
  }

  addToCartClickHandler(e) {
    if (!this.product) return;
    e.preventDefault();
    e.stopPropagation();

    this.isProcessing = true;
    this.setComponentClass();

    console.log("isAddedToCart ", this.isAddedToCart);
    console.log("relatedCartItem ", this.relatedCartItem);

    if (this.isAddedToCart && this.relatedCartItem) {
      console.log("remove");
      this.cartService.removeFromCart(this.relatedCartItem["item_id"]);
    } else {
      console.log("add");
      this.cartService.addToCart(this.product, 1);
    }
  }

  loadingChangeHandler() {
    this.isDisabled = this.loadingController.getLoadingStatus("cart");
    if (!this.isDisabled) this.isProcessing = false;
    this.setComponentClass();
  }

  previewImageSliderClickHandler(e) {
    this.findChild(this.getSelector("__title a")).then((element) => {
      let link = element.getAttribute("href");
      window.location.href = link;
    });
  }

  destroy() {
    if (this.previewImageSlider) {
      this.previewImageSlider.removeEventListener(
        "click",
        this.previewImageSliderClickHandler
      );
      this.previewImageSlider.destroy();
    }
  }
}
