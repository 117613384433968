import { DomComponent } from "../core/dom.component";
import classNames from "classnames";
import { LayoutController } from "../controllers/layout.controller";
import { ViewportController } from "../controllers/viewport.controller";

export default class CharNavigationComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-char-navigation');

        this.layoutController = this.app.getController('layout');
        this.viewportController = this.app.getController('viewport');

        this.i18nService = this.app.i18nService;

        this.isShrink = false;

        this.sticky = null;
        this.i18nData = null;

        this.topOffset = 268;
        
        this.init();
    }

    init() {
        this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(ViewportController.EVENT_BREAKPOINT_CHANGE, this.viewportBreakPointChangeHandler.bind(this));
        this.viewportBreakPointChangeHandler();
    }

    shrink() {
        if(this.isShrink) return;
        this.isShrink = true;
        this.setComponentClass();
    }

    scrollHandler(event) {
        if(!this.isMobile) return;
        let isShrink = this.layoutController.getScrollTop() > this.topOffset;
        if(this.layoutController.getScrollHeight() - this.layoutController.getHeight() < 135 || (!isShrink && this.isShrink != isShrink)) {
            this.isShrink = false;
            this.setComponentClass();
            return;
        }
        if(isShrink == this.isShrink) return;
        this.shrink();
        this.dispatchEvent(CharNavigationComponent.EVENT_SHRINK);
    }

    viewportBreakPointChangeHandler(forceToSearchBarEnabled = true) {
        this.isMobile = this.viewportController.isMobile;
        this.setComponentClass();
    }

    setComponentClass(){
        let className = classNames(
            this.getComponentClass(),
            {
                [this.getSelector('--shrink', '')]: this.isShrink
            }
        )
        this.setClass(className)
        if(this.layoutController && this.layoutController.header) {
            this.layoutController.header.isNoShadow = this.isShrink;
            this.layoutController.header.setComponentClass();
        }        
    }
}

CharNavigationComponent.EVENT_SHRINK = "shrink";