import React from 'react';
const Rating = ({rating,size,customStyle}) =>{
    let style = {};
    if(size === "medium"){
        style.fontSize = '15px';
    }
    // const getClassName = (value) =>{
    //     if(value <= rating) return 'c-rating__star-yellow';
    //     else if(value === Math.ceil(rating)) return 'c-rating__star-halfyellow'
    //     return '';
    // }
    const widthPercentage = (rating * 100) / 5 + "%"; 

    let style2 = {backgroundPosition: "0 100%",float: 'left',height: '17.5px',display: "block",width:widthPercentage}
    style2 =  customStyle ? {...style2 ,...customStyle.span} : style2;
    return (
        <div className="c-rating_sprite_image" style={customStyle ? customStyle.div :{ width:'90px',height:"18px"}}>
           <span className="c-rating_sprite_image" style={style2}>

           </span>
        </div>
    )
}
export default Rating;