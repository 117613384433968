import { BaseController } from "../core/base.controller";
import ReactDOM from "react-dom";
import React from 'react';
import { DomComponent } from "../core/dom.component";
import { Suspense,lazy } from "react";
const  Cart  =lazy(()=>import("../components/cart.component"));
const SuccessRecommendations = lazy(()=>import("../components/success-recommendations.component"));
class CartComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        try{
            this.component = ReactDOM.render(
            <Suspense fallback={null}>
                <Cart app={this.app} currentProps={this.getAttributes()}/>
            </Suspense>, this.domElement);
        }catch(ex){
            console.trace(ex,'traceerror');
        }
    }
}

CartComponent.decorator = {
    selector: "fsd-cart"
}
export class CartController extends BaseController {

    constructor(app) {
        super(app, {
            declarations: [
                CartComponent
            ]
        });

        this.cart = null;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();

        this.unbxdRecommendationService = this.app.unbxdRecommendationService;
        this.unbxdService = this.app.unbxdService;        this.app.findChild('#fsd-recommendation--cart-detail').then( element => {
            const checkoutOrders = JSON.parse(JSON.parse(element.getAttribute('json-checkout-orders')));
            ReactDOM.render(<Suspense fallback={null}><SuccessRecommendations checkoutOrders={checkoutOrders.items} app={this.app} /></Suspense>, element);
        }).catch( reason => this.handleSelectorError(reason));

    }

    onMounted() {
        this.getDomComponent('CartComponent').then( domComponent => this.cart = domComponent.component).catch( reason => this.handleSelectorError(reason));
    }

}
