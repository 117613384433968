import BaseReactComponent from "../core/base-react.component";
import React, { Component } from 'react';
import CartService from "../services/cart.service";

const debounce = (cb,  delay = 1000) => {
    let timeout;

    return (...args) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
};
export {
    debounce
};
export default class NumberStepper extends BaseReactComponent {
    
    constructor(props, context) {
        super(props, context);

        this.state = {
            value: props.qty || parseInt(props.qty)
        }
        this.qtyChangeHandler = this.qtyChangeHandler.bind(this);
        this.showMinmAxDialog = this.showMinmAxDialog.bind(this);
        
    }
    appOnInit() {
        if (super.appOnInit) super.appOnInit();
        this.cartService = this.app.cartService;
        this.dialogController = this.app.getController('dialog');
       
        if(this.cartService){
            this.cartService.addEventListener(CartService.EVENT_CART, this.changeValue.bind(this));
            this.cartService.addEventListener(CartService.EVENT_REFRESH_CART, this.changeValueAfterWishlist.bind(this))
        }
         
    }

    componentDidMount(){
        let isOtherSellerItem = this.props.itemOptions && this.props.itemOptions[0].value !== "FoodServiceDirect.com";
        this.debouncLog = debounce((value) => {
                console.error("value",value)
                this.qtyChangeHandler(value);
            }, isOtherSellerItem ? 1550 : 250)  
    }

    changeValueAfterWishlist(){
        if(this.props.sku){
            this.cartService.cart.items.map(obj=>{
                if(this.props.sku===obj.sku && this.state.value !== obj.qty){
                    this.setState({value:obj.qty})
                }
            })
        }
        
    }
    componentWillReceiveProps(nextprops){
        if(this.props.saveForLater && this.state.qty !== nextprops.qty){ 
            this.setState({value:nextprops.qty}) 
        } else if(nextprops.sku !==this.props.sku && this.state.qty !==nextprops.qty){
            this.setState({value:nextprops.qty})
        }
        
    }
    changeValue(){
        if(Object.keys(this.cartService.updateCart).includes(this.props.sku)){
            this.setState({value : this.cartService.updateCart[this.props.sku]})
        }
    }
    render() { 
        return  <div className="c-number-stepper">
                    <div className="c-number-stepper__container">
                        <span className="c-number-stepper__decrease">
                            <i onClick={this.decreaseClickHandler.bind(this)} className="icon__fsd icon__fsd--quantity-minus"></i>
                        </span>
                        <div className="c-number-stepper__input">
                                <input type="number" onChange={(e) => this.inputChangeHandler(e)} 
                                value={this.state.value}
                                /> 
                        </div>
                        <span className="c-number-stepper__increase">
                            <i onClick={this.increaseClickHandler.bind(this)} className="icon__fsd icon__fsd--quantity-plus"></i>
                        </span>
                    </div>
                </div>;
    }

    decreaseClickHandler() {
        const newValue = this.state.value - 1;
        if(this.props.saveList) this.showMinmAxDialog(newValue,this.props.min,this.props.max) 
        if (this.props.min && newValue < parseInt(this.props.min)) return;
        this.setState({
            value: newValue
        });
        // this.qtyChangeHandler(newValue);
        this.debouncLog(newValue)
    }

    increaseClickHandler() {
        const newValue = this.state.value + 1;
        if(this.props.saveList) this.showMinmAxDialog(newValue,this.props.min,this.props.max) 
        if (this.props.max && newValue > parseInt(this.props.max)) return;
        this.setState({
            value: newValue
        });
        // this.qtyChangeHandler(newValue);
        this.debouncLog(newValue)
    }

    showMinmAxDialog(newValue,min, max){
        let callDialog = (msg) => {
            this.dialogController.notify({
                theme: 'danger',
                text: msg
            })
        }
        
        if(min > 1 && newValue < min ) callDialog(`The fewest you may add is ${min}`);
        if(newValue > max ) callDialog(`The maximum you may add is ${max}`);
    }

    inputChangeHandler(e) {
        let newValue = parseInt(e.target.value) ||this.state.value;
        if(this.props.saveList) this.showMinmAxDialog(newValue,this.props.min,this.props.max) 

        if (this.props.min) newValue = Math.max(newValue, parseInt(this.props.min));
        if (this.props.max) newValue = Math.min(newValue, parseInt(this.props.max));
        this.setState({
            value: newValue   
        });
        // newValue !== this.state.value && this.qtyChangeHandler(newValue);
        newValue !== this.state.value && this.debouncLog(newValue)
    }

    qtyChangeHandler(value) {
        if(this.props.available_stock_qty && value > this.props.available_stock_qty){
            value = this.props.available_stock_qty;
            this.setState({value});  
        }
        // if(this.props.onQtyChange && value !== this.state.value) {
        if(this.props.onQtyChange) {    
            this.props.onQtyChange(value);
        }
    }
}

NumberStepper.prototype._defaultProps = {
    min: 1
}