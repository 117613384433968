import BaseService from "../core/base.service";

export default class UnbxdService extends BaseService {

    constructor(app){
        super(app);
        this.isReady = false;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();

        this.mageService = this.app.mageService;

        this.checkIfUnbxdLoaded().then( result => {
            this.isReady = true;
            if(window.dataLayer ){
                const transactionEvent = dataLayer.find( item => item.event == "transaction");
                const catalogEvent = dataLayer.find( item => item.event == "catalog");
                const searchEvent = dataLayer.find( item => item.event == "search");

                if(transactionEvent) this.fireOrders(transactionEvent);
                if(catalogEvent) this.fireImpressions(catalogEvent, false);
                if(catalogEvent) this.fireBrowse(catalogEvent);
                if(searchEvent) this.fireImpressions(searchEvent, true);

            }
            setTimeout(() => {
                this.dispatchEvent(UnbxdService.EVENT_READY);
            }, 500);            
        });
    }

    fireProductView(product){
        // if(!this.mageService.trackEnabled) return;
        if(!product && !this.isReady) return;
        Unbxd.track("product_view", {"pid": product.sku});
    }

    fireOrders(transaction){
        // if(!this.mageService.trackEnabled) return;
        if(!transaction && !this.isReady) return;
        const products = transaction.ecommerce.purchase.products;
        products.forEach(product => {
            var payload = {
                "pid": product.sku,
                "variantId": product.variant,
                "qty": product.quantity.toString(),
                "price": parseFloat(product.price).toFixed(2)
            };
            console.log(payload,"order unbxd");
            Unbxd.track("order", payload);
        });
    }

    fireAddToCart(product, quantity){
        // if(!this.mageService.trackEnabled) return;
        if(!product && !this.isReady) return;
        this.fireProductClick(product);
        Unbxd.track("addToCart",{
            "pid": product.sku || product.uniqueId,
            "variantId": product.variant,
            "qty": quantity.toString()});
    }

    fireProductClick(product,index,experience_pagetype){
        if((!product && !this.isReady)) return;
        Unbxd.track("click", {"pid": product.sku || product.uniqueId,"prank":index ?  index.toString() : null,experience_pagetype,experience_widget:"WIDGET1"});
    }

    fireImpressions(dataLayer, isSearchPage){
        // if(!this.mageService.trackEnabled) return;
        if(!dataLayer && !this.isReady) return;
        var pid_list = new Array();

        const impressions = dataLayer.ecommerce.impressions;
        impressions.forEach((element) => {
            if (element.hasOwnProperty('sku')) {
                pid_list.push(element.sku);
            }
        });        

        // deciding the impressions
        var unbxd_imp;
        if (isSearchPage) {
            unbxd_imp = "search_impression";
        } else {
            unbxd_imp = "browse_impression";
        }

        Unbxd.track(unbxd_imp, { 'pid_list': pid_list });
    }

    fireRecommendationImpressions(pIds, boxType) {
        if(!pIds || !boxType || !this.isReady) {
            Unbxd.track('impression', {'box_type': boxType, 'pids_list': pIds});
        }
    }

    fireBrowse(dataLayer){
        // if(!this.mageService.trackEnabled) return;
        if(!dataLayer && !this.isReady) return;

        var category = dataLayer.ecommerce.category;
	    var categoryName = category.name;
	    var categoryPath = category.id;
	    Unbxd.track('categoryPage', {
            "page": categoryPath, 
            "page_type": "BOOLEAN", 
            "page_name": categoryName
        });

    }

    fireSearch(siteSearchTerm){
        // if(!this.mageService.trackEnabled) return;
        if(!siteSearchTerm || !this.isReady) return;
        Unbxd.track("search", {"query": siteSearchTerm});
    }

    fireFacets(query, facets, isCategory = false) {
        if(!query || !facets) return;

        if(!isCategory) {
            let newFacets = {}
            facets = Object.keys(facets).forEach(facetKey => {
                let keyNameArr = facetKey.split("_");
                keyNameArr.pop();
                const facetName = keyNameArr.join("_");
                const facetValue = facets[facetKey];
                newFacets = {...newFacets, [facetName]: facetValue}
            });
            facets = newFacets;
        }

        Unbxd.track("facets", {
            "query": query,
            "facets": facets
        });
    }

    fireRecommendationWidgetImpressions(experience_pagetype, pids_list){
        if(!experience_pagetype || !pids_list) return;
            Unbxd.track("experience_impression", {
                experience_pagetype,
                experience_widget: "WIDGET1",
                pids_list
            });
    }

    checkIfUnbxdLoaded() {
        return new Promise((resolve, reject) => {
          let timeStart = Date.now();
          const TIMEOUT = 3000;
      
        let _isLoaded = function() {
          if (Date.now() - timeStart > TIMEOUT) {
            // reject('Timeout. Unbxd not injected!');
            return;
          }
          if (window.Unbxd && typeof window.Unbxd.track === "function") {
            resolve(window.Unbxd);
            return;
          } else {
            setTimeout(_isLoaded, 500);
          }
        };
      
        _isLoaded();
        });
    }
}

UnbxdService.EVENT_READY= "ready";