import BaseService from "../core/base.service";
import jsonp from "jsonp";


export default class AccountService extends BaseService {
    
    constructor(app) {
        super(app);
        this.isReady = false;
        this.isGuestUser = true;
        this.user = null;
        this.isLoggedIn = false;
        this.token;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.mageService = this.app.mageService;
        this.routerController = this.app.getController('router');
        this.dialogController = this.app.getController('dialog');

    }

    setUser(user, isLoggedIn) {
        this.user = user;
        this.isLoggedIn = isLoggedIn;
        this.isGuestUser = !(this.user && this.isLoggedIn);
        this.isReady = true;
        this.dispatchEvent(AccountService.EVENT_READY);
        this.dispatchEvent(AccountService.EVENT_USER_CHANGE);
    }

    setToken(token) {
        this.token = token;
        this.dispatchEvent(AccountService.EVENT_TOKEN_CHANGE);
    }

    uploadTaxExemption(file) {
        console.log(file);
        return new Promise((resolve, reject)=> {
            this.http.post('/rest/V1/fsd/customer/file_upload', file).then( result => {
                //set/update user tax exemption
                resolve(result);
            }).catch(error => {
                reject(error);
            });
        });
    }

    removeTaxExemption() {
        return new Promise((resolve, reject)=> {
            //todo remove tax exemption file
            setTimeout(()=> {
                resolve({result: 'success'});
            }, 4000);
        });

    }

    logout() {
        this.setUser(null)
        this.setToken(null);
        this.mageService.clearCookies();
    }
    //Order Status Page Api Call
    orderStatus(queryParams){
        if(!queryParams){
            queryParams = new URLSearchParams(window.location.search);
        }
        
        let orderId = queryParams.get('orderid');
        let email = queryParams.get('email');
        return new Promise((resolve, reject)=> {
            if(!orderId || !email) resolve(false);
            else{
                email = email.toLowerCase();
                this.http.get(`/rest/V1/fsd/order/tracking-info?order_id=${orderId}&email=${email}`).then( result => {
                    if(!result?.data[0]?.order_info){
                        this.dialogController.notify({
                            theme: "danger",
                            text: 'Please enter correct email and order id to get details',
                            timeout:5000
                        });
                        resolve(false);
                    }
                    else{
                        resolve(result.data[0]);
                        var searchParams = new URLSearchParams(window.location.search);
                        searchParams.set("orderid", orderId);
                        searchParams.set("email", email);
                        var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
                        history.replaceState(null, '', newRelativePathQuery);
                    }

                    
                }).catch(error => {
                        this.dialogController.notify({
                            theme: "danger",
                            text: error,
                            timeout:5000
                        });
                        resolve(false);
                });
            }
            
        });
    }
}

AccountService.EVENT_READY = 'ready';
AccountService.EVENT_USER_CHANGE = 'user-change';
AccountService.EVENT_TOKEN_CHANGE = 'token-change';