import { DomComponent } from "../core/dom.component";

export default class CategoryNavListComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-category-nav-list');
        this.levels = [];
        this.init();
    }

    init() {
        this.findChildren(this.getSelector('__item--parent a')).then( elements => {
            elements.forEach( element => this.levels.push( element.innerText ));
        });

        this.findChild(this.getSelector('__item--selected')).then( element => {
            this.levels.push( element.innerText );
        });
    }
}