import { BaseApiService } from "./base-api.service";


 class FooterService extends BaseApiService{
    constructor(app) {
        super(app);
        this.columns = null;
    }
    appOnInit() {
        super.appOnInit();
    }
    footerApi(){
            this.get(`fsd/footer/columns`).then(result => {
                 this.columns= result[0];
                this.dispatchEvent(FooterService.EVENT_DATA);
            }).catch(reason => {

            })
    }
}
export default FooterService;
FooterService.EVENT_DATA = "data-change";