import { BaseApiService } from "./base-api.service";
import CartService from "./cart.service";

export default class CustomerService extends BaseApiService {
    constructor(app) {
        super(app);
        this.user = null;
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.cartService = this.app.cartService;
        this.accountService = this.app.accountService;
        this.dialogController = this.app.getController('dialog');
        
    }

    getCurrentSession() {
        const { pathname } = window.location
        return new Promise((resolve, reject) => {
            this.post(`fsd/${this.getEndpoint('customers')}/current-session?route=${pathname}`).then(result => {
                if(result && result[0]) {
                    resolve(result[0]);
                }
            }).catch(reason => {
                reject(reason);
            })
        });
    }
    getMSTSDetails(){
        return new Promise( (resolve, reject) => {
            this.get(`/msts/getbuyerdetails`).then( result => {
                if(result[0]) {
                    resolve(result[0]);
                }
            }).catch( reason => {
               
            });
        });
    }
    getCustomer() {
        return new Promise((resolve, reject) => {
            this.get(`fsd/${this.getEndpoint('customers')}/me`, null, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then(result=> {
                if (result && result[0]) {
                    if(this.app.settings.isPartnerStore && result[0]){
                     //   document.getElementsByClassName('c-header__top-bar')[0].classList.add('partner-store-green-bar');
                     //   document.getElementsByClassName('c-header__top-bar')[0].innerHTML = 'FoodServiceDirect.com now offers 30 days invoice terms,<a href="/msts/invoiceme/register"> click here </a>to learn more and sign up';
                    }
                    // console.error("ME~",result);
                    this.user = result[0];
                    console.trace("error= this.dispatchEvent(CustomerService.EVENT_PLATFORM_ID);");
                    // this.dispatchEvent(CustomerService.EVENT_PLATFORM_ID);
                    resolve(result[0]);
                }
            }).catch(reason => {
                reject(reason);
            });
        });
    }
    signInCustomer(user){
        return new Promise((resolve, reject) => {
            this.http.post('/fsd/customer/login', {...user,cartId:this.cartService.getCartId()}).then( result => {
                if(result.data.status){
                    this.getCustomer().then( customer => {
                        this.accountService.setUser(customer, true);
                        this.cartService.getCart();
                        this.dispatchEvent(CustomerService.EVENT_REFRESH);
                        

                    });
                }else {
                    this.dialogController.notify({
                        theme: 'danger',
                        text: result.data.message
                    })
                }
  
                
                resolve(result);
            }).catch(error => {
                reject(error);
            });
        });
    }
    
    getSuggestions(value){
        return new Promise((resolve, reject) => {
            this.http.post(`/email-domain-suggestions/getDomains/${value}`).then( result => {
                resolve(result);
            }).catch(error => {
                reject(error);
            });
        });
    }
    
    createUser(user){
        return new Promise((resolve, reject) => {
            this.http.post('/customer/createCustomerWithOrder', user).then( result => {
                resolve(result);
            }).catch(error => {
                reject(error);
            });
        });
    }
    checkCustomerPresent(email){
        this.accountService.guestEmail = email.customer_email;
        return new Promise((resolve, reject) => {
            this.http.post('/customers/isEmailAvailable', email).then( result => {
                this.showSignUp = result.data;
                this.exitsemail = result.data === false ? email.customer_email : null;
                this.dispatchEvent(CustomerService.EVENT_EMAIL_GUEST);
                resolve(result);
            }).catch(error => {
                reject(error);
            });
        });
    }
    removeCreditCard(hash) {
        return new Promise((resolve, reject) => {
            // this.delete(`fsd/${this.getEndpoint('customers')}/me/removeCreditCard/${hash}`)
            //     .then(response => {
            //         resolve(response);  
            //     }).catch( reason => {
            //         reject(error);
            //     });
            this.post('braintree/deletecreditcard',{
                "hash":hash
                }).then( response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        });
    }

    iframeAuth(user) {
        return new Promise((resolve, reject) => {
            this.http.post('https://api-cashback.diningalliance.com/auth',user).then( result => {
                resolve(result.data.data);
            }).catch(error => {
                reject(error);
            });
        });
    }

    iframeValidateUser(platformId,gpoId){
        return new Promise((resolve, reject) => {

            let url = `fsd/gpo/validate-user?pid=${platformId}&uid=${this.accountService.user.id}`;
            if(gpoId) url = url + `&gid=${gpoId}`;

            this.http.post(url).then( result => {
                resolve(result.data[0]);
            }).catch( reason => {
                reject(error);
            });
        });
    }
}

CustomerService.EVENT_REFRESH =  'refresh';
CustomerService.EVENT_EMAIL_GUEST = 'event-email-guest';
CustomerService.EVENT_PLATFORM_ID = 'event-platform-id';