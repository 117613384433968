import React, { useState, useRef, useEffect } from "react";
import BaseReactComponent from "../core/base-react.component";
import Swiper from "swiper";
import CategoryService from "../services/category.service";

const SwiperSlider = ({ data }) => {
  let sliderRef = useRef(null);
  let nextRef = useRef(null);
  let prevRef = useRef(null);
  console.log("data", data);
  useEffect(() => {
    let slider = new Swiper(sliderRef, {
      slidesPerView: "auto",
      pagination: false,
      freeMode: true,
      spaceBetween: 25,
      navigation: {
        nextEl: nextRef.current,
        prevEl: prevRef.current,
      },
      // hashNavigation: true,
    });
  }, []);

  return (
    <>
      <div
        ref={(i) => (sliderRef = i)}
        className="swiper-container c-category-level1-card--container"
      >
        <div className="swiper-wrapper c-category-level1-card--wrapper">
          {data.map((categories) => {
            return (
              <>
                <div className="c-category-level1-card--list swiper-slide">
                  <a href={categories.url}>
                    <div className="c-category-level1-card--img">
                      <img
                        src={categories.previewImage}
                        title={categories.name}
                        width="211"
                      />
                    </div>
                    <h4 className="c-category-level1-card--title_secondary">
                      {categories.name}
                    </h4>
                  </a>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className="c-category-navigation__nav">
        <div
          ref={nextRef}
          class="c-category-navigation__nav-item c-category-navigation__nav-next"
        ></div>
        <div
          ref={prevRef}
          class="c-category-navigation__nav-item c-category-navigation__nav-prev"
        ></div>
      </div>
    </>
  );
};

export default class LeftPanelSubCategory extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      categories: null,
      current: props.categoryId,
    };
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.categoryService = this.app.categoryService;
    this.categoryService.addEventListener(
      CategoryService.EVENT_CATEGORIES_CHANGE,
      this.categoriesChangeHandler.bind(this)
    );
  }

  categoriesChangeHandler() {
    if (
      this.categoryService.categories &&
      this.categoryService.categories.length
    ) {
      this.setState({
        categories: this.categoryService.categories.find(
          (category) => category.id === this.state.current
        ),
        isProcessing: false,
      });
    }
  }

  render() {
    if (!this.state.categories) return null;
    const {name , url} = this.state.categories
    const categoryBreadcrumbs = [
        {name: "Home", url_path: "/"},
        {name: name, url_path: url}
    ]
    return (
      <>
        <div className="c-category-level1-card--page ">
          <div className="row">
            <div className="col-20  c-category-level1-card--title c-filter-bar__category-title-header">
            <div className="c-breadcrumb c-breadcrumb--sm" style={{ marginBottom: 20}}>
	             <ul className="c-breadcrumb__container">
               {Object.values(categoryBreadcrumbs).map(({url_path,name},index)=>(
               <li key={index} className="c-breadcrumb__item"><a href={url_path} className="c-breadcrumb__item-link">{name}</a></li>
               ))}
               </ul>
               </div>
              {this.state.categories.name}
            </div>
            <div className="col-md-20 col-lg-4 mb-lg-0 order-md-0 order-1">
              <div style={{ marginTop : "25px" , fontSize : "18px"}} className="c-filter-category__title">Categories</div>
              {this.state.categories.items.map((item) => (
                <ul class="c-filter-category__items mb-2">
                  <li class="c-filter-category__item">
                    <a class="c-filter-category__link" href={item.url}>
                      {item.name}
                    </a>
                    {/* <span class="c-filter-category__count">
                      {!item.productCount ? "0" : item.productCount}
                    </span> */}
                  </li>
                </ul>
              ))}
              <div style={{ marginBottom : "0px"}} className="c-filter-category__title"></div>
            </div>
            <div className="col-md-20 col-lg-16">
              <div className={`row list c-category-level1-card`}>
                {this.state.categories.items.map((item) => (
                  <>
                    <div className="c-category-level1-card--wrapper col-xl-20 ">
                      <h3 className="c-category-level1-card--title_primary">
                        {item.name}
                      </h3>

                      <SwiperSlider data={item.items} />
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
