import React, { Component } from 'react';
import classNames from 'classnames';
import BaseApp from "./base.app";

export default class BaseReactComponent extends React.Component {
    constructor(props, context, params=null) {
        super(props, context);
        this.app = props ? props.app : null;
        this._defaultProps = params && params.defaultProps ? params.defaultProps : null;
        this.updateCurrentProps(props);

        if(this.app) {
            if(this.app.isReady) {
                this.appReadyHandler();
            }else{
                this.app.addEventListener(BaseApp.EVENT_APP_INIT, this.appReadyHandler.bind(this));
            }
        }
    }

    appReadyHandler() {
        this.appIsReady = true;
        if(this.appOnInit) this.appOnInit();
    }

    superRender() {
        this.updateCurrentProps();
    }

    updateCurrentProps(props, stateChanges=null, pipes, callback) {
        if(!props) props = this.props;
        if(props) this.currentProps = this._defaultProps ? {...this._defaultProps, ..._.pick(props, Object.keys(this._defaultProps)), ..._.pick(props.currentProps, Object.keys(this._defaultProps))} : {};
        if(stateChanges) {
            let changes = stateChanges === true ? _.pick(props, Object.keys(this.state)) : stateChanges;
            if(pipes) {
                Object.keys(pipes).forEach( key => {
                    if(changes[key] && typeof pipes[key] === 'function') changes[key] = pipes[key](changes[key]);
                });
            }
            this.setState(changes, ()=>{
                if(callback) callback(this.state);
            });
        }else{
            if(callback) callback(this.currentProps);
        }
    }
}