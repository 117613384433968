
import BaseService from "../core/base.service";
import AccountService from "./account.service";
import CartService from "./cart.service";

export class StoreCreditService extends BaseService {

    constructor(app) {
        super(app);
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();

        this.accountService = this.app.accountService;
        this.loadingController = this.app.getController('loading');
        this.dialogController = this.app.getController('dialog');
        this.checkoutController = this.app.getController('checkout');
        this.checkoutService = this.checkoutController.checkoutService;
        this.cartService = this.app.cartService;

        this.isActive = false;
        this.hasStoreCredit = false;
        this.balance = 0;
        this.availableStoreCredit = 0;

        this.accountService.addEventListener(AccountService.EVENT_USER_CHANGE, this.userChangeHandler.bind(this));
        this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartChangeHandler.bind(this));
        this.userChangeHandler();
    }

    userChangeHandler() {
        this.updateBalance();
    }

    cartChangeHandler() {
        this.updateBalance();
    }

    updateBalance() {
        console.log(this.balance,'balanceAmount');
        
        this.balance = this.accountService.user ? parseFloat(this.accountService.user.balance_amount) : 0;
        this.hasStoreCredit = this.balance > 0;
        if(this.cartService.cart && this.cartService.cart.totals && parseFloat(this.cartService.cart.totals.customerbalance) < 0) {
            this.availableStoreCredit = Math.max(this.balance + parseFloat(this.cartService.cart.totals.customerbalance), 0);
        }else{
            this.availableStoreCredit = this.balance;
        }
        this.dispatchEvent(StoreCreditService.EVENT_CHANGE_AVAILABLE_STORE_CREDIT);
    }

    setEnvironment(env) {
        if(env) {
            if(env.isActive) this.isActive = env.isActive;
        }
    }

    setBalance(value) {
        return new Promise((resolve, reject) => {
            if(!value) value = this.availableStoreCredit;
            this.loadingController.showLoading('checkout');
            this.cartService.setBalance(value).then(result => {
                this.loadingController.hideLoading('checkout');
                resolve(result)
            }).catch(error => {
                this.loadingController.hideLoading('checkout');
                reject(error)
            });
        });
    }

    getEndpoint(key) {
        let endpoint = this.app.environment.endpoint[key];
        if(!endpoint) return null;
        return endpoint;
    }
}

StoreCreditService.EVENT_CHANGE_AVAILABLE_STORE_CREDIT = "change-available-store-credit";