import { DomComponent } from "../core/dom.component";
import classNames from 'classnames';

export default class ExpandableListBlock extends DomComponent {

    constructor(app, element, settings) {
        super(app, element, 'c-expandable-list-block');
        this.isExpanded = false;
        this.settings = settings || {};
        this.id = this.settings.id;
        this.onStatusChange = this.settings.onStatusChange;
        this.init();
    }

    init() {
        this.findChild(this.getSelector('__caption')).then( element => {
            element.onclick = this.captionClickHandler.bind(this);
        })
        this.setComponentClass();
    }

    setComponentClass(){
        let className = classNames(
            this.getComponentClass(),
            {
                [this.getSelector('--expanded', '')]: this.isExpanded
            }
        )
        this.setClass(className)
    }

    captionClickHandler() {
        this.isExpanded ? this.collapse(true) : this.expand(true);
    }

    expand(emitChange = false) {
        if(this.isExpanded) return;
        this.isExpanded = true;
        this.setComponentClass();
        if(this.onStatusChange && emitChange) this.onStatusChange(this);
    }

    collapse(emitChange = false) {
        if(!this.isExpanded) return;
        this.isExpanded = false;
        this.setComponentClass();
        if(this.onStatusChange && emitChange) this.onStatusChange(this);
    }
}