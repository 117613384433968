import React, { Component } from "react";
import ReactDOM from "react-dom";
import { DomComponent } from "../core/dom.component";
import BaseReactComponent from "../core/base-react.component";
import classNames from "classnames";
import { ViewportController } from "../controllers/viewport.controller";

export class SearchSideBarComponent extends BaseReactComponent {
  constructor(prop, context) {
    super(prop, context);

    this.renderStore = {
      categories: null,
    };

    this.state = {
      contentJson: this.props.contentJson,
      isAccordion: this.props.isAccordion,
    };

    this.maxLevel = 0;
    this.levels = [];
    this.i18nScopeKey = "SEARCH_SIDE_BAR";
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.routerController = this.app.getController("router");
    this.viewportController = this.app.getController("viewport");
    this.productsController = this.app.getController("products");

    this.unbxdSearchService = this.app.unbxdSearchService;

    this.categoryOnClick = this.props.onClick;
    this.filterOnClick = this.props.filterOnClick;
    this.selectedFilters = this.unbxdSearchService.selectedFilters;

    this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(
      ViewportController.EVENT_BREAKPOINT_CHANGE,
      this.viewportBreakpointChangeHandler.bind(this)
    );
  }

  render() {
    this.categories = this.getCategories();
    if (this.state.contentJson && this.categories) {
      let key = 0;
      this.renderStore.categories = this.levels.map((level) => {
        return this.categories
          .filter((cat) => {
            return cat.level == level;
          })
          .map((category) => {
            key++;
            return (
              <li
                key={category.filterParam + key}
                className="c-catalog-sidebar__group-item"
                style={{ paddingLeft: 5 * (category.level - 1) + "px" }}
              >
                <div className="c-checkbox">
                  <input
                    id={category.filterParam + key}
                    onClick={this.categoryOnClick.bind(this, category)}
                    type="checkbox"
                    defaultChecked={category.type == "breadcrumb"}
                  />
                  {category.type == "bucket" && (
                    <label htmlFor={category.filterParam + key}>
                      {category.title}{" "}
                      {category.value && <strong>({category.value})</strong>}
                    </label>
                  )}
                  {category.type == "breadcrumb" && (
                    <label htmlFor={category.filterParam + key}>
                      <strong>
                        {category.title}{" "}
                        {category.value && `(${category.value})`}
                      </strong>
                    </label>
                  )}
                </div>
              </li>
            );
          });
      });
    }

    this.filterValues = this.getFilterValues();
    if (this.state.contentJson && this.filterValues) {
      let key = 0;
      this.renderStore.otherFilters = this.filterValues.map((filter) => {
        key++;
        return (
          <div key={key} className="c-catalog-sidebar__group">
            <div className="c-catalog-sidebar__group-caption">
              <div className="c-catalog-sidebar__group-caption-left">
                  <div className="c-catalog-sidebar__group-caption-title">
                    <h5>{filter.displayName}</h5>
                  </div>
              </div>
              <div className="c-catalog-sidebar__group-caption-right"></div>
            </div>
            <div className="c-catalog-sidebar__group-content">
              <ul className="c-catalog-sidebar__group-items">
                {filter.filters.map((value) => {
                  // console.error("hello filter values", value)
                  key++;
                  // eslint-disable-next-line no-lone-blocks
                  {
                      return (
                        value?.value !== 0 ? (
                        <li key={key} className="c-catalog-sidebar__group-item">
                          <div className="c-checkbox">
                            <input
                              defaultChecked={value.isSelected}
                              type="checkbox"
                              id={value.id}
                              onClick={() => {
                                this.filterClickHandler(filter, value);
                              }}
                            />
                            <label htmlFor={value.id}>
                              {value.title}{" "}
                              <strong>
                                {value.value && `(${value.value})`}
                              </strong>
                            </label>
                          </div>
                        </li>
                        ): null
                      );
                  }
                })}
              </ul>
            </div>
          </div>
        );
      });
    }

    return (
      <div
        className={classNames("c-catalog-sidebar", {
          "c-catalog-sidebar--accordion c-catalog-sidebar--scrollable": this
            .state.isAccordion,
        })}
      >
        <div className="c-catalog-sidebar__container">
          <div className="c-catalog-sidebar__sections">
              <div
                className="c-catalog-sidebar__section c-catalog-sidebar__categories"
                section-name="categories"
              >
                <div
                  className={
                    this.state.isAccordion
                      ? "c-catalog-sidebar__section-caption"
                      : "c-catalog-sidebar__section-content"
                  }
                >
                  <div className="c-catalog-sidebar__section-content-body">
                    {/* <div className="c-catalog-sidebar__group">
                      <div className="c-catalog-sidebar__group-caption">
                        <div className="c-catalog-sidebar__group-caption-left">
                          <div className="c-catalog-sidebar__group-caption-title">
                            <h5>
                              {this.i18nService.translate([
                                this.i18nScopeKey,
                                "TITLE_LABEL",
                              ])}
                            </h5>
                          </div>
                        </div>
                        <div className="c-catalog-sidebar__group-caption-right"></div>
                      </div>
                      <div className="c-catalog-sidebar__group-content">
                        <ul className="c-catalog-sidebar__group-items mt-4 pl-1">
                          {this.renderStore.categories}
                        </ul>
                      </div>
                    </div> */}
                    {this.renderStore.otherFilters}
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }

  getCategories() {
    if (
      this.state &&
      this.state.contentJson &&
      this.state.contentJson.facets &&
      this.state.contentJson.facets.multilevel
    ) {
      let breadcrumb = this.state.contentJson.facets.multilevel.breadcrumb;
      let bucket = this.state.contentJson.facets.multilevel.bucket;
      let categories = [];
      this.breadcrumb = [];

      this.maxLevel = 0;

      if (!this.isBreadcrumbEmpty(breadcrumb)) this.getBreadcrumb(breadcrumb);

      bucket
        .map((el) => {
          let valuesLength = el.values.length;
          for (let i = 0; i < valuesLength; i += 2) {
            let index = i;
            let category = {
              filterParam: el.filterParam,
              level: el.level,
              position: el.position,
              title: el.values[i],
              value: el.values[i + 1],
              isSelected: false,
              type: "bucket",
            };
            this.maxLevel =
              this.maxLevel < category.level ? category.level : this.maxLevel;
            categories.push(category);
          }
        })
        .sort((first, second) => {
          return first.title - second.title;
        });

      this.levels = [...Array(this.maxLevel + 1).keys()];

      this.breadcrumb.sort((first, second) => {
        return first.title - second.title;
      });

      categories = categories.filter((cat) => {
        if (this.breadcrumb && this.breadcrumb.length > 0) {
          return (
            this.breadcrumb.filter((bcb) => {
              return bcb.title == cat.title && bcb.level == cat.level;
            }).length == 0
          );
        }
        return true;
      });

      this.unbxdSearchService.categoryBreadcrumb = this.breadcrumb.map(
        (elem) => {
          return { title: elem.title, level: elem.level };
        }
      );

      return this.breadcrumb.concat(categories);
    }
  }

  getBreadcrumb(breadcrumb) {
    let category = {
      filterParam: breadcrumb.filterField,
      level: breadcrumb.level,
      isSelected: true,
      title: breadcrumb.values[0].value,
      type: "breadcrumb",
    };
    this.maxLevel =
      this.maxLevel < category.level ? category.level : this.maxLevel;
    this.breadcrumb.push(category);
    if (breadcrumb.child) this.getBreadcrumb(breadcrumb.child);
  }

  isBreadcrumbEmpty(breadcrumb) {
    for (var x in breadcrumb) {
      return false;
    }
    return true;
  }

  getFilterValues() {
    this.selectedFilters = this.unbxdSearchService.selectedFilters;
    if (
      this.state &&
      this.state.contentJson &&
      this.state.contentJson.facets &&
      this.state.contentJson.facets.text
    ) {
      this.filterValues = [];
      return this.state.contentJson.facets.text.map((filter) => {
        let displayName = filter.display_name;
        let filterField = filter.filterField;

        let val = {
          facetName: filter.facetName,
          filterField: filter.field,
          displayName: filter.display_name,
          position: filter.position,
          filters: [],
        };
        if (filter.values && filter.values.length > 0) {
          let filterValues = [];
          for (let i = 0; i < filter.values.length; i += 2) {
            let filterVal = {
              title: filter.values[i],
              value: filter.values[i + 1],
              isSelected: this.isFilterSelected(filter.field + i),
              id: filter.field + i,
            };
            // filterVal.isSelected = this.state.selectedFilters && this.state.selectedFilters.length > 0 ? this.isFilterSelected(filterVal) : false;
            val.filters.push(filterVal);
          }
        }
        return val;
      });
      return [];
    }
    return [];
  }

  filterClickHandler(filter, value) {
    filter.filters = value;
    value.isSelected = !value.isSelected;

    this.filterOnClick(filter);
  }

  isFilterSelected(id) {
    if (this.state && this.selectedFilters) {
      let filterValues = this.selectedFilters;
      return (
        filterValues.filter((filt) => {
          return filt.filters.id === id;
        }).length > 0
      );
    }
    return false;
  }

  viewportBreakpointChangeHandler() {
    this.setState({
      isAccordion: this.viewportController.isMobile,
    });
  }
}
