import BaseService from "../core/base.service";
import AccountService from "./account.service";

export class BaseApiService extends BaseService {
    
    constructor(app) {
        super(app);
        this.host = this.app.environment.api.host;
        this.path = this.app.environment.api.path;
        this.apiUrl = this.host + '/' + this.path;
        this.responseResultKey;
        this.headers;
        this.queryParams;

        this.http.defaults.baseURL = this.apiUrl;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.initHeaders();
        this.accountService = this.app.accountService;
        // this.accountService.addEventListener(AccountService.EVENT_TOKEN_CHANGE, this.tokenChangeHandler.bind(this));
        // this.tokenChangeHandler();
        this.accountService.addEventListener(AccountService.EVENT_USER_CHANGE, this.userChangeHandler.bind(this));
        this.userChangeHandler();
    }

    get(endpoint, params, options) {
        if (!endpoint) endpoint = '';
        if(!options) options = {};
        if(options.responseResultKey !== undefined) this.responseResultKey = options.responseResultKey;
        
        if (params) params.forEach( item => this.setQueryParam(item.key, item.value));
        const config = {
            headers: {...this.headers, ...options.headers},
            params: this.queryParams,
            withCredentials: options.withCredentials || false,
            responseType: options.responseType || 'json',
            validateStatus: options.validateStatus
            // transformResponse: [this.map.bind(this)]
        };
        let url = Array.isArray(endpoint) ? endpoint.join('/') : endpoint;
        
        if(options.returnRawResult) {
            return this.http.get(url, config);
        }else{
            return new Promise( (resolve, reject) => {
                return this.http.get(url, config).then( result => this.successHandler(result, resolve, reject)).catch( reason => this.superHandleError(reason, reject));
            });
        }
    }

    post(endpoint, data, params, options) {
        if (!endpoint) endpoint = '';
        if(!options) options = {};
        if(options.responseResultKey !== undefined) this.responseResultKey = options.responseResultKey;
        
        if (params) params.forEach( item => this.setQueryParam(item.key, item.value));

        const config = {
            headers: {...this.headers, ...options.headers},
            params: this.queryParams,
            withCredentials: options.withCredentials || false,
            responseType: options.responseType || 'json',
            transformResponse: [this.map.bind(this)],
            validateStatus: options.validateStatus
        };
        let url = Array.isArray(endpoint) ? endpoint.join('/') : endpoint;
        if(options.returnRawResult) {
            return this.http.post(url, data, config);
        }else{
            return new Promise( (resolve, reject) => {
                this.http.post(url, data, config).then( result => this.successHandler(result, resolve, reject)).catch( reason => this.superHandleError(reason, reject));
            });
        }
    }
    

    put(endpoint, data, params, options) {
        if (!endpoint) endpoint = '';
        if(!options) options = {};
        if(options.responseResultKey !== undefined) this.responseResultKey = options.responseResultKey;
        
        if (params) params.forEach( item => this.setQueryParam(item.key, item.value));

        const config = {
            headers: {...this.headers, ...options.headers},
            params: this.queryParams,
            withCredentials: options.withCredentials || false,
            responseType: options.responseType || 'json',
            transformResponse: [this.map.bind(this)],
            validateStatus: options.validateStatus
        };
        let url = Array.isArray(endpoint) ? endpoint.join('/') : endpoint;
        if(options.returnRawResult) {
            return this.http.put(url, data, config);
        }else{
            return new Promise( (resolve, reject) => {
                this.http.put(url, data, config).then( result => this.successHandler(result, resolve, reject)).catch( reason => this.superHandleError(reason, reject));
            });
        }
    }

    delete(endpoint, options) {
        if (!endpoint) endpoint = '';
        if(!options) options = {};
        if(options.responseResultKey !== undefined) this.responseResultKey = options.responseResultKey;

        const config = {
            headers: {...this.headers, ...options.headers},
            withCredentials: options.withCredentials || false,
            responseType: options.responseType || 'json',
            transformResponse: [this.map.bind(this)],
            validateStatus: options.validateStatus
        };
        let url = Array.isArray(endpoint) ? endpoint.join('/') : endpoint;
        if(options.returnRawResult) {
            return this.http.delete(url, config);
        }else{
            return new Promise( (resolve, reject) => {
                this.http.delete(url, config).then( result => this.successHandler(result, resolve, reject)).catch( reason => this.superHandleError(reason, reject));
            });
        }
    }

    setQueryParam(key, value, encode=false) {
        if(!this.queryParams) this.queryParams = new URLSearchParams();
        if(this.queryParams.has(key)) {
            this.queryParams.set(key, encode ? encodeURIComponent(value) : value);
        }else{
            this.queryParams.append(key, encode ? encodeURIComponent(value) : value);
        }
    }

    initHeaders() {
        this.headers = {
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        // this.headers = new Headers();
        // this.headers.append('Accept', 'application/json');
        // this.headers.append('Content-Type', 'application/json');
        // this.headers.append('Access-Control-Allow-Origin', '*');
    }

    setHeaders(key, value, encode=false) {
        if(!this.headers) this.initHeaders();
        this.headers[key] = encode ? encodeURIComponent(value) : value;
        // if(this.headers.has(key)) {
        //     this.headers.set(key, encode ? encodeURIComponent(value) : value);
        // }else{
        //     this.headers.append(key, encode ? encodeURIComponent(value) : value);
        // }
    }

    map(response) {
        let payload;
        if(response && response.status) {
            payload = response.data;
            if(typeof payload === 'string') payload = _.trim(payload, '"');
            try {
                if(typeof payload === 'string') payload = this.app.parseJSON(payload);
            }catch(error) {}
        }else {
            payload = response;
        }
        
        return this.responseResultKey ? payload[this.responseResultKey] : payload;
    }

    successHandler(response, resolve, reject) {
        let payload = this.map(response);
        if(response.status) {
            switch(response.status) {
                case 200:
                    if(resolve) resolve(payload);
                break; 
                default:
                    let message;
                    message = payload.message;
                    if(payload.parameters && message) {
                        if(payload.parameters.length) {
                            payload.parameters.forEach( (parameter, index) => {
                                message = message.replace(`%${(index+1)}`, parameter);
                            });
                        }else{
                            Object.keys(payload.parameters).forEach( key => {
                                message = message.replace(`%${key}`, payload.parameters[key]);
                            });
                        }
                    }
                    this.superHandleError({status: response.status, message: message}, reject);
                break;
            }
        }else {
            payload = response;
            if(resolve) resolve(payload);
        }
    }

    superHandleError(error, reject) {
        console.log("Base Api Error : ", error);
        if(reject) reject(error);
    }

    tokenChangeHandler() {
        // if(!this.accountService.isGuestUser) this.setHeaders('Authorization', 'Bearer '+this.accountService.token);
    }

    userChangeHandler() {
        if(!this.accountService.isGuestUser) this.setHeaders('X-Requested-With', 'XMLHttpRequest');
    }

    getEndpoint(key) {
        let endpoint = this.app.environment.endpoint[key];
        if(!endpoint) return null;
        if (endpoint == 'carts') {
            if(this.accountService.isGuestUser) endpoint = 'guest-'+endpoint;
        }
        return endpoint;
    }
}