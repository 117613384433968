export default {
    i18n: {
        "HEADER": {
            "LOGIN": {
                "HELLO": "Hello",
                "ACCOUNT_URL": "/customer/account/edit",
                "SIGN_IN_URL": "/customer/account/login",
                "SIGN_IN_LABEL": "Sign in",
                "SIGN_IN_PARTNER_LABEL": "Partner Sign in",
                "SIGN_IN_MOBILE_LABEL": "Login",
                "SIGN_IN_MOBILE_PARTNER_LABEL": "Partner Login",
                "SIGN_UP_URL": "/customer/account/create",
                "SIGN_IN_PARTNER_URL": "/customer/account/partner-sign-in",
                "SIGN_UP_LABEL": "Sign up",
                "OR_LABEL": "or",
                "PARTNERS_LABEL": "Partners",
                "CREATE_ACCOUNT_LABEL": "Create Account",
                "HI": "Hi",
                "ACCOUNT_LABEL": "Account",
                "MY_ACCOUNT_LABEL": "My Account",
                "ACCOUNT_DASHBOARD": "Account Dashboard",
                "ACCOUNT_DASHBOARD_URL": "/customer/account",
                "MY_ORDERS": "My Orders",
                "MY_ORDERS_URL": "/sales/order/history/",
                "ADDRESS_BOOK": "Address Book",
                "ADDRESS_BOOK_URL": "/customer/address/new",
                "ACCOUNT_INFORMATION": "Account Information",
                "ACCOUNT_INFORMATION_URL": "/customer/account/edit",
                "STORED_PAYMENT": "Stored Payment Methods",
                "STORED_PAYMENT_URL": "/vault/cards/listaction",
                "PRODUCT_REVIEWS": "My Product Reviews",
                "PRODUCT_REVIEWS_URL": "/review/customer",
                "NEWSLETTER": "Newsletter Subscriptions",
                "NEWSLETTER_URL": "/newsletter/manage",
                "SIGN_OUT": "Sign Out",
                "SIGN_OUT_URL": "/customer/account/logout"
            }
        },
        "DRAWER_MENU": {
            "SHOW_ALL_LABEL": "Shop All"
        },
        "ADDRESS_FORM": {
            "TITLE": "Add a New Address",
            "ADDRESS_NAME_LABEL": "Address Name",
            "ADDRESS_NAME_PLACEHOLDER": "Address Name",
            "ADDRESS_NAME_HELPER_TEXT": "Your address will be saved with this name",
            "FULL_NAME_LABEL": "Full Name",
            "FULL_NAME_PLACEHOLDER": "Full Name",
            "FIRST_NAME_LABEL": "First Name",
            "FIRST_NAME_PLACEHOLDER": "First Name",
            "LAST_NAME_LABEL": "Last Name",
            "LAST_NAME_PLACEHOLDER": "Last Name",
            "EMAIL_LABEL": "Email",
            "EMAIL_PLACEHOLDER": "Email",
            "COMPANY_LABEL": "Company Name",
            "COMPANY_PLACEHOLDER": "Company Name",
            "ADDRESS_LABEL": "Address",
            "ADDRESS_PLACEHOLDER": "Address",
            "CITY_LABEL": "City",
            "CITY_PLACEHOLDER": "City",
            "POST_CODE_LABEL": "Zip Code",
            "POST_CODE_PLACEHOLDER": "Zip Code",
            "REGION_CODE_LABEL": "State",
            "REGION_CODE_PLACEHOLDER": "Select State",
            "TELEPHONE_LABEL": "Phone Number",
            "TELEPHONE_PLACEHOLDER": "Phone Number",
            "DEFAULT_ADDRESS_LABEL": "Make my default address",
            "SAVE_LABEL": "Save & Continue",
            "CANCEL_LABEL": "Cancel",
        },
        "ADDRESS_SELECT_LIST": {
            "EDIT_LABEL": "Edit Address",
            "NEW_LABEL": "Add a New Address",
            "CHANGE_LABEL": "Change or Add a New Address",
            "CHANGE_LABEL_ONLY_ADD": "Add a New Address"
        },
        "CREDIT_CARD_SELECT_LIST": {
            "EDIT_LABEL": "Edit Credit Card",
            "NEW_LABEL": "Add a New Credit Card",
            "CHANGE_LABEL": "Change or Add a New Credit Card"
        },
        "CREDIT_CARD_FORM": {
            "TITLE": "Add a New Credit Card",
            "DESCRIPTION": "Type in your credit card info for this payment. You can choose to save your credit card info in a secure environment for future payments. All transactions are fulfilled by <a href='https://www.authorize.net' target='_blank'>Authorize.Net</a>",
            "NAME_LABEL": "NAME ON CARD",
            "NAME_PLACEHOLDER": "John Doe",
            "NUMBER_LABEL": "CARD NO",
            "NUMBER_PLACEHOLDER": "---- ---- ---- ----",
            "EXPIRY_LABEL": "EXPIRATION DATE",
            "EXPIRY_PLACEHOLDER": "--/--",
            "CVC_LABEL": "CVC",
            "CVC_PLACEHOLDER": "---",
            "DEFAULT_CREDIT_CARD_LABEL": "Make my default card",
            "REMEMBER_LABEL": "Remember for future payment",
            "SAVE_LABEL": "Save & Continue",
            "CANCEL_LABEL": "Cancel",
            "SAVE_CARD_LABEL": "Save card for future payments",
            "ZIP_NOTIFICATION": {
                "TITLE": "<h5>Friendly reminder about ZIPs</h5><p>Make sure the ZIP code in your billing address matches with your credit cards ZIP. Otherwise, your payment may be rejected.</p>",
                "CUSTOM_DESCRIPTION": {
                    "BILLING_ZIP_LABEL": "Billing ZIP",
                    "EDIT_ADDRESS_LABEL": "Change Address"
                }
            },
            "CARD_VALIDATION_NOTIFICATION": {
                "TITLE": "What is CVV Number?",
                "DESCRIPTION": "The CVV Number on your credit card or debit card is a 3 digit number on VISA®, MasterCard® and Discover® branded credit and debit cards. On your American Express® branded credit or debit card it is a 4 digit numeric code."
            },
            "CVV_INFO": {
                "TITLE": "What Is the CVV/CVC Number?",
                "DESCRIPTION": "The CVV Number on your credit card or debit card is a 3 digit number on VISA®, MasterCard® and Discover® branded credit and debit cards. On your American Express® branded credit or debit card it is a 4 digit numeric code."
            },
            "ERROR_NOTIFICATION": {
                "TITLE": "<h5>Hmm – something’s not right.</h5><p>We were unable to process the credit card information provided. Try checking on the following items:</p>",
                "INFO": "<b>Still having trouble?</b> You can call us at <b>+1 424 373 3663</b> from <b>8am-8pm EST Monday-Friday</b>, and we’ll help you complete your order."
            }
        },
        "CART_NOTIFICATION": {
            "TITLE": "Shopping Cart",
            "NOTIFICATION_TITLE": "A new item was succesfully added",
            "NOTIFICATION_DESCRIPTION": "Spend another {remainingAmount, number, currency} to qualify for free",
            "NOTIFICATION_DESCRIPTION_FREE_SHIPPING": "Free shipping",
            "SUB_TOTAL_LABEL": "Sub Total",
            "SUB_TOTAL_COUNT": "{count} items",
            "GO_TO_SHOPPING_CART_LABEL": "Go to Shopping Cart",
            "EMPTY_CART_MESSAGE": "<p>You have<span> no items </span> in your shopping cart.</p>",
            "FREE_SHIPPING_INFO": "Free Shipping over {freeShippingAmount, number, currency}",
            "CART_REMOVE_SUCCESS_MESSAGE": "Cart item removed successfully",
            "CONTINUE_SHOPPING_LABEL": "Continue Shopping",
            "CHECKOUT_LABEL":"Check Out"
        },
        "CATALOG_SEARCH_BAR": {
            "ALL_CATEGORIES": "All Categories",
            "ALL_CATEGORIES_SHORT": "Category",
            "SEARCH_INPUT_PLACEHOLDER": "What are you looking for?",
            "SEARCH_INPUT_PLACEHOLDER_SHORT": "Search...",
            "SUGGEST_TYPE": {
                "brand": "BRAND",
                "product": "PRODUCT",
                "popular_searches": "POPULAR",
                "recent_searches": "RECENT",
                "category": "CATEGORY"
            },
            "UNBXD_SUGGEST_TYPE": {
                "categories": "Categories",
                "key_suggestions": "Key Suggestions",
                "brands": "Brands",
                "top_search_queries": "Top Searches"        // changed
            },
            "VIEW_ALL_LABEL": "View All Results",
            "NO_RESULT_LABEL": "Search for \"{query}\"",
            "HISTORY_LABEL": "History"                      // new
        },
        "CATEGORY_EXPLORER": {
            "SEE_ALL": "See All"
        },
        "CREDIT_CARD_ADD_SELECT": {
            "ADD_NEW": "Add a New Credit Card",
            "NAME_ON_CARD": "Name on Card",
            "CREDIT_CARD_NUMBER": "Credit Card Number",
            "EXPIRATION_DATE": "Expiration Date",
            "EXPIRATION_MONTH": "Expiration Month",
            "EXPIRATION_YEAR": "Expiration Year",
            "SELECT": "Select",
            "CVC": "CVC",
            "USE_THIS_CREDIT_CARD": "Use this credit card",
            "CANCEL": "Cancel",
            "CONTINUE": "Continue"
        },
        "ORDER_SUMMARY_BOX": {
            "TOTAL_LABEL": "Total",
            "SAVE_LABEL": "You save { saveAmount, number, currency }",
            "APPLY_CODE_LABEL": "Apply code ( optional )",
            "CHECKOUT_LABEL": "Place Your Order",
            "SUB_TOTAL_LABEL": "Sub Total",
            "ORDER_SUMMARY_LABEL": "Order Summary",
            "SHIPPING_LABEL": "Shipping",
            "FREE_SHIPPING_INFO_LABEL": "Currently you are at { currentAmount, number, currency }, you need { remainingAmount, number, currency } to qualify to free shipping.",
            "FREE_SHIPPING_SUCCESS_LABEL": "Congratulations, you've reached the {requiredAmountForFreeShipping, number, currency} threshold, we will not charge a shipping fee for the items in this cart.",
            "SALES_TAX_LABEL": "Sales Taxes",
            "BUSINESS_OWNER_LABEL": "Business Owner?",
            "UPLOAD_TAX_EXEMPTION_FORM": "{uploadAnchor} a TaxExemption Form",
            "UPLOAD_LABEL": "Upload",
            "TERMS_CHECK_LABEL": "By placing an order, you are agreeing to our {privacyAnchor} and {termsOfUseAnchor}",
            "PRIVACY_POLICY": "Privacy Policy",
            "TERMS_OF_USE": "Terms of Use",
            "TOTAL_AMOUNT_LABEL": "{totalAmount, number, currency}",
            "SHIPPING_AMOUNT_LABEL": "{shippingAmount, number, currency}",
            "SALES_TAX_AMOUNT_LABEL": "{salesTaxAmount, number, currency}",
            "GO_TO_CHECKOUT_LABEL": "Checkout",
            "STORE_CREDIT_LABEL": "Store Credit"
        },
        "SHIPPING_METHOD_LIST": {
            "TITLE_LABEL": "Shipping Methods",
            "SHIPPING_AMOUNT_LABEL": "{ shippingAmount, number, currency }",
            "NO_DATA_TITLE": "There is no selected shipping address.",
            "NO_DATA_DESCRIPTION": "Select or add a new shipping address to view available shipping methods."
        },
        "CART_ITEM": {
            "REMOVE_ITEM_ERROR_MESSAGE": "Can't remove item from cart"
        },
        "FILE_UPLOAD": {
            "UPLOAD_FILE_LABEL": "Upload File",
            "DELETE": "Delete"
        },
        "TAX_EXEMPTION_FILE": {
            "UPLOAD_FILE_LABEL": "Upload Tax Exemption File",
        },
        "APPLY_INPUT": {
            "SUBMIT_LABEL": "Apply"
        },
        "APPLY_CODE_LIST": {
            "CAPTION_TITLE": "Promotion Code",
            "COUPON_CODE_PLACEHOLDER": "Enter Code",
            "COUPON_ERROR_MESSAGE": "Coupon code is not valid"
        },
        "CHECKOUT": {
            "STEPS": {
                "ADDRESS": {
                    "TITLE": "Shipping Address & Billing Info",
                    "SHORT_TITLE": "Delivery",
                    "CONTINUE_LABEL": "Continue",
                    "SECTIONS": {
                        "DELIVERY": {
                            "TITLE": "Delivery Address",
                            "DESCRIPTION": "Select the address you'd like to use or you can {addNewShippingAddress}.",
                            "ADD_NEW_SHIPPING_ADDRESS_LABEL": "enter a new shipping address",
                            "PHONE": "Phone",
                            "EDIT_ADDRESS": "Edit Address",
                            "ADD_NEW_ADDRESS": "Change or Add a New Address",
                            "SELECTED": "Delivery"
                        },
                        "BILLING": {
                            "TITLE": "Billing Info",
                            "SAME_AS_SHIPPING_LABEL": "Use as delivery address",
                            "ADD_NEW_BILLING_ADDRESS_LABEL": "enter a new billing address",
                            "PHONE": "Phone",
                            "EDIT_ADDRESS": "Edit Address",
                            "ADD_NEW_ADDRESS": "Add a New Address",
                            "SELECTED": "Billing"
                        },
                        "SHIPPING_METHOD": {
                            "TITLE": "Shipping Methods",
                        }
                    }
                },
                "PAYMENT": {
                    "TITLE": "Payment Method",
                    "SHORT_TITLE": "Payment",
                    "CONTINUE_LABEL": "Continue",
                    "SECTIONS": {
                        "PAYMENT": {
                            "TITLE": "Select a payment method",
                            "FREE_TITLE": "No payment required",
                            "APPLE_PAY_NOT_SUPPORTED": "Your browser or device does not support Apple Pay on the web. To pay with Apple Pay, open this page in Safari on a compatible device."
                        }
                    },
                },
                "REVIEW": {
                    "TITLE": "Review Your Order",
                    "SHORT_TITLE": "Order",
                    "CONTINUE_LABEL": "Continue",
                    "EDIT_CART_LABEL": "Edit your cart"
                }
            },
            "MESSAGES": {
                "SHIPPING_ADDRESS_SELECT": {
                    "ERROR": {
                        "TITLE": "There is no available shipping method for this address"
                    }
                }
            },
            "PAYMENT_METHODS": {
                "FREE": {
                    "DESCRIPTION": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec iaculis urna et lorem lacinia interdum non id augue. Donec consectetur, diam nec porta vestibulum, orci metus egestas mauris, in ultricies leo magna nec felis.",
                    "CTA_LABEL": "Continue as Free"
                },
                "AUTHNETCIM": {
                    "DESCRIPTION": "",
                    "CTA_LABEL": "Continue"
                },
                "PAYPAL": {
                    "DESCRIPTION": "Continuing will take you to your PayPal account. Afterward, you\'ll be able to review and submit your order.",
                    "CTA_LABEL": "Log in into your PayPal Account"
                },
                "APPLE_PAY": {
                    "DESCRIPTION": "Continuing will take you to your PayPal account. Afterward, you\'ll be able to review and submit your order.",
                    "CTA_LABEL": "Log in into your ApplePay Account"
                }
            },
            "ASIDE": {
                "TERMS_CHECK_LABEL": "By placing an order, you are agreeing to our {privacyAnchor} and {termsOfUseAnchor}",
                "PRIVACY_POLICY": "Privacy Policy",
                "TERMS_OF_USE": "Terms of Use",
                "CART_ITEM_COUNT": "{count, plural, =0 {No Item}=1 {# Item}other {# Items}} in Cart",
                "GOTO_CART_LABEL": "View / Edit Cart"
            },
            "ERROR_NOTIFICATION": {
                "TITLE": "Hmm – that didn’t work.",
                "DESCRIPTION": "Make sure you are signed in and/or that the code is still valid."
            }
        },
        "VALIDATE": {
            "REQUIRED": "can't be blank",
            "REGION_CODE_NOT_MATCH": "not available for selected state"
        },
        "PROMOTION_CODE_LIST": {
            "ACTION_TITLE": "Promotional Codes",
            "APPLY_INPUT_PLACEHOLDER": "Enter your code"
        },
        "FREE_SHIPPING_BOX": {
            "QUALIFIED_FOR_SHIPPING": "Qualified for free shipping",
            "QUALIFYING_TO_SHIPPING": "Qualifying to free shipping",
            "QUALIFIED_FOR_INFO": "Spend an additional {remainingAmount, number, currency} to qualify for free shipping.",
            "QUALIFIED_TO_INFO": "Congratulations, you've reached the {requiredAmountForFreeShipping, number, currency} threshold, we will not charge a shipping fee for the items in this cart."
        },
        "CART": {
            "TITLE": "Your Cart",
            "KEEP_SHOPPING_LABEL": "Keep Shopping",
            "EMPTY_CART_LABEL": "Empty Cart",
            "NO_ITEM_TITLE": "Your Cart is Empty",
            "NO_ITEM_DESCRIPTION": "It looks like your cart is empty. Let's find what you need.",
            "START_SHOPPING_LABEL": "Start Shopping",
            "SECURE_TITLE": "Your Information is Secure",
            "SECURE_DESCRIPTION": "We do not share your personal information, address, or contact information with anyone.",
            "SIGN_CTA_TITLE": "Sign in to complete your order faster.",
            "SIGN_CTA_DESCRIPTION": "Don’t have an account? That's OK – you can make one now to save time on future check outs.",
            "SIGN_CTA_LABEL": "Register Now!",
            "SIGN_CTA_SIGN_IN": "<p>Already have an account ? <a href='sign-in.html'>Sign in</a></p>"
        },
        "CART_ITEM_TABLE": {
            "REMOVE_LABEL": "Remove",
        },
        "PRODUCT_SUMMARY_TABLE": {
            "QUANTITY_LABEL": "Quantity",
            "EACH_LABEL": "each",
            "SAVE_LABEL": "You save"
        },
        "MAIL_SUB_BAR": {
            "TITLE": "Subscribe To Our Newsletter",
            "SUB_TITLE": "SUBSCRIBE TO OUR NEWSLETTER TO RECEIVE NEWS ON UPDATE",
            "SIGN_UP": "Sign up",
            "TRY_AGAIN": "Try Again",
            "GO_BACK": "Go Back",
            "INPUT_PLACEHOLDER": "Enter your e-mail address"
        },
        "CHECKOUT_SUMMARY_LIST": {
            "SHIPPING_ADDRESS": "Shipping Address",
            "BILLING_ADDRESS": "Billing Address",
            "PAYMENT_METHOD": "Payment Method",
            "STORE_CREDIT": "Store Credit",
            "STORE_CREDIT_INFO": "<strong>{ storeCreditAmount, number, currency }</strong> will be paid from your store credits",
            "CHANGE_LABEL": "Change"
        },
        "CHECKOUT_SIGN_UP_FORM": {
            "CUSTOMER_GROUP_PLACEHOLDER": "Select",
            "CUSTOMER_GROUP_LABEL": "Customer Group",
            "PASSWORD_PLACEHOLDER": "Password",
            "PASSWORD_LABEL": "Password",
            "CONFIRM_PASSWORD_PLACEHOLDER": "Confirm Password",
            "CONFIRM_PASSWORD_LABEL": "Confirm Password",
            "SIGN_UP_LABEL": "Sign Up Now"
        },
        "STORE_CREDIT": {
            "TITLE": "Pay with your store credit",
            "AVAILABLE_CREDIT_LABEL": "Available"
        },
        "PAYPAL": {
            "ERROR_MESSAGE": {
                "ADDRESS_ERROR": "Error! PayPal has declined this request because the address provided does not match PayPal's records."
            }
        },
        "HOME_PAGE_RECOMMENDATIONS": {
            "TITLE": "Top Selling Products",
            "NO_SHIPPING": "No Shipping",
            "VIEW_ALL_LABEL": "View All"
        },
        "PRODUCT_DETAIL_RECOMMENDATIONS": {
            "TITLE": "Similar Products"
        },
        "CART_RECOMMENDATIONS": {
            "TITLE": "You May Also Like"
        },
        "SEARCH_RESULT_PAGE": {
            "SHOWING_LABEL": "Showing <strong>{currentPage}-{pageSize}</strong> / {numberOfProducts} Products for <strong> {queryString} </strong>",
            "SHOW_FILTERS_LABEL": "Show Filters",
            "SORT_BY_LABEL": "Sort By",
            "SEARCH_RESULTS_LABEL": "Search Results",
            "PRODUCT_NAME_ASC": "Product Name: A-Z",
            "PRODUCT_NAME_DESC": "Product Name: Z-A",
            "PRICE_DESC": "Price: High to Low",
            "PRICE_ASC": "Price: Low to High",
            "SHIPS_IN_ASC": "Ships In (days) : Low to High",
            "SHIPS_IN_DESC": "Ships In (days) : High to Low",
            "RELEVANCE": "Relevance",
            "PRICE_LABEL": "{ price, number, currency }",
            "NO_RESULT_LABEL": "Sorry, no results found for {queryString}",
            "SHOWING_NO_PRODUCT_LABEL": "Showing <b><i>0</i></b> Products",
            "DID_YOU_MEAN_LABEL": "Did you mean <a href='{didYouMeanUrl}'>{didYouMeanString}?</a>"
        },
        "SEARCH_SIDE_BAR": {
            "TITLE_LABEL": "CATEGORIES"
        },
        "MANUFACTURER_BRANDS_EXPLORER": {
            "FEATURED_BRANDS_LABEL": "Featured Brands"
        },
        // new area for categories
        "FILTER_BAR": {
            "ALL_FILTER": "All Filters",
            "FILTERS_BUTTON_TITLE": "All Filters"
        },
        "PILL_GROUP": {
            "MORE_BUTTON_LESS_TITLE": "Show Less",
            "MORE_BUTTON_MORE_TITLE": "More",
            "CLEAR_ALL_FILTERS_TEXT": "Clear All Filters",
            "SHOWING_LABEL_TEXT": "Showing <b>{currentProductsCount} / {totalProductsCount} </b> Products"
        }
    }
}