import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';

export default class NotificationBoxComponent extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);
        this.domElement;
        this.notification = props.notification;

        if(this.notification.timeout) setTimeout(() => {
            this.close();
        }, this.notification.timeout);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.dialogController = this.app.getController('dialog');
    }

    componentDidMount() {
        this.gestureManager = new Hammer(this.domElement);
        this.gestureManager.on('swiperight', this.close.bind(this));
    }
  
    componentWillUnmount() {
        this.gestureManager.destroy();
    }
    
    render() {
        let className = classNames(
            'c-notification',
            {
                ['c-notification--'+this.notification.theme]: this.notification.theme
            }
        )
        return (
            <div ref={r => this.domElement = r} className={className}>
                <div className="c-notification__container">
                    <p className="c-notification__info">{this.notification.text}</p>
                    <a onClick={(e) => this.close()} className="c-notification__close icon__fsd icon__fsd--close-white"></a>
                </div>
            </div>
        )
    }
    
    close() {
        this.app.closest('.c-dialog-box', this.domElement).then( dialogBoxElement => {
            this.dialogController.dismiss(null, dialogBoxElement.getAttribute('dialog-ref-id'));
        }).catch( reason => {
            this.dialogController.dismiss();
        });
    }
}