import Axios from "axios";

function initAfterpay(id,layoutController,dialogController) {
        let state;
        let excludedStates = window.FsdApp.settings.excludedStates;
        AfterPay.initializeForPopup({
            countryCode: 'US',
            onCommenceCheckout: function (actions) {
                fetch("/afterpay/express/createCheckout", { method: 'post', headers: { 'X-Requested-With': 'XMLHttpRequest' } }).then(res => res.json()).then(res => actions.resolve(res.afterpay_token));
            },
            onShippingAddressChange: function (data, actions) {
                state  = data.state;
                fetch("/afterpay/express/getShippingOptions",
                    {
                        method: 'post',
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: new URLSearchParams(data)
                    }).then(res => res.json()).then(res => actions.resolve(res.shippingOptions));
            },
            onComplete: function (data) {
                if(excludedStates.includes(state)){
                            dialogController.notify({
                              theme: "danger",
                              text: "Transaction declined",
                            });
                }
                else if(data.data.orderToken){
                    layoutController.showLoading();
                    Axios.post("/afterpay/express/placeOrder",'orderToken=' + data.data.orderToken + '&status=' + data.data.status,{
                      headers:{
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
                    }).then(res=>{
                      window.location.href = "/checkout/onepage/success"
                    }).catch(err=>{
                      layoutController.hideLoading();
                            dialogController.notify({
                              theme: "danger",
                              text: "Transaction declined",
                            });
                    })
                }
            },
            target: `#${id}`,
            shippingOptionRequired: true,
        })
}
export async function createAfterpayWidget () {
    const token = await fetch("/afterpay/express/createCheckout", { method: 'post', headers: { 'X-Requested-With': 'XMLHttpRequest' } }).then(res => res.json()).then(res => res.afterpay_token);

    window.afterpayWidget = new AfterPay.Widgets.PaymentSchedule({
      token,
      target: '#afterpay-widget-container',
      locale: 'en-US', 
      onReady: function (event) {
          console.trace(event);
        // Fires when the widget is ready to accept updates.  
      },
      onChange: function (event) {
        // Fires after each update and on any other state changes.
        // See "Getting the widget's state" for more details.
      },
      onError: function (event) {
        // See "Handling widget errors" for more details.
      },
    })
  }
export default initAfterpay;