import React, {Component} from "react";
import ReactDOM from "react-dom";
import { DomComponent } from "../core/dom.component";
import BaseReactComponent from "../core/base-react.component";
import { Button as ReactButton } from "@material-ui/core";
import classNames from "classnames";

export class ButtonComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        this.component = ReactDOM.render(<Button app={this.app} currentProps={this.getAttributes()} />, this.domElement);
    }
}

ButtonComponent.decorator = {
    selector: 'fsd-button'
}

export class Button extends BaseReactComponent {
    constructor(prop, context) {
        super(prop, context, {
            defaultProps: {
                variant: 'button',
                theme: 'primary',
                label: undefined,
                disabled: false,
                error: false,
                fullWidth: false,
                autoFocus: false,
                size: null,
                isProccessing: false
            }
        });

        this.renderStore = {
            componentClassName: undefined,
            button: undefined
        }
        
        this.state = {
            error: this.currentProps.error,
            isFocused: this.currentProps.autoFocus,
            isProccessing: this.currentProps.isProccessing
        };
    }

    componentWillReceiveProps(props) {
        let changes = _.pick(props, 'isProcessing,label,disabled,theme'.split(','));
        this.setState(changes);
    }

    render() {
        this.superRender();
        this.renderStore.componentClassName = classNames(
            'c-button',
            { 
                'c-button--disabled': this.currentProps.disabled,
                'c-button--error': this.state.error,
                'c-button--focused': this.state.isFocused,
                'c-button--processing': this.state.isProcessing,
                'c-button--full-width': this.currentProps.fullWidth,
                ['c-button--'+this.currentProps.theme]: this.currentProps.theme,
                ['c-button--'+this.currentProps.variant]: this.currentProps.variant,
                ['c-button--'+this.currentProps.size]: this.currentProps.size,
            }
        );
        
        switch(this.currentProps.variant) {
            case 'button':
            case 'outline':
                this.renderStore.button = <ReactButton 
                                            onClick={this.props.onClick}
                                            data-test-id={this.props.dataTestId}
                                            variant={this.currentProps.variant}
                                            classes={{
                                                root: this.renderStore.componentClassName,
                                                label: 'c-button__label'
                                            }}>
                                            {this.currentProps.label || this.props.children}
                                            </ReactButton>
            break;
            case 'icon':

            break;
            case 'anchor':
            case 'link':
            case 'text':
                this.renderStore.button = <ReactButton 
                                            onClick={this.props.onClick}
                                            data-test-id={this.props.dataTestId}
                                            variant="text"
                                            classes={{
                                                root: this.renderStore.componentClassName,
                                                label: 'c-button__label'
                                            }}>
                                            {this.currentProps.label || this.props.children}
                                            </ReactButton>
            break;
            default: 
                this.renderStore.button = <ReactButton 
     
                                            onClick={this.props.onClick}
                                            data-test-id={this.props.dataTestId}
                                            variant={this.currentProps.variant}
                                            classes={{
                                                root: this.renderStore.componentClassName,
                                                label: 'c-button__label'
                                            }}>
                                            {this.currentProps.label || this.props.children}
                                            </ReactButton>
            break;
        }

        return this.renderStore.button;
    }
}