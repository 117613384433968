import { DomComponent } from "../core/dom.component";
import ReactDOM from 'react-dom';
import React,{lazy,Suspense} from 'react';
const MstsRegisterFormComponent = lazy(()=>import('./msts.registerform'));
const SuccessPage = lazy(()=>import('./successpage'));

export  class MstsRegisterForm extends DomComponent {
    constructor(app, element) {
      super(app, element);
    }
  
    appOnInit() {
      this.component = ReactDOM.render(
        <Suspense fallback={null}>
            <MstsRegisterFormComponent app={this.app} />
        </Suspense>,
        this.domElement
      );
    }
  }
  
  MstsRegisterForm.decorator = {
    selector: "msts-form",
  };
  export class SuccessPageComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }
    appOnInit() {
        this.component = ReactDOM.render(
        <Suspense fallback={null}><SuccessPage app={this.app} currentProps={this.getAttributes()} /></Suspense>, this.domElement);
    }
}

SuccessPageComponent.decorator = {
    selector: 'fsd-checkout-signup-form'
}
