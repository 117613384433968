import { BaseApiService } from "./base-api.service";
import { RouterController } from "../controllers/router.controller";
import GraphqlService from "./base-graphql.service";

export default class CategoryService extends BaseApiService {

    constructor(app) {
        super(app);
        this.categories = [];

        this.queryParams = null;
    }

    appOnInit() {
        super.appOnInit();

        this.routerController = this.app.getController('router');

        this.setPageInfo();
        this.setCurrentCategory();
        this.setFilterGroups();

        this.routerController.addEventListener(RouterController.EVENT_QUERY_PARAMS_CHANGE, this.queryParamChangeHandler.bind(this));
        this.queryParamChangeHandler();
        this.getSelectedFilters();
    }
    getProductImages(sku){
        const http = new GraphqlService(this.app);
        return new Promise( (resolve, reject) => {
            http.graphql({
                query:`{
                    products :product_details(
                      skus_comma_separated:"${sku}"
                    ){
                        sku
                        is_in_stock
                        vendor_id
                        title:name
                        image
                        product_type
                        product_review{
                            review_count
                            rating_avg
                        }

                        productUrl:url
                        liquidation
                        liquidation_expiry_date
                        new_arrivals
                        new_arrivals_expiry_date
                        rebate_eligibility
                        shipping_type
                        ships_in
                        isSpecialPriceAvailable:special_price
                        special_price
                        ships_in
                        price
                        mp_price
                        mp_special_price
                        mp_special_from_date
                        mp_special_to_date
                        sold_as
                        ships_in_days
                        stock_type
                    }
                  }`
            }).then(res=> {
                resolve(res.products);
            })
        });
    }

    getBrandAndCategoryData(sku) {
        const http = new GraphqlService(this.app);
        return new Promise((resolve, reject) => {
            http.graphql({
                query: `{
                    products :product_details(
                      skus_comma_separated:"${sku}"
                    ){
                        sku
                        brand
                        category_l1
                        category_l2
                        category_l3
                        category_l4
                    }
                  }`
            }).then(res => {
                resolve(res.products);
            })
        });
    }
    getSearchProductData(sku){
        const http = new GraphqlService(this.app);
        return new Promise( (resolve, reject) => {
            http.graphql({
                query:`{
                    products :product_details(
                      skus_comma_separated:"${sku}"
                    ){
                        sku
                        vendor_id
                        image
                        name
                        special_price
                        price
                        url
                        ships_in
                        shipping_type
                        is_in_stock
                        qty
                        liquidation
                        liquidation_expiry_date
                        new_arrivals
                        new_arrivals_expiry_date
                        rebate_eligibility
                        mp_price
                        mp_special_price
                        mp_special_from_date
                        mp_special_to_date
                        product_review{
                            review_count
                            rating_avg
                        }
                        sold_as
                        ships_in_type
                        ships_in_days
                        stock_type
                        min_sale_qty
                        max_sale_qty
                    }
                  }`
            }).then(res=>{
                resolve(res.products);
            })
        });
    }
    setFilterGroups() {
        if(document.getElementsByClassName('json-filters').length){
            this.filterGroups = JSON.parse(document.getElementsByClassName('json-filters')[0].getAttribute('json-filters'));
            this.dispatchEvent(CategoryService.EVENT_FILTERS_SET);
        }
    }

    setCurrentCategory() {
        this.currentCategory = this.app.getJSON('current-category');
        this.dispatchEvent(CategoryService.EVENT_CURRENT_CATEGORY_SET);
    }

    setPageInfo() {
        this.pageInfo = this.app.getJSON('page-info');
        this.dispatchEvent(CategoryService.EVENT_PAGE_INFO_SET)
    }

    setCategories(categories, isFlatData) {
        if (isFlatData) {
            this.fillCategory(categories, categories.filter(item => item.level == 2), this.categories);
        } else {
            this.categories = categories;
        }
        this.categories = _.sortBy(this.categories, this.getSortOption('position'));
        this.dispatchEvent(CategoryService.EVENT_CATEGORIES_CHANGE);
    }

    getCategories() {
        return new Promise((resolve, reject) => {
            if (!this.categories || !this.categories.length) {
                // let endpoint = this.app.environment.name == 'development' ? 'https://www.foodservicedirect.com/rest/V1/get-categories/list?searchCriteria=[]' : 'get-categories/list?searchCriteria=[]';
                let endpoint = this.app.environment.name == 'development' ? 'https://foodservicedirect.com/rest/V1/get-categories/list?searchCriteria=[]' : 'get-categories/list?searchCriteria=[]';

                this.get(endpoint).then(result => {
                    if (result && result.items) {
                        this.setCategories(result.items, true);
                    }
                    resolve(this.categories)
                }).catch(reason => reject(reason));
            } else {
                resolve(this.categories);
            }
        });
    }

    fillCategory(dataSource, sourceArray, targetArray) {
        if (!dataSource || !dataSource.length) return;

        const sort = this.getSortOption('name');

        let categoryItem, childIds, newSource;
        sourceArray.forEach(item => {

            categoryItem = {
                children: item.children,
                id: item.id,
                level: item.level,
                name: item.name,
                parentId: item.parent_id,
                position: item.position,
                productCount: item.product_count,
                previewImage: item.preview_image,
                url: item.url,
                items: []
            }
            targetArray.push(categoryItem);

            childIds = item.children ? item.children.split(',') : null;
            if (childIds && childIds.length) {
                newSource = dataSource.filter(childItem => childIds.indexOf(childItem.id.toString()) >= 0);
                newSource = _.sortBy(newSource, sort);
                this.fillCategory(dataSource, newSource, categoryItem.items);
            }
        });

    }

    getSortOption(key) {
        return {
            'position': (o) => { return parseInt(o.position, 10) },
            'name': ['name']
        }[key || 'name'];
    }

    getSubCategories(levels) {
        if (!this.categories) Promise.reject(null);
        let parentIds = levels.split(',');
    }

    getSelectedFilters() {
        if(this.filterGroups && this.filterGroups.length > 0) {
            for (const [key, value] of this.queryParams.entries()) {
                let filters = this.filterGroups.filter(filter => {
                    return filter.name === key;
                });
                if (filters) {
                    filters.forEach(filter => {
                        filter.items.filter(item => {
                            return item.value === value;
                        }).forEach(item => {
                            item.selected = true;
                        });
                    });
                }
            }
        }        
    }

    addFilter(filter) {
        this.queryParams.set(filter.name, filter.value);
        this.applyFilter();
    }

    removeFilter(filter) {
        this.queryParams.delete(filter.name);
        this.applyFilter();
    }

    removeAllFilters() {
        this.filterGroups.forEach(filter => {
            this.queryParams.delete(filter.name);
        });
        this.applyFilter();
    }

    applyFilter() {
        let queryParams = {};
        for (const [key, value] of this.queryParams.entries()) {
            queryParams = { ...queryParams, [key]: value }
        }

        this.routerController.navigate(null, {
            queryParams: queryParams,
            preserveQueryParams: true
        });
    }

    queryParamChangeHandler() {
        this.queryParams = this.routerController.queryParams;
    }

    get pills() {

        if(this.filterGroups) {
          let pills = [];
          this.filterGroups.forEach(filter => {   
            let pillArr = filter.items.filter(f => { return f.selected }).map(item => {
              return {
                ...item,
                title: filter.title,
                name: filter.name
              }
            });
            pills = pills.concat(pillArr);
          });
    
          return pills;
        }
    
      }

}

CategoryService.EVENT_CATEGORIES_CHANGE = "categories-change";
CategoryService.EVENT_FILTERS_SET = "filters-set";
CategoryService.EVENT_CURRENT_CATEGORY_SET = "current-category-set";
CategoryService.EVENT_PAGE_INFO_SET = "page-info-set";