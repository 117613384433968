import BaseReactComponent from "../core/base-react.component";
import React, { Component, Fragment } from "react";
import classNames from "classnames";
import AccountService from "../services/account.service";
import HeaderComponent from "./header.component";
import { ViewportController } from "../controllers/viewport.controller";
import UAParser from "ua-parser-js";
import Cookies from "universal-cookie";
import CartService from "../services/cart.service";

let cookies = new Cookies();
let pdpV2 = document.getElementById('pdp-v2')
// let pdpV2 = document.getElementsByClassName('p-product-detail__content')
// let endpoint = "http://unliverlocal.dev.emb/rest/V1/fsd/get-ip-location"
// let endpoint = "https://api.ip2location.com/v2/?ip=119.160.99.117&key=TPRZEY8UER&package=WS12";


export default class HeaderSignsComponent extends BaseReactComponent {
  constructor(props, context) {
    super(props, context);

    this.i18nScopeKey = "HEADER_SIGNS";

    this.uaParser = new UAParser();
    this.deviceInfo = this.uaParser.getResult();

    this.renderStore = {
      componentClassName: null,
      desktopSigns: null,
      mobileSigns: null,
      menuBarOpenItems: null,
    };

    this.state = {
      user: null,
      isExpanded: false,
      isShrink: false,
      mobileSignOpened: false,
      postal_code: null,
      changeDisplay: "block",
      regexp: /^[0-9\b]+$/,
      inputValue: "",
      validationError: false,
    };

    this.changeZipModal = this.changeZipModal.bind(this);
    this.handleZipUpdate = this.handleZipUpdate.bind(this);
    this.handleZipCodeCheck = this.handleZipCodeCheck.bind(this);
    this.logoutHandler = this.logoutHandler.bind(this);

  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.accountService = this.app.accountService;
    this.menuController = this.app.getController("menu");
    this.viewportController = this.app.getController("viewport");
    this.geoCodeSettings = this.app.settings.geocodeOptions;
    this.clickOutside = this.handleClickOutside.bind(this);
    this.getZipCode = this.getZipCode.bind(this);
    this.mageService = this.app.mageService;
    this.cartService = this.app.cartService;
    this.cartService.addEventListener(
      CartService.EVENT_CHANGE,
      this.cartChangeHandler.bind(this)
    );

    this.app
      .getController("layout")
      .header.addEventListener(
        HeaderComponent.EVENT_SHRINK,
        this.handleShrink.bind(this)
      );
    this.accountService.addEventListener(
      AccountService.EVENT_USER_CHANGE,
      this.userChangeHandler.bind(this)
    );
    this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(
      ViewportController.EVENT_BREAKPOINT_CHANGE,
      this.viewportBreakPointChangeHandler.bind(this)
    );
  }

  async componentDidMount(){
    this.cartChangeHandler();
  }

  cartChangeHandler() {
    this.setState({
        cart: this.cartService.cart,
      });
  }

  getZipCode() {

      if (!cookies.get("selectedPostalCode")) {
        console.log("cookie No")
        if (this.accountService.isLoggedIn) {
          console.log("cookie user logged In")
          let addresses = this.accountService.user.addresses;
          console.log("cookie user logged In user address", addresses)
          for (var i = 0; i < addresses.length; i++) {

            if (addresses[i].default_shipping) {
              console.log("cookie shipping address exist", addresses[i].postcode)
              cookies.set("selectedPostalCode", addresses[i].postcode, {
                path: "/",
              });
              this.setState({
                postal_code: addresses[i].postcode,
              });
            }
            else {
              console.log("cookie shipping address not exist ", addresses[i].default_shipping);
            }
          }
        }
        else {
          console.log("cookie user logged off and value not in cookie ")
        }
      }
      else if (cookies.get("selectedPostalCode") === "undefined") {
        console.log("cookie is undefined")
        cookies.set("selectedPostalCode", '', {
          path: "/",
        });
        this.setState({
          postal_code: null,
        });
      }
      else if (cookies.get("selectedPostalCode")) {
        this.setState({
          postal_code: cookies.get("selectedPostalCode"),
        });
        // if (this.accountService.isLoggedIn) {
        //   console.log("cookie user logged In")
        //   let addresses = this.accountService.user.addresses;
        //   console.log("cookie user logged In user address", addresses)
        //   for (var i = 0; i < addresses.length; i++) {

        //     if (addresses[i].default_shipping) {
        //       cookies.set("selectedPostalCode", addresses[i].postcode, {
        //         path: "/",
        //       });
        //       console.log(" cookie user logged In cookie shipping address exist", addresses[i].postcode)

        //       this.setState({
        //         postal_code: addresses[i].postcode,
        //       });
        //     }
        //     else {
        //       console.log("cookie post code exist in cookie ", addresses[i].default_shipping);
        //       this.setState({
        //         postal_code: cookies.get("selectedPostalCode"),
        //       });
        //     }
        //   }
        //   console.log("cookie post code address length ", addresses.length);

        //   if (addresses.length === 0) {
        //     console.log("cookie post code enter 0 length ");
        //     this.setState({
        //       postal_code: cookies.get("selectedPostalCode"),
        //     });
        //   }

        // }
        // else {
        //   console.log("cookie yes but user looged off ")
        //   this.setState({
        //     postal_code: cookies.get("selectedPostalCode"),
        //   });
        // }
      }
  }

  logoutHandler(){
    cookies.remove("selectedPostalCode", { path: "/" });
    cookies.remove("selectedAdressType", { path: "/" });
  }

  render() {
    this.renderStore.componentClassName = classNames(
      "c-header__menu-bar-account-menu",
      {
        ["c-header__menu-bar-account-menu--expanded"]: this.state.isExpanded,
        ["c-header__menu-bar-account-menu-sign--expanded"]: this.state
          .mobileSignOpened,
      }
    );

    this.renderStore.menuBarOpenItems = (
      <ul className="c-header__menu-bar-account-menu-open-items">
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "ACCOUNT_DASHBOARD_URL",
            ])}
          >
            {this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "ACCOUNT_DASHBOARD",
            ])}
          </a>
        </li>
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href="/rebates/gpo/index"
          >
            Food Service Rebates
          </a>
        </li>
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "MY_ORDERS_URL",
            ])}
          >
            {this.i18nService.translate(["HEADER", "LOGIN", "MY_ORDERS"])}
          </a>
        </li>
        <li className="c-header__menu-bar-account-menu-open-item">
          <a href='/savedlists/list/view'>
            My Saved Lists
          </a>
        </li>
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "ADDRESS_BOOK_URL",
            ])}
          >
            {this.i18nService.translate(["HEADER", "LOGIN", "ADDRESS_BOOK"])}
          </a>
        </li>
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "ACCOUNT_INFORMATION_URL",
            ])}
          >
            {this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "ACCOUNT_INFORMATION",
            ])}
          </a>
        </li>
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "STORED_PAYMENT_URL",
            ])}
          >
            {this.i18nService.translate(["HEADER", "LOGIN", "STORED_PAYMENT"])}
          </a>
        </li>
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "PRODUCT_REVIEWS_URL",
            ])}
          >
            {this.i18nService.translate(["HEADER", "LOGIN", "PRODUCT_REVIEWS"])}
          </a>
        </li>
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "NEWSLETTER_URL",
            ])}
          >
            {this.i18nService.translate(["HEADER", "LOGIN", "NEWSLETTER"])}
          </a>
        </li>
        {this.state.cart && this.state.cart.canUseReferralProgramAndSpend &&
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href="/aw_raf/advocate/"
            data-test-id="home-headerSigns-link-referalRewardsUrl"
          >
            Referral Rewards        
          </a>
        </li>
        }
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href="/quick-order"
          >
            Quick Order     
          </a>
        </li>
        <li className="c-header__menu-bar-account-menu-open-item">
          <a
            href="/order-status"
          >
            Track Order    
          </a>
        </li>
        <li onClick={this.logoutHandler} className="c-header__menu-bar-account-menu-open-item c-header__menu-bar-account-menu-open-item--sign-out">
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "SIGN_OUT_URL",
            ])}
          >
            {this.i18nService.translate(["HEADER", "LOGIN", "SIGN_OUT"])}
          </a>
        </li>
        <div className="c-header__menu-bar-account-menu-open-button-group">
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "SIGN_IN_URL",
            ])}
            className="c-button c-button--primary c-button--full-width"
          >
            {this.i18nService.translate(["HEADER", "LOGIN", "SIGN_IN_LABEL"])}
          </a>
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "SIGN_UP_URL",
            ])}
            className="c-button c-button--outline c-button--full-width"
          >
            {this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "CREATE_ACCOUNT_LABEL",
            ])}
          </a>
          <a
            href={this.i18nService.translate([
              "HEADER",
              "LOGIN",
              "SIGN_IN_PARTNER_URL",
            ])}
            className="c-button c-button--outline c-button--full-width"
          >
            {this.i18nService.translate(["HEADER", "LOGIN", "PARTNERS_LABEL"])}
          </a>
        </div>
      </ul>
    );
    let color = "#21487d";
    let fontWeight = 400;
    let fontSize = "15px"
    let fontStyle = "italic";
    if (!this.state.user) {
      this.renderStore.desktopSigns = (
        <div
          className="c-header__menu-bar-sign w-100"
          style={{ background: "none" }}
        >
          <div className="container d-flex c-header__border_bottom">
            {/* <div className="w-50 justify-content-start"> <span className="c-header__shipping-info"> <b>Reduced Shipping</b> For 2+ Items! <b>Free Shipping</b> Over $750</span></div> */}
            {/* change for new header */}
            <div className="w-50 justify-content-start"> 
              <span data-test-id="home-headerSigns-text-reduceShipping">
                <b className="c-header__shipping-info">Reduced Shipping</b>
                <span className="c-header__item_dollars-info">for 2+ Items! </span> 
                <b className="c-header__free_shipping-info">Free Shipping</b> 
                <span className="c-header__item_dollars-info">over $750 </span>
              </span>
            </div>
            <div className="w-50 justify-content-end d-flex">

              {/* {window.mageConfig.settings.isIP2LocationEnabled === "1" && pdpV2 && this.state.postal_code ? <> <span className="postallocation c-header__menu-bar-account-welcome">
                <span onClick={this.changeZipModal} className="locationicon">
                  {this.state.postal_code}
                </span>
              </span>
                <div style={{ backgroundColor: color }} className="c-header__vertical-seperator"></div></> : null} */}
              <a style={{ color, fontWeight }} href="/customer/account/create/">
                Quick Order
              </a>
              <div
                style={{ backgroundColor: color }}
                className="c-header__vertical-seperator"
              ></div>

              <a style={{ color, fontWeight }} href="/order-status"
              data-test-id="home-headerSigns-link-OrderStatusUrl"
              >
                Track Order
              </a>
              <div
                style={{ backgroundColor: color }}
                className="c-header__vertical-seperator"
              ></div>
              <a
                style={{ color, fontWeight }}
                href={this.i18nService.translate([
                  "HEADER",
                  "LOGIN",
                  "SIGN_IN_URL",
                ])}
                data-test-id="home-headerSigns-link-signInUrl"
              >
                {this.i18nService.translate(["HEADER", "LOGIN", "SIGN_IN_LABEL"])}
              </a>
              <span style={{ color, fontStyle }} className="pl-2 pr-2">
                |
            </span>
              <a
                style={{ color, fontWeight }}
                href={this.i18nService.translate([
                  "HEADER",
                  "LOGIN",
                  "SIGN_UP_URL",
                ])}
                data-test-id="home-headerSigns-link-signUpUrl"
              >
                {this.i18nService.translate([
                  "HEADER",
                  "LOGIN",
                  "CREATE_ACCOUNT_LABEL",
                ])}
              </a>
              <div
                style={{ backgroundColor: color }}
                className="c-header__vertical-seperator"
              ></div>
              <a style={{ color, fontWeight }} href="/compare-products">
                Compare List
              </a>
              <div
                style={{ backgroundColor: color }}
                className="c-header__vertical-seperator"
              ></div>
              <a
                style={{ color, fontWeight }}
                href={this.i18nService.translate([
                  "HEADER",
                  "LOGIN",
                  "SIGN_IN_PARTNER_URL",
                ])}
                data-test-id="home-headerSigns-link-signPartnerUrl"
              >
                {this.i18nService.translate([
                  "HEADER",
                  "LOGIN",
                  "PARTNERS_LABEL",
                ])}
              </a>
            </div>

          </div>
        </div>
      );
    } else {
      this.renderStore.desktopSigns = (
        <div className="p-3 w-100" style={{ background: "#f0f2f4" }}>
          <div className="c-header__menu-bar-accountp-3 d-flex container ">
            <div className="w-50 justify-content-start"> 
              <span>
               <b className="c-header__shipping-info" >Reduced Shipping</b> 
               <span className="c-header__item_dollars-info">for 2+ Items! </span> 
               <b className="c-header__free_shipping-info">Free Shipping</b> 
               <span className="c-header__item_dollars-info">over $750 </span>
              </span>
            </div>
            <div className="w-50 justify-content-end d-flex">

              {/* {window.mageConfig.settings.isIP2LocationEnabled === "1" && pdpV2 && this.state.postal_code ? <> <span className="postallocation c-header__menu-bar-account-welcome">
                <span onClick={this.changeZipModal} className="locationicon">
                  {this.state.postal_code}
                </span>
              </span>
                <div style={{ backgroundColor: color }} className="c-header__vertical-seperator"></div> </> : null} */}
              <a style={{ color, fontWeight , fontSize }} href="/quick-order">
                Quick Order
              </a>
              <div
                style={{ backgroundColor: color }}
                className="c-header__vertical-seperator"
              ></div>

              <a style={{ color, fontWeight , fontSize }} href="/order-status"
              data-test-id="home-headerSigns-link-OrderStatusUrl_logedin"
              >
                Track Order
              </a>
              <div
                style={{ backgroundColor: color }}
                className="c-header__vertical-seperator"
              ></div>
              <a style={{ color, fontWeight , fontSize }} href="/compare-products">
                Compare List
              </a>
              <div
                style={{ backgroundColor: color }}
                className="c-header__vertical-seperator"
              ></div>
              <span
                style={{ color }}
                className="c-header__menu-bar-account-welcome"
                data-test-id="home-headerSigns-text-username"
              >
                {this.i18nService.translate(["HEADER", "LOGIN", "HI"])},{" "}
                {this.state.user.firstname}
              </span>
              <div
                style={{ backgroundColor: color }}
                className="c-header__vertical-seperator"
              ></div>
              <div
                ref={(e) => (this.accountMenu = e)}
                className={this.renderStore.componentClassName}
              >
                <span
                  style={{ color }}
                  className="c-header__menu-bar-account-menu-title"
                  onClick={this.accountMenuOpenHandler.bind(this)}
                  data-test-id="home-headerSigns-dropdown-accountMenuOpen"
                >
                  {this.i18nService.translate([
                    "HEADER",
                    "LOGIN",
                    "ACCOUNT_LABEL",
                  ])}
                </span>
                <div className="c-header__menu-bar-account-menu-open">
                  <div className="c-header__menu-bar-account-menu-open-header">
                    <h5 className="c-header__menu-bar-account-menu-open-title"
                    data-test-id="home-headerSigns-text-accountMenuTitle"
                    >
                      {this.i18nService.translate([
                        "HEADER",
                        "LOGIN",
                        "MY_ACCOUNT_LABEL",
                      ])}
                    </h5>
                    <span
                      className="c-header__menu-bar-account-menu-open-close"
                      onClick={this.accountMenuCloseHandler.bind(this)}
                      data-test-id="home-headerSigns-btn-accountMenuClose"
                    >
                      <i className="icon__fsd icon__fsd--close-gray"></i>
                    </span>
                  </div>
                  {this.renderStore.menuBarOpenItems &&
                    this.renderStore.menuBarOpenItems}
                </div>
              </div>
              <div
                class="c-header__vertical-seperator all-carts"
                style={{ backgroundColor: color }}
              ></div>
              <a
                className="text-15 all-carts"
                href="/storelocation/customer/carts"
                data-test-id="home-headerSigns-link-allCarts"
              >
                All Carts
            </a>
            </div>
          </div>
        </div>
      );
    }

    this.renderStore.mobileSigns = (
      <div className="c-header__menu-bar-account">
        {/* {window.mageConfig.settings.isIP2LocationEnabled === "1" && pdpV2 && this.state.postal_code ?
          <> <span className="postallocation">
            <span onClick={this.changeZipModal} className="locationicon">
              {this.state.postal_code}
            </span>
          </span> </>
          : null} */}
        <div
          ref={(e) => (this.accountMenu = e)}
          className={this.renderStore.componentClassName}
        >
          <span
            className="c-header__menu-bar-account-menu-title"
            onClick={this.accountMenuOpenHandler.bind(this)}
          >
            {this.i18nService.translate(["HEADER", "LOGIN", "ACCOUNT_LABEL"])}
          </span>
          <div className="c-header__menu-bar-account-menu-open">
            <div className="c-header__menu-bar-account-menu-open-header">
              <h5 className="c-header__menu-bar-account-menu-open-title">
                {this.i18nService.translate([
                  "HEADER",
                  "LOGIN",
                  "MY_ACCOUNT_LABEL",
                ])}
              </h5>
              <span
                className="c-header__menu-bar-account-menu-open-close"
                onClick={this.accountMenuCloseHandler.bind(this)}
              >
                <i className="icon__fsd icon__fsd--close-gray"></i>
              </span>
            </div>
            {this.renderStore.menuBarOpenItems &&
              this.renderStore.menuBarOpenItems}
          </div>
          <a
            className="text-15 all-carts pl-3 ml-3"
            href="/storelocation/customer/carts"
          >
            All Carts
          </a>
        </div>
      </div>
    );

    return (
      <Fragment>
        {this.state.isMobile
          ? this.renderStore.mobileSigns
          : this.renderStore.desktopSigns}
        {this.deviceInfo.browser.name === "IE" ? (
          <div
            id="iemyModal"
            class="iemodal"
            style={{ display: this.state.changeDisplay }}
          >
            <div class="iemodal-content">
              <div class="iecloseblock">
                <span
                  class="ieclose"
                  onClick={this.changeDisplaymodal.bind(this)}
                >
                  &times;
                </span>
              </div>
              <div class="ietextblock">
                <p>
                  This website is optimized for Chrome, Firefox, Edge and Safari
                  browsers. You might want to consider downloading one of these
                  or upgrade your Internet Explore to Edge browser.
                </p>
              </div>

              <p>
                Click&nbsp;
                <a
                  class="chromelink"
                  target="_self"
                  href="https://www.google.com/chrome/?brand=CHBD&gclid=Cj0KCQjw-O35BRDVARIsAJU5mQWHezCcyi2b9XKlsHPjnzHxV5QAw8LivnfK9pqcb1oztlCNdZz0BbwaAvHJEALw_wcB&gclsrc=aw.ds"
                >
                  Here
                </a>
                &nbsp;to Download Google Chrome.
              </p>
            </div>
          </div>
        ) : null}
        {this.state.zipModalOpen ? (
          <div id="ZipmyModal" className="Zipmodal">
            <div className="Zipmodal-content">
              <div className="Zipcloseblock">
                <span className="Zipclose" onClick={this.changeZipModal}>
                  &times;
                </span>
              </div>
              <div className="zipheader"> Zipcode </div>

              <div className="Ziptextblock">
                <p>
                  We use your Zip Code to estimate the delivery time to your location.
                  Please enter your Zip Code here. To learn more about how and where we ship, click&nbsp;
                  <a
                    class="chromelink"
                    target="_blank"
                    href="https://foodservicedirect.zendesk.com/hc/en-us/sections/360001987873-Shipping"
                  >
                    Here
                </a>
                  {/* Tell us your zip code so we can show you accurate inventory in
                  your area! We currently cannot ship to addresses in Alaska,
                  Hawaii or Puerto Rico - sorry! */}
                </p>
              </div>
              <div className="errorArea">
                {this.state.validationError ? (
                  <div className="zipCopeError">
                    Please enter a valid 5 digit zip code
                  </div>
                ) : null}
              </div>
              <div>
                <form className="zipFormClass" onSubmit={this.handleZipUpdate}>
                  <input
                    className="inputZipClass"
                    type="text"
                    pattern="\d*"
                    maxLength="5"
                    value={this.state.inputValue}
                    onChange={this.handleZipCodeCheck}
                  />

                  <input
                    className="updateZiButton"
                    type="submit"
                    value="UPDATE"
                  />
                </form>
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
  changeDisplaymodal() {
    this.setState({
      changeDisplay: "none",
    });
  }

  userChangeHandler() {
    this.getZipCode();
    if (!this.accountService.user) return;
    this.setState({
      user: this.accountService.user,
    });
  }

  expandHandler(isExpanded) {
    this.setState(
      {
        isExpanded: isExpanded,
        mobileSignOpened: this.state.isMobile
          ? this.state.user
            ? !isExpanded
            : isExpanded
          : false,
      },
      () => {
        this.state.isExpanded || this.state.mobileSignOpened
          ? this.viewportController.addGestureEvent("tap", this.clickOutside)
          : this.viewportController.removeGestureEvent(
            "tap",
            this.clickOutside
          );
      }
    );
  }

  isMobile() {
    return this.menuController && this.menuController.mobileMenuIsActive;
  }

  accountMenuOpenHandler() {
    this.expandHandler(true);
  }

  accountMenuCloseHandler() {
    this.expandHandler(false);
  }

  viewportBreakPointChangeHandler() {
    if (!this.viewportController) return;
    this.setState({
      isMobile: this.viewportController.isMobile,
    });
  }

  handleClickOutside(event) {
    if (
      event &&
      event.target &&
      this.accountMenu &&
      !this.accountMenu.contains(event.target)
    ) {
      this.accountMenuCloseHandler();
    }
  }

  handleShrink(event) {
    this.setState({
      isShrink: event.target.isShrink,
    });
  }

  changeZipModal() {
    this.setState({
      zipModalOpen: !this.state.zipModalOpen,
      inputValue: "",
      validationError: false,
    });
  }

  handleZipCodeCheck(e) {
    let zipCode = e.target.value;

    if (zipCode === "" || this.state.regexp.test(zipCode)) {
      this.setState({
        newZipCode: zipCode,
        validationError: false,
        inputValue: zipCode,
      });
    } else {
      this.setState({ validationError: true });
    }
  }
  handleZipUpdate(event) {
    console.log("length ", this.state.inputValue.length);
    cookies.set("selectedPostalCode", this.state.inputValue, {
      path: "/",
    });
    console.log("get cookies valie ", cookies.get("selectedPostalCode")); // Pacman

    event.preventDefault();
    if (this.state.inputValue.length < 5) {
      this.setState({
        validationError: true,
      });
    } else {
      this.setState({
        zipModalOpen: !this.state.zipModalOpen,
        postal_code: this.state.inputValue,
        inputValue: "",
        validationError: false,
      });
    }
  }
}
