import { BaseController } from "../core/base.controller";
import CategorySliderComponent from "../components/category-slider.component";
import PillGroupComponent from "../components/pill-group.component";

export class CategoryController extends BaseController {

  constructor(app) {
    super(app, {
      declarations: [
        
        CategorySliderComponent,
        PillGroupComponent
      ]
    });

    this.categorySliders = [];

  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.categoryService = this.app.categoryService;
    this.layoutController = this.app.getController('layout');
    this.dialogController = this.app.getController('dialog');

    this.app.findChildren('.c-category-slider').then(elements => {
      elements.forEach(element => {

        this.app.closest('.c-category-showcase', element).then(_ => {
          let settings = {
            type: 'fit',
            autoSlides: true,
            centerInsufficientSlides: true
          }
          const categorySlider = new CategorySliderComponent(this.app, element, settings);
          this.categorySliders.push(categorySlider);

        }).catch(_ => {

          const categorySlider = new CategorySliderComponent(this.app, element);
          this.categorySliders.push(categorySlider);

        });

      });
    }).catch(err=>this.handleSelectorError(err));



  }

  onMounted() {
    this.getDomComponent('selectedFilterPills').then( pillGroup => {
      pillGroup.component.setPills(this.categoryService.pills, this.categoryService.pageInfo);
    });
    this.getDomComponent('mobileSelectedFilterPills').then( pillGroup => {
      pillGroup.component.setPills(this.categoryService.pills, this.categoryService.pageInfo);
    });
  }

  

}