
export default class AppLoader {
    constructor(appFactory, props) {
        const config = {...{
            instanceName: 'App',
            requiredFeatures: [
                Modernizr.strictmode,
                Modernizr.es5array,
                Modernizr.es5date,
                Modernizr.es5function,
                Modernizr.es5object,
                Modernizr.es5string,
                Modernizr.es5syntax,
                Modernizr.es5undefined,
                Modernizr.es6array,
                Modernizr.es6collections,
                Modernizr.es6math,
                Modernizr.es6number,
                Modernizr.es6object,
                // Modernizr.es6string,
                Modernizr.generators,
                // Modernizr.contains,
                Modernizr.promises
            ],
            polyfillSrc: 'assets/polyfill.min.js',
            appName: 'Frontend Bundle Manager',
            appVersion: 'v.1.0'
        }, ...props}

        document.addEventListener('DOMContentLoaded', () => {
            if(config.appName) {
                console.log(config.appName);
                console.log("==================");
            }

            if(config.appVersion) console.log(config.appVersion);

            const polyfillRequired = config.requiredFeatures.some(item => !item)

            if(polyfillRequired) {
                this.loadScript(config.polyfillSrc, function () {
                    window[config.instanceName] = new appFactory();
                });
            }else {
                window[config.instanceName] = new appFactory();
            }
        });
    }

    loadScript(src, done) {
        var js = document.createElement('script');
        js.src = src;
        js.onload = function () {
            done();
        };
        js.onerror = function () {
            done(new Error('Failed to load script ' + src));
        };
        document.head.appendChild(js);
    }
}