import React, {Component} from "react";
import ReactDOM from "react-dom";
import { DomComponent } from "../core/dom.component";
import BaseReactComponent from "../core/base-react.component";
import { FormControlLabel, Switch } from "@material-ui/core";
import classNames from "classnames";
import FormControl from "./form-control";

export class SwitchControlComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        this.component = ReactDOM.render(<SwitchControl app={this.app} currentProps={this.getAttributes()} />, this.domElement);
    }
}

SwitchControlComponent.decorator = {
    selector: 'fsd-switch-control'
}


export class SwitchControl extends FormControl {
    constructor(prop, context) {
        super(prop, context, {
            defaultProps: {
                form: null,
                labelPlacement: 'start',
                checked: false,
                value: undefined,
                label: undefined,
                name: undefined,
                disabled: false,
                theme: 'default',
                align: undefined, //space-between
            }
        });

        this.renderStore = {
            componentClassName: undefined
        }
        
        this.state = {
            form: null,
            checked: this.currentProps.checked,
            value: this.currentProps.value,
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.inputBlurHandler = this.inputBlurHandler.bind(this);
        this.inputFocusHandler = this.inputFocusHandler.bind(this);
    }

    get value() {
        let value = this.state.value !== undefined ? this.state.value : this.state.checked;
        return value;
    }

    componentDidMount() {
        this.formControlComponentDidMount();

        let options = this.formattedOptions;
        let value = this.state.value;
        if(!value) value = this.state.defaultValue;
        if(!value && options && options.length > 0) value = options[0].value;
        this.setState({
            options: options,
            value: value
        });
    }

    componentWillReceiveProps(props) {
        let changes = _.pick(props, 'checked'.split(','));
        this.setState(changes);
    }

    componentWillUnmount() {
        
    }

    render() {
        this.renderStore.componentClassName = classNames(
            'c-switch-control',
            { 
                'c-switch-control--disabled': this.currentProps.disabled,
                'c-switch-control--checked': this.state.checked,
                ['c-switch-control--'+this.currentProps.theme]: this.currentProps.theme,
                ['c-switch-control--'+this.currentProps.align]: this.currentProps.align
            }
        );

        this.switch = <Switch
                        classes={{
                            root: 'c-switch-control__switch',
                            icon: 'c-switch-control__switch-handle-icon',
                            iconChecked: 'c-switch-control__switch-handle-icon--checked',
                            switchBase: 'c-switch-control__switch-handle',
                            checked: 'c-switch-control__switch-handle--checked',
                            colorPrimary: 'c-switch-control__switch-handle--primary',
                            colorSecondary: 'c-switch-control__switch-handle--secondary',
                            disabled: 'c-switch-control__switch-handle--disabled',
                            bar: 'c-switch-control__switch-bar'
                        }}
                        name={this.currentProps.name}
                        label={this.currentProps.label}
                        disabled={this.currentProps.disabled}
                        disableRipple={this.app.settings.materialUI.disableRipple}
                        checked={this.state.checked ? true : false}
                        value={this.state.value}
                        onChange={this.inputChangeHandler}
                        onBlur={this.inputBlurHandler}
                        onFocus={this.inputFocusHandler}
                        // color={this.currentProps.theme}
                    />;

        this.formControlLabel = <FormControlLabel
                            classes={{
                                root: 'c-switch-control__container',
                                labelPlacementStart: 'c-switch-control__container--start',
                                labelPlacementTop: 'c-switch-control__container--top',
                                labelPlacementBottom: 'c-switch-control__container--bottom',
                                disabled: 'c-switch-control__container--disabled',
                                label: 'c-switch-control__label',
                            }}
                            control={ this.switch }
                            label={this.currentProps.label}
                            disabled={this.currentProps.disabled}
                            labelPlacement={this.currentProps.labelPlacement}
                            />
        return <div className={this.renderStore.componentClassName}>
            {this.formControlLabel}
        </div>
    }

    inputChangeHandler(e) {
        this.setState({checked: e.target.checked}, () => {
            if(this.props.onChange) this.props.onChange({state: this.state, target: this, defaultEvent: e});
            this.validate();
        });
    }

    inputBlurHandler(e) {
        this.setState({isFocused: false});
    }

    inputFocusHandler() {
        this.setState({isFocused: true});
    }

}