import React, {Component} from "react";
import ReactDOM from "react-dom";
import { DomComponent } from "../core/dom.component";
import BaseReactComponent from "../core/base-react.component";
import { TextField as ReactTextField } from "@material-ui/core";
import classNames from "classnames";
import validate from "validate.js";

export default class FormControl extends BaseReactComponent {
    constructor(prop, context, params) {
        super(prop, context, params);
        this.updateFormControlProps();
    }

    formControlComponentDidMount(){
        this.addToForm();
    }

    formControlRender() {
        this.superRender();
        this.updateFormControlProps();
    }

    addToForm() {
        setTimeout(()=>{
            let form = this.props.form
            if(form) {
                if(form.current) form = form.current;
                this.setState({form: form}, ()=>{
                    if(this.props.form && this.props.form.addController) {
                        this.props.form.addController(this.currentProps.name, this, this.currentProps.validate);
                    }
                });
            }
        },0);
    }

    updateFormControlProps() {
        if(this.currentProps.required) {
            if(!this.currentProps.validate) this.currentProps.validate = {};
            this.currentProps.validate.presence = {allowEmpty: this.currentProps.allowEmptyForRequired || false};
        }
    }

    validate(callback) {
        if(this.currentProps.validate) {
            let error = typeof this.currentProps.validate === 'function' ? this.currentProps.validate(this.state.value) : validate.single(this.state.value, this.currentProps.validate);
            let errorText = this.state.errorText;
            if(!errorText) errorText = this.currentProps.label && error ? _.capitalize(this.currentProps.label) + ' ' + error : error;
            this.setState({
                error: error != null,
                errorText: errorText
            }, () => {
                if(callback) callback(this.state);
                if(this.props.onValidate) this.props.onValidate(this.state);
                // if(this.props.form && this.props.form.validate) this.props.form.validate();
            });
        }else{
            this.setState({error: false}, () => {
                if(callback) callback(this.state);
                if(this.props.onValidate) this.props.onValidate(this.state);
                // if(this.props.form && this.props.form.validate) this.props.form.validate();
            });
        }
    }

}