import { DomComponent } from "../core/dom.component";
import FormComponent from "./form.component";
import classNames from "classnames";

export default class RegisterFormComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'p-sign-up__new-customer-form');
        this.registerForm;
        this.customerCodeInput = null;
        this.changePasswordIsActive = false;

        this.customerGroupSelectBoxDiv = null;
        this.customerGroupSelectBox = null;
        this.init();
    }

    init() {
        this.findChild(this.getSelector(' .c-form')).then(element => {
            this.registerForm = new FormComponent(this.app, element);
            this.customerCodeInputChangeHandler(true);
            // this.accountInformationForm.addEventListener(FormComponent.EVENT_SUBMIT, this.accountInformationFormSubmit)
        }).catch(err=>this.handleSelectorError(err));

        this.findChild(this.getSelector(' .c-form__customer-section')).then( element => {
            this.customerSection = element;
        }).catch(err=>this.handleSelectorError(err));

        this.findChild(this.getSelector(' .c-form__customer-section select')).then( element => {
            this.customerGroup = element;
            this.customerCodeInputChangeHandler(true);
        }).catch(err=>this.handleSelectorError(err));

        this.findChild(this.getSelector(' .c-form__group-customer input')).then( element => {
            this.customerCode = element;
            this.customerCodeInputChangeHandler(true);
        }).catch(err=>this.handleSelectorError(err));

        this.findChild(this.getSelector(' #customer-code-link')).then( element => {
            this.customerCodeLink = element;
            this.customerCodeLink.onclick = this.customerCodeInputChangeHandler.bind(this, false);
        }).catch(err=>this.handleSelectorError(err));

        this.findChild(this.getSelector(' #cancel-customer-code-link')).then( element => {
            this.cancelCustomerCodeLink = element;
            this.cancelCustomerCodeLink.onclick = this.customerCodeInputChangeHandler.bind(this, true)
        }).catch(err=>this.handleSelectorError(err));        
    }

    customerCodeInputChangeHandler(cancel) {
        if(this.customerGroup != null && this.customerCode != null){
            if(cancel){
                this.customerSection.classList.remove('c-form__customer-section--customer-code-reveal');
                this.customerGroup.disabled = false;
                this.customerCode.disabled = true;
            }else{
                this.customerSection.classList.add('c-form__customer-section--customer-code-reveal');
                this.customerGroup.disabled = true;
                this.customerCode.disabled = false;
            }
            this.registerForm.update();
        }        
    }
    
}