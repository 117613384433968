import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';
import HeaderComponent from "./header.component";
import { SearchSideBarComponent } from "./search-side-bar.component";

export default class SearchSidebarDrawerComponent extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);
        this.app = props.app;
        this.catalogSidebarDrawer = null;

        this.state = {
            isMobile: this.props.isMobile,
            contentJson: this.props.contentJson
        }

        this.renderStore = {
            sidebar: null
          }

    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();

        this.dialogController = this.app.getController('dialog');
        this.layoutController = this.app.getController('layout');

        this.categoryClickHandler = this.props.categoryClickHandler;
        this.filterOnClick = this.props.filterOnClick;
    }
  
    componentWillUnmount() {
        if(this.gestureManager) this.gestureManager.destroy();
    }

    render() {

        if(this.state.contentJson){
            this.renderStore.sidebar = <SearchSideBarComponent ref={ el => this.searchSideBar = el }
                                                  app={this.app}
                                                  onClick={this.categoryOnClickHandler.bind(this)}
                                                  filterOnClick={this.filterOnClickHandler.bind(this)}
                                                  isAccordion={this.state.isMobile}
                                                  contentJson={this.state.contentJson}>
                                        </SearchSideBarComponent>
        }

        return (
            <div className="c-catalog-sidebar-drawer">
                <div className="c-catalog-sidebar-drawer__container">
                    <a onClick={(e) => this.closeDrawer()} className="c-catalog-sidebar-drawer__close c-button c-button--link"></a>
                    <div className="c-catalog-sidebar-drawer__content">
                        { this.renderStore.sidebar && this.renderStore.sidebar }
                    </div>
                </div>
            </div>
        )
    }

    closeDrawer() {
        this.dialogController.dismiss();
    }

    updateDrawer(response) {
        this.setState({
            contentJson: response
        }, () => {
            this.searchSideBar.setState({
                contentJson: response
            });
        });
    }

    categoryOnClickHandler(category) {
        this.categoryClickHandler(category);
    }

    filterOnClickHandler(filter) {
        this.filterOnClick(filter);
    }

}