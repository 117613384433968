import BaseService from "../core/base.service";
import jsonp from "jsonp";

export default class HomeService extends BaseService {
  constructor(app) {
    super(app);
    this.homeBanner = null;
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.mageService = this.app.mageService;
    this.routerController = this.app.getController("router");
    this.dialogController = this.app.getController("dialog");
  }

  getBannerList() {
    return new Promise((resolve, reject) => {
      this.http.get("/rest/V1/fsd-banners/getSliderBanners").then((result) => {
          let bannerData = {
            bannerList: [],
            sliderList: [],
          };
          result?.data[0]?.items.map((item) => {
            if (item.banner_type === "slider") bannerData.sliderList.push(item);
            else bannerData.bannerList.push(item);
          });
          this.homeBanner = bannerData;
          this.dispatchEvent(HomeService.EVENT_DATA);
          resolve(this.homeBanner);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getBannerData() {
    return new Promise((resolve, reject) => {
      this.http.get("/rest/V1/fsd-banners/getStaticSliderBanners").then((result) => {
          let bannerData = {
            bannerList: [],
            sliderList: [],
            completeSliderList:[]
          };
          result?.data[0]["static-banner-items"].map((item) => {
            if (item.banner_type === "slider") bannerData.sliderList.push(item);
            else bannerData.bannerList.push(item);
          });
          bannerData.completeSliderList = result?.data[0]["static-banner-items"]
          // console.error("result22 ,,,,",result)
          this.homeBanner = bannerData;
          this.dispatchEvent(HomeService.EVENT_DATA);
          resolve(this.homeBanner);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  

  
}

HomeService.EVENT_DATA = "banner-data-change"; 