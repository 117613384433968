import { BaseApiService } from "./base-api.service";

export default class StoreService extends BaseApiService {
    
    constructor(app) {
        super(app);
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.store = new window.Basil(this.app.settings.storeOptions);
    }

    set(key, value, params) {
        this.store.set(key, value, params);
    }

    get(key, params) {
        return this.store.get(key, params);
    }

    setLocal(key, value, params = {}) {
        params.storages=["local"];
        this.store.set(key, value, params);
    }

    getLocal(key, params={}) {
        params.storages=["local"];
        return this.store.get(key, params);
    }

    removeLocal(key, params={}) {
        params.storages=["local"];
        return this.store.remove(key, params);
    }

    setCookie(key, value, params = {}) {
        params.storages=["cookie"];
        if(this.app.environment.name != 'development') params.domain = '.'+window.location.host;
        this.store.set(key, value, params);
    }

    getCookie(key, params={}) {
        params.storages=["cookie"];
        if(this.app.environment.name != 'development') params.domain = '.'+window.location.host;
        return this.store.get(key, params);
    }
    
    removeCookie(key, params={}) {
        params.storages=["cookie"];
        if(this.app.environment.name != 'development') params.domain = '.'+window.location.host;
        return this.store.remove(key, params);
    }

    setSession(key, value, params = {}) {
        params.storages=["session"];
        this.store.set(key, value, params);
    }

    getSession(key, params={}) {
        params.storages=["session"];
        return this.store.get(key, params);
    }
    
    removeSession(key, params={}) {
        params.storages=["session"];
        return this.store.remove(key, params);
    }

    setMemory(key, value, params = {}) {
        params.storages=["memory"];
        this.store.set(key, value, params);
    }

    getMemmory(key, params={}) {
        params.storages=["memory"];
        return this.store.get(key, params);
    }

    removeMemmory(key, params={}) {
        params.storages=["memory"];
        return this.store.remove(key, params);
    }
}