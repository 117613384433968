import React, {Component} from "react";
import ReactDOM from "react-dom";
import { DomComponent } from "../core/dom.component";
import classNames from "classnames";
import BaseReactComponent from "../core/base-react.component";
import CheckoutService from "../services/checkout.service";

// if(window.paypal) delete window.paypal;
// var paypalScript = document.createElement('script');
// paypalScript.setAttribute('type', 'text/javascript');
// paypalScript.setAttribute('src', 'https://www.paypal.com/sdk/js?client-id=sb');
// document.head.appendChild(paypalScript);
  
export class PaypalButtonComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.component = ReactDOM.render(<PaypalButton app={this.app} currentProps={this.getAttributes()} />, this.domElement);
    }
}

PaypalButtonComponent.decorator = {
    selector: 'fsd-paypal-button'
}

export class PaypalButton extends BaseReactComponent {
    constructor(prop, context) {
        super(prop, context, {
            defaultProps: {
                
            }
        });

        this.renderStore = {
            componentClassName: undefined,
            // paypalButton: paypal.Buttons.driver('react', { React, ReactDOM })
        }

        this.state = {
            paypalButton: null
        };

        this.buttonRef = React.createRef();
        
        this.createOrder = this.createOrder.bind(this);
        this.onApproveHandler = this.onApproveHandler.bind(this);
        this.paymentHandler = this.paymentHandler.bind(this);
        this.authorizeHandler = this.authorizeHandler.bind(this);
        this.cancelHandler = this.cancelHandler.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
        this.checkoutController = this.app.getController('checkout');
        this.loadingController = this.app.getController('loading');
        this.dialogController = this.app.getController('dialog');
        this.routerController = this.app.getController('router');
        this.checkoutService = this.checkoutController.checkoutService;
        this.paypalService = this.checkoutService.paypalService;
        this.accountService = this.app.accountService;
        this.mageService = this.app.mageService;
        var event = document.createEvent("Event");
        event.initEvent('paypal-load',false,true);
        document.dispatchEvent(event);
        document.addEventListener('paypal-load-complete',this.paypalButton.bind(this));
    
    }
    componentDidMount(){
        if(this.paypalService.load){
            this.paypalButton();
        }
    }
    paypalButton() {
        this.paypalService.load = true;
        const {checkout,color} = this.props;
        if(this.buttonRef.current) {
            this.setState({
                paypalButton: paypal.Button.render({
                    env: this.paypalService.environment,
                    style: {
                        color: color,
                        size: 'responsive',
                        height: 40,
                        shape: 'rect',
                        label: 'checkout',
                        fundingicons: false,
                        layout: 'horizontal',
                        tagline: 'false',
                        label:'checkout'
                    },
                    funding:{
                       // allowed:[paypal.FUNDING.CARD],
                        disallowed:[paypal.FUNDING.VENMO,  paypal.FUNDING.CREDIT ]
                    },
                    commit:false,
                    payment: this.paymentHandler,
                    onAuthorize: this.authorizeHandler,
                    onCancel: this.cancelHandler,
                    onError: this.errorHandler,
                    onShippingChange: function(data, actions) {
                        console.log(data,'shipping-address');
                        
                      //  localStorage.setItem('shipping-address',JSON.stringify(data.shipping_address));
                    }
                }, this.buttonRef.current)
            }, ()=> {
                console.log("paypalButton = ", this.state.paypalButton);
            })
        }
    }

    componentWillReceiveProps(props) {
        
    }

    render() {
        // return <div className="c-paypal-button">
        //             <this.renderStore.paypalButton style={{
        //                 color: 'blue',
        //                 size: 'responsive',
        //                 height: 50,
        //                 shape: 'rect',
        //                 label: 'checkout',
        //                 fundingicons: 'false',
        //                 layout: 'horizontal',
        //                 tagline: 'false'
        //             }} createOrder={this.createOrder} onApprove={this.onApproveHandler} />
        //         </div>

        return  <div ref={this.buttonRef} className={`c-paypal-button ${this.props.checkout ? '' :'not-checkout'}`}>

                </div>
    }

    async paymentHandler(data, actions) {
        // console.log("data = ", data);
        // console.log("actions = ", actions);
        // this.paypalService.getToken(data, actions).then(response => {
        //     console.log("paymentHandler result = ", response);
        // }).catch(err => {
        //     console.log("paymentHandler err = ", err);
        // });
        if(this.checkoutService.addshipping && this.props.checkout){
                if(!this.checkoutService.addshipping.isValid){
                    this.dialogController.notify({
                        theme: "danger",
                        text: "Invalid Address"
                    });
                    return null;
                }
        }
        if(!this.props.checkout){
          //  await this.checkoutService.setShippingInformationForPaypal();
        }
        let response = this.paypalService.getToken(data, actions,!this.props.checkout);
        
        console.error("paymentHandler response = ", response);
        return response;
      
    }

    authorizeHandler(data, actions) {
        console.error("authorize data = ", data);
       
        if(data.returnUrl) {
            let additional_data ={
                "paypal_express_checkout_token" : data.paymentToken,
                "paypal_express_checkout_redirect_required" : false,
                "paypal_express_checkout_payer_id" : data.payerID
                }
                if(!this.props.checkout) { 
                   // this.checkoutService.getPaypalAuth({token:data.paymentToken,quoteId:this.checkoutService.cartId,payerIdentifier:data.payerID}).then(res=>{
                        // let formData = new FormData();
                        // formData.append('paymentToken', data.paymentToken);
                        // formData.append('payerId', data.payerID);
                        // formData.append('quoteId', this.checkoutService.cartId);
                        // (this.accountService.user && this.accountService.user.id ) && (formData.append('customerId', this.accountService.user.id));
                        // formData.append('form_key', this.mageService.formKey);
                         let url =`/paypal/express/onAuthorization/?paymentToken=${data.paymentToken}&payerId=${data.payerID}&quoteId=${this.checkoutService.cartId}&form_key=${this.mageService.formKey}${this.accountService.user && this.accountService.user.id ? `&customerId=${this.accountService.user.id}` : ''}`;
                        fetch(url).then(res=>res.json()).then(result=>{
                        if(result){
                            console.log(result);
                            this.checkoutService.getShippingAddressFromQuote().then(shippingAddress=>{
                                localStorage.setItem('shipping-address',JSON.stringify(shippingAddress));
                                window.location.href = this.app.environment.route.checkout
                            })
                            //this.upsAddressData = result.response_data.candidate_address;
                            //this.dispatchEvent(CheckoutService.SHOW_UPS_POPUP);
                            //resolve(result);
                        }
                   
                    }).catch((err)=>{
                       // reject(err);
                     
                    })
                  //  })
                      //  window.location.href = this.app.environment.route.checkout;
                   
                 }
                // Promise.allSettled([setPaypalData]).then(results=>{
                //     console.log(results,'sdsadasda');
                // });
                else{
                    this.checkoutService.selectCurrentPaymentMethodByCode('paypal_express',{additional_data});
                }
            
            this.loadingController.hideLoading('checkout');
          //  this.dispatchEvent(CheckoutService.SHOW_PLACEORDER_BUTTON);
           // this.routerController.href(data.returnUrl);
        }
    }

    errorHandler(err) {
        console.log("errorHandler = ", err)
    }

    cancelHandler() {
        this.paypalService.cancel();
    }

    createOrder(data, actions) {
        console.log("create order => ", data, actions, this, this.paypalService);
        this.paypalService.createTransaction();
    }

    onApproveHandler(data, actions) {
        console.log("approve handler => ", data, actions);
        this.paypalService.captureTransaction(data.orderID);
    }
}