import { BaseController } from "../core/base.controller";
import BrandSliderComponent from "../components/brand-slider.component";
import { LayoutController } from "./layout.controller";
import CharNavigationComponent from "../components/char-navigation.component";

export class BrandsController extends BaseController {

    constructor(app) {
        super(app);
        this.featuredBrandsSlider = null;
        this.charNavigation = null;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();

        this.app.findChild('.c-brand-slider').then( element => {
            this.featuredBrandsSlider = new BrandSliderComponent(this.app, element)
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChild('.c-char-navigation').then( element => {
          this.charNavigation = new CharNavigationComponent(this.app, element)
          this.dispatchEvent(IndexController.EVENT_CONTROLLER_READY);
        }).catch( reason => this.handleSelectorError(reason));

      this.layoutController = this.app.getController('layout');
      this.layoutController.addEventListener(LayoutController.EVENT_SCROLL, this.scrollHandler.bind(this));
    }

    scrollHandler() {
      if(this.charNavigation) this.charNavigation.scrollHandler();
    }

}