import config from './config';
import environment from './environments/environment.production'; //this class is dynamically created by gulp
import { ViewportController } from './controllers/viewport.controller';
import { LoadingController } from './controllers/loading.controller';
import { LayoutController } from './controllers/layout.controller';
import { ProductsController } from './controllers/products.controller';
import BaseApp from './core/base.app';
import MenuController from './controllers/menu.controller';
import { ProductController } from './controllers/product.controller';
import { DialogController } from './controllers/dialog.controller';
import { FormsController } from './controllers/forms.controller';
import AccountService from './services/account.service';
import MissingProductsService from './services/missing-products.service';
import CartService from './services/cart.service';
import CategoryService from './services/category.service';
import ManufacturerBrandsService from './services/manufacturer-brands.service';
import { RouterController } from './controllers/router.controller';
import I18nService from './services/i18n.service';
import { CheckoutController } from './controllers/checkout.controller';
import { SearchController } from './controllers/search.controller';
import { CartController } from './controllers/cart.controller';
import { FocusStoreController } from './controllers/partner-store.controller';
import mock from './mock';
import { AccountController } from './controllers/account.controller';
import StoreService from './services/store.service';
import MageService from './services/mage.service';
import WishlistService from './services/wishlist.service';
import ValidateService from './services/validate.service';
import GAService from './services/ga.service';
import GAService4 from  './services/ga4.service';
import JUService from './services/ju.service';
import NewsletterService from './services/newsletter.service';
import { ManufacturerController } from './controllers/manufacturer.controller';
import { AppController } from './controllers/app.controller';
import CustomerService from './services/customer.service';
import PartnerStoreService from './services/partner.store.service';
import UnbxdService from './services/unbxd.service';
import PipeService from './services/pipe.service';
import UAParser from 'ua-parser-js';
import UnbxdSearchService from './services/unbxd-search.service';
import { IndexController } from './controllers/index.controller';
import { BrandsController } from './controllers/brands.controller';
import UnbxdRecommendationService from './services/unbxd-recommendation.service';
import ComapreProductsService from './services/product-comparison.service';
import { CategoryController } from './controllers/category.controller';
import RecSys from './services/recys.service';
import FooterService from './services/footer.service';
import ImageGalleryService from './services/image-gallery.service';
import SavedListService from "./services/save-list.service";
import HomeService from './services/home-banner.service';
import BraintreeService from './services/braintree.service';
import Cookies from "universal-cookie";
import ReactDOM from 'react-dom';
import React,{ Suspense } from 'react';
const  ReferallPopup = React.lazy(()=> import('./components/popup-referall'));
let cookies = new Cookies();

export default class App extends BaseApp {
    constructor() {
        super();
        this.config = config;
        this.environment = environment;
        this.settings = config.settings;
        this.settings.environment = this.environment.name;
        this.settings.baseUrl = window.require && window.require.baseUrl ? window.require.baseUrl + '/' : '';
        this.uaParser = new UAParser();
        this.deviceInfo = this.uaParser.getResult();
        this.app = this;
        this.accountService = new AccountService(this.app);
        this.missingProductsService = new MissingProductsService(this.app);
        this.homeService = new HomeService(this.app)
        this.footerService = new FooterService(this.app);
        this.partnerService = new PartnerStoreService(this.app);
        this.customerService = new CustomerService(this.app);
        this.imageGalleryService = new ImageGalleryService(this.app);
        this.cartService = new CartService(this.app);
        this.categoryService = new CategoryService(this.app);
        this.recSysService = new RecSys(this.app);
        this.manufacturerBrandsService = new ManufacturerBrandsService(this.app);
        this.i18nService = new I18nService(this.app, window.fsdI18n || mock.i18n);
        this.pipeService = new PipeService(this.app);
        this.storeService = new StoreService(this.app);
        this.validateService = new ValidateService(this.app);
        this.gaService = new GAService(this.app);
        this.gaService4 = new GAService4(this.app);
        this.juService = new JUService(this.app);
        this.unbxdService = new UnbxdService(this.app);
        this.newsletterService = new NewsletterService(this.app);
        this.comapreProductsService = new ComapreProductsService(this.app);
        this.unbxdRecommendationService = new UnbxdRecommendationService(this.app);
        this.unbxdSearchService = new UnbxdSearchService(this.app);
        this.wishlistService = new WishlistService(this.app);
        this.savedListService = new SavedListService(this.app)
        this.braintreeService = new BraintreeService(this.app);
        //
        window.console.log = function () { };
        window.console.error = function () { };
        this.cartChangeHandler = this.cartChangeHandler.bind(this);
        this.init();
    }

    init() {
        this.addClass('l-main--ready');
        this.setClassName();
        this.setControllers();
        this.addGlobalListeners();
        this.overlayOpened();
        
        const embeddedCategories = this.getJSON('embedded-categories');
        const embeddedManufacturerBrands = this.getJSON('embedded-manufacturerBrands');

        if(embeddedCategories) {
            this.categoryService.setCategories(embeddedCategories);
        }else{
            this.categoryService.getCategories();
        }

        if(embeddedManufacturerBrands) {
            this.categoryService.manufacturerBrands = manufacturerBrands;
        }else {
            // this.manufacturerBrandsService.getMockManufacturerBrands();
            this.manufacturerBrandsService.getManufacturerBrands();
        }

        this.cartChangeHandlerProxyListener = this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartChangeHandler);

        this.mageService = new MageService(this.app);
        this.ready();
        
        if(window.FsdAppReady) {
            setTimeout(() => {
                window.FsdAppReady(this.app);
            }, 300);
        }
        this.refFriendPopUp();
    }
    refFriendPopUp(){
        if (cookies.get("aw-raf-welcome-popup") || cookies.get("create_referral_account_success")) {
        try{
            let popupName = cookies.get("aw-raf-welcome-popup") ? 'newAccount' : 'startshop';
            ReactDOM.render(
            <Suspense fallback={null}><ReferallPopup popupName={popupName} /></Suspense>,document.getElementsByClassName('l-main__dialog')[0]);
        }catch(ex){
            console.trace(ex);
        }
    }
        

    }
    addGlobalListeners() {
        window.addEventListener('scroll', (event) => {
            this.getController('layout').scrollHandler(event);
        });
    }

    setClassName() {
        let classNames = [];
        if(this.deviceInfo && this.deviceInfo.browser && this.deviceInfo.browser.name) classNames.push("browser-"+_.kebabCase(this.deviceInfo.browser.name));
        if(this.deviceInfo && this.deviceInfo.device && this.deviceInfo.device.type) classNames.push("device-"+_.kebabCase(this.deviceInfo.device.type));
        if(this.deviceInfo && this.deviceInfo.os && this.deviceInfo.os.name) classNames.push("os-"+_.kebabCase(this.deviceInfo.os.name));
        this.addClass(classNames);
    }

    cartChangeHandler() {
        if(this.cartService.cart) {
            this.removeClass('has-no-cart');
            this.removeClass('has-no-cart-items');
            this.addClass('has-cart');
            if(this.cartService.cart.items && this.cartService.cart.items.length) {
                this.removeClass('has-no-cart-items');
                this.addClass('has-cart-items');
            }else{
                this.removeClass('has-cart-items');
                this.addClass('has-no-cart-items');
            }
        }else{
            this.removeClass('has-cart');
            this.removeClass('has-cart-items');
            this.removeClass('has-no-cart-items');
            if(this._controller.app && this._controller.app.currentSession && this._controller.app.currentSession.cart && this._controller.app.currentSession.cart.id) {
                this.addClass('has-no-cart');
            }else{
                this.addClass('has-no-cart-items');
            }
        }
    }

    overlayOpened(isOpened = false) {
        isOpened ? this.addClass('overlay-opened') : this.removeClass('overlay-opened');
    }

    setControllers() {
        this.createController('app', AppController);        
        this.createController('index', IndexController);
        this.createController('loading', LoadingController);
        this.createController('router', RouterController);
        this.createController('viewport', ViewportController);
        this.createController('layout', LayoutController);
        this.createController('menu', MenuController);
        this.createController('products', ProductsController);
        this.createController('product', ProductController);
        this.createController('dialog', DialogController);
        this.createController('forms', FormsController);
        this.createController('checkout', CheckoutController);
        this.createController('search', SearchController);
        this.createController('focus', FocusStoreController);
        this.createController('cart', CartController);
        this.createController('account', AccountController);
        this.createController('manufacturer', ManufacturerController);
        this.createController('index', IndexController);
        this.createController('brand', BrandsController);     
        this.createController('category', CategoryController);     
        this.dispatchEvent(App.EVENT_CONTROLLER_READY);
    }

}

App.EVENT_CONTROLLER_READY = "controller-ready";
App.EVENT_READY = "ready";