import { BaseController } from "../core/base.controller";
import CheckoutService from '../services/checkout.service';
import { CheckoutStepsComponent } from "../components/checkout-steps.component";
import { OrderSummaryBoxComponent } from "../components/order-summary-box.component";
import CartService from "../services/cart.service";
import { PaypalButtonComponent } from "../components/paypal-button.component";
import { PaypalBraintreeComponent } from "../components/paypal-braintree-button.component";

export class CheckoutController extends BaseController {
    constructor(app) {
        super(app, {
            declarations: [
                CheckoutStepsComponent,
                OrderSummaryBoxComponent,
                PaypalButtonComponent,
                PaypalBraintreeComponent
            ]
        });
        
        this._isCheckoutPage = false;
        this.checkoutSteps = null;
        this.dialogPromise, this.dialogResolve, this.dialogReject;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.checkoutService = new CheckoutService(this.app, this.getIsCheckoutPage.bind(this));
        this.accountService = this.app.accountService;
        this.cartService = this.app.cartService;
        this.layoutController = this.app.getController('layout');
        this.loadingController = this.app.getController('loading');
        this.dialogController = this.app.getController('dialog');
        this.gaService = this.app.gaService;
        this.couponCodeList = null;
        this.i18nService = this.app.i18nService;
        this.mageService = this.app.mageService;
        this.cartChangeHandlerProxyListener = this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartChangeHandler.bind(this));
    }

    getIsCheckoutPage() { 
        return this._isCheckoutPage 
    }

    onMounted() {
        this.getDomComponent(CheckoutStepsComponent.name).then( domComponent => {
            this.checkoutSteps = domComponent.component;
            this._isCheckoutPage = true;
            this.isCheckoutPage = true;
        }).catch( reason => this.handleSelectorError(reason));
        this.getDomComponent(OrderSummaryBoxComponent.name).then( domComponent => {
            this.orderSummaryBox = domComponent.component;
            this._isCheckoutPage = true;
        }).catch( reason => this.handleSelectorError(reason));
    }

    init() {
        
    }
    
    cartChangeHandler() {
        // this.app.findChild(OrderSummaryBoxComponent.decorator.selector).then(element => {
            
        // }).catch(error=>this.app.handleSelectorError(error));
        // this.app.findChild(CheckoutStepsComponent.decorator.selector).then(element => {
            
        // }).catch(error=>this.app.handleSelectorError(error));
    }

    openDialog(content) {
        if(this.dialogPromise) this.dialogReject ? this.dialogReject({message: 'Another dialog is open'}) :  Promise.reject();
        if(this.checkoutSteps) this.checkoutSteps.openDialog(content);
        this.dialogPromise = new Promise((resolve, reject) => {
            this.dialogResolve = resolve;
            this.dialogReject = reject;
        });
        return this.dialogPromise;
    }

    closeDialog(result, reason) {
        if(!this.checkoutSteps) return;
        this.checkoutSteps.closeDialog();
        if(this.dialogResolve) {
            this.dialogResolve(result);
        }else{
            if(this.dialogReject) this.dialogReject(reason);
        }
        this.dialogPromise = this.dialogResolve = this.dialogReject = undefined;
    }
}

CheckoutController.EVENT_STEP_CHANGE = 'step-change';