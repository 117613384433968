import Axios from "axios";
import EventDispatcher from "./classes/EventDispatcher";
import BaseApp from "./base.app";

export default class BaseService extends EventDispatcher {
    constructor(app) {
        super();
        this.app = app;
        this.http = Axios.create();
        if(this.app){
            if(this.app.isReady) {
                this.appReadyHandler();
            }else{
                this.app.addEventListener(BaseApp.EVENT_APP_INIT, this.appReadyHandler.bind(this));
            }
        }
    }

    appReadyHandler() {
        if(this.appOnInit) this.appOnInit();
    }
}