import { BaseApiService } from "./base-api.service";
import IntlMessageFormat from "intl-messageformat";

export default class I18nService extends BaseApiService {
    
    constructor(app, dictionary, locale, defaultFormats) {
        super(app);
        this.dictionary = dictionary;
        this.availableLocales = this.app.settings.i18n.availableLocales;
        this.defaultLocale = locale || this.app.settings.i18n.defaultLocale;
        this.dictionary = dictionary;
        this.defaultFormats = defaultFormats;
        this.init();
    }

    init() {
        this.setLocaleByCode(this.defaultLocale.code);
    }

    setLocale(locale) {
        this.currentLocale = locale || this.defaultLocale;
        if(this.currentLocale.defaultFormats) this.defaultFormats = this.currentLocale.defaultFormats;
        this.dispatchEvent(I18nService.EVENT_LOCALE_CHANGE);
    }

    setLocaleByCode(code) {
        if(!this.availableLocales) return;
        let existLocale = this.availableLocales.find( locale => locale.code == code);
        this.setLocale(existLocale);
    }

    translate(key, formatArgs, defaultFormats, allowEmpty) {
        if(!this.currentLocale) return null;
        if(Array.isArray(key)) key = key.join('.');
        try {
            let message = eval('this.dictionary.'+key) || key;
            this.newMessage = new IntlMessageFormat(message, this.currentLocale.culture, defaultFormats || this.defaultFormats);
            let formattedMessage = this.newMessage.format(formatArgs);
            return allowEmpty && key==formattedMessage ? "" : formattedMessage;
        }catch(error) {
            return allowEmpty ? "" : key;
        }
    }

    format(value, args, defaultFormats) {
        if(!args || args.includes(null)) return value;
        let message = "{value,"
        if(Array.isArray(args)) {
            message += args.join(',');
        }else{
            message += ","+args;
        }
        message += "}";
        this.newMessage = new IntlMessageFormat(message, this.currentLocale.culture, defaultFormats || this.defaultFormats);
        return this.newMessage.format({value: value});
    }
}

I18nService.EVENT_LOCALE_CHANGE = 'localeChange';