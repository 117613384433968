import BaseReactComponent from "../core/base-react.component";
import React, { Component } from "react";
import classNames from "classnames";
import CategoryService from "../services/category.service";

export default class CategoryExplorerCompoent extends BaseReactComponent {
  constructor(props, context) {
    super(props, context);

    this.i18nScopeKey = "CATEGORY_EXPLORER";

    this.renderStore = {};

    this.state = {
      isLoading: true,
      selectedCategory: null,
      hasPreview: false,
      setSelectedCategoryForSearchFilter: "",
    };
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.categoryService = this.app.getController("menu").categoryService;
  }

  componentDidMount() {
    this.categoryService.addEventListener(
      CategoryService.EVENT_CATEGORIES_CHANGE,
      this.categoriesChangeHandler.bind(this)
    );
    this.categoriesChangeHandler();
  }

  mapCategories(target, source) {
    let category;
    let urlPath;
    source.forEach((item, index) => {
      // urlPath = item.url || (item.custom_attributes ? item.custom_attributes.find( attribute => attribute.attribute_code == 'url_path') : null);
      category = {
        id: item.id || entity_id,
        label: item.name || item.title,
        isSelected: index == 0,
        productCount: item.product_count,
        previewImage: item.preview_image || item.previewImage,
        url: item.url, //urlPath ? urlPath.value + '.html' : '#',
        detailData: { categories: [] },
      };
      if (item.items && item.items.length)
        this.mapCategories(category.detailData.categories, item.items);
      target.push(category);
    });
  }

  mainCategoryClickHandler(category, e) {
    this.setSelectedCategory(category);
    console.log("selected category setSelectedCategory", category);
    this.setState({
      setSelectedCategoryForSearchFilter: category.label,
    });
  }

  categoriesChangeHandler() {
    let mainCategories = [];
    if (
      this.categoryService.categories &&
      this.categoryService.categories.length
    ) {
      this.mapCategories(mainCategories, this.categoryService.categories);

      this.setState({
        mainCategories: mainCategories.slice(0, 10),
        totalMainCategoryItemCount: mainCategories.length,
        isLoading: false,
      });
      setTimeout(() => {
        if (this.state.mainCategories && this.state.mainCategories.length)
          this.setSelectedCategory(this.state.mainCategories[0]);
      }, 0);
    }
  }

  setSelectedCategory(category) {
    const mainCategories = this.state.mainCategories.map((item) => {
      item.isSelected = category == item;
      // item.preview_image = "assets/images/mock/categories/category-foods.png";
      return item;
    });
    this.setState({
      mainCategories: mainCategories,
      selectedCategory: category,
      hasPreview: category.previewImage != null,
      setSelectedCategoryForSearchFilter: category.label,
    });

    // console.log("selected category main", this.state.mainCategories )
    // console.log("selected category hani", this.state.selectedCategory )
  }
  setSelectedCategoryForSearchFilter() {
    // console.log("selected category for search filter", this.state.setSelectedCategoryForSearchFilter)
    sessionStorage.setItem(
      "searchFilterCategory",
      this.state.setSelectedCategoryForSearchFilter
    );
  }

  render() {
    this.renderStore.className = classNames("c-category-explorer", {
      "c-category-explorer--has-preview": this.state.hasPreview,
    });

    if (this.state.mainCategories) {
      this.renderStore.mainCategoryList = this.state.mainCategories.map(
        (category) => {
          if (category.label === "Clearance") return null;
          const itemClassName = classNames(
            "c-category-explorer__main-category-list-item",
            {
              "c-category-explorer__main-category-list-item--selected":
                category.isSelected,
            }
          );
          return (
            <>
              <li
                onClick={(e) => this.mainCategoryClickHandler(category, e)}
                className={itemClassName}
                key={category.id}
              >
                <div className="c-category-explorer__category-name-container">
                  {this.state.hasPreview && (
                    <div className="c-category-explorer__preview">
                      <img src={category.previewImage} />
                    </div>
                  )}

                  <span>{category.label}</span>
                </div>
                <span>
                  <button className="c-category-explorer__right-arrow-icon">
                    {/* <button className="c-product-card__add-button c-button c-button--icon" /> */}
                  </button>
                </span>

                {/* <div className="c-product-card__footer-right">
                        <button className="c-product-card__add-button c-button c-button--icon" />
                    </div> */}
              </li>
              <div className="c-category-explorer__border-bottom"></div>
            </>
          );
        }
      );
    } else {
      this.renderStore.mainCategories = null;
    }

    if (this.state.selectedCategory) {
      this.renderStore.subCategoryList =
        this.state.selectedCategory.detailData.categories.map((subCategory) => {
          return (
            <li
              onClick={() => this.setSelectedCategoryForSearchFilter()}
              className="c-category-explorer__sub-category-list-item"
              key={subCategory.id}
            >
              <a href={subCategory.url}>{subCategory.label}</a>
              <span className="c-category-explorer__sub-category-list-item-badge">
                {subCategory.productCount}
              </span>
            </li>
          );
        });
    } else {
      this.renderStore.subCategoryList = null;
    }

    return (
      <div className={this.renderStore.className}>
        <div className="c-category-explorer__container">
          <div className="c-category-explorer__list-col">
            <ul className="c-category-explorer__main-category-list">
              {this.renderStore.mainCategoryList}
            </ul>
            <div className="c-category-explorer__main-category-action">
              {this.renderStore.mainCategoryList &&
                this.renderStore.mainCategoryList.length >= 10 && (
                  <a
                    className="c-category-explorer__view-more-button"
                    href="#view-more"
                  >
                    {this.i18nService.translate([this.i18nScopeKey, "SEE_ALL"])}
                  </a>
                )}
            </div>
          </div>
          <div className="c-category-explorer__detail-col">
            <div className="c-category-explorer__sub-category-list">
              {this.renderStore.subCategoryList}
            </div>
            {/* {this.state.hasPreview && <div className="c-category-explorer__preview">
                            <img src={this.state.selectedCategory.previewImage} />
                        </div>} */}
          </div>
        </div>
      </div>
    );
  }
}
