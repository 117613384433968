import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import ReactDOM from "react-dom";
import classNames from 'classnames';
import { DomComponent } from "../core/dom.component";
import { Button } from './button.component';

export class ApplyInputComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        this.component = ReactDOM.render(<ApplyInput app={this.app} currentProps={this.getAttributes()} />, this.domElement);
    }
}

ApplyInputComponent.decorator = {
    selector: 'fsd-apply-input'
}

export class ApplyInput extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);
        this.i18nScopeKey = 'APPLY_INPUT';

        this.renderStore = {
            componentClassName: 'c-apply-input'
        }

        this.state = {
            isActive: props.isActive,
            isProcessing: props.isProcessing,
            placeholder: props.placeholder || '',
            value: props.value || '',
            submitLabel: props.submitLabel,
            isFocused: false,
            readonly: props.readonly
        }

        this.changeHandler = this.changeHandler.bind(this);
        this.focusHandler = this.focusHandler.bind(this);
        this.blurHandler = this.blurHandler.bind(this);
        this.keyDownHandler = this.keyDownHandler.bind(this);
        this.activate = this.activate.bind(this);
        this.submit = this.submit.bind(this);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
    }

    componentDidMount() {
        if(!this.state.submitLabel && this.i18nService) this.setState({submitLabel: this.i18nService.translate([this.i18nScopeKey, 'SUBMIT_LABEL'])});
    }

    componentWillReceiveProps(props) {
        let changes = _.pick(props, 'value,readonly,submitLabel,isActive,isProcessing'.split(','));
        this.setState(changes);
    }

    render() {
        this.renderStore.componentClassName = classNames(
            'c-apply-input',
            {
                'c-apply-input--active': this.state.isActive || this.state.isProcessing,
                'c-apply-input--readonly': this.state.readonly,
                'c-apply-input--processing': this.state.isProcessing
            }
        )
    let inputId = this.props.storeCredit ? "checkout-payment-input-storecredit" : "checkout-promo-input-promocode";
    let buttonId = this.props.storeCredit ? "checkout-payment-btn-applystorecredit" : "checkout-promo-btn-applycode";

        return <div className={this.renderStore.componentClassName}>
            <div className="c-apply-input__container" onClick={this.activate}>
                <input type="text" className="c-apply-input__field bg-transparent" data-test-id={inputId} placeholder={this.state.placeholder} 
                    ref={( r => this.input = r)}
                    value={this.state.value || ''}
                    onChange={this.changeHandler}
                    onFocus={this.focusHandler}
                    onBlur={this.blurHandler}
                    onKeyDown={this.keyDownHandler}
                    disabled={this.state.readonly || !this.state.isActive} />
                {/* <button onClick={this.activate} className="c-apply-input__edit-button c-button c-button--icon c-button--link"></button> */}
                <Button size='sm' dataTestId={buttonId} disabled={!this.state.isActive && !this.state.isProcessing} onClick={this.submit} isProcessing={this.state.isProcessing}>{this.state.submitLabel || ''}</Button>
                {/* <button disabled={!this.state.isActive} onClick={this.submit} className={classNames("c-apply-input__aplly-button c-button c-button--primary", {'c-button--processing': this.state.isProcessing})}>{this.state.submitLabel}</button> */}
            </div>
        </div>
    }

    changeHandler(e) {
        this.setState({
            value: this.input.value
        }, () => {
            if(this.props.onChange) this.props.onChange(this.state.value);
        });
    }

    focus() {
        this.input.focus();
    }

    blur() {
        this.input.blur();
    }

    reset() {
        this.setState({value: this.props.value || '', isActive: false, isProcessing: false}, ()=>{this.blur()});
    }

    submit() {
        if(!this.input.value) return;
        this.setState({
            value: this.input.value,
            isActive: false
        }, () => {
            if(this.props.onSubmit) this.props.onSubmit(this.state.value);
        });
    }

    activate() {
        if(this.state.isActive) return;
        this.setState({isActive: true});
        setTimeout(() => this.focus(), 50);
    }

    deactivate() {
        if(!this.state.isActive) return;
        this.setState({isActive: false});
    }

    focusHandler() {
        this.setState({isFocused: true}, ()=>{
            if(this.props.onFocus) this.props.onFocus(this.state.isFocused);
        });
    }

    blurHandler() {
        if(!this.state.value) {
            this.deactivate();
        }
        this.setState({isFocused: false}, ()=>{
            if(this.props.onBlur) this.props.onBlur(this.state.isFocused);
        });
    }

    keyDownHandler(e) {
        switch(e.keyCode) {
            case 27: 
                this.reset();
            break;
            case 13:
                this.submit();
            break;
        }
    }

}