import React, {Component} from "react";
import ReactDOM from "react-dom";
import { DomComponent } from "../core/dom.component";
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { TextField as ReactTextField } from "@material-ui/core";
import classNames from "classnames";
import FormControl from "./form-control";
import XRegExp from "xregexp";
import { InputLabel } from "@material-ui/core";

function createInfinityMask(pattern, rawValue, config, placeholderChar=" ") {
    if(typeof pattern == 'string') pattern = XRegExp(pattern);
    let {previousConformedValue} = config;
    if(rawValue && rawValue.charAt(rawValue.length-1) != placeholderChar) rawValue = _.trim(rawValue);
    if(previousConformedValue && previousConformedValue.charAt(previousConformedValue.length-1) != " ") previousConformedValue = _.trim(previousConformedValue);
    let length = previousConformedValue ? previousConformedValue.length+1 : rawValue.length;
    let maskArr = new Array(length).fill(pattern, 0, length);
    return maskArr;
}

export class TextFieldComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        this.component = ReactDOM.render(<TextField app={this.app} currentProps={this.getAttributes()} />, this.domElement);
    }
}

TextFieldComponent.decorator = {
    selector: 'fsd-text-field'
}

export class TextField extends FormControl {
    constructor(prop, context) {
        super(prop, context, {
            defaultProps: {
                variant: 'outlined', //standard, filled
                type: 'text',
                value: '',
                label: undefined,
                placeholder: undefined,
                helperText: undefined,
                errorText: undefined,
                isShrink: false,
                name: undefined,
                disabled: false,
                error: false,
                required: false,
                fullWidth: false,
                multiline: false,
                rows: undefined,
                rowsMax: undefined,
                theme: 'default',
                autoFocus: false,
                defaultValue: '',
                margin: 'none', //dense, normal
                startIcon: null,
                endIcon: null,
                startButton: null,
                endButton: null,
                validate: null,
                pattern: null,
                mask: null,
                pipe: null,
                placeholderChar: '\u2000',
                showMask: false,
                guide: false,
                validateOnBlur: false
            }
        });
        
        this.emailErrorInvalidText = "is not a valid email,can't be blank"
        this.emailErrorValidText = "is not a valid email can't be blank"

        this.renderStore = {
            componentClassName: undefined,
            input: undefined,
            inputProps: undefined,
            helperClass: undefined
        }

        this.state = {
            pattern: this.getPattern(this.currentProps.pattern),
            mask: this.getMask(this.currentProps.mask, this.currentProps.placeholderChar),
            pipe: this.getPipe(this.currentProps.pipe),
            value: this.currentProps.value || this.currentProps.defaultValue || '',
            error: this.currentProps.error,
            errorText: this.currentProps.errorText,
            isFocused: this.currentProps.autoFocus,
            isShrink: this.currentProps.isShrink,
            validateOnBlur: this.currentProps.validateOnBlur,
            showSuggestions:false,
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectDomain = this.selectDomain.bind(this);
        this.inputBlurHandler = this.inputBlurHandler.bind(this);
        this.inputFocusHandler = this.inputFocusHandler.bind(this);
        this.createTextMask = this.createTextMask.bind(this);
    }

    get value() {
        let value = this.state.value;
        if(!value) return value;
        switch(this.currentProps.mask) {
            case 'us-national-phone':
                value = value.replace(/\D+/g, '');
            break;
        }
        return value;
    }

    componentDidMount() {
        this.formControlComponentDidMount();
        document.addEventListener('mousedown', this.handleDocumentClick);
        // if(this.state.mask) this.setState({isShrink: true});
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentClick);
    }

    handleDocumentClick = (event) => { 
        // this block is for hiding suggestion list onBLur 
        const isClickInsideInput = event.target.name === 'username';
        const isClickInsideSuggestion = event.target.classList.contains('c-react-form-field-suggest-item');
        if (!isClickInsideInput && !isClickInsideSuggestion) this.setState({showSuggestions:false})
    };
    

    componentWillReceiveProps(props) {
        let changes = _.pick(props, 'mask,pattern,pipe,isShrink,validateOnBlur'.split(','));
        if(changes.mask) changes.mask = this.getMask(changes.mask);
        if(changes.pattern) changes.pattern = this.getMask(changes.pattern);
        if(changes.pipe) changes.pipe = this.getPipe(changes.pipe);
        this.setState(changes);
    }

    render() {
        this.renderStore.componentClassName = classNames(
            'c-text-field',
            { 
                'c-text-field--disabled': this.currentProps.disabled,
                'c-text-field--error': this.props.error,
                'c-text-field--focused': this.state.isFocused,
                'c-text-field--shrink': this.state.isShrink,
                'c-text-field--required': this.currentProps.required,
                'c-text-field--full-width': this.currentProps.fullWidth,
                ['c-text-field--margin-'+this.currentProps.margin]: this.currentProps.margin,
                ['c-text-field--'+this.currentProps.variant]: this.currentProps.variant,
                ['c-text-field--'+this.currentProps.theme]: this.currentProps.theme,
                ['c-text-field--'+this.currentProps.align]: this.currentProps.align,
            }
        );
        
        this.renderStore.inputProps = {
            classes: {
                root: 'c-text-field__input',
                input: 'c-text-field__input-base',
                notchedOutline: 'c-text-field__input--outline'
            },
            readOnly : this.props.readOnly
        }

        if(this.state.mask) {
            this.renderStore.inputProps.inputComponent = this.createTextMask;
        }else{
            delete this.renderStore.inputProps.inputComponent;
        }

        if(this.currentProps.variant == 'standard') delete this.renderStore.inputProps.classes.notchedOutline;

        let inputLabelProps = {
            classes: {
                root: 'c-text-field__label',
                shrink: 'c-text-field__label--shrink'
            },
            shrink: this.state.isShrink
        };
        if(!this.state.isShrink) delete inputLabelProps.shrink;

        this.renderStore.helperClass = classNames(
            'c-text-field__helper',
            'c-react-form__group-helper',
            {
                'c-react-form__group-helper--error': this.props.error,
                'c-react-form__group-helper--disabled': this.props.error
            }
        );
        this.renderStore.input = <><InputLabel className ={`${this.props.type === "password" && "ca-inp_icon-password-lab" } c-react-form__group-label-text`}>{this.currentProps.label}</InputLabel>
                    <ReactTextField 
                        ref = {(input)=> this[this.currentProps.name] = input}
                        name={this.currentProps.name}
                        style={this.props.style}
                        classes= {{
                            root: 'c-text-field__control'
                        }}
                        InputLabelProps={inputLabelProps}
                        InputProps={this.renderStore.inputProps}
                        FormHelperTextProps={{
                            classes: {
                                root: this.renderStore.helperClass
                            }
                        }}
                        inputProps={{
                            maxLength: this.props.maxLength,
                            pattern: this.state.pattern,
                            autocomplete: this.props.autocomplete,
                            "data-test-id":this.props.dataTestId
                        }}
                        autoFocus={this.state.isFocused}
                        variant={this.currentProps.variant}
                        placeholder={this.currentProps.placeholder}
                        id={this.props.id}
                        // label={this.currentProps.label}
                        type={this.currentProps.type}
                        value={this.state.value}
                        helperText = {this.props.error ? 
                            (this.props.errorText == this.emailErrorInvalidText ? this.emailErrorValidText : this.props.errorText) 
                            : this.currentProps.helperText
                        }
                        required={this.currentProps.required}
                        error={this.props.error}
                        fullWidth={this.currentProps.fullWidth}
                        multiline={this.currentProps.multiline}
                        onChange={this.inputChangeHandler}
                        onBlur={this.inputBlurHandler}
                        onFocus={this.inputFocusHandler}
                        /> </>

        return <div className={this.renderStore.componentClassName}>
            {this.renderStore.input}
            {this.state.showSuggestions && !!this.props.domainSuggestions.length && 
            <div className="c-react-form-field-suggest-box">
                {
                this.props.domainSuggestions.map( (domain,index) => {
                    return (
                        <p key={index} className="c-react-form-field-suggest-item"  onMouseDown={() => this.selectDomain(this.state.value , domain)}>
                            {this.state.value.slice(0,this.state.value.indexOf("@")+1)}<strong>{domain}</strong>
                        </p>
                    )
                })
                }
            </div>}
        </div>
    }

    createTextMask(props) {
        const { inputRef, ...other } = props;
      
        return (
          <MaskedInput
            {...other}
            ref={ref => {
              inputRef(ref ? ref.inputElement : null);
            }}
            mask={this.state.mask}
            pipe={this.state.pipe}
            placeholderChar={this.currentProps.placeholderChar}
            showMask={this.currentProps.showMask}
            guide={this.currentProps.guide}
          />
        );
    };  

    selectDomain(value,domain) {
        let filterVal = value.slice(0,value.indexOf("@")+1)
        this.setState({
            value:filterVal + domain,
            showSuggestions:false,
            error:false
        },() => {
            if(this.props.onChange) this.props.onChange({state: this.state, target: this,});
        });
        if(this.props.checkEmailonSelect) this.props.checkEmailonSelect(filterVal, domain) 
    }

    inputChangeHandler(e) {
       console.error('came input handler',e.target.value);
       let value = e.target.value;

        if(this.props.domainSuggestions){ 
           if(value.indexOf('@') > 0 && ( value.indexOf('@')+1 < value.length ) ) this.setState({showSuggestions:true})
           else this.setState({showSuggestions:false})
        }
        
        
        if(!this.state.mask && this.state.pipe) {
            const valid = this.state.pipe(value);
            value = valid !== false ? value : this.state.value;
        }
        this.setState({value: value}, () => {
            console.error('came input handler',this.state);
            if(this.props.onChange) this.props.onChange({state: this.state, target: this, defaultEvent: e});
        });
    }

    inputBlurHandler(e) {
        
       //  console.log(e.target.setCustomValidity(""),'e.target')
        if(!e.target.value && !this.props.required && this.props.checkonlyValid){
            this.setState({error:false});
            return;
        }
        
        if(this.state.validateOnBlur) this.validate();
        this.setState({isFocused: false}, ()=>{
           
            
            if(this.props.onBlurUpsValidation){
              //  this.props.onBlurUpsValidation();
            }
            if(this.props.onBlur) this.props.onBlur({state: this.state, target: this, defaultEvent: e} ,this.props.onBlurUpsValidation ? true : false);
        });
    }

    inputFocusHandler(e) {
        this.setState({isFocused: true}, ()=>{
            if(this.props.onFocus) this.props.onFocus({state: this.state, target: this, defaultEvent: e});
        });
    }

    getMask(mask, placeholderChar=" ") {
        if(!mask) return null;
        let formattedMask;
        switch(mask) {
            case 'tel':
            case 'phone':
                formattedMask = createNumberMask({
                    prefix: "",
                    suffix: "",
                    includeThousandsSeparator: false,
                    allowLeadingZeroes: true
                });
            break;
            case 'us-national-phone':
                formattedMask = ['+','1', /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
            break;
            case 'zipcode':
                formattedMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
            break;
            case 'year':
                formattedMask = [/\d/, /\d/, /\d/, /\d/];
            break;
            case 'msts_credit_amount':
                 formattedMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
            break;
            case 'tax_id':
                formattedMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
           break;
            case 'number':
            case 'numeric':
                formattedMask = createNumberMask({
                    prefix: "",
                    suffix: "",
                    includeThousandsSeparator: false,
                });
            break;
            case 'alphanumeric':
                formattedMask = function(rawValue, config) {
                    return createInfinityMask('[\\pL]|[0-9]|[\\s]', rawValue, config, placeholderChar);
                }
            break;
            case 'alphanumeric-extended':
                formattedMask = function(rawValue, config) {
                    return createInfinityMask('[\\pL]|[0-9]|[\\s\,\.\-\/\:]', rawValue, config, placeholderChar);
                }
            break;
            case 'alpha':
                formattedMask = function(rawValue, config) {
                    return createInfinityMask('[\\pL]|[\\s]', rawValue, config, placeholderChar);
                }
            break;
            default: 
                formattedMask = Array.isArray(mask) ? mask : null;
            break;
        }
        return formattedMask;
    }

    getPattern(pattern) {
        if(!pattern) return null;
        let formattedPattern;
        switch(pattern) {
            case 'username':
                formattedPattern = '^[a-z0-9_-]{3,16}$';
            break;
            case 'password':
                formattedPattern = '^[a-z0-9_-]{6,18}$';
            break;
            case 'slug':
                formattedPattern = '^[a-z0-9-]+$';
            break;
            case 'email':
                formattedPattern = '^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$';
            break;
            case 'url':
                formattedPattern = '^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$';
            break;
            case 'ip':
                formattedPattern = '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$';
            break;
            case 'numeric':
            case 'number':
                formattedPattern = '\d*'
            break;
            case 'alpha':

            break;
            case 'alphanumeric':

            break;
            default:
                formattedPattern = pattern;
            break;
        }

        return formattedPattern;
    }

    getPipe(pipe) {
        if(!pipe) return pipe;
        if(typeof pipe == 'function') return pipe;
        if(typeof pipe == 'string') return {
            alpha: function(conformedValue) {
                return XRegExp.test(conformedValue, XRegExp.globalize(XRegExp('^[\\pL\\s]*$'))) ? conformedValue : false;
            },
            alphanumeric: function(conformedValue) {
                return XRegExp.test(conformedValue, XRegExp.globalize(XRegExp('^[\\pL\\s0-9]*$'))) ? conformedValue : false;
            },
            alphanumericExtended: function(conformedValue) {
                return XRegExp.test(conformedValue, XRegExp.globalize(XRegExp('^[\\pL\\s\,\.\-\/\:0-9]*$'))) ? conformedValue : false;
            }
        }[pipe];
    }
}