import { BaseController } from "../core/base.controller";
import ReactDOM from "react-dom";
import React from "react";
import SearchService from "../services/search.service";
import CatalogSearchBar from "../components/catalog-search-bar.component";
import SearchBarComponent from "../components/search-bar.component";
import UnbxdService from "../services/unbxd.service";
import SearchResultPage from "../components/search-result.component";
import CategoryService from '../services/category.service';
import { ViewportController } from '../controllers/viewport.controller';
import SearchSidebarDrawerComponent from '../components/search-side-bar-drawer.component';

export class SearchController extends BaseController {

    constructor(app) {
        super(app);
        this.catalogSearchBars = [];
        this.searchSidebarDrawer = null;
        this.searchResultComponent = null;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.searchService = new SearchService(this.app);
        this.unbxdService = this.app.unbxdService;
        this.categoryService = this.app.categoryService;
        this.unbxdSearchService = this.app.unbxdSearchService;
        this.routerController = this.app.getController('router');
        this.viewportController = this.app.getController('viewport');
        this.dialogController = this.app.getController('dialog');

        this.app.findChildren('[catalog-search-bar]').then( elements => {
            let catalogSearchBar, settings;
            elements.forEach( element => {
                settings = element.getAttribute('catalog-search-bar') ? JSON.parse(element.getAttribute('catalog-search-bar')) : null;
                catalogSearchBar = ReactDOM.render(<CatalogSearchBar app={this.app} settings={settings} />, element);
                this.catalogSearchBars.push(catalogSearchBar);
            })
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChildren('.c-header__search').then( elements => {
            let catalogSearchBar, settings;
            elements.forEach( element => {
                settings = element.getAttribute('catalog-search-bar') ? JSON.parse(element.getAttribute('catalog-search-bar')) : null;
                catalogSearchBar = ReactDOM.render(<SearchBarComponent app={this.app} settings={settings} />, element);
                this.catalogSearchBars.push(catalogSearchBar);
            })
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChild('#fsd-search-page').then(element => {
            let queryString = this.routerController.queryParams.get('q');
            if(!queryString || (queryString && queryString.length == 0)) return;
            let noResultImage = this.app.getJSON('no-result-image', element);
            this.searchResultComponent = ReactDOM.render(<SearchResultPage app={this.app} queryString={queryString} noResultImage={noResultImage} />, element);
        }).catch(err=>this.handleSelectorError(err));
        
        if(this.unbxdService.isReady){
            this.unbxdReadyHandler();
        }else{
            this.unbxdService.addEventListener(UnbxdService.EVENT_READY, this.unbxdReadyHandler.bind(this));           
        }                
    }

    openSearchSidebarDrawer() {
        this.searchSidebarDrawerIsOpen = true;
        this.dialogController.drawer((container) => {
            if(!this.searchResultComponent) return;

            let isMobile = this.viewportController.isMobile;
            let response = this.searchResultComponent.state.response;

            this.searchSidebarDrawer = ReactDOM.render(<SearchSidebarDrawerComponent 
                                                                                    app={this.app} 
                                                                                    isMobile={isMobile}
                                                                                    contentJson={response}
                                                                                    categoryClickHandler={this.categoryOnClickHandler.bind(this)}
                                                                                    filterOnClick={this.filterOnClickHandler.bind(this)}/>, container); 
        }, {dialogRefId: 'searchSideBarDrawer' }).catch( reason => this.searchSidebarDrawerIsOpen = false);
    }

    closeSearchSidebarDrawer() {
        this.searchSidebarDrawerIsOpen = false;
        this.dialogController.dismiss(null, 'searchSideBarDrawer');

        if(this.searchSidebarDrawer) {
            this.searchSidebarDrawer = null;
        }
    }

    categoryOnClickHandler(category) {
        if(!this.searchResultComponent || !this.searchSidebarDrawer) return;
        let queryParams = this.unbxdSearchService.categoryClickHandler(category);
        let searchQuery = this.routerController.queryParams.get('q');
        this.unbxdSearchService.search(searchQuery, queryParams).then( response => {
            if(!this.searchSidebarDrawer) return;
            this.searchSidebarDrawer.updateDrawer(response);
            this.searchResultComponent.changeContentFromAccordion(response);
        });
    }

    filterOnClickHandler(filter) {
        if(!this.searchResultComponent || !this.searchSidebarDrawer) return;
        let queryParams = this.unbxdSearchService.filterOnClick(filter);
        let searchQuery = this.routerController.queryParams.get('q');
        this.unbxdSearchService.search(searchQuery, queryParams).then( response => {
            if(!this.searchSidebarDrawer) return;
            this.searchSidebarDrawer.updateDrawer(response);
            this.searchResultComponent.changeContentFromAccordion(response);
        });
    }

    unbxdReadyHandler(){
        let searchQuery = this.routerController.queryParams.get('q');
        this.unbxdService.fireSearch(searchQuery);
    }
}