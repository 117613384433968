import { BaseApiService } from "./base-api.service";

export default class ImageGalleryService extends BaseApiService {
    constructor(app) {
        super(app);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
    }




   
}