import { DomComponent } from "../core/dom.component";
import FormComponent from "./form.component";
import RatingComponent from "./rating.component";

export default class ReviewEditorComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-review-editor');
        this.reviewForm;
        this.rating;
        this.ratingChange = this.ratingChangeHandler.bind(this);
        this.reviewFormSubmit = this.reviewFormSubmitHandler.bind(this);
        this.ratingInput = null;
        this.init();
    }

    init() {
        this.findChild(this.getSelector('__form .c-form')).then(element => {
            this.reviewForm = new FormComponent(this.app, element);
            this.reviewForm.addEventListener(FormComponent.EVENT_SUBMIT, this.reviewFormSubmit)
        }).catch(err=>this.handleSelectorError(err));

        this.findChild(this.getSelector('__form [rating-value]')).then( element => {
            this.ratingInput = element;
        }).catch(err=>this.handleSelectorError(err));

        this.findChild(this.getSelector('__form .c-rating')).then(element => {
            this.rating = new RatingComponent(this.app, element, {name: "review-editor-rating", value: "1"}); // remove name settings if use ratingInput;
            this.rating.addEventListener(RatingComponent.EVENT_CHANGE, this.ratingChange);
        }).catch(err=>this.handleSelectorError(err));
    }               
                                                    
    ratingChangeHandler() {                                         
        if(this.ratingInput) {
            if(this.rating.valueList) {
                this.ratingInput.setAttribute('value', this.rating.valueList[this.rating.value-1]);
            }
            // this.ratingInput.setAttribute('value', (this.rating.value * 4) + ((5 - this.rating.value) * 3) );
        }
    }

    reviewFormSubmitHandler() {
        let payload = {};
        for(let key in this.reviewForm.value) {
            payload[key.split('review-editor-')[1]] = this.reviewForm.value[key] || undefined;
        }
        console.log("form value : ", this.reviewForm.value);
        console.log("custom payload: ", payload);
    }

}