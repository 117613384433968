import { BaseApiService } from "./base-api.service";

export default class ComapreProductsService extends BaseApiService {
  constructor(app) {
    super(app);
    this.compareProductsData = null;
    this.getCompareProducts = this.getCompareProducts.bind(this);
    this.deleteCompareProduct = this.deleteCompareProduct.bind(this);
    this.addCompareProduct = this.addCompareProduct.bind(this);
  }
  appOnInit() {
    super.appOnInit();
    this.loadingController = this.app.getController("loading");
  }

  addCompareProduct(sku) {
    return new Promise((resolve, reject) => {
      this.post(`/fsd/product-comparison/addItem/${sku}`)
        .then((result) => {
          console.error("Result --addItem", result);
          resolve(result);
        })
        .catch((err) => {
          console.error("error1", err);
          reject(err);
        });
    });
  }

  deleteCompareProduct(sku) {
    return new Promise((resolve, reject) => {
      this.post(`/fsd/product-comparison/deleteItem/${sku}`)
        .then((result) => {
          console.error("Result --deleteItem", result);
          this.getCompareProducts();
          resolve(result);
        })
        .catch((err) => {
          console.error("error1", err);
          reject(err);
        });
    });
  }

  getCompareProducts() {
    return new Promise((resolve, reject) => {
      this.get("/fsd/product-comparison/view")
        .then((result) => {
          if (result.length){
            this.compareProductsData = result[0].comparison_skus;
          } else this.compareProductsData = null;
          this.dispatchEvent(ComapreProductsService.EVENT_CHANGE);
        })
        .catch((err) => {
          console.error("error1", err);
          reject(err);
        });
    });
  }
}
ComapreProductsService.EVENT_CHANGE = "items-changed";
ComapreProductsService.EVENT_REFRESH = "refresh-cart";
