import { DomComponent } from "../core/dom.component";
import { ViewportController } from '../controllers/viewport.controller';
import classNames from 'classnames';
import Swiper from 'swiper';

export default class CategorySliderComponent extends DomComponent {
  constructor(app, element, settings) {
    super(app, element, 'c-category-slider');
    this.swiper = null;
    this.settings = settings;
    this.init();
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
  }

  init() {
    this.setSwiper();
  }

  setSwiper() {
    if (this.swiper) return;
    this.findChild(this.getSelector('__container')).then(element => {
      this.findChildren(this.getSelector('__item')).then(slides => {
        this.swiper = new Swiper(element, {
          slidesPerView: this.settings && this.settings.autoSlides ? 'auto' : 5,
          spaceBetween: 25,
          centerInsufficientSlides: !(this.settings && this.settings.centerInsufficientSlides),
          watchOverflow: true,
          mousewheel: {
            forceToAxis: true,
            invert: true
          },
          freeMode: true,
          breakpoints: {
            [this.app.settings.breakpoints.xxl.min]: {
              slidesPerView: this.settings && this.settings.autoSlides ? 'auto' : 4,
              spaceBetween: 25,
              centerInsufficientSlides: !(this.settings && this.settings.centerInsufficientSlides),
              watchOverflow: true,
            },
            [this.app.settings.breakpoints.lg.max]: {
              slidesPerView: this.settings && this.settings.autoSlides ? 'auto' : 3,
              spaceBetween: 25,
              centerInsufficientSlides: !(this.settings && this.settings.centerInsufficientSlides),
              watchOverflow: true
            },
          },
          navigation: {
            nextEl: this.querySelector(this.getSelector('__nav-next')),
            prevEl: this.querySelector(this.getSelector('__nav-prev')),
            disabledClass: this.getSelector('__nav-item--disabled', ''),
            hiddenClass: this.getSelector('__nav-item--hidden', '')
          }
        });
      });
    });
  }
}
