import BaseService from "../core/base.service";

export default class MageService extends BaseService {
    
    constructor(app) {
        super(app);
        this.formKey = null;
        this.account = null;
        this.cart = null;
        this.config = null;
        this.settings = null;
        this.client = null;
        this.authorizeNet = null;
        this.environment = 'production';
        this.trackEnabled = false;
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.routerController = this.app.getController('router');
        this.storeService = this.app.storeService;
        this.accountService = this.app.accountService;
        this.cartService = this.app.cartService;
        this.dialogController = this.app.getController('dialog');
        this.checkClient();
    }

    // addUNBXDScript() {
    //     if(this.settings && this.settings.unbxd){
    //         if(this.settings.unbxd.autosuggest && this.settings.unbxd.autosuggest.jsUrl) {
    //             const autoSuggestUrl = this.settings.unbxd.autosuggest.jsUrl;
    //             var autoSuggestScript = document.createElement('script');
    //             autoSuggestScript.setAttribute('type', 'text/javascript');
    //             autoSuggestScript.setAttribute('src', autoSuggestUrl);
    //             document.head.appendChild(autoSuggestScript);
    //         }else if(typeof this.settings.unbxd.autosuggest === "string") {
    //             //Todo: Remove this block when new unbxd settings will be updated
    //             const autoSuggestUrl = this.settings.unbxd.autosuggest;
    //             var autoSuggestScript = document.createElement('script');
    //             autoSuggestScript.setAttribute('type', 'text/javascript');
    //             autoSuggestScript.setAttribute('src', autoSuggestUrl);
    //             document.head.appendChild(autoSuggestScript);
    //         }
    //     }        
    // }

    checkClient() {
        this.getCookie('client').then( result => {    
            this.client = result;
            if(this.client && this.client.releaseVersion != this.app.environment.releaseVersion) {
                this.clientInit(this.client.releaseVersion);
            }else{
                this.setClient();
            }
        }).catch( reason => {
            this.clientInit();
        });
    }

    setClient() {
        let clientInfo = {
            releaseVersion: this.app.environment.releaseVersion
        }
        if(this.client && this.client.releaseVersion) clientInfo.previousRelaseVersion = this.client.releaseVersion;
        this.storeService.setCookie('client', clientInfo);
        this.init();
    }

    clientInit(existVersion) {
        this.clearCookies();
        this.setClient();
    }

    init() {
        if(super.appOnInit) super.appOnInit();
        
        // this.config = {"settings":{"environment":null,"cart":{"free_shipping":null,"hide_other_shipping_methods":true},"authorizeNet":{"clientKey":null,"apiLoginID":""},"unbxd":{"apiKey":"ed39b885dbb44eca3c2782623eb2ba9d","autosuggest":{"cssUrl":"https://unbxd.s3.amazonaws.com/dev-foodservicedirect-com805741550815410_autosuggest.css","jsUrl":"https://unbxd.s3.amazonaws.com/dev-foodservicedirect-com805741550815410_autosuggest.js"},"search":{"cssUrl":"https://unbxd.s3.amazonaws.com/dev-foodservicedirect-com805741550815410_search.css","jsUrl":"https://unbxd.s3.amazonaws.com/dev-foodservicedirect-com805741550815410_search.js"},"isEnabled":true,"siteName":"www-foodservicedirect-com-805741548149387"},"search":{"type":"readonly","autoSuggestUrl":"search/ajax/suggest","hasCategoryFilter":false,"resultUrl":"/search-result"},"paypal":{"environment":"sandbox"},"storeCredit":{"isActive":true},"store":{"storeCode": "default"}}};
        this.config = window.mageConfig;
        this.settings = this.config ? this.config.settings : null;
        // this.setAccount();
        
        if(this.settings) {
            this.initSettings();
        }else{
            this.getCookie('settings').then( result => {
                this.settings = result;
                this.initSettings();
            }).catch( error => {
                this.setFormKeyFromInput();
            });
        }
        
        this.getCookie('client').then( result => {   
            this.client = result;
        });

        setTimeout(() => {
            this.getMageMessages().then( result => {
                if(result) result.forEach( message => {
                    this.dialogController.notify({
                        theme: message.type,
                        text: this.app.decodeURIComponent(message.text, true),
                        timeout: null
                    }).catch(reason => {});
                });
                this.removeMageMessages();
            }).catch(reason => {});
        }, 1500);

        // this.addUNBXDScript();
        this.setData = true;
        this.dispatchEvent(MageService.EVENT_READY);
    }

    setFormKeyFromInput() {
        
    }

    initSettings() {
        if(this.settings.environment) this.environment = this.settings.environment;
        this.trackEnabled = this.environment === "production" || this.environment === "staging";
        
        if(this.settings.form_key) {
            this.formKey = this.settings.form_key;
        }else{
            this.app.findChild('input[name="form_key').then( element => {
                this.formKey = element.getAttribute('value');
            });
        }
        if(this.settings.authorizeNet) this.authorizeNet = this.settings.authorizeNet;
    }

    setAccount() {
        this.getCookie('account').then( result => {
            this.account = result;
            // if(this.account && this.account.token) this.accountService.setToken(this.account.token);
            if(this.account && this.account.user) {
                // if(this.account.is_logged_in) this.account.user.is_logged_in = this.account.is_logged_in;
                // if(this.account.user.addresses) this.account.user.addresses.map( item => {
                //     for(let key in item) {
                //         item[key] = this.app.decodeURIComponent(item[key], true)
                //     }
                // })
                // this.accountService.setUser(this.account.user);
            }
            // this.setCart();
        }).catch( error => {
            // this.setCart();
            this.handleError(error);
        });
    }

    setCart() {
      
        this.getCookie('cart').then( result => {
            this.cart = result;
            if(this.cart && this.cart.id) this.cartService.createCart(this.cart.id);
        }).catch( reason => {
            // this.cartService.createCart();
            this.handleError(reason);
        });
    }

    setCookie(name, value) {
        if(typeof value != 'string') value = JSON.stringify(value);
        this.storeService.setCookie(name, value);
    }

    getCookie(name) {
        return new Promise((resolve, reject) => {
            let cookie = this.storeService.getCookie(name);
            if(typeof cookie == "string") cookie = this.app.parseJSON(cookie);
            if(cookie) {
                resolve(cookie);
            }else{
                reject(new Error(`Cookie not found: ${this.storeService.store.options.namespace}${this.storeService.store.options.keyDelimiter}${name}`));
            }
        });
    }

    clearCookies() {
        this.storeService.removeCookie('account');
        this.storeService.removeCookie('cart');
    }

    showMageMessages(messageType, placeholderName, settings = {}) {
        this.getMageMessages(messageType).then( result => {
            if(result && result.length) {
                this.dialogController.inline(placeholderName, {
                    theme: settings.theme,
                    title: settings.title,
                    description: result
                });
            }
            this.removeMageMessages();
        }).catch(reason => {});
    }

    getMageMessages(messageType, onlyText=true) {
        return new Promise((resolve, reject) => {
            let messages = this.storeService.getCookie('mage-messages', {namespace: ''});
            // if(!messages) messages = "%5B%7B%22type%22%3A%22error%22%2C%22text%22%3A%22You%20did%20not%20sign%20in%20correctly%20or%20your%20account%20is%20temporarily%20disabled.%22%7D%2C%20%7B%22type%22%3A%22success%22%2C%22text%22%3A%22You%20did%20sign%20in%20correctly.%22%7D%5D";
            if(messages) {
                // try {
                    if(!Array.isArray(messages)) messages = this.app.parseJSON(messages, false, true);
                    if(messages && messages.length) {
                        let result;
                        if(messageType) {
                            let filteredResult = messages.filter( message => message.type == messageType);
                            if(filteredResult.length) {
                                result = onlyText ? filteredResult.map( message => message = this.app.decodeURIComponent(message.text, true)) : filteredResult;
                            }else{
                                reject({message: "Mage has no messages with type : "+messageType});
                            }
                        }else{
                            result = messages;
                        }
                        resolve(result);
                    }else{
                        reject({message: "Mage messages decode error"});
                    }
                // }catch(error) {
                //     reject({message: "Mage messages error"});
                // }
            }else {
                reject({message: "Mage messages not found"});
            }
        });
    }

    removeMageMessages() {
        this.storeService.removeCookie('mage-messages', {namespace: ''});
    }

    handleError(error) {
        if(this.app.environment.name != 'production' ) {
            console.log(error.message);
        }
    }
}

MageService.EVENT_READY = "ready";