import { BaseController } from "../core/base.controller";
import DialogBoxComponent from "../components/dialog-box.component";
import classNames from "classnames";
import ReactDOM from "react-dom";
import React from "react";
import NotificationBoxComponent from "../components/notification-box.component";
import InlineNotificationComponent from "../components/inline-notification.component";
import InlineDialogBoxComponent from "../components/inline-dialog-box.component";
import { ContextMenuComponent } from "../components/context-menu.component";

export class DialogController extends BaseController {
  constructor(app) {
    super(app);

    this.dialogs = [];
    this.defaultDialogOptions = {
      dialogRefId: "auto",
      backdrop: true,
      backdropAction: "dismiss",
      keyboardEnterAction: "confirm",
      keyboardEscapeAction: "dismiss",
      dialogWidth: "40vw",
      dialogHeight: "55vh",
      dialogMinHeight: "470px",
      dialogMaxWidth: "auto",
      dialogBackgroundColor: null,
      scrollLock: false,
      dismissConfirm: null,
      onInit: null,
      onUpdate: null
    };

    this.inlineDialogs = [];
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.layoutController = this.app.getController("layout");
    this.init();
  }

  init() {
    this.dialogRoot = this.app.createDomComponent(this.app, "div", {
      className: "l-main__dialog"
    });
  }

  async inline(refId, content, options) {
    let promise;
    await this.scanInlinePlaceholders()
      .then(result => {
        let existInlineDialogBox = this.getInlineDialog(refId);
        if (existInlineDialogBox) {
          if (content && (content.title || content.text)) {
            let notification = Object.assign({}, { timeout: null }, content);
            existInlineDialogBox.addContent(
              function(container) {
                this.createDialog(
                  "inline-notification",
                  { notification: notification },
                  container,
                  existInlineDialogBox
                );
              }.bind(this),
              options
            );
          } else {
            existInlineDialogBox.addContent(content, options);
          }
          promise = existInlineDialogBox
            ? existInlineDialogBox.dialogPromise
            : Promise.reject({
                message: "Inline dialog box not found. Ref Id: " + refId
              });
        } else {
          promise = Promise.reject({
            message: "Inline dialog box not found. Ref Id: " + refId
          });
        }
      })
      .catch(reason => {
        promise = Promise.reject({
          message: "inline container not found as named " + refId
        });
      });

    return promise;
  }

  notify(content, options) {
    let notificationDefaultDialogOptions = {
      type: "notification",
      backdrop: false,
      backdropAction: null,
      dialogWidth: "auto",
      dialogHeight: "auto",
      dialogMinHeight: "auto",
      popper: {
        reference: this.layoutController.header.contentElement,
        options: {
          positionFixed: true,
          eventsEnabled: false,
          placement: "bottom-end",
          modifiers: {
            offset: {
              offset: "0,10"
            }
          }
        }
      }
    };
    let dialogOptions = Object.assign(
      {},
      notificationDefaultDialogOptions,
      options
    );
    if (content && content.text && content.text !== "Invalid Form Key. Please refresh the page.") {
      console.error("hello happy content", content )
      let self = this;
      let notification = Object.assign({}, { timeout: content.theme === "danger" ? 10000 : 3000 }, content);
      content = function(container) {
        ReactDOM.render(
          <NotificationBoxComponent
            app={self.app}
            notification={notification}
          />,
          container
        );
      };
    }
    return this.open(content, dialogOptions);
  }

  modal(content, options) {
    let modalDefaultDialogOptions = {
      type: "modal",
      keyboardEnterAction: null
    };
    let dialogOptions = Object.assign({}, modalDefaultDialogOptions, options);
    return this.open(content, dialogOptions);
  }
  drawer(content, options) {
    let modalDefaultDialogOptions = {
      type: "drawer",
      backdrop: false,
      dialogWidth: "auto",
      dialogHeight: `calc(100% - ${this.layoutController.getContentTop()}px)`,
      drawer: {
        direction: "right"
      },
      scrollLock: true,
      
    };
    let dialogOptions = Object.assign({}, modalDefaultDialogOptions, options);
    return this.open(content, dialogOptions);
  }
  context(content, options) {
    let notificationDefaultDialogOptions = {
      type: "context",
      title: "Actions",
      primaryAction: null,
      backdrop: false,
      backdropAction: "dismiss",
      dialogWidth: "auto",
      dialogHeight: "auto",
      dialogMinHeight: "auto",
      popper: {
        reference: options.refElement,
        options: {
          positionFixed: true,
          eventsEnabled: true,
          placement: "top",
          modifiers: {
            offset: {
              offset: "0,calc(-100% + 10)"
            }
          }
        }
      }
    };
    let dialogOptions = Object.assign(
      {},
      notificationDefaultDialogOptions,
      options
    );
    if (content) {
      let self = this;
      let contentObj = content;
      content = function(container) {
        ReactDOM.render(
          <ContextMenuComponent
            app={self.app}
            title={contentObj.title}
            primaryAction={contentObj.primaryAction}
            render={contentObj.render}
            items={contentObj.items}
          />,
          container
        );
      };
    }
    return this.open(content, dialogOptions);
  }
  overlay(content, options) {
    let modalDefaultDialogOptions = {
      type: "overlay",
      dialogWidth: "100%",
      dialogHeight: "100%",
      scrollLock: true
    };
    let dialogOptions = Object.assign({}, modalDefaultDialogOptions, options);
    return this.open(content, dialogOptions);
  }

  open(content, options) {
    if(options && options.dialogRefId && this.dialogs.some( dialog => dialog.refId == options.dialogRefId)) {
      return this.close(null, options.dialogRefId)
    }
    let dialogBox = this.addDialog(content, options);
    return dialogBox ? dialogBox.dialogPromise : null;
  }

  close(result, dialogRefId = null) {
    return this.removeDialog(dialogRefId, result);
  }

  dismiss(reason = null, dialogRefId = null) {
    return this.removeDialog(dialogRefId, reason);
  }

  addDialog(content, options = {}) {
    if (!this.dialogs) this.dialogs = [];
    let dialogOptions = Object.assign({}, this.defaultDialogOptions, options);
    if(dialogOptions.dialogRefId && this.dialogs.some( dialog => dialog.refId == dialogOptions.dialogRefId)) {
      return null;
    }
    if (!dialogOptions.dialogRefId || dialogOptions.dialogRefId == "auto")
      dialogOptions.dialogRefId = _.uniqueId("dialog-ref-");

    let element = document.createElement("div");
    this.dialogRoot.appendChild(element);
    let dialogBox = new DialogBoxComponent(this.app, element);
    dialogBox.refId = options.dialogRefId;
    dialogBox.addContent(content, dialogOptions);

    this.dialogs.push(dialogBox);

    this.setRootClass();
    if (dialogOptions.scrollLock) this.layoutController.lockScroll();

    return dialogBox;
  }

  removeDialog(dialogRefId, result) {
    let promise;
    let existInlineDialog = this.getInlineDialog(dialogRefId);
    if (existInlineDialog) {
      if (result) {
        if (existInlineDialog.dialogResolve)
          existInlineDialog.dialogResolve(result);
      } else {
        if (existInlineDialog.dialogReject)
          existInlineDialog.dialogReject(null);
      }
      existInlineDialog.empty();
      this.inlineDialogs.splice(
        this.inlineDialogs.indexOf(existInlineDialog),
        1
      );
      promise = existInlineDialog.dialogPromise;
    } else {
      if (this.dialogs && this.dialogs.length) {
        let existDialog = this.dialogs.find(
          dialog => dialog.refId == dialogRefId
        );
        if (!existDialog) existDialog = this.dialogs[this.dialogs.length - 1];
        if (existDialog) {
          if (result) {
            if (existDialog.dialogResolve) existDialog.dialogResolve(result);
          } else {
            if (existDialog.dialogReject) existDialog.dialogReject(null);
          }
          existDialog.destroy();
          this.dialogs.splice(this.dialogs.indexOf(existDialog), 1);
        }
        promise = existDialog.dialogPromise;
      }
      this.setRootClass();
      this.layoutController.unlockScroll();
    }
    return promise;
  }

  setRootClass() {
    let rootClass = classNames("l-main__dialog", {
      "l-main__dialog--active": this.dialogs && this.dialogs.length > 0
    });
    this.dialogRoot.setClass(rootClass);
  }

  getInlineDialog(refId) {
    if (!this.inlineDialogs) return null;
    return this.inlineDialogs.find(item => item.dialogRefId == refId);
  }

  createDialog(componentName, componentSettings, container, inlineDialogBox) {
    if (!container) return null;
    let component;
    switch (componentName) {
      case "inline-notification":
      default:
        let options = inlineDialogBox
          ? _.merge(inlineDialogBox.dialogOptions, componentSettings)
          : componentSettings;
        if (!options) options = {};
        component = ReactDOM.render(
          <InlineNotificationComponent
            app={this.app}
            notification={options.notification}
            hasCloseButton={
              options.hasCloseButton != undefined
                ? options.hasCloseButton
                : true
            }
          />,
          container
        );
        break;
    }
    if (component) component.dialog = inlineDialogBox;
    return component;
  }

  scanInlinePlaceholders() {
    return new Promise((resolve, reject) => {
      this.app
        .findChildren("[json-dialog-placeholder]")
        .then(elements => {
          this.inlineDialogs = [];
          let dialogOptions, existInlineDialogBox;
          elements.forEach(element => {
            dialogOptions = this.app.getJSON("dialog-placeholder", element);
            if (dialogOptions && dialogOptions.name)
              dialogOptions.dialogRefId = dialogOptions.name;
            if (dialogOptions && dialogOptions.dialogRefId) {
              existInlineDialogBox = this.getInlineDialog(
                dialogOptions.dialogRefId
              );
              if (!existInlineDialogBox) {
                existInlineDialogBox = new InlineDialogBoxComponent(
                  this.app,
                  element,
                  dialogOptions
                );
                this.inlineDialogs.push(existInlineDialogBox);
              }
            }
          });
          resolve(this.inlineDialogs);
        })
        .catch(error => {
          reject(error);
          this.handleSelectorError(error);
        });
    });
  }
}
