import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';

export default class DrawerMenuComponent extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);

        this.i18nScopeKey = 'DRAWER_MENU';

        this.renderStore = {
            componentClassName: null,
            items: null
        }

        this.state = {
            items: props.items || [],
            title: props.title,
            showAllLink: props.showAllLink
        }      
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
    }
    
    componentDidMount() {
               
    }
  
    componentWillUnmount() {

    }

    render(){
        this.renderStore.componentClassName = classNames(
            'c-drawer-menu',
            {
                ['c-drawer-menu--'+this.props.theme]: this.props.theme
            }
        )
        if(this.state.items) {
            this.renderStore.items = this.state.items.map( (item, index) => {
                return  <li key={index} className={classNames("c-drawer-menu__item", {"c-drawer-menu__item--highlighted": item.highlighted})}>
                            {/* {item.action == 'href' && <a href={item.actionParams.url} target={item.actionParams.target || '_self'} className="c-drawer-menu__item-action">{item.label}</a>} */}
                            {/* {item.action != 'href' && <a onClick={this.itemClickHandler.bind(this, item)} className="c-drawer-menu__item-action">{item.label}</a>} */}
                            {
                                (item.label.toUpperCase()) === 'CLEARANCE' ? 
                                <a onClick={this.itemClickHandler.bind(this, item)} className="c-drawer-menu__item-action-clearance">
                                    <span className="c-drawer-menu__item-label" key={item.label}>{item.label}</span>
                                </a> : (item.label.toUpperCase()) === 'NEW ARRIVALS' ? 
                                <a onClick={this.itemClickHandler.bind(this, item)} className="c-drawer-menu__item-action-newarrivals">
                                    <span className="c-drawer-menu__item-label" key={item.label}>{item.label}</span>
                                </a> :
                                <a onClick={this.itemClickHandler.bind(this, item)} className="c-drawer-menu__item-action">
                                    {item.image && <img className="c-drawer-menu__item-image" src={item.image} alt={item.label}/>}
                                    <span className="c-drawer-menu__item-label" key={item.label}>{item.label}</span>
                                </a>
                            }
                        </li>
            });
            if(this.props.theme === "outlined")
            this.renderStore.items.push(<li className={classNames("c-drawer-menu__item")}>
            {/* {item.action == 'href' && <a href={item.actionParams.url} target={item.actionParams.target || '_self'} className="c-drawer-menu__item-action">{item.label}</a>} */}
            {/* {item.action != 'href' && <a onClick={this.itemClickHandler.bind(this, item)} className="c-drawer-menu__item-action">{item.label}</a>} */}
                <a href="/order-status" className="c-drawer-menu__item-action">
                         <span className="c-drawer-menu__item-label">Track Order</span>
                </a>
            
        </li>)
        }

        return  <div ref={ e => this.rootElement = e} className={this.renderStore.componentClassName}>
                    <div className="c-drawer-menu__container">
                        {this.state.title && <div className="c-drawer-menu__caption">
                            <span className="c-drawer-menu__title">
                                {this.state.title}
                            </span>
                            {this.state.showAllLink && <span className="c-drawer-menu__show-all">
                                <a href={this.state.showAllLink}>{this.i18nService.translate([this.i18nScopeKey, 'SHOW_ALL_LABEL'])}</a>
                            </span>}
                        </div>}
                        <ul className="c-drawer-menu__items">
                            {this.renderStore.items}
                        </ul>
                    </div>
                </div>
    }

    addSection(section) {
        this.state.sections.push(section);
    }

    itemClickHandler(item) {
        if(this.props.onAction && item) this.props.onAction(item.action, item.actionParams);
    }
}