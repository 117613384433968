import BaseService from "../core/base.service";
import ReactGA from 'react-ga';
import AccountService from "./account.service";

export default class GAService extends BaseService {

  constructor(app) {
    super(app);
    this.dimensions = {};
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.accountService = this.app.accountService;
    this.cartService = this.app.cartService;
    this.mageService = this.app.mageService;
    // ReactGA.initialize(this.app.settings.gaTrackingId, { testMode: this.app.environment.name != 'production' });
    if (!window.ga) ReactGA.initialize(this.app.settings.gaTrackingId, { 
      testMode: false,
      allowLinker: true
    });
    ReactGA.plugin.require('ec');
    window.ReactGA = ReactGA;
    this.ga = ReactGA.ga;

    this.checkIfAnalyticsLoaded().then(result => {
      setTimeout(() => {
        if(this.accountService.isReady) {
          this.init();
        }else{
          this.accountService.addEventListener(AccountService.EVENT_READY, this.init.bind(this));
        }
      }, 300);
    });
  }

  init() {
    this.set('dimension4', this.accountService.isGuestUser ? "NOT LOGGED IN" : this.accountService.user && this.accountService.user.group_name);
    ReactGA.set(this.dimensions);

    ReactGA.pageview(window.location.pathname + window.location.search);
    this.sendDataLayer();
    this.dispatchEvent(GAService.EVENT_READY);
  }

  set(key, value) {
    if(!this.dimensions) this.dimensions = {};
    this.dimensions[key] = value;
  }

  sendDataLayer() {
    if (!this.mageService.trackEnabled) return;
    if (window.dataLayer) {
      const transacetionEvent = window.dataLayer.find(item => item.event === 'transaction');
      if (transacetionEvent) {
        if (transacetionEvent.ecommerce && transacetionEvent.ecommerce.purchase) this.purchase(transacetionEvent.ecommerce.purchase.products, transacetionEvent.ecommerce.purchase.actionField);
      }
    }
  }

  addImpression(impressions) {
    if (!this.mageService.trackEnabled) return Promise.reject(null);
    return new Promise((resolve, reject) => {
      if (impressions) {
        if (!Array.isArray(impressions)) impressions = [impressions];
        impressions.forEach(impression => {
          ReactGA.plugin.execute('ec', 'addImpression', impression);
        });
        resolve(impressions);
      } else {
        reject({ message: 'Invalid Impressions' });
      }
    });
  }

  addPromo(promos) {
    if (!this.mageService.trackEnabled) return Promise.reject(null);
    return new Promise((resolve, reject) => {
      if (promos) {
        if (!Array.isArray(promos)) promos = [promos];
        promos.forEach(promo => {
          ReactGA.plugin.execute('ec', 'addPromo', promo);
        });
        resolve(promos);
      } else {
        reject({ message: 'Invalid Promos' });
      }
    });
  }

  addProduct(products) {
    if (!this.mageService.trackEnabled) return Promise.reject(null);
    return new Promise((resolve, reject) => {
      if (products) {
        if (!Array.isArray(products)) products = [products];
        products.forEach(product => {
          ReactGA.plugin.execute('ec', 'addProduct', {...this.dimensions, ...product});
        });
        resolve(products);
      } else {
        reject({ message: 'Invalid Products' });
      }
    });
  }

  addToCart(products) {
    if (!this.mageService.trackEnabled) return Promise.reject(null);
    return new Promise((resolve, reject) => {
      this.addProduct(products).then(result => {
            ReactGA.plugin.execute('ec', 'setAction', 'add');
            ReactGA.event({
              category: 'Ecommerce',
              action: 'Add To Cart',
              label: (result.length && result[0].label || null)  
            });
        resolve(result);
      }).catch(reason => reject(reason));
    });
  }
  clickOnWidgets(label){
    if (!this.mageService.trackEnabled) return null;
    ReactGA.event({
      category: 'Ecommerce',
      action: 'WidgetsClick',
      label
    });
  }
  removeFromCart(products) {
    if (!this.mageService.trackEnabled) return Promise.reject(null);
    return new Promise((resolve, reject) => {
      this.addProduct(products).then(result => {
        ReactGA.plugin.execute('ec', 'setAction', 'remove');
        ReactGA.event({
          category: 'Ecommerce',
          action: 'Remove From Cart'
        });
        resolve(result);
      }).catch(reason => reject(reason));
    });
  }

  purchase(products, transaction) {
    if (!this.mageService.trackEnabled) return Promise.reject(null);
    return new Promise((resolve, reject) => {
      this.addProduct(products).then(result => {
        ReactGA.plugin.execute('ec', 'setAction', 'purchase', transaction);
        ReactGA.event({
          category: 'Ecommerce',
          action: 'Purchase'
        });
        // this.ga('send', 'pageview');
        resolve(result);
      }).catch(reason => reject(reason));
    });
  }

  refund(products, transaction) {
    if (!this.mageService.trackEnabled) return Promise.reject(null);
    return new Promise((resolve, reject) => {
      this.addProduct(products).then(result => {
        ReactGA.plugin.execute('ec', 'setAction', 'refund', transaction);
        // this.ga('send', 'pageview');
        ReactGA.event({
          category: 'Ecommerce',
          action: 'Refund',
          label: 'Product',
          nonInteraction: true
        });

        resolve(result);
      }).catch(reason => reject(reason));
    });
  }

  checkout(products, step, option) {
    if (!this.mageService.trackEnabled) return Promise.reject(null);
    return new Promise((resolve, reject) => {
      this.addProduct(products).then(result => {
        ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
          step: step,
          option: option
        });
        ReactGA.event({
          category: 'Ecommerce',
          action: 'Checkout'
        });
        resolve(result);
      }).catch(reason => reject(reason));
    });
  }

  getProductsFromCart() {
    if (!this.mageService.trackEnabled) return Promise.reject(null);
    return new Promise((resolve, reject) => {
      if (this.cartService.cart && this.cartService.cart.items) {
        let products = [];
        this.cartService.cart.items.forEach((product, index) => {
          products.push({
            'id': product.sku,
            'name': product.name,
            'category': product.category,
            'brand': product.brand,
            'variant': product.variant,
            'price': product.price,
            'position': index,
            'quantity': product.qty
          });
        });
        resolve(products);
      } else {
        reject({ message: 'Empty Cart' })
      }
    });
  }

  clear() {
    ReactGA.plugin.execute('ec', 'clear');
  }

  detail(products, promos, impressions) {
    if (!this.mageService.trackEnabled) return Promise.reject(null);
    return Promise.all([
      this.addProduct(products),
      this.addPromo(promos).catch(e => { return e }),
      this.addImpression(impressions).catch(e => { return e }),
      new Promise((resolve, reject) => {
        ReactGA.plugin.execute('ec', 'setAction', 'detail');
        // this.ga('send', 'pageview');
        ReactGA.event({
          category: 'Ecommerce',
          action: 'Product View',
          label: 'Product',
          nonInteraction: true
        });
        resolve(products, promos, impressions);
      })
    ])
  }


  checkIfAnalyticsLoaded() {
    return new Promise((resolve, reject) => {
      let timeStart = Date.now();
      const TIMEOUT = 5000;

      let _isLoaded = function () {
        if (Date.now() - timeStart > TIMEOUT) {
          reject('Timeout. Google analytics not injected!');
          return;
        }
        if (window.ga && ga.create && gaplugins && typeof gaplugins.EC === 'function') {
          resolve(ga);
          return;
        } else {
          setTimeout(_isLoaded, 500);
        }
      };

      _isLoaded();
    });
  }
}

GAService.EVENT_READY = "ready";