import { BaseApiService } from "./base-api.service";

export default class SavedListService extends BaseApiService {
  constructor(app) {
    super(app);
    this.getSavedLists = this.getSavedLists.bind(this);
    this.savedListApi = this.savedListApi.bind(this);
  }
  appOnInit() {
    super.appOnInit();
    this.loadingController = this.app.getController("loading");
  }

  savedListApi(url, data) {
    return new Promise((resolve, reject) => {
      this.post(`/saved-lists/${url}`, data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.error("error1", err);
          reject(err);
        });
    });
  }

  getSavedLists(param) {
    let url = "/saved-lists/viewList";
    if (param) url = url + param;
    return new Promise((resolve, reject) => {
      this.get(url)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.error("error1", err);
          reject(err);
        });
    });
  }

  quickOrderAddtoCart(payload) {
    return new Promise((resolve, reject) => {
      this.post("/fsd/quick-order-by-quick-entry", payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.error("error1", err);
          reject(err);
        });
    });
  }
  
  quickOrderAddListtoCart(list_id) {
    return new Promise((resolve, reject) => {
      this.post("/fsd/quick-order-by-saved-list", {listId:list_id})
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.error("error1", err);
          reject(err);
        });
    });
  }

  quickOrderDownloadErrorReport(errorSkus) {
    return new Promise((resolve, reject) => {
      this.post("/fsd/quick-order/download-error-report", errorSkus)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.error("error1", err);
          reject(err);
        });
    });
  }

  downloadSampleCsv() {
    return new Promise((resolve, reject) => {
      this.get("/fsd/quick-order/download-sample-file")
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.error("error1", err);
          reject(err);
        });
    });
  }

  quickOrderImportCsv(formData){
    return new Promise((resolve, reject) => {
      this.post("/fsd/quick-order-by-import-csv", formData)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.error("error1", err);
          reject(err);
        });
    });
  }
  callViewSaveList(){
    this.dispatchEvent(SavedListService.SAVELIST_CHANGE);
  }
}
SavedListService.SAVELIST_CHANGE = "savelist-changed";
SavedListService.EVENT_REFRESH = "refresh-cart";
