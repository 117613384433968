import { DomComponent } from "../core/dom.component";
import FormComponent from "./form.component";
import classNames from "classnames";

export default class AccountInformationFormComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-account-information-form');
        this.accountInformationForm;
        this.accountInformationFormSubmit = this.accountInformationFormSubmitHandler.bind(this);
        this.changePasswordInput = null;
        this.changePasswordInputChange = this.changePasswordInputChangeHandler.bind(this);
        this.changePasswordIsActive = false;
        this.init();
    }

    init() {
        this.findChild(this.getSelector(' .c-form')).then(element => {
            this.accountInformationForm = new FormComponent(this.app, element);
            this.accountInformationForm.addEventListener(FormComponent.EVENT_SUBMIT, this.accountInformationFormSubmit)
        });

        this.findChild(this.getSelector('__change-password-input input')).then( element => {
            this.changePasswordInput = element;            
            this.changePasswordInput.onchange = this.changePasswordInputChange;
            this.changePasswordInputChangeHandler();
        });        
    }

    setComponentClass() {
        let className = classNames(
            this.getSelector("", ""),
            {
                [this.getSelector("--change-password", "")]: this.changePasswordIsActive
            }
        )
        this.setClass(className);
    }

    accountInformationFormSubmitHandler() {
        console.log(this.accountInformationForm.value);
    }

    changePasswordInputChangeHandler() {
        this.changePasswordIsActive = this.changePasswordInput.checked;
        this.setComponentClass();
    }

}