import BaseService from "../core/base.service";

export class PaypalService extends BaseService {

    constructor(app) {
        super(app);
        this.session = null;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.paypal_load = false;
        this.host = this.app.environment.api.host;
        this.accountService = this.app.accountService;
        this.dialogController = this.app.getController('dialog');
        this.loadingController = this.app.getController('loading');
        this.checkoutController = this.app.getController('checkout');
        this.i18nService = this.app.i18nService;
        this.checkoutService = this.checkoutController.checkoutService;
        this.environment = this.app.environment.name !== 'production' ? 'sandbox' : 'production';
        
    }

    setEnvironment(env) {
        if(env) {
            if(env.environment) this.environment = env.environment;
        }
    }
    
    getToken(data, actions,checkout) {
        let self = this;
        this.loadingController.showLoading('checkout');
        let request = actions.request || this.http;
        let url = `${this.getEndpoint('paypalGetToken')}${checkout ? '?button=1' : ''}`;
        console.log('DevTestPaypal -- ',url)
        return this.http.get(url, null, {
            validateStatus: null
        }).then(response => {
            if(response) {
                if(response.status) {
                    switch(response.status) {
                        case 200:
                            if(response.data && response.data.message && response.data.message.type === 'error') {
                                console.log(response);
                                self.loadingController.hideLoading('checkout');
                                const responseText = response.data.message.text;
                                const code = responseText.substring(responseText.indexOf('#') + 1, responseText.indexOf(':'));
                                let notificationText = responseText;
                                if(code == '10736'){
                                    notificationText = this.i18nService.translate(['PAYPAL', 'ERROR_MESSAGE', 'ADDRESS_ERROR'])
                                }
                                self.dialogController.notify({
                                    theme: 'danger',
                                    text: notificationText
                                });
                                return null;
                            }else{
                                const query = response.data.url.split('?');
                                const params = new URLSearchParams(query[1]);
                                // const params = (new URL(response.data.url)).searchParams;
                                const token = params.get('token'); 
                                return token;       
                            }
                        break; 
                        default:
                            self.loadingController.hideLoading('checkout');
                            if(response.data && response.data.message) {
                                self.dialogController.notify({
                                    theme: 'danger',
                                    text: response.data.message.text
                                });
                            }
                            return null;
                        break;
                    }
                }
            }else{
                self.loadingController.hideLoading('checkout');
                return null;
            }
        }).catch( error => {
            self.loadingController.hideLoading('checkout');

            console.log("paypal error = ", error);
            console.log("paypal error.message = ", error.message);

            if(error.data && error.data.message && error.data.message.type === 'error') {
                self.loadingController.hideLoading('checkout');
                self.dialogController.notify({
                    theme: 'danger',
                    text: error.data.message.text
                });
            }else{
                self.dialogController.notify({
                    theme: 'danger',
                    text: error && error.message ? error.message : "Unknown Error"
                });
            }
            return null;
        });
    }

    returnAction(data, actions) {
        return actions.request.post(this.getEndpoint('paypalReturnAction'), {
            paymentID: data.paymentID,
            payerID:   data.payerID
          })
            .then(function(res) {
              console.log(res);
            });
    }

    cancel() {
        this.loadingController.hideLoading('checkout');
    }

    //new sdk
    createTransaction() {
        return this.http.post(this.getEndpoint('paypalCreateTransaction'), null, {
            responseType: 'json',
        }).then(function(res) {
            return res.json();
        }).then(function(data) {
            return data.orderID;
        });
    }

    //new sdk
    captureTransaction(orderID) {
        return this.http.post(this.getEndpoint('paypalCaptureTransaction'), {
            body: JSON.stringify({
              orderID: orderID
            })
          }).then(function(res) {
            return res.json();
          }).then(function(details) {
            alert('Transaction funds captured from ' + details.payer_given_name);
          });
    }

    getEndpoint(key) {
        let endpoint = this.app.environment.endpoint[key];
        if(!endpoint) return null;
        return endpoint;
    }
}