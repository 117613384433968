
import { DomComponent } from "../core/dom.component";
import classNames from "classnames";

export default class RatingComponent extends DomComponent {
    constructor(app, element, settings) {
        super(app, element, 'c-rating');
        this.value;
        this.stars = [];
        this.starClick = this.starClickHandler.bind(this);
        this.settings = settings || {};
        this.valueList = null;
        this.init();
    }

    init() {
        this.valueList = this.getJSON('value-list');
        this.findChildren(this.getSelector('__star')).then( starElements => {
            this.stars = [];
            starElements.forEach( (starElement, index) => {
                starElement.setAttribute('value', index+1);
                starElement.onclick = this.starClick;

                this.stars.push({
                    element: starElement,
                    value: index
                });
            });
            let name = this.domElement.getAttribute('name');
            if(!name && this.settings.name) this.domElement.setAttribute('name', this.settings.name);
            let value = this.domElement.getAttribute('value');
            this.setValue(value || this.settings.value);
        });
    }

    setValue(value) {
        this.value = parseInt(value);
        if(this.value) {
            this.domElement.setAttribute('value', this.value);
        }
        this.setComponentClass();
        this.dispatchEvent(RatingComponent.EVENT_CHANGE);
    }

    setComponentClass() {
        let className = classNames(
            this.getSelector('', ""),
            this.getSelector('--outline', ""),
            {
              [this.getSelector('--'+this.value, '')]: this.value && this.value > 0 && this.value <= 5
            }
        );
        this.setClass(className);
    }

    starClickHandler(e) {
        this.setValue(e.currentTarget.getAttribute('value'));
    }
}

RatingComponent.EVENT_CHANGE = "change";