import { BaseController } from "../core/base.controller";
import FormComponent from "../components/form.component";
import RegisterFormComponent from "../components/register-form.component";

export class FormsController extends BaseController {

    constructor(app) {
        super(app);
        this.forms = [];
        this.registerForm = null;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.app.findChildren('.c-form').then( elements => {
            elements.forEach( element => {
                this.forms.push(new FormComponent(this.app, element));
            })
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChild('.p-sign-up__new-customer-form').then( element => {
            this.registerForm = new RegisterFormComponent(this.app, element);
        }).catch( reason => this.handleSelectorError(reason));
    }
}