import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';
import CategoryService from '../services/category.service';
import { ViewportController } from '../controllers/viewport.controller';

export default class CatalogSearchBar extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);
        
        this.i18nScopeKey = 'CATALOG_SEARCH_BAR';
        
        this.searchInput = null;
        this.categorySelect = null;
        this.wrapperRef = null;
        this.initialValue = props.value;

        this.renderStore = {
            componentClass: null,
            categoryOptions: null,
            searchResultList: null
        }

        this.clearSearch = {
            componentClass: null
        }

        let defaultSettings = {
            isWide: false
        }

        this.state = {
            isMobile: false,
            isFocused: false,
            isProcessing: false,
            settings: props.settings || defaultSettings,
            searchResult:  null,
            searchValue: null,
            selectedCategory: 0,
            clearButtonIsActive: false,
            isSuggestionOpen: false,
            hasValue: false
        }
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
        this.layoutController = this.app.getController('layout');
        this.viewportController = this.app.getController('viewport');
        this.searchController = this.app.getController('search');
        this.searchService = this.searchController.searchService;
        this.categoryService = this.app.categoryService;
        this.routerController = this.app.getController('router');

        this.categoryService.addEventListener(CategoryService.EVENT_CATEGORIES_CHANGE, this.categoriesChangeHandler.bind(this));
        this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(ViewportController.EVENT_BREAKPOINT_CHANGE, this.viewportBreakpointChangeHandler.bind(this));
        this.clickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.searchInputDebounced = _.debounce(this.searchInputChangeHandler.bind(this), 400);
        let searchQuery = this.routerController.queryParams.get('q');
        if(searchQuery && !this.initialValue) this.initialValue = searchQuery;
        if(this.initialValue) this.searchInput.value = this.initialValue;
        this.setCategoryById(null, true);
    }

    render() {
        if(!this.appIsReady) return null;
        let hasResult = this.state.searchResult && this.state.searchResult.length > 0;
        // if(hasResult && !this.state.isProcessing) {
        //     this.layoutController.showOverlay();
        // }else{
        //     this.layoutController.hideOverlay();
        // }
        this.renderStore.componentClass = classNames(
            'c-catalog-search-bar',
            {
                'c-catalog-search-bar--wide': this.state.settings.isWide,
                'c-catalog-search-bar--open': hasResult,
                'c-catalog-search-bar--mobile': this.state.isMobile
            }
        );
        
        if(this.state.categories && this.state.categories.length) {
            this.renderStore.categoryOptions = this.state.categories.map( (category) => {
                return <option key={'category-'+category.id} value={category.id}>{category.name}</option>
            });
        }

        this.renderStore.searchResult = null;
        if(this.state.searchResult) {
            this.open();          
            this.renderStore.searchResultList = this.state.searchResult.map( (resultItem, index) => {
                resultItem.typeLabel = this.i18nService.translate([this.i18nScopeKey, 'SUGGEST_TYPE', resultItem.type], null, null, true);
                return <li key={'suggest-'+resultItem.type + '-' + index} className={classNames('c-catalog-search-bar__result-item', {'c-catalog-search-bar__result-item--selected': resultItem.selected})}>
                    <a href={resultItem.url} className="c-catalog-search-bar__result-item-button">
                        {(resultItem.typeLabel && resultItem.typeLabel.length) && <span className="c-catalog-search-bar__result-item-type">{resultItem.typeLabel}</span>}
                        <span className="c-catalog-search-bar__result-item-title">{resultItem.title}</span>
                    </a>
                </li>
            });
        }else {            
            this.close();  
            this.renderStore.searchResultList = null;        
        }
        
        return <div ref={ wrapper => this.wrapperRef = wrapper} className={this.renderStore.componentClass}>
                <div className="c-catalog-search-bar__background"></div>
                <div className="c-catalog-search-bar__container container">
                    <div className="c-catalog-search-bar__input-bar">
                        <span className="c-catalog-search-bar__input">
                            <input ref={ r => this.searchInput = r } 
                                onFocus={this.searchIntputFocusHandler.bind(this)} 
                                onBlur={this.searchIntputBlurHandler.bind(this)} 
                                onKeyDown={this.searchInputKeyDownHandler.bind(this)} 
                                onChange={this.searchInputDebounced} 
                                type="text" placeholder={this.state.isMobile ? this.i18nService.translate([this.i18nScopeKey, 'SEARCH_INPUT_PLACEHOLDER_SHORT']) : this.i18nService.translate([this.i18nScopeKey, 'SEARCH_INPUT_PLACEHOLDER'])} />
                            {this.state.clearButtonIsActive && <span className='c-catalog-search-bar__input-close' onClick={this.clearSearchInput.bind(this)}>
                                <i className="icon__fsd icon__fsd--close-gray"></i>
                            </span>}
                                
                        </span>
                        {this.renderStore.categoryOptions && this.searchService.hasCategoryFilter && <span className="c-catalog-search-bar__selectbox">
                            <select ref={ r => this.categorySelect = r } onChange={this.categorySelectChangeHandler.bind(this)}>
                                <option value="0">{this.state.isMobile ? this.i18nService.translate([this.i18nScopeKey, 'ALL_CATEGORIES_SHORT']) : this.i18nService.translate([this.i18nScopeKey, 'ALL_CATEGORIES'])}</option>
                                {this.renderStore.categoryOptions}
                            </select>
                        </span>}
                        <span className="c-catalog-search-bar__button">
                            <button onClick={this.submitButtonClickHandler.bind(this)} className={classNames('c-button c-button--secondary c-button--icon', {'c-button--processing': this.state.isProcessing})}>
                                <i className="icon__fsd icon__fsd--search-primary"></i>
                            </button>
                        </span>
                    </div>
                    <div className="c-catalog-search-bar__result">
                        <ul className="c-catalog-search-bar__result-list">
                            {this.renderStore.searchResultList}
                        </ul>
                    </div>
                </div>
            </div>
    }

    setCategoryById(id, forceGetFromQueryParams, forceToChange) {
        if(!this.state.categories) return;
        if(!id && forceGetFromQueryParams) id = this.routerController.queryParams.get('cat');
        let existCategory = this.state.categories.find( item => item.id == id);
        if(existCategory && this.categorySelect) this.categorySelect.value = existCategory.id;
        if(forceToChange) this.categorySelectChangeHandler();
    }

    open(){
        if(this.state.isSuggestionOpen){ return }
        this.setState({
            isSuggestionOpen: true
        });
        // document.addEventListener('mousedown', this.clickOutside);
        this.viewportController.addGestureEvent('tap', this.clickOutside);
    }

    close(){
        if(!this.state.isSuggestionOpen){ return }     
        this.setState({
            searchResult: null,
            isSuggestionOpen: false
        });
        // document.removeEventListener('mousedown', this.clickOutside);
        this.viewportController.removeGestureEvent('tap', this.clickOutside);
    }

    clearSearchInput(e) {
        this.searchInput.value = "";
        this.setValue(null);
    }

    setValue(value, callback){
        this.setState({
            searchValue: value,
            hasValue: (value && value.length > this.app.settings.minSearchCharCount),
            clearButtonIsActive: (value && value.length > 0)
        }, () => {
            this.close();
            if(callback){
                callback(this.state);
            }
        });                      
    }

    categoriesChangeHandler() {
        let mainCategories = [];
        if(this.categoryService.categories && this.categoryService.categories.length) {
            this.setState({
                categories: this.categoryService.categories,
                isProcessing: false
            }, ()=>{
                this.setCategoryById(null, true);
            });
        }
    }

    viewportBreakpointChangeHandler() {
        if(this.categorySelect) {
            this.categorySelect.selectedIndex = 0;
            this.categoriesChangeHandler();
        }
        this.setState({
            isMobile: this.viewportController.isMobile
        });
    }

    categorySelectChangeHandler(e) {
        if(!this.categorySelect) return;
        this.setState({
            selectedCategory: this.categorySelect.value
        });
        this.searchInputChangeHandler();
    }

    searchInputChangeHandler(e) {       

        this.setValue(this.searchInput.value, () => {
            if(this.state.hasValue){
                this.search(this.searchInput.value);
            }            
        }); 
      
    }

    searchIntputFocusHandler(e) {
        this.setState({ isFocused: true });
        this.searchInputChangeHandler();
    }

    searchIntputBlurHandler(e) {
        this.setState({isFocused: false});
    }

    submitButtonClickHandler() {
        this.gotoList();
    }

    searchInputKeyDownHandler(event) {
        switch(event.keyCode) {
            case 9: //TAB
              if(this.state.isFocused) {
                // event.preventDefault();
                // this.searchInput.blur();
              }
            //   this.selectItem(this.state.items[0]);
            break;
            case 13: //Enter
              if(this.state.isFocused){
                this.gotoList();
              }else{
                if(this.state.selectedItem) {
                  
                }
              }
            break;
            case 27: //ESC
              if(this.state.selectedItem) {
                this.searchInput.focus();
                // this.selectItem(null);
              }else {
                if(this.state.isFocused) this.clearSearchInput();
              }
            break;
            //case 37: //Left
            case 38: //Top
            //   event.preventDefault();
            //   if(!this.state.isFocused) this.selectPrev();
            break;
            //case 39: //Right
            case 40: //Down
              event.preventDefault();
              if(!this.state.isFocused) {
                // this.selectNext();
              }else{
                if(this.isFocused) {
                  event.preventDefault();
                  this.searchInput.nativeElement.blur();
                }
                //this.selectItem(this.state.items[0]);
              }
            break;
        }
    }

    gotoList() {
        // if(this.searchService.searchType == 'readonly') return;
        if(this.searchInput.value && _.trim(this.searchInput.value).length > 0) {
            window.location.href = this.searchService.resultUrl + "?q=" + this.searchInput.value + (this.state.selectedCategory ? '&cat='+this.state.selectedCategory : '');
        }
    }
    search(value) {
        if(this.searchService.searchType == 'readonly') return;
        if(value && value.length) {
            this.setState({
                isProcessing: true,
                searchValue: value,
                searchResult: null
            });
        }else{
            this.setState({
                isProcessing: false,
                searchResult: null,
                searchValue: ''
            });
        }
        this.searchService.searchCatalogSuggestion(value, this.state.selectedCategory).then( result => {
            let searchResult = result.map( resultItem => {
                return {
                    id: resultItem.item_id,
                    title: resultItem.title || resultItem.name,
                    url: resultItem.url,
                    type: resultItem.type,
                    selected: false
                }
            });
            this.setState({
                isProcessing: false,
                searchResult: searchResult
            });
        }).catch( reason => this.setState({
            isProcessing: false,
            searchResult: null
        }));
    }

    handleClickOutside(event) {
        if (event && event.target && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.close();
        }          
    }

}