import { DomComponent } from "../core/dom.component";
import { ViewportController } from "../controllers/viewport.controller";
import classNames from "classnames";
import Swiper from "swiper";

export default class CategoryNavigationComponent extends DomComponent {
  constructor(app, element) {
    super(app, element, "c-category-navigation");
    this.swiper = null;

    this.init();
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.setSwiper();
    // this.viewportController = this.app.getController("viewport");

    // this.viewportBreakpointChangeHandler =
    //   this.viewportBreakpointChangeHandler.bind(this);
    // this.viewportBreakpointChangeProxyListener =
    //   this.viewportController.addEventListener(
    //     ViewportController.EVENT_BREAKPOINT_CHANGE,
    //     this.viewportBreakpointChangeHandler
    //   );
    // this.viewportBreakpointChangeHandler();
  }

  init() {
    if (!this.viewportController) this.setSwiper();
  }

  setSwiper() {
    if (this.swiper) return;
    this.findChild(this.getSelector("__container")).then((element) => {
      const disableSpacing = element.getAttribute('data-spacing');
      this.swiper = new Swiper(element, {
        spaceBetween: disableSpacing ? 0 : 10,
        touchRatio: 0.2,
        slidesPerView: "auto",
        slideToClickedSlide: false,

        scrollbar: {
          draggable: true,
          dragSize: 100,
        },
        mousewheel: true,
        navigation: {
          nextEl: this.querySelector(this.getSelector("__nav-next")),
          prevEl: this.querySelector(this.getSelector("__nav-prev")),
          disabledClass: this.getSelector("__nav-item--disabled", ""),
          hiddenClass: this.getSelector("__nav-item--hidden", ""),
        },
        freeMode: true,
      });

      setTimeout(() => this.app.trigger("resize"), 50);
    });
  }

  viewportBreakpointChangeHandler() {
    if (!this.viewportController) return;
    if (this.viewportController.isDesktop && !this.swiper) {
      this.setSwiper();
    } else {
      if (this.swiper) {
        this.swiper.destroy();
        this.swiper = null;
      }
    }
  }
}
