import { DomComponent } from "../core/dom.component";
import classNames from 'classnames';

export default class ChoiceBoxComponent extends DomComponent {
    constructor(app, element, choiceSettings) {
        super(app, element, 'c-choice-box');
        
        this.choices;
        this.choiceSettings = choiceSettings;
        this.selectInput;
        this.hasAvailableChoices = false;
        this.selectedItems = null;

        this.init();
    }

    init() {
        this.findChild(this.getSelector(' > select')).then(element => {
            this.selectInput = element;
            let choicesList = this.getJSON('choices') || [];
            this.choiceSettings = {...this.choiceSettings, ...this.getJSON('choice-options')} || {};
            this.choices = new Choices(element, {
                choices: choicesList,
                renderChoiceLimit: this.choiceSettings.maxChoiceCount || -1,
                maxItemCount: this.choiceSettings.maxItemCount || -1,
                removeItemButton: true,
            });
            this.selectInput.addEventListener('change', this.changeHandler.bind(this));
            this.setSelectedItems();
        })
    }

    setComponentClass() {
        let className = classNames(
            this.getSelector("", ""),
            {
                [this.getSelector("--no-available-choices", "")]: !this.hasAvailableChoices
            }
        )
        this.setClass(className);
    }

    setChoices(items) {
        this.choices.setChoices(items);
    }

    setSelectedItems(items) {
        this.selectedItems = items || this.choices.getValue();
        this.hasAvailableChoices = this.selectedItems.length < this.choices.config.maxItemCount;
        this.setComponentClass();
    }

    changeHandler(event) {
        console.log("choice box change handler => ", event);
        let value;
        this.setSelectedItems();

        if(this.selectedItems.length == 1) {
            value = this.selectedItems[0].value;
        }else{
            if(this.selectedItems.length > 1) {
                let values = [];
                this.selectedItems.forEach( item => values.push(item.value));
                value = values.join(',');
            }
        }
        this.selectInput.value = value;
        this.setComponentClass();
        this.dispatchEvent(ChoiceBoxComponent.EVENT_CHANGE);
    }
}

ChoiceBoxComponent.EVENT_CHANGE = "change";