import { DomComponent } from "../core/dom.component";
import Swiper from "swiper";

export default class CategoryNavigationComponent extends DomComponent {
  constructor(app, element) {
    super(app, element, "c-team-slider");
    this.init();
  }

  init() {
      console.log('insde');
    this.findChild(this.getSelector("__slider")).then((element) => {
      this.swiper = new Swiper(element, {
        slidesPerView: 2,
        slidesPerColumn: 3,
        slidesPerGroup:2,
        breakpoints: {
            [this.app.settings.breakpoints.xl.min]: {
                slidesPerView: 2,
                slidesPerColumn: 3,
                slidesPerGroup:2,
            },
            [this.app.settings.breakpoints.lg.min]: {
                slidesPerView: 1,
                slidesPerColumn: 3,
                slidesPerGroup:2,
            },
            [this.app.settings.breakpoints.md.min]: {
                slidesPerView: 1,
                slidesPerColumn: 3,
                slidesPerGroup:1,
            },
            [this.app.settings.breakpoints.sm.min]: {
                slidesPerView: 1,
                slidesPerColumn: 3,
                slidesPerGroup:1,
            }
        },
        navigation: {
          nextEl: this.querySelector(this.getSelector("__nav-next")),
          prevEl: this.querySelector(this.getSelector("__nav-prev")),
          disabledClass: this.getSelector("__nav-item--disabled", ""),
          hiddenClass: this.getSelector("__nav-item--hidden", ""),
        },
        pagination: {
          el: this.getSelector("__pagination"),
          clickable: true,
          bulletClass: this.getSelector("__pagination-bullet", ""),
          bulletActiveClass: this.getSelector(
            "__pagination-bullet--active",
            ""
          ),
        },
      });
    });
  }
}
