import { BaseApiService } from "./base-api.service";

export default class ManufacturerBrandsService extends BaseApiService {
    
    constructor(app) {
        super(app);
        this.manufacturerBrands = {};
        this.showMoreKey = "show_all_brands";
    }

    getManufacturerBrands() {
        return new Promise( (resolve, reject) => {
            if(!this.manufacturerBrands || this.manufacturerBrands != {}) {
                let endpoint = this.app.environment.name == 'development' ? 'http://integration-5ojmyuq-s2alu7ch6gndo.us-3.magentosite.cloud/rest/V1/get-brands-manufacturer/' : 'get-brands-manufacturer';
                
                this.get(endpoint).then(result => {
                    if(typeof result === 'string') result = JSON.parse(result);
                    if(result) {
                        this.setManufacturerBrands(result);
                    }

                    resolve(this.manufacturerBrands);
                }).catch( reason => reject(reason) )
            }else {
                resolve(this.manufacturerBrands);
            }
        })
    }

    // getMockManufacturerBrands() {
    //     return new Promise( (resolve, reject) => {
    //         let response = {  
    //             "brands":{  
    //                "meta":[  
    //                   {  
    //                      "show_all_brands":{  
    //                         "label": "Show All",
    //                         "url":"more_brands.html"
    //                      }
    //                   }
    //                ],
    //                "items":[
    //                 {
    //                    "title":"Hellmanns",
    //                    "logo":"https://www.foodservicedirect.com/media//cache/hellmanns.png",
    //                    "url":"https://www.foodservicedirect.com/manufacture/index/view/id/4/?brand=925"
    //                 },
    //                 {
    //                    "title":"Bestfood",
    //                    "logo":"https://www.foodservicedirect.com/media//cache/bestfood.png",
    //                    "url":"https://www.foodservicedirect.com/manufacture/index/view/id/4/?brand=2348"
    //                 },
    //             ]
    //             },
    //             "manufacturer":{  
    //                "meta":[],
    //                "items":[
    //                 {
    //                    "name":"Unilever",
    //                    "logo":"assets/images/logo/unilever.png",
    //                    "url":"https://www.foodservicedirect.com/manufacture/index/view/id/4/"
    //                 },
    //                 {
    //                    "name":"Winco Foods",
    //                    "logo":"assets/images/logo/winco-foods.png",
    //                    "url": "https://www.foodservicedirect.com/manufacture/index/view/id/4/"
    //                 }
    //             ]
    //             }
    //          }
    //          if(response) {
    //              this.setManufacturerBrands(response);
    //          }
    //          resolve(this.manufacturerBrands);
    //     });
    // }

    setManufacturerBrands(manufacturerBrands) {
        if(manufacturerBrands) {
            this.manufacturerBrands = manufacturerBrands;
            this.dispatchEvent(ManufacturerBrandsService.EVENT_MANUFACTURER_BRANDS_CHANGE);
        }
    }

}

ManufacturerBrandsService.EVENT_MANUFACTURER_BRANDS_CHANGE = "manufacturer-brands-change";