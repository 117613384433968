import { DomComponent } from "../core/dom.component";
import classNames from 'classnames';
import Popper from "popper.js";
import ReactDOM from "react-dom";

export default class InlineDialogBoxComponent extends DomComponent {

    constructor(app, element, dialogOptions) {
        super(app, element, 'c-inline-dialog-box');
        this.dailogController = this.app.getController('dialog');
        this.content = null;
        this.dialogOptions = dialogOptions || {};
        this.dialogRefId = dialogOptions ? dialogOptions.dialogRefId || dialogOptions.name : null;
        this.dialogPromise;
        this.dialogResolve;
        this.dialogReject;
        this.popper = null;
        this.init();
    }

    init() {
        this.contentElement = document.createElement('div');
        this.setClass(this.getSelector('__content', ''), this.contentElement);
        this.appendChild(this.contentElement);

        this.setComponentClass();
        this.dialogPromise = new Promise((resolve, reject) => {
            this.dialogResolve = resolve;
            this.dialogReject = reject;
        });
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.contentElement);
    }

    onEmpty() {
        this.setClass(this.getBassClass());
        ReactDOM.unmountComponentAtNode(this.contentElement);
    }

    setComponentClass() {
        let componentClass = classNames(
            this.getBassClass(),
            this.getComponentClass(),
            this.dialogOptions.className
        );
        this.setClass(componentClass);
    }

    addContent(content, dialogOptions) {
        console.log('-----------2--------------');
        this.findChild(this.getSelector('__content')).then( element => {
            this.dialogOptions = Object.assign({}, this.dialogOptions, dialogOptions);
            this.setComponentClass();

            if(this.dialogOptions) {
                this.content = content;
                

                if(typeof content === 'function') {
                    content(element);
                }else{
                    this.html(content, element)
                }
                if(this.dialogOptions.popper) {
                    this.popper = new Popper(this.dialogOptions.popper.reference, element, this.dialogOptions.popper.options);
                }

                if(this.dialogOptions.onInit) this.dialogOptions.onInit(this);
            }
        });
    }
}