import { BaseApiService } from "./base-api.service";
import jsonp from "jsonp";
import MageService from "../services/mage.service";
import PatnerService from "./partner.store.service";
import axios from 'axios';

const customSearchUrl = "https://canada.paperhouse.com/api"
const todayDate =  new Date().toISOString().split("T")[0];


export default class UnbxdSearchService extends BaseApiService {
    
    constructor(app) {
        super(app);
    }

    appOnInit() {
        super.appOnInit();


        this.dialogController = this.app.getController('dialog');
        this.categoryService = this.app.categoryService;

        this.brandNames = '';
        this.queryParams = {}
        this.searchFilter = sessionStorage.getItem('searchFilterValue') || 'fsd';
        this.searchFilterCategory = sessionStorage.getItem('searchFilterCategory') || '';

        this.selectedCategories = [];
        this.categoryBreadcrumb = [];
        this.selectedFilters = [];
        
        this.facets = {};
        this.queryString = null;

        this.unbxdService = this.app.unbxdService; 
        this.mageService = this.app.mageService;       
        this.partnerService = this.app.partnerService;
        this.specialStockType = "Special Order";

        this.mageService.addEventListener(MageService.EVENT_READY, this.appInitHandler.bind(this));        
        
        this.partnerService.addEventListener(PatnerService.EVENT_STORE_CHANGE, this.getPartnerBrands.bind(this)); 
        
    }
    
    getPartnerBrands() {
        // console.log("getPartnerBrands", this.partnerService.storeInfo)
        console.log("getPartnerBrands", this.partnerService.storeInfo)
        if(this.partnerService.storeInfo.store_addresses){
            let active = this.partnerService.storeInfo.store_addresses.filter((sa, i) => {
                return sa.is_current_active_store;
            })[0];
            console.log("active location ", active);

       
        if(active){
            const reducer = (result, store) => result += `franchise_brand_code:\"${store.title}\" OR `

            const brands = active.store_location_brands;
        
            let reduced = brands.reduce(reducer, '');
            reduced = reduced.slice(0, -4);
    
            console.log("reduced location ", reduced);
            this.brandNames = encodeURIComponent(reduced);
        }     
    }
        
    }

    appInitHandler() {
        this.mageConfig = this.app.mageService.settings;
        this.storeCode = this.mageConfig && this.mageConfig.store ? this.mageConfig.store.storeCode :null || null;
    }

    handleError(error, reject) {
        if(error && error.message) {
            this.dialogController.notify({
                theme: 'danger',
                text: error.message
            }).then(result => {}).catch( reason => {});
        }
        if(reject) reject(error);
    }

    // checkIfAvailable() {
    //     return this.apiKey && this.siteKey;
    // }

    isSpecialPriceAvailable(product) {
        let isSpecialPriceAvailable = false;
        let today = new Date();
        if(product.special_price) {
          let specialPriceToDate = product.special_price_to_date ? new Date(product.special_price_to_date) : null;
          let specialPriceFromDate = product.special_price_from_date ? new Date(product.special_price_from_date) : null;

          isSpecialPriceAvailable = specialPriceToDate && specialPriceFromDate && today >= specialPriceFromDate && today <= specialPriceToDate;
        } 
        return isSpecialPriceAvailable;
    }

    addSpecialPriceAvailableToProduct(products) {
        if(products && products.length > 0) {
            products = products.map(product => {
                let isSpecialPriceAvailable = this.isSpecialPriceAvailable(product);
                return {...product, isSpecialPriceAvailable: isSpecialPriceAvailable}
            })
        }
        return products;
    }

    search(queryString, queryParams = {}){
        // if(!this.checkIfAvailable()) return;
        this.queryString = queryString;
        return new Promise((resolve, reject) => {
            let url = `/search_us?q=${queryString}`

            // generate url with queryParams
            const keys = Object.keys(queryParams);
            if(keys && keys.length > 0){
                keys.forEach(key => {
                    if(key != "filter") {
                        url += `&${key}=${queryParams[key]}`;
                    }                    
                });
            }

            // filters are duplicated so we cant set it to an object
            let filter;

            if(queryParams.filter && Object.keys(queryParams.filter).length > 0){
                url = this.createFilterString(queryParams.filter, url);
                console.log("hello filter in query", url)
            }            
            // url += filter;

            this.axiosCustomSearch().get(url).then(res=>{
                console.error("happy data", url )
                // let skus = res?.data?.results.join(",");
                const data = {
                    facets : res?.data?.facets,
                    response:{
                        numberOfProducts: res?.data?.metadata?.results_count,
                        products : res?.data?.facets?.products
                    },
                    searchMetaData: res?.data?.metadata
                }
                resolve(data);
                
            }).catch( error => {
                this.handleError(error, reject);
                return;
            })
        });
    }

    createFilterString(filters, url) {
        if(!filters) return;
        Object.keys(filters).forEach(key => {
         filters[key].values.map(item => {
            url += `&filter=${key}:${item}`
         })
        //   url += `&filter=${key}:${filters[key].values}`;
        });
        return url;
    }

    // **** Category filter handler ****
    categoryClickHandler(category) {
        if(!this.isCategoryContains(category)){
          this.removeSameLevelFromCategoryFilter(category);
          this.selectedCategories.push(category);
          this.categoryBreadcrumb.push(category);
        }else{
          this.selectedCategories = this.selectedCategories.filter( cat => {
            return cat.title != category.title && cat.level <= category.level
          })
          this.categoryBreadcrumb = this.categoryBreadcrumb.filter( cat => {
              return cat.title != category.title && cat.level <= category.level;
          })
        }

        let categoryFilter = this.categoryBreadcrumb.map(elem => {
            return elem.title;
        }).join('>');

        this.queryParams = {...this.queryParams, 'category-filter':  encodeURIComponent(categoryFilter)}
        return this.queryParams;
    }

    isCategoryContains(category) {
        return this.selectedCategories.filter( cat => {
          return cat.title == category.title && cat.level == category.level
        }).length > 0
    }
    
      removeSameLevelFromCategoryFilter(category) {
        if(!this.isCategoryContains(category)){
          this.selectedCategories = this.selectedCategories.filter(cat => {
            return cat.level != category.level
          });
          this.categoryBreadcrumb = this.categoryBreadcrumb.filter(cat => {
              return cat.level != category.level
          })
        }
    }

    // **** Facets handler ****

    isFacetsContains(facet) {
        return Object.keys(this.facets).filter(key => {
            return key == facet.facetName
        }).length > 0;
    }

    addFacets(filter) {
        if(!this.isFacetsContains(filter)) {
            this.facets = {...this.facets, [filter.facetName]: [filter.filters.title]}
        }else {
            this.facets[filter.facetName].push(filter.filters.title);
        }
    }

    initFacets() {
        this.facets = {};
        this.selectedFilters.forEach(filter => {
            this.addFacets(filter)
        });
    }

    // **** Other filters handler ****

    isFilterContains(filter) {
        return this.selectedFilters.filter( filt => {
          return filt.filters.id == filter.filters.id
        }).length > 0;
    }
    
    filterOnClick(filter) {
    
        if(!this.isFilterContains(filter)){
            this.selectedFilters.push(filter);
        }else {
            this.selectedFilters = this.selectedFilters.filter(filt => {
            return filt.filters.id != filter.filters.id; 
          });
        }

        this.initFacets();
        if(this.facets && this.facets != {}) {
            if(this.unbxdService.isReady){
                this.unbxdService.fireFacets(this.queryString, this.facets);
            }
        }
    
        let filters = this.categorizeFilters();
        
        this.queryParams = {...this.queryParams, filter: filters}
        return this.queryParams;
    
    }
    
    categorizeFilters() {
        let filters = {};
        this.selectedFilters.map(filter => {
          let key = filter.filterField;
          let value = filter.filters.title
          if(filters[key] && filters[key].values){
            filters[key].values.push(filter.filters.title);
          }else {
            filters[key] = {
              values: [filter.filters.title]
            }
          }
        })
        return filters;
    }

    // **** Autosuggest area ****
    getAutosuggest(query,searchType) { 
        // if(!this.checkIfAvailable()) return;
        return new Promise((resolve, reject) => {
            let url = `/${searchType}?q=${query}&n=10`
            if(searchType !== "autocomplete_us"){
                url += `&fallback=False`
            }
            this.axiosCustomSearch().get(url).then(res=>{
                if(searchType === "autocomplete_us"){
                    resolve(this.formatUnbxdAutosuggestResult(res?.data?.results,searchType,res?.data?.products))
                }else{
                    let products = res?.data?.facets?.products;
                    resolve(this.formatUnbxdAutosuggestResult(products))
                    // this.categoryService.getSearchProductData(skus).then((products) => {
                    //     // console.error("axiosCustomSearch---products",products)
                    //     resolve(this.formatUnbxdAutosuggestResult(products))
                        
                    // })
                }
                
            }).catch( error => {
                this.handleError(error, reject);
                return;
            })
        })        
    }

    formatUnbxdAutosuggestResult(result,searchType,product){
        if(!result) return;
        let autosuggestLeft = [];
        let autosuggestRight = [];
        let categories = [{typeId: "categories"}];                  // default one element for title
        let keywords = [{typeId: "key_suggestions"}];               // default one element for title
        let brands = [{typeId: "brands"}];                          // default one element for title
        let topSearchQueries = [{typeId: "top_search_queries"}];    // default one element for title

        // let response = result.response;
        // let products = this.addSpecialPriceAvailableToProduct(response.products);
        if(searchType === "autocomplete_us") autosuggestLeft = result
        else autosuggestRight = result
        // products.forEach( element => {
        //     if(element.doctype === "POPULAR_PRODUCTS"){
        //         autosuggestRight.push(element);
        //     }else{
        //         switch(element.doctype){
        //             case "grainger_category":
        //                 // Categories
        //                 element.typeString = "Categories";
        //                 element.typeId = "categories";
        //                 categories.push(element);
        //                 break;
        //             case "KEYWORD_SUGGESTION":
        //                 // Key Suggestions
        //                 element.typeString = "Key Suggestions";
        //                 element.typeId = "key_suggestions";
        //                 keywords.push(element);
        //                 break;
        //             case "brand":
        //                 // Brands
        //                 element.typeString = "Brands"
        //                 element.typeId = "brands";
        //                 brands.push(element);
        //                 break;
        //             case "TOP_SEARCH_QUERIES":
        //                 // Top search queries
        //                 element.typeString = "Top Search Queries";
        //                 element.typeId = "top_search_queries";
        //                 topSearchQueries.push(element);
        //                 break;
        //             default:
        //                 break;
        //         }                
        //     }
        // });
        // remove title if there are no autosuggest with that type
        // if(topSearchQueries.length == 1) topSearchQueries = [];
        // if(keywords.length == 1) keywords = [];
        // if(categories.length == 1) categories = [];
        // if(brands.length == 1) brands = [];

        // autosuggestLeft.push(topSearchQueries, keywords, categories, brands);
        return {
            "products" : product,
            "autosuggestLeft": autosuggestLeft,
            "autosuggestRight": autosuggestRight
        }
    }
    selectedStore (searchFilterValue) {
        sessionStorage.setItem('searchFilterValue',searchFilterValue);
        this.searchFilter = searchFilterValue;
    }
    getHistory() {

        let recentSearches = JSON.parse(localStorage.getItem("__searchhistories__"));
        return recentSearches;

    }

    setHistory(query) {

        let recentSearches = JSON.parse(localStorage.getItem("__searchhistories__"));

        if(!Array.isArray(recentSearches)){
            localStorage.removeItem("__searchhistories__");
            recentSearches = [];
        }

        let isExists = recentSearches && recentSearches.filter( search => {
            return search.query == query
        }).length > 0;

        if(isExists) return;

        let element = {
            query: query,
            created_at: Date.now()
        }

        if(!recentSearches) recentSearches = [];
        if(recentSearches.length > 4){
            recentSearches.pop();
        }        
        recentSearches.splice(0, 0, element);

        localStorage.setItem("__searchhistories__", JSON.stringify(recentSearches));
    }
    addStoreFilter(isAutosuggest = false) {
        console.log("getPartnerBrands", this.partnerService);      

        let filter = '';
        let filterItem = isAutosuggest ? 'popularProducts.' :  '';
        if(this.storeCode){
            switch(this.storeCode){
                case 'default':
                    // base
                    filter = `&${filterItem}filter=product_websites:"base"`;    
                    if(this.searchFilterCategory){
                        filter = `${filter}&${filterItem}filter=categoryPath1:${this.searchFilterCategory}`
                    }              
                break;
                case 'focus_website_store_view':
                    // focus
                    const storeFilter = this.searchFilter === "focus" ? this.brandNames : 'product_websites:focus';
                    filter = `&${filterItem}filter=${storeFilter}`;
                break;
            }
        }
        return filter;
    }

    // cut price
    getCurrentPrice(special_price , special_from_date , special_to_date , price ) {
    let todayDate =  new Date().toISOString().split("T")[0];
        const specialPrice = Number(special_price);
        const originalPrice = Number(price);

        if (specialPrice == null){
            return originalPrice;  // show price if special price null
        }
        else if (specialPrice && specialPrice >= originalPrice){
            return originalPrice;
        } // show original price if special price is greater
        else if(specialPrice && special_from_date ==null && special_to_date == null){
            return specialPrice;  // always show special price
        }

        else if (specialPrice && 
            todayDate >=  special_from_date && 
            todayDate<= special_to_date){
            return specialPrice; // special price show according to date
        }
            else if (specialPrice && 
            todayDate >=  special_from_date && 
            special_to_date == null){
            return specialPrice; // special_to_date is null 
            }
            else if (specialPrice && 
            special_from_date == null && 
            todayDate<= special_to_date){
            return specialPrice; // special_from_date is null
            }
        else{
            return originalPrice;
        }
    }

    // regular price
    getOldPrice(special_price , special_from_date , special_to_date , price){
        let todayDate =  new Date().toISOString().split("T")[0];
        const specialPrice = Number(special_price);
        const originalPrice = Number(price);
        if (specialPrice && specialPrice >= originalPrice){
        return null
        }
    
        else if(specialPrice && special_from_date == null && special_to_date == null){
        return  originalPrice;
        }
    
        else if (specialPrice && todayDate >= special_from_date && todayDate <= special_to_date){
            return originalPrice;
        }
        else if (specialPrice && 
        todayDate >=  special_from_date && 
        special_to_date == null){
            return originalPrice;
        }
        else if (specialPrice && 
        special_from_date == null && 
        todayDate<= special_to_date){
            return  originalPrice;
        }
    
    }

    getShipInDays (ships_in_days){
        if (ships_in_days >= 21 && ships_in_days <= 30){
           return "3-4 week(s)"
        }
        else if (ships_in_days > 30){
          return "6-7 week(s)"
        }
    
        else {
          return `${ships_in_days} day(s)${ships_in_days === "1" ? "*" : ""}`
        }
    
      }

    axiosCustomSearch () {
        return axios.create({
        baseURL: customSearchUrl,
        method:'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        responseType: 'json'
    })}


    isShowClearanceTag (liquidation , getDateClearance ) {
        if (liquidation === 1 && getDateClearance == null) {
          return true
        }
        else if (liquidation === 1 && todayDate <= getDateClearance) {
         return true
        }
        else {
         return false
        }
     }

    isShowArrivalTag (new_arrivals , getDateArrivals ) {
        if (new_arrivals === "Yes" && getDateArrivals == null) {
          return true
        }
        else if (new_arrivals === "Yes" && todayDate <= getDateArrivals) {
         return true
        }
        else {
         return false
        }
      }


// for new search

 // cut price
 getSearchCurrentPrice(special_price , special_from_date , special_to_date , price ) {
    let todayDate =  new Date().toISOString().split("T")[0];
        const specialPrice = Number(special_price);
        const originalPrice = Number(price);

        if (specialPrice == ""){
            return originalPrice;  // show price if special price null
        }
        else if (specialPrice && specialPrice >= originalPrice){
            return originalPrice;
        } // show original price if special price is greater
        else if(specialPrice && special_from_date =="" && special_to_date == ""){
            return specialPrice;  // always show special price
        }

        else if (specialPrice && 
            todayDate >=  special_from_date && 
            todayDate<= special_to_date){
            return specialPrice; // special price show according to date
        }
            else if (specialPrice && 
            todayDate >=  special_from_date && 
            special_to_date == ""){
            return specialPrice; // special_to_date is null 
            }
            else if (specialPrice && 
            special_from_date == "" && 
            todayDate<= special_to_date){
            return specialPrice; // special_from_date is null
            }
        else{
            return originalPrice;
        }
    }

    // regular price
getSearchOldPrice(special_price , special_from_date , special_to_date , price){
        let todayDate =  new Date().toISOString().split("T")[0];
        const specialPrice = Number(special_price);
        const originalPrice = Number(price);
        if (specialPrice && specialPrice >= originalPrice){
        return null
        }
    
        else if(specialPrice && special_from_date == "" && special_to_date == ""){
        return  originalPrice;
        }
    
        else if (specialPrice && todayDate >= special_from_date && todayDate <= special_to_date){
            return originalPrice;
        }
        else if (specialPrice && 
        todayDate >=  special_from_date && 
        special_to_date == ""){
            return originalPrice;
        }
        else if (specialPrice && 
        special_from_date == "" && 
        todayDate<= special_to_date){
            return  originalPrice;
        }
    
    }

isShowClearanceTagSearch (flags , getDateClearance ) {
        if (flags.includes("liquidation") && getDateClearance == null) {
          return true
        }
        else if (flags.includes("liquidation") && todayDate <= getDateClearance) {
         return true
        }
        else {
         return false
        }
     }

isShowArrivalTagSearch (flags , getDateArrivals ) {
        if (flags.includes("new_arrivals") && getDateArrivals == null) {
          return true
        }
        else if (flags.includes("new_arrivals") && todayDate <= getDateArrivals) {
         return true
        }
        else {
         return false
        }
      }

}




    


