import { BaseApiService } from "./base-api.service";
import MageService from "./mage.service";
import WishListService from "./wishlist.service";
import React from 'react';
import Cookies from "universal-cookie";

let cookies = new Cookies();

export default class CartService extends BaseApiService {
  constructor(app) {
    super(app);
    this.cart = null;
    this.currentShipping = {
      amount: 0,
    };
    this.multipleShippingVendors = [];
    this.selectedShippingMethod = null;
    this.coupons = null;
    this.updateCart = {};
    this.remainingAmountForFreeShipping = 0;
    this.isEmpty = true;
    this.lastCartAction = null;
    this.onLoadFirst = true;
    this.isFreeShippingAvailable = false;
    this.requiredAmountForFreeShipping = 175;
    this.showCartLoader = true;
    this.setRequiredAmountForFreeShipping(this.requiredAmountForFreeShipping);
  }

  appOnInit() {
    super.appOnInit();
    this.storeService = this.app.storeService;
    this.accountService = this.app.accountService;
    this.customerService = this.app.customerService;
    this.mageService = this.app.mageService;
    this.gaService = this.app.gaService;
    this.gaService4 = this.app.gaService4;
    this.juService = this.app.juService;
    this.unbxdService = this.app.unbxdService;
    this.loadingController = this.app.getController("loading");
    this.dialogController = this.app.getController("dialog");
    this.wishListService = this.app.wishlistService;
    document.addEventListener("add-to-cart", () => this.addtocartforpdp());
    this.wishListService.addEventListener(WishListService.EVENT_REFRESH, () =>
      this.getCart("refresh-cart-for-wishlist")
    );
    if (this.mageService.settings) {
      this.mageServiceReadyHandler();
    } else {
      this.mageService.addEventListener(
        MageService.EVENT_READY,
        this.mageServiceReadyHandler.bind(this)
      );
    }
  }
  addtocartforpdp() {
    this.addToCart(window.productItem.item, window.productItem.qty);
  }
  mageServiceReadyHandler() {
    if (this.mageService.settings && this.mageService.settings.cart) {
      this.setRequiredAmountForFreeShipping(
        this.mageService.settings.cart.free_shipping
      );
    }
  }
  removeStoreCredit(isApplepay){
    this.loadingController.showLoading("cart");
    this.delete(`/fsd/store-credit/remove`).then(res=>{
      !isApplepay && this.getCart()
      .then((result) => {
        this.dispatchEvent(CartService.EVENT_CHANGE);
        this.loadingController.hideLoading("cart");
      })
      .catch((reason) => {
        this.loadingController.hideLoading("cart");
        
      });
    })
  }
  getCartId() {
    return this.accountService && this.accountService.isGuestUser
      ? this.cartId
      : "mine";
  }
  setGrandTotal(totals) {
    return parseFloat(totals.grand_total);
  }
  createCart(cartId) {
    if (cartId) {
      this.cartId = cartId;
      return this.getCart();
    } else {
      return new Promise((resolve, reject) => {
        let endpoint =
          this.accountService && this.accountService.isGuestUser
            ? this.getEndpoint("carts")
            : this.getEndpoint("carts") + "/mine";
        this.post(endpoint, null, null, { returnRawResult: true })
          .then((result) => {
            this.cartId = _.trim(result.data, '"');
            return this.getCart()
              .then((cartResult) => resolve(cartResult))
              .catch((cartReason) => reject(cartReason));
          })
          .catch((reason) => {
            this.handleError(reason, reject);
          });
      });
    }
  }
  removeOOS() {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem("removeoos")) {
        resolve(true);
      } else {
        this.get(
          `fsd/${this.getEndpoint(
            "carts"
          )}/${this.getCartId()}/remove-oos-items`
        )
          .then((result) => {
            localStorage.removeItem("removeoos");
            resolve(result);
          })
          .catch((err) => {
            resolve(err);
          });
      }
    });
  }
  getCart(refreshcart) {
    if (!this.cartId) return Promise.reject({ message: "Undefined cart id" });
    return new Promise((resolve, reject) => {
      this.loadingController.showLoading("cart");
      this.removeOOS().then((res) => {
        this.get(`fsd/${this.getEndpoint("carts")}/${this.getCartId()}`)
          .then((result) => {
            this.showCartLoader = false;
            if (result && result[0]) {
              if(cookies.get("applepay_nonce")) this.dispatchEvent(CartService.SET_APPLEPAY_DATA)
              else if (result[0].paypal_express_checkout_nonce_token && this.onLoadFirst) {
                this.paypalData = {
                  braintreePayapalNonceToken: result[0].paypal_express_checkout_nonce_token
                }
                this.dispatchEvent(CartService.SET_PAYPAL_DATA);
                this.onLoadFirst = false;
              }
              console.trace("getCart1",result[0])
              this.setCart(result[0], refreshcart);
              resolve(this.cart);
            } else {
              console.trace("getCart2")
              this.setCart(null);
              reject({ message: "Undefined cart" });
            }
          })
          .catch((reason) => {
            this.showCartLoader = false;
            this.setCart(null);
            this.handleError(reason, reject);
          });
      });
    });
  }

  setCart(cartData, refreshcart) {
    console.trace("setCart1",cartData, refreshcart)
    this.cart = cartData;
    if (this.cart) {
      if (this.cart.id) {
        this.cartId = this.cart.id;
      } else {
        if (this.cartId) this.cart.id = this.cartId;
      }
      if(this.cart.items){
         // console.trace('cart items',this.cart.items);
          this.multipleShippingVendors = [];
          this.multipleVendorsTotalItems = {};
          this.selectedShippingMethod = this.cart.shipping_method;
          this.cart.items.map(item=>{
            const options = JSON.parse(item.options);
                options.map(option=>{
                    if(option.label === "Sold By"){
                      // set totalitems for different vendors
                      if(this.multipleShippingVendors.includes(option.value)){
                        this.multipleVendorsTotalItems[option.value] = this.multipleVendorsTotalItems[option.value] + 1;
                      }
                      
                      if(!this.multipleShippingVendors.includes(option.value)){
                        this.multipleShippingVendors.push(option.value);
                        this.multipleVendorsTotalItems[option.value] = 1;
                       }
                    }
                }) 
          })
      }
      
      console.trace('cart items',this.multipleShippingVendors,this.multipleVendorsTotalItems); 
      this.updateTotals(this.cart.totals, false);
      
      if (this.cart.totals && this.cart.totals.coupon_code) {
        this.coupons = [
          {
            title: this.cart.totals.coupon_code,
            value: this.cart.totals.discount_amount,
          },
        ];
      } else {
        this.coupons = null;
      }

      this.isEmpty = !(
        this.cart &&
        this.cart.totals &&
        parseInt(this.cart.totals["items_qty"]) > 0
      );
      // this.storeService.setCookie('cart', JSON.stringify({id: this.cartId}));
    } else {
      this.cartId = null;
      this.coupons = null;
      this.isEmpty = true;
      // this.storeService.removeCookie('cart');
    }

    this.remainingAmountForFreeShipping =
      this.cart && this.cart.totals
        ? this.requiredAmountForFreeShipping -
          parseFloat(this.cart.totals["foodserviceSubtotal"])
        : this.requiredAmountForFreeShipping;
    this.loadingController.hideLoading("cart");
    this.loadingController.hideLoading("remove-cart");
    if (refreshcart) {
      this.dispatchEvent(CartService.EVENT_REFRESH_CART);
    }
    console.trace("setCart2",cartData, refreshcart)
    this.dispatchEvent(CartService.EVENT_CHANGE);
    this.lastCartAction = null;
  }

  displayDeliveryDates(delivery_days_data) {
    if (!delivery_days_data) return null;
    delivery_days_data = JSON.parse(delivery_days_data);
        return <span className="mb-2">{delivery_days_data.delivery_by}</span>
  } 

  getShipInDays(ships_in_days){
    if (ships_in_days >= 21 && ships_in_days <= 30){
       return "3-4 weeks"
    }
    else if (ships_in_days > 30){
      return "6-7 weeks"
    }

    else {
      return `${ships_in_days} day(s)${ships_in_days === "1" ? "*" : ""}`
    }
  } 

  setCookies(value) {
    if (!cookies.get("selectedPostalCode") || value !== cookies.get("selectedPostalCode")) {
      cookies.set("selectedPostalCode", value, {
          path: "/",
      });
    }
  }
  setAddressTypeCookie(value) {
    if (!cookies.get("selectedAdressType") || value !== cookies.get("selectedAdressType")) {
      cookies.set("selectedAdressType", value, {
          path: "/",
      });
    }
    this.dispatchEvent(CartService.EVENT_ADDRESSTYPE_CHANGE);
  }

  clearApplePayCookie(){
    cookies.remove("applepay_nonce", { path: "/" });
  }

  updateTotals(totals, forceToDispatchEvent = true) {
    if (this.cart) {
      let foodserviceSubtotal = 0;
      let fsdThresholdItemExists = null;
      let vendors = {};
      this.cart.items.map(item=>{  
        const options = JSON.parse(item.options);
            options.map(option=>{
                if(option.label === "Sold By"){
                  totals[option.value] = totals[option.value] || {};
                  if(option.value==="FoodServiceDirect.com" && ( item.shipping_type === "Included in Treshold" || item.shipping_type === false || item.shipping_type === "Ships Free" ) ){
                    foodserviceSubtotal = foodserviceSubtotal + item.row_total;
                    if( item.shipping_type === "Included in Treshold" || item.shipping_type === false ){
                      fsdThresholdItemExists = true;
                    }
                  }
                  if(item.vendor_purchasing_limit && item.vendor_purchasing_limit.has_min_max){
                    const {vendor_min_purchase,vendor_max_purchase}= item.vendor_purchasing_limit;
                    vendors[option.value] = vendors[option.value] ? vendors[option.value] + parseFloat(item.row_total) : parseFloat(item.row_total);
                    totals[option.value].value = vendors[option.value];
                    totals[option.value].min = vendors[option.value] <= parseInt(vendor_max_purchase);
                    totals[option.value].max = vendors[option.value] >= parseInt(vendor_min_purchase);
                    totals[option.value].error = !(totals[option.value].max && totals[option.value].min);
                    totals[option.value].vendor_purchasing_limit = item.vendor_purchasing_limit;
                    if(totals[option.value].error){
                      // totals.redirectTrue = true;
                      totals.errorSeller =  totals.errorSeller || [];
                      if(totals.errorSeller.includes(option.value)) return 
                      totals.errorSeller.push(option.value);
                    }else{ 
                      // this block is for removing the errorSeller if the seller error is gone 
                      totals.errorSeller = totals.errorSeller || []; 
                      if(totals.errorSeller.includes(option.value)){ 
                        let errSellerIndex = totals.errorSeller.indexOf(option.value); 
                        totals.errorSeller.splice(errSellerIndex, 1); 
                      } 
                    } 
                  }
                  if(item.shipping_type === "Never ships Free"){
                    totals[option.value].neverShipsFree = true;
                  }
                }
              })
      })
      if(totals.errorSeller && totals.errorSeller.length) totals.redirectTrue = true;
      // setting cold pack fee 
      totals.cold_pack_fee = totals?.cold_pack_fee ? totals.cold_pack_fee : totals?.total_segments?.find( segment => segment.code === "cold_pack_fee")?.value;
      
      this.cart.totals = totals;
      if(foodserviceSubtotal && this.cart.totals){
        this.cart.totals.foodserviceSubtotal = foodserviceSubtotal;
      }
      if(fsdThresholdItemExists && this.cart.totals)  this.cart.totals.fsdThresholdItemExists = fsdThresholdItemExists
      if (this.cart.totals) {
        if(this.cart.items)
        this.isFree = this.cart.totals.grand_total <= 0;
        if (this.cart.totals.total_segments) {
          this.cart.totals.total_segments.forEach((item) => {
            if (!this.cart.totals.hasOwnProperty(item.code))
              this.cart.totals[item.code] = item.value;
          });
        }
        if (parseFloat(this.cart.totals.customerbalance) > 0)
          this.cart.totals.customerbalance =
            parseFloat(this.cart.totals.customerbalance) * -1;
        this.remainingAmountForFreeShipping = this.cart.totals
          ? this.requiredAmountForFreeShipping -
            parseFloat(this.cart.totals["foodserviceSubtotal"])
          : this.requiredAmountForFreeShipping;
      }
      this.loadingController.hideLoading("cart");
      if (forceToDispatchEvent) this.dispatchEvent(CartService.EVENT_CHANGE);
    }
  }

  updateItemQty(item, updateCart) {
    return new Promise((resolve, reject) => {
      this.loadingController.showLoading("cart");
      let payload = {
        cartItem: {
          qty: item.qty,
          quote_id: this.cart && this.cart.id ? this.cart.id : this.cartId,
        },
      };

      this.put(
        `${this.getEndpoint("carts")}/${this.getCartId()}/items/${
          item.item_id
        }`,
        payload,
        null
      )
        .then((result) => {
          this.getCart()
            .then((result) => {
              if (updateCart) {
                this.updateCart = { ...this.updateCart, [item.sku]: item.qty };
                this.dispatchEvent(CartService.EVENT_CART);
              }
              resolve(result);
            })
            .catch((reason) => {
              reject(reason);
            });
        })
        .catch((reason) => {
          this.handleError(reason, reject);
        });
    });
  }

  clearItems() {
    return new Promise((resolve, reject) => {
      this.loadingController.showLoading("cart");

      this.delete(`fsd/${this.getEndpoint("carts")}/${this.getCartId()}/items`)
        .then((response) => {
          this.getCart()
            .then((result) => {
              resolve(result);
            })
            .catch((reason) => {
              reject(reason);
            });
        })
        .catch((reason) => {
          this.handleError(reason, reject);
        });
    });
  }

  async addToCart(product, qty, options, multiple, allsku) {
    if (!this.cartId) {
      await this.createCart();
      return this.addToCart(product, qty, options);
    } else {
      return new Promise((resolve, reject) => {
        let data = {
          cartItem: {
            sku: product.sku,
            qty: qty || 1,
            vendor_id: product.vendor_id,
            quote_id: this.cart && this.cart.id ? this.cart.id : this.cartId,
          },
        };
        if (product.product_type === "configurable") {
          data.cartItem.product_type = "configurable";
          data.cartItem.product_option = product.product_option;
        }
        this.loadingController.showLoading("cart");
        let url = !this.accountService.isGuestUser ? "/cartitems/mine/items" : "/fsd/guest-carts/"+this.getCartId()
        this.post(
          url,
          data,
          null
        )
          .then((addedItem) => {
            if (this.unbxdService.isReady) {
              this.unbxdService.fireAddToCart(product, qty);
            }
            // this.gaService.addToCart({
            //   id: product.sku,
            //   name: product.name,
            //   category: product.category,
            //   brand: product.brand,
            //   variant: product.variant,
            //   position: product.position,
            //   price: product.price,
            //   quantity: qty,
            //   label:product.label
            // });
            this.gaService4.addToCart([{
              item_id: product.sku,
              item_name: product.name ?? product.product_name ?? product.title ,
              price: product.price,
              quantity: qty
            }]);

            this.juService.sendCartItemAdd({
              id: product.id,
              sku: product.sku,
              name: product.name,
              quantity: qty,
              price: product.price,
            });
            this.lastCartAction = "add";
            if (!multiple) {
              this.getCart()
                .then((result) => {
                  if(addedItem){
                    this.lastSku = addedItem.sku;
                  }

                  resolve(result);
                })
                .catch((reason) => {
                  reject(reason);
                });
            } else {
              resolve(addedItem);
            }
          })
          .catch((reason) => {
            this.handleError(reason, reject);
          });
      });
    }
  }
  notifyApi(sku) {
    // return new Promise((resolve,reject)=>{
    this.post(`/product/stocknotify`, {
      data: { sku, email: this.accountService.user.email },
    })
      .then((res) => {
        this.dialogController
          .notify({
            theme: "success",
            text:
              "Thank you. We will email you as soon as the item is available to order",
          })
          .then((result) => {})
          .catch((reason) => {});
      })
      .catch((err) => {
        reject(err);
      });
    //})
  }
  removeFromCart(itemId, hidepopup) {
    return new Promise((resolve, reject) => {
      // TODO: cart ekranında çağrıldığında checkout loading'i çıkmalı
      this.loadingController.showLoading("cart");
      this.loadingController.showLoading("remove-cart");
      this.delete(
        "fsd/" +
          this.getEndpoint("carts") +
          "/" +
          this.getCartId() +
          "/items/" +
          itemId
      )
        .then((result) => {
          if (!hidepopup) {
            this.lastCartAction = "remove";
          }

          this.getCart()
            .then((cartResult) => resolve(cartResult))
            .catch((cartReason) => reject(cartReason));
        })
        .catch((reason) => {
          this.handleError(reason, reject);
        });
    });
  }

  setCurrentShipping(shipping) {
    this.currentShipping = shipping;
    this.dispatchEvent(CartService.EVENT_SHIPPING_CHANGE);
  }

  setRequiredAmountForFreeShipping(value) {
    this.requiredAmountForFreeShipping = value;
    this.isFreeShippingAvailable =
      !isNaN(this.requiredAmountForFreeShipping) &&
      this.requiredAmountForFreeShipping > 0;
    this.dispatchEvent(
      CartService.EVENT_REQUIRED_AMOUNT_FOR_FREE_SHIPPING_CHANGE
    );
  }

  async addCoupon(couponCode) {
    if (!this.cartId) {
      await this.createCart();
    }
    return new Promise((resolve, reject) => {
      let data = this.accountService.isGuestUser
        ? { cartId: this.cartId, couponCode: couponCode }
        : { couponCode: couponCode };

      this.loadingController.showLoading("cart");
      this.put(
        this.getEndpoint("carts") +
          "/" +
          this.getCartId() +
          "/coupons/" +
          couponCode,
        data,
        null
      )
        .then((result) => {
          this.getCart()
            .then((result) => {
              this.dispatchEvent(CartService.EVENT_COUPON);
              resolve(result);
            })
            .catch((reason) => {
              this.loadingController.hideLoading("cart");
              reject(reason);
            });
        })
        .catch((reason) => {
          this.handleError(reason, reject);
          this.loadingController.hideLoading("cart");
          this.dispatchEvent({
            type: CartService.EVENT_COUPON_ERROR,
            target: reason,
          });
          reject(reason);
        });
    });
  }

  removeCoupon(couponCode) {
    if (!this.cart) return Promise.reject(new Error("Cart not found"));
    return new Promise((resolve, reject) => {
      this.loadingController.showLoading("cart");
      this.delete(
        this.getEndpoint("carts") + "/" + this.getCartId() + "/coupons",
        null
      )
        .then((result) => {
          this.getCart()
            .then((result) => {
              this.dispatchEvent(CartService.EVENT_COUPON);
              resolve(result);
            })
            .catch((reason) => {
              this.loadingController.hideLoading("cart");
              reject(reason);
            });
        })
        .catch((reason) => {
          this.handleError(reason, reject);
        });
    });
  }

  getCoupons() {}

  getDiscountedSubtotal(totals){
    const {shipping_amount,shipping_discount_amount,subtotal} = totals;
    let discountPercentage = (shipping_discount_amount / shipping_amount) * 100;
    let discountedSubTotal = subtotal - (discountPercentage / 100) * subtotal;
    return discountedSubTotal;
  }

  setBalance(amount) {
    return new Promise((resolve, reject) => {
      this.post(
        `${this.getEndpoint("carts")}/${this.getCartId()}/balance/apply`
      )
        .then((result) => {
          this.getCart()
            .then((result) => resolve(result))
            .catch((error) => reject(error));
        })
        .catch((error) => this.handleError(error, reject));
    });
  }

  handleError(error, reject) {
    this.loadingController.hideLoading("remove-cart");
    this.loadingController.hideLoading("cart");
    if (error && error.message) {
      this.dialogController
        .notify({
          theme: "danger",
          text: error.message,
        })
        .then((result) => {})
        .catch((reason) => {});
    }
    if (error && error.status) {
      switch (error.status) {
        case 401:
          this.accountService.logout();
          break;
      }
    }
    if (reject) reject(error);
  }
}

CartService.EVENT_CHANGE = "change";
CartService.EVENT_SHIPPING_CHANGE = "shipping-change";
CartService.EVENT_REQUIRED_AMOUNT_FOR_FREE_SHIPPING_CHANGE =
  "required-amount-for-free-shipping-change";
CartService.EVENT_COUPON_ERROR = "error-occured";
CartService.EVENT_COUPON = "change";
CartService.EVENT_REFRESH = "refresh";
CartService.EVENT_CART = "cart-refresh";
CartService.EVENT_REFRESH_CART = "refresh-after-wishlist";
CartService.SET_PAYPAL_DATA = "set-paypal-data";
CartService.SET_APPLEPAY_DATA = "set-applepay-data";
CartService.EVENT_ADDRESSTYPE_CHANGE = "set-addresstype-change";
