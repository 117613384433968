import { DomComponent } from "../core/dom.component";
import classNames from 'classnames';
import Popper from "popper.js";
import ReactDOM from "react-dom";

export default class DialogBoxComponent extends DomComponent {

    constructor(app, element) {
        super(app, element, 'c-dialog-box');
        this.dailogController = this.app.getController('dialog');
        this.viewportController = this.app.getController('viewport');
        this.content = null;
        this.dialogOptions = null;
        this.dialogRefId = null;
        this.dialogPromise;
        this.dialogResolve;
        this.dialogReject;
        this.popper = null;
        this._cleckReady = false;
        this.init();
    }

    init() {
        this.backdropElement = document.createElement('div');
        this.setClass(this.getSelector('__backdrop', ''), this.backdropElement);
        this.appendChild(this.backdropElement);

        this.contentElement = document.createElement('div');
        this.setClass(this.getSelector('__content', ''), this.contentElement);
        this.appendChild(this.contentElement);

        this.backdropClickHandlerProxy = this.backdropClickHandler.bind(this);

        this.setClassName();
        this.dialogPromise = new Promise((resolve, reject) => {
            this.dialogResolve = resolve;
            this.dialogReject = reject;
        });
    }

    onDestroy() {
        // document.removeEventListener('click', this.backdropClickHandlerProxy);
        this.viewportController.removeGestureEvent('tap', this.backdropClickHandlerProxy);
        ReactDOM.unmountComponentAtNode(this.contentElement);
    }

    setClassName() {
        let componentClass = classNames(
            this.getComponentClass()
        );
        this.setClass(componentClass);
    }

    addContent(content, dialogOptions) {
        this.findChild(this.getSelector('__content')).then( element => {
            if(dialogOptions) {
                this.content = content;
                this.dialogOptions = dialogOptions;
                this.dialogRefId = this.dialogOptions.dialogRefId;
                this.domElement.setAttribute('dialog-ref-id', this.dialogRefId);

                if(this.dialogOptions.animation) this.addClass(this.getSelector('--'+this.dialogOptions.animation, ''));
                if(this.dialogOptions.dialogClass) this.addClass(this.dialogOptions.dialogClass, element);

                element.style.width = dialogOptions.dialogWidth || 'auto',
                element.style.height = dialogOptions.dialogHeight || 'auto',
                element.style.maxWidth = dialogOptions.dialogMaxWidth || 'auto',
                element.style.minHeight = dialogOptions.dialogMinHeight || 'none'
                if(dialogOptions.dialogTop || dialogOptions.dialogBottom) {
                    element.style.position = 'absolute';
                    element.style.top = dialogOptions.dialogTop || 'auto';
                    element.style.bottom = dialogOptions.dialogBottom || 'auto';
                }
                element.style.left = dialogOptions.dialogLeft || 'auto';
                element.style.right = dialogOptions.dialogRight || 'auto';

                if(typeof content === 'function') {
                    content(element);
                }else{
                    this.html(content, element)
                }
                if(dialogOptions.popper) {
                    this.popper = new Popper(dialogOptions.popper.reference, element, dialogOptions.popper.options);
                }

                if(dialogOptions.onInit) dialogOptions.onInit(this, this.html(null, element));
                setTimeout(()=> {
                    this._clickReady = true;
                }, 50);
            }
        });
        this.findChild(this.getSelector('__backdrop')).then( element => {
            if(dialogOptions) {
                
                if(this.dialogOptions.backdropClass) this.addClass(this.dialogOptions.backdropClass, element);

                element.style.display = dialogOptions.backdrop ? 'block' : 'none';
                if(dialogOptions.backdropAction != null) {
                    // document.addEventListener('click', this.backdropClickHandlerProxy);
                    this.viewportController.addGestureEvent('tap', this.backdropClickHandlerProxy);
                }
            }
        });
    }

    backdropClickHandler(event) {
        if (!this.contentElement.contains(event.target)) {
            if(this._clickReady && this.dialogOptions && this.dialogOptions.backdropAction) {
                switch(this.dialogOptions.backdropAction) {
                    case 'dismiss':
                        this.dailogController.close(this.dialogRefId);
                    break;
                }
            }
        }
    }
}