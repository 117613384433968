import { BaseApiService } from "./base-api.service";
import GraphqlService from "./base-graphql.service";
import braintree from "braintree-web";

class BraintreeService extends BaseApiService {
  constructor(app) {
    super(app);
    this.clientToken = null;
    this.getClientToken = this.getClientToken.bind(this);
    this.getDeviceData = this.getDeviceData.bind(this);
    this.getNonce = this.getNonce.bind(this);
    this.applepayClientToken = null;
    this.deviceData = null;
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.cartService = this.app.cartService;
    this.environment = this.app.environment.name !== 'production' ? 'sandbox' : 'production';
  }

  setEnvironment(env) {
    if(env) {
        if(env.environment) this.environment = env.environment;
    }
  }

  getNonce(cardPayload) {
    const { cardCode, cardNumber, expiry, postalCode } = cardPayload;
    return new Promise((resolve, reject) => {
      braintree.client.create(
        {
          authorization: this.clientToken,
        },
        function (err, client) {
          client.request(
            {
              endpoint: "payment_methods/credit_cards",
              method: "post",
              data: {
                creditCard: {
                  number: cardNumber,
                  expirationDate: expiry,
                  cvv: cardCode,
                  billingAddress: {
                    postalCode,
                  },
                },
              },
            },
            function (err, response) {
              if(err) reject(err)
              resolve(response);
              // Send response.creditCards[0].nonce to your server
            }
          );
        }
      );
    });
  }

  generatePaypalPaymentToken() {
    // return new Promise((resolve, reject) => {
      let payload = {
        query: `mutation {
          createBraintreePaypalPaymentToken(
            input: { 
              cartId: "${this.cartService.cartId}"
            }
          ) {
            paymentToken
            redirectUrl
            billingAgreementToken
          }
        }`,
      };
      const http = new GraphqlService(this.app);
      return http
        .graphql(payload)
        .then((res) => {
          console.error("generatePaypalPaymentToken service==", res);
          if(res.createBraintreePaypalPaymentToken){
            const {redirectUrl} = res.createBraintreePaypalPaymentToken
            const query = redirectUrl.split('?');
            const params = new URLSearchParams(query[1]);
            // const params = (new URL(response.data.url)).searchParams;
            const token = params.get('token'); 
            res.createBraintreePaypalPaymentToken.token = token
            return res.createBraintreePaypalPaymentToken;   
          }
          // resolve(res);
        })
        // .catch((err) => reject(err));
    // });
  }

  generatePaypalPaymentNonceToken(noncePayload) {
    console.error("generatePaypalPaymentNonceToken noncePayload==", noncePayload);
    // return new Promise((resolve, reject) => {
      let payload = {
        query: `mutation {
          createBraintreePaypalNonceTokenByMethodToken(
            input: { 
              paymentToken: "${noncePayload.paymentToken}"
              correlationId: "${noncePayload.correlationId}"
              payerId: "${noncePayload.payerId}"
              cartId: "${noncePayload.cartId}" 
              isExpressCheckout: ${noncePayload.isExpressCheckout}
            }
          ) {
            paymentMethodNonceToken
          }
        }`,
      };
      const http = new GraphqlService(this.app);
      return http
        .graphql(payload)
        .then((res) => {
          console.error("generatePaypalPaymentNonceToken==", res);
          return res
          // resolve(res);
        })
        // .catch((err) => reject(err));
    // });
  }

  getClientToken() { // braintree credit cart client token
    return new Promise((resolve, reject) => {
      let payload = {
        query: `mutation {
                    createBraintreeClientToken
                }`,
      };
      const http = new GraphqlService(this.app);
      http

        .graphql(payload)
        .then((res) => {
          if (res.createBraintreeClientToken) {
            this.clientToken = res.createBraintreeClientToken;
            this.dispatchEvent(BraintreeService.EVENT_DATA);
            resolve(res.createBraintreeClientToken);
          }
        })
        .catch((err) => reject(err));
    });
  }

  getDeviceData() {
    return new Promise((resolve, reject) => {
      console.log("dataCollectorInstance1")
      braintree.client.create({
        authorization: this.clientToken
      }, function (err, clientInstance) {
        braintree.dataCollector.create({
          client: clientInstance
        }, function (err, dataCollectorInstance) {
          if (err) {
            // Handle error in creation of data collector
            reject(err)
            return;
          }
          console.log("dataCollectorInstance",dataCollectorInstance)
          resolve(dataCollectorInstance.deviceData)
        });
      });
    });
  }

  createBraintreeCardNonceByHash(publicHash) {
    return new Promise((resolve, reject) => {
      let payload = {
        query: `mutation {
          createBraintreeCreditCardNonceTokenByHash(
            input: {
              publicHash: "${publicHash}"
            }
          ) {
            paymentMethodNoncetoken
          }
        }`,
      };
      const http = new GraphqlService(this.app);
      http
        .graphql(payload)
        .then((res) => {
          console.error("createBraintreeCreditCardNonceTokenByHash==", res);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  getApplepayClientToken() { // braintree applepay client token
    return new Promise((resolve, reject) => {
      let payload = {
        query: `query {
          createBraintreeApplePayClientToken {
            applePayClientToken
          }
        }`,
      };
      const http = new GraphqlService(this.app);
      http
        .graphql(payload)
        .then((response) => {
          console.error("applepayClientToken==", response);
          this.applepayClientToken = response.createBraintreeApplePayClientToken.applePayClientToken;
          this.dispatchEvent(BraintreeService.EVENT_SETUP_APPLEPAY);
          resolve(response.createBraintreeApplePayClientToken.applePayClientToken);
        })
        .catch((err) => reject(err));
    });
  }
  
  cancel() {
    this.loadingController.hideLoading('checkout');
  }

}
export default BraintreeService;
BraintreeService.EVENT_DATA = "data-change";
BraintreeService.EVENT_SETUP_APPLEPAY = "data-setup-applepay";
