import { BaseController } from "../core/base.controller";
import HeaderComponent from "../components/header.component";
import { LoadingController } from "./loading.controller";
import classNames from "classnames";
import FooterComponent from "../components/footer.component";
import Popper from "popper.js";

export class LayoutController extends BaseController {
    constructor(app) {
        super(app);

        this.scollIsLocked = false;
        this.header = null;
        this.footer = null;
        this.scrollTopButton = null;
        this.drawer = {
            element: null,
            left: { element: null, isOpen: false },
            right: { element: null, isOpen: false }
        }
        this.modal = null;
        this.popper = null;
        this.gestureManager = null;
        this.outsideClickProxy = this.outsideClickHandler.bind(this);

        this._lastScrollTop = 0;
        this._scrollDirection = 0;
        this._clickCount = 0;
    }

    appOnInit() {
        if (super.appOnInit) super.appOnInit();
        this.mainContainer = this.app.domElement;
        this.loadingController = this.app.getController('loading');
        this.viewportController = this.app.getController('viewport');

        this.app.findChild('.c-header').then(element => {
            this.header = new HeaderComponent(this.app, element);
        }).catch(reason => this.handleSelectorError(reason));

        this.app.findChild('.c-footer').then(element => {
            this.footer = new FooterComponent(this.app, element);
        });

        this.app.findChild('.l-main__scroll-top').then(element => {
            this.scrollTopButton = element;
            this.scrollTopButton.onclick = this.scrollTop.bind(this);
        });

        this.app.findChild('.l-main__drawer').then(drawerElement => {
            if (!this.drawer) this.drawer = {};
            this.drawer.element = drawerElement;
            this.app.findChild('.l-main__drawer-left').then(leftDrawerElement => {
                this.drawer.left = { element: leftDrawerElement, isOpen: false };
            });
            this.app.findChild('.l-main__drawer-right').then(rightDrawerElement => {
                this.drawer.right = { element: rightDrawerElement, isOpen: false };
            });

            this.gestureManager = new Hammer(this.drawer.element);
            this.gestureManager.on('swipe', this.drawerSwipeHandler.bind(this));
        });

        this.loadingController.addEventListener(LoadingController.EVENT_CHANGE, this.loadingChangeHandler.bind(this));
    }

    drawerSwipeHandler(e) {
        if (!e || !e.direction) return;
        switch (e.direction) {
            case Hammer.DIRECTION_LEFT:
                if (this.drawer && this.drawer.left && this.drawer.left.isOpen) this.closeDrawer('left');
                break;
            case Hammer.DIRECTION_RIGHT:
                if (this.drawer && this.drawer.right && this.drawer.right.isOpen) this.closeDrawer('right');
                break;
        }
        this.dispatchEvent({ type: LayoutController.EVENT_SWIPE, target: e });
    }

    getScrollTop() {
        return window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);
    }

    getScrollHeight() {
        return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight);
    }

    getContentTop() {
        // return this.header.contentElement.offsetTop + this.header.contentElement.offsetHeight;
        return 0;
    }

    getHeight() {
        return window.innerHeight;
    }

    _setScrollDirection(direction) {
        if (this._scrollDirection == direction) return;
        this._scrollDirection = direction;
        this.dispatchEvent(LayoutController.EVENT_SCROLL_DIRECTION_CHANGE);
    }
    getScrollDirection() { return this._scrollDirection }

    scrollHandler(event) {
        if (this.scollIsLocked) {
            event.preventDefault();
            window.scrollTo(0, this._lastScrollTop);
            return;
        }

        if (this._lastScrollTop < this.getScrollTop()) {
            this._setScrollDirection(1);
        } else {
            this._setScrollDirection(-1);
        }
        this._lastScrollTop = this.getScrollTop();
        if (this.scrollTopButton) this.app.setClass(classNames('l-main__scroll-top', { 'l-main__scroll-top--active': this._lastScrollTop > this.getHeight() }), this.scrollTopButton);
        if (this.header) this.header.scrollHandler();
        this.dispatchEvent(LayoutController.EVENT_SCROLL);
    }

    showOverlay(name = 'content', lockScroll = true, hideScroll = true) {
        switch (name) {
            default:
            case 'content':
                this.app.findChild('.l-main__content').then(element => this.app.addClass('l-main__content--overlay', element));
                break;
            case 'header':
                this.header.showOverlay();
                break;
        }
        if (lockScroll) this.lockScroll(hideScroll);
    }

    hideOverlay(name = 'content', unlockScroll = true) {
        switch (name) {
            case 'content':
                this.app.findChild('.l-main__content').then(element => this.app.removeClass('l-main__content--overlay', element));
                break;
            case 'header':
                this.header.hideOverlay();
                break;
        }
        if (unlockScroll) this.unlockScroll();
    }

    lockScroll(hideScroll = false) {
        if (!this.mainContainer) return;
        this.scollIsLocked = true;
        this.app.addClass('l-main--no-scroll', this.mainContainer);
        this.app.findChild('html', document).then( element => {
          element.style.cssText = `
            scroll-behavior: auto;
          `;

          this.app.findChild('.l-main__container').then( element => {
            element.scrollTo(0, this._lastScrollTop);
          });
        })
    }

    unlockScroll() {
        if (!this.mainContainer) return;
        this.scollIsLocked = false;
        this.app.removeClass('l-main--no-scroll', this.mainContainer);
        window.scrollTo(0, this._lastScrollTop);
        this.app.findChild('html', document).then( element => {
          element.style.cssText = `
            scroll-behavior: smooth;
          `;

          this.app.findChild('.l-main__container').then( element => {
            element.scrollTo(0, 0);
          });
        })
    }

    scrollTop() {
        if (!this.mainContainer) return;
        window.scrollTo(0, 0);
        this.mainContainer.scrollTop = 0;
    }

    scrollTo(x, y) {
        window.scrollTo(x, y);
    }

    showLoading() {
        this.app.addClass('l-main--loading', this.mainContainer);
    }

    hideLoading() {
        this.app.removeClass('l-main--loading', this.mainContainer)
    }

    loadingChangeHandler() {
        this.loadingController.getLoadingStatus(LoadingController.LOADING_MAIN) ? this.showLoading() : this.hideLoading();
    }

    openDrawer(type, content) {
        if (!this.drawer || !this.drawer[type]) return;
        this._clickCount = 0;

        let drawer = this.drawer[type];
        if (!drawer.content && content) this.setDrawer(type, content);
        this.drawer.left.isOpen = type == "left";
        this.drawer.right.isOpen = type == "right";

        this.app.setClass(classNames(
            'l-main__drawer',
            {
                'l-main__drawer--active': this.drawer.left.isOpen || this.drawer.right.isOpen,
                'l-main__drawer--left': this.drawer.left.isOpen,
                'l-main__drawer--right': this.drawer.right.isOpen
            }
        ), this.drawer.element);

        this.lockScroll();
        setTimeout(() => {
            // document.addEventListener('click', this.outsideClickProxy);
            this.viewportController.addGestureEvent('tap', this.outsideClickProxy);
            this._clickCount++;
        }, 500);
        this.dispatchEvent(LayoutController.EVENT_DRAWER_OPEN);
        this.dispatchEvent(LayoutController.EVENT_DRAWER_CHANGE);
        this.app.overlayOpened(true);
    }

    closeDrawer(type, forceToEmpty) {
        if ((this.drawer.left.isOpen === false && this.drawer.right.isOpen === false)) return;
        if (forceToEmpty) this.setDrawer(type, null);
        this.drawer.left.isOpen = false;
        this.drawer.right.isOpen = false;
        this.app.setClass('l-main__drawer', this.drawer.element);
        this.unlockScroll();
        // document.removeEventListener('click', this.outsideClickProxy);
        this.viewportController.removeGestureEvent('tap', this.outsideClickProxy);
        this.dispatchEvent(LayoutController.EVENT_DRAWER_CLOSE);
        this.dispatchEvent(LayoutController.EVENT_DRAWER_CHANGE);
        this.app.overlayOpened(false);
    }

    setDrawer(type, content) {
        if (!this.drawer || !this.drawer[type]) return;
        let drawer = this.drawer[type];
        if (drawer.content) {
            //todo destroy content
            ReactDOM.unmountComponentAtNode(drawer.element);
            this.app.empty('', drawer.element);
            drawer.content = null;
        }
        if (typeof content === 'function') {
            content(drawer.element);
        } else {
            this.app.html(content, drawer.element);
        }
    }

    enableHeaderSearchBar(enabled) {
        this.header.enableSearchBar(enabled);
    }

    outsideClickHandler(e) {
        if (e && e.target == this.drawer.element) {
            if (this._clickCount > 0) {
                if (this.drawer.left && this.drawer.left.isOpen) this.closeDrawer('left');
                if (this.drawer.right && this.drawer.right.isOpen) this.closeDrawer('right');
            }
        }
    }
}

LayoutController.EVENT_SCROLL_DIRECTION_CHANGE = "scroll-direction-change";
LayoutController.EVENT_SCROLL = "scroll";
LayoutController.EVENT_SWIPE = "swipe";
LayoutController.EVENT_DRAWER_OPEN = "drawer-open";
LayoutController.EVENT_DRAWER_CLOSE = "drawer-close";
LayoutController.EVENT_DRAWER_CHANGE = "drawer-change";