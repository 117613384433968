import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';
import ManufacturerBrandsService from '../services/manufacturer-brands.service';

export default class ManufacturerBrandsExplorer extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);

        this.i18nScopeKey = 'MANUFACTURER_BRANDS_EXPLORER';

        this.renderStore = {
            brands: null,
            manufacturers: null,
            hasMoreBrandElement: null,

        };

        this.state = {
            isLoading: true,
            response: null,
            brands: null,
            manufacturers: null,
            hasMoreBrands: false,
            brandsMeta: null,
            showAllButton: null
        }
    }

    appOnInit() {
        if (super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
        this.manufacturerBrandsService = this.app.manufacturerBrandsService;
    }

    componentDidMount() {
        this.manufacturerBrandsService.addEventListener(ManufacturerBrandsService.EVENT_MANUFACTURER_BRANDS_CHANGE, this.manufacturerBrandsChangeHandler.bind(this));
        this.manufacturerBrandsChangeHandler();
    }

    manufacturerBrandsChangeHandler() {

        if (this.manufacturerBrandsService.manufacturerBrands && this.manufacturerBrandsService.manufacturerBrands != {}) {

            let manufacturerBrands = this.manufacturerBrandsService.manufacturerBrands;

            this.setState({
                isLoading: false,
                response: manufacturerBrands,
                brands: manufacturerBrands.brands.items ? manufacturerBrands.brands.items : [],
                manufacturers: manufacturerBrands.manufacturer.items ? manufacturerBrands.manufacturer.items : [],
                hasMoreBrands: manufacturerBrands.brands.meta.filter(meta => { return Object.keys(meta).indexOf(this.manufacturerBrandsService.showMoreKey) > -1 }).length > 0,
                brandsMeta: manufacturerBrands.brands.meta
            })
        }

    }

    render() {

        if (this.state) {

            if (this.state.brands) {
                this.renderStore.brands = this.state.brands.map(brand => {
                    return <li key={brand.title} className="c-brand-explorer__brand">
                        <a href={brand.url} className="c-brand-explorer__brand-link">
                            {brand.logo &&
                                <div className="c-brand-explorer__brand-image">
                                    <img src={brand.logo || ''} alt={brand.title} />
                                </div>
                            }
                            {/* <div className="c-brand-explorer__brand-content">
                                <span className="c-brand-explorer__brand-title">{brand.title}</span>
                            </div> */}
                        </a>
                    </li>
                });
            }

            if (this.state.manufacturers) {
                this.renderStore.manufacturers = this.state.manufacturers.map(manufacturer => {
                    return <li key={manufacturer.title} className="c-brand-explorer__manufacturer">
                        <a href={manufacturer.url} className="c-brand-explorer__manufacturer-link">
                            <div className="c-brand-explorer__manufacturer-image">
                                <img src={manufacturer.logo || ''} alt={manufacturer.title} />
                            </div>
                            <span className="c-brand-explorer__manufacturer-title">{manufacturer.title}</span>
                        </a>
                    </li>
                })
            }

            if (this.state.hasMoreBrands) {
                this.state.brandsMeta.forEach(meta => {
                    if (Object.keys(meta).indexOf(this.manufacturerBrandsService.showMoreKey) >= 0) {
                        let moreMeta = meta[this.manufacturerBrandsService.showMoreKey];
                        this.renderStore.hasMoreBrandElement = <li className="c-brand-explorer__brand">
                            <a className="c-brand-explorer__brand-show-all" href={moreMeta.url}> {moreMeta.label} </a>
                        </li>

                        this.renderStore.showAllButton = <a href={moreMeta.url} className="c-button c-button--sm c-button--outline">{moreMeta.label}</a>
                    }
                });
            }

        }

        return (
            <div className="c-brand-explorer">
                <div className="c-brand-explorer__col c-brand-explorer__col--main">
                    <div className="c-brand-explorer__caption">
                        <h4 className="c-brand-explorer__caption-title">{this.i18nService.translate([this.i18nScopeKey, 'FEATURED_BRANDS_LABEL'])}</h4>
                        {this.renderStore.showAllButton && this.renderStore.showAllButton}
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-6">
                                <ul className="c-brand-explorer__manufacturers">
                                    {this.renderStore.manufacturers && this.renderStore.manufacturers}
                                </ul>
                            </div>
                            <div class="col-lg-12 c-brand-explorer__brand-container">
                                <ul className="c-brand-explorer__brands">
                                    {this.renderStore.brands && this.renderStore.brands}
                                    {this.renderStore.hasMoreBrandElement && this.renderStore.hasMoreBrandElement}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="c-brand-explorer__container">
                    <div className="c-brand-explorer__col c-brand-explorer__col--aside">
                        <ul className="c-brand-expolorer__manufacturers">
                            { this.renderStore.manufacturers && this.renderStore.manufacturers }
                        </ul>
                    </div>
                    <div className="c-brand-explorer__col c-brand-explorer__col--main">
                        <div className="c-brand-explorer__caption">
                            <h4 className="c-brand-explorer__caption-title">{this.i18nService.translate([this.i18nScopeKey, 'FEATURED_BRANDS_LABEL'])}</h4>
                            { this.renderStore.showAllButton && this.renderStore.showAllButton }
                        </div>
                        <ul className="c-brand-explorer__brands">
                            { this.renderStore.brands && this.renderStore.brands }
                            { this.renderStore.hasMoreBrandElement && this.renderStore.hasMoreBrandElement }
                        </ul>
                    </div> */}
                {/* <div className="c-brand-explorer__col c-brand-explorer__col--aside">
                        <ul className="c-brand-expolorer__manufacturers">
                            { this.renderStore.manufacturers && this.renderStore.manufacturers }
                        </ul>
                    </div> */}
                {/* </div> */}
            </div >
        )
    }

}