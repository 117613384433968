import React, {Component} from "react";
import ReactDOM from "react-dom";
import { DomComponent } from "../core/dom.component";
import BaseReactComponent from "../core/base-react.component";
import { LinearProgress as ReactLinearProgress } from "@material-ui/core";
import classNames from "classnames";
import { ViewportController } from "../controllers/viewport.controller";

export class LinearProgressComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        this.component = ReactDOM.render(<LinearProgress app={this.app} currentProps={this.getAttributes()} />, this.hostElement);
    }
}

LinearProgressComponent.decorator = {
    selector: 'fsd-linear-progress'
}

export class LinearProgress extends BaseReactComponent {
    constructor(prop, context) {
        super(prop, context, {
            defaultProps: {
                variant: 'determinate',
                value: 0,
                minValue: 0,
                maxValue: 100,
                minLabel: null,
                maxLabel: null,
                numberFormat: null,
                prefix: "",
                suffix: "",
                error:""
            }
        });

        this.hostElementRef = React.createRef();
        this.valueLabelElementRef = React.createRef();

        this.renderStore = {
            componentClassName: undefined
        }
        
        this.state = {
            value: this.currentProps.value,
            valueX: 0
        };

        this.viewportBreakpointChangeHandler = this.viewportBreakpointChangeHandler.bind(this);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
        this.viewportController = this.app.getController('viewport');
    }

    componentDidMount() {
        if(this.hostElementRef && this.hostElementRef.current && !this.state.hostElement) {
            this.setState({
                hostElement: this.hostElementRef.current,
                valueLabelElement: this.valueLabelElementRef.current,
                valueLabelWidth: this.valueLabelElementRef.current.offsetWidth
            },()=>{
                this.viewportBreakpointChangeHandler();
            });
            this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(ViewportController.EVENT_BREAKPOINT_CHANGE, this.viewportBreakpointChangeHandler);
        }
    }

    componentWillReceiveProps(props) {
        // let changes = _.pick(props, 'value'.split(','));
        this.updateCurrentProps(props);
        // this.setState(changes);
    }

    componentDidUpdate() {
        if(!this.state.hostElement && !this.state.valueLabelElement && (this.state.valueLabelElement.offsetWidth != this.state.valueLabelWidth)) {
            this.setState({
                hostWidth: this.state.hostElement.offsetWidth,
                valueLabelWidth: this.state.valueLabelElement.offsetWidth
            }, ()=>{
                
            });
        }
    }

    componentWillMount() {
        if(this.viewportBreakpointChangeProxyListener) this.viewportController.removeEventListener(ViewportController.EVENT_BREAKPOINT_CHANGE, this.viewportBreakpointChangeProxyListener);
    }

    viewportBreakpointChangeHandler() {
        if(!this.state.hostElement && !this.state.valueLabelElement) return;
        this.setState({
            hostWidth: this.state.hostElement.offsetWidth,
            valueLabelWidth: this.state.valueLabelElement.offsetWidth
        }, ()=>{
            
        });
    }

    render() {
        this.renderStore.componentClassName = classNames(
            'c-linear-progress',
            { 
                'c-linear-progress--disabled': this.currentProps.disabled,
                ['c-linear-progress--'+this.currentProps.variant]: this.currentProps.variant,
                ['c-linear-progress--'+this.currentProps.theme]: this.currentProps.theme,
                ['c-linear-progress--'+this.currentProps.align]: this.currentProps.align,
            }
        );
        
        let progress = (this.currentProps.value - this.currentProps.minValue) * 100 / (this.currentProps.maxValue - this.currentProps.minValue);
        progress = Math.min(100, Math.max(0, progress));
        let x = 0;
        if(this.state.hostElement && this.state.valueLabelWidth) {
            x = (this.state.hostWidth * (progress/100)) - this.state.valueLabelWidth/2;
            x = Math.min(this.state.hostWidth - this.state.valueLabelWidth, Math.max(0, x));
            x = (x/this.state.hostWidth) * 100;
        }
        
        let minLabel = this.i18nService.format(this.currentProps.minValue, ['number', this.currentProps.numberFormat]);
        let maxLabel = this.i18nService.format(this.currentProps.maxValue, ['number', this.currentProps.numberFormat]);
        let prefix = "";
        if(this.currentProps.value < this.currentProps.minValue) prefix = "<";
        if(this.currentProps.value > this.currentProps.maxValue) prefix = ">";
        let valueLabel = prefix + this.i18nService.format(Math.max(this.currentProps.minValue, Math.min(this.currentProps.maxValue, this.currentProps.value)), ['number', this.currentProps.numberFormat]);

        return <div ref={this.hostElementRef} className={this.renderStore.componentClassName}>
            {/* <div className="c-linear-progress__total-track progress">
                <div className="c-linear-progress__current-track progress-bar" role="progressbar" style={{width: Math.min(progress, 100)+"%"}} aria-valuenow={ Math.min(progress, 100)}
                    aria-valuemin="0" aria-valuemax="100"></div>
            </div> */}
            <div className="c-linear-progress__track">
                <ReactLinearProgress
                            classes= {{
                                root: 'c-linear-progress__track-line',
                                bar: `${this.currentProps.error==="bg-warning" ? "bg-warning" : `c-linear-progress__track-line-bar ${this.currentProps.error}`}`,
                            }}
                            variant={this.currentProps.variant}
                            value={progress} ></ReactLinearProgress></div>
            <div className="c-linear-progress__stats">
                <span className="c-linear-progress__start-label">{minLabel}</span>
                <span ref={this.valueLabelElementRef} className="c-linear-progress__current-label" style={{left: x+"%"}}>{valueLabel}</span>
                <span className="c-linear-progress__total-label">{ maxLabel }</span>
            </div>
        </div>
    }

}