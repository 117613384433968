import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import ReactDOM from "react-dom";
import classNames from 'classnames';
import CartService from '../services/cart.service';
import { DomComponent } from '../core/dom.component';
import { LinearProgress } from './linear-progress.component';

export class FreeShippingBoxComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        if (super.appOnInit) super.appOnInit();
        this.component = ReactDOM.render(<FreeShippingBox app={this.app} currentProps={this.getAttributes()} />, this.domElement);
    }
}

FreeShippingBoxComponent.decorator = {
    selector: "fsd-free-shipping-box"
}


export class FreeShippingBox extends BaseReactComponent {

    constructor(props, context) {
        super(props, context);
        this.domElement = null;

        this.i18nScopeKey = 'FREE_SHIPPING_BOX';

        this.state = {
            cart: null,
            requiredAmountForFreeShipping: null,
            availableShippingMethods: props.availableShippingMethods
        }
    }
    

    appOnInit() {
        if (super.appOnInit) super.appOnInit();
        this.cartService = this.app.cartService;
        this.i18nService = this.app.i18nService;
        this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartChangeHandler.bind(this));
        this.cartService.addEventListener(CartService.EVENT_REQUIRED_AMOUNT_FOR_FREE_SHIPPING_CHANGE, this.requiredAmountChangeHandlerProxyListener.bind(this));
    }

    componentDidMount() {
        this.cartChangeHandler();
    }
    
    cartChangeHandler() {
        this.setState({
            cart: this.cartService.cart,
            requiredAmountForFreeShipping: this.cartService.requiredAmountForFreeShipping
        });
    }

    requiredAmountChangeHandlerProxyListener() {
        this.setState({
            requiredAmountForFreeShipping: this.cartService.requiredAmountForFreeShipping
        });
    }
    renderMinLinearBar(){
        if (this.state.cart && this.state.cart.totals && this.state.cart.totals[this.props.business_name] && this.state.cart.totals[this.props.business_name].min) {
            const { value, vendor_purchasing_limit } = this.state.cart.totals[this.props.business_name];
            return (
                <>
                    <div>Minimum order required by seller</div>
                    <div className="c-free-shipping-box__price-status-bar" >
                        <LinearProgress
                            app={this.app}
                            error={parseInt(value) < parseInt(vendor_purchasing_limit.vendor_min_purchase) ? 'bg-warning' : 'bg-warning'}
                            numberFormat="currency"
                            value={parseFloat(value)}
                            maxValue={parseFloat(vendor_purchasing_limit.vendor_min_purchase)}
                        />
                    </div>
                </>
            )
        }
    }

    

    render() {
        let progress = 0;
        let business_name = this.props.business_name
        if (this.cartService.isFreeShippingAvailable && this.state.cart && this.state.cart.totals) {
            progress = parseInt(this.state.cart ? this.state.cart.totals['foodserviceSubtotal'] : 0) / parseInt(this.state.requiredAmountForFreeShipping) * 100;
            progress = Math.max(0, Math.min(progress, 100));
        } else {
            return null;
        }

        let qualified = this.state.cart && this.state.cart.totals ? parseFloat(this.state.cart.totals['foodserviceSubtotal']) >= parseFloat(this.state.requiredAmountForFreeShipping) : false;
        let componentClassName = classNames(
            'c-free-shipping-box',
            {
                'c-free-shipping-box--qualified': qualified
            }
        )
        let shippingAmountExists = Math.round(parseInt(this.state.cart && this.state.cart.totals && this.state.cart.totals.shipping_amount)) > 0 ;
        let fsdThresholdItemExists = this.state.cart && this.state.cart.totals && this.state.cart.totals.fsdThresholdItemExists;
        
        let otherVendorShippingAmount = 0;
        if(business_name !== "FoodServiceDirect.com"){ // this logic is for showing free shiping bar box for other vendors
            this.props.availableShippingMethods.map(item => {
                if(item.carrier_title === business_name) otherVendorShippingAmount = otherVendorShippingAmount + item.amount;
            })
        }
        
        return (

            <div ref={r => this.domElement = r} className={componentClassName}>
                {( business_name === "FoodServiceDirect.com") ? (this.state?.cart?.totals[this.props.business_name]?.min || shippingAmountExists || fsdThresholdItemExists)   && !this.state.cart.totals[business_name].neverShipsFree ? 
                <div className="c-free-shipping-box__wrapper">
                    <div className="c-free-shipping-box__container">
                        {(shippingAmountExists || fsdThresholdItemExists) && 
                         <>
                            <div className="c-free-shipping-box__caption">
                                <h4 className="c-free-shipping-box__caption-title free-ship-text">{qualified ? this.i18nService.translate([this.i18nScopeKey, 'QUALIFIED_FOR_SHIPPING']) : this.i18nService.translate([this.i18nScopeKey, 'QUALIFYING_TO_SHIPPING'])}</h4>
                            </div>
                            <div className="c-free-shipping-box__price-status-bar">
                                <LinearProgress
                                    app={this.app}
                                    numberFormat="currency"
                                    value={this.state.cart && this.state.cart.totals ? parseFloat(this.state.cart.totals['foodserviceSubtotal']) : 0}
                                    maxValue={parseFloat(this.state.requiredAmountForFreeShipping)}
                                />
                            </div>
                            <div className="c-free-shipping-box__description">
                                <p>{
                                    progress < 100
                                        ? this.i18nService.translate([this.i18nScopeKey, 'QUALIFIED_FOR_INFO'], { currentAmount: this.state.cart && this.state.cart.totals ? parseFloat(this.state.cart.totals['foodserviceSubtotal']) : '', remainingAmount: this.state.requiredAmountForFreeShipping ? parseFloat(this.state.requiredAmountForFreeShipping - (this.state.cart ? parseFloat(this.state.cart.totals['foodserviceSubtotal']) : 0)).toFixed(2) : '' })
                                        : this.i18nService.translate([this.i18nScopeKey, 'QUALIFIED_TO_INFO'], { currentAmount: this.state.cart && this.state.cart.totals ? parseFloat(this.state.cart.totals['foodserviceSubtotal']) : '', requiredAmountForFreeShipping: this.state.requiredAmountForFreeShipping })
                                }</p>
                            </div>
                         </>
                        }
                        {this.renderMinLinearBar()}
                    </div> 
                </div>     
                : 
                null
                : 
                      
                    otherVendorShippingAmount === 0 && this.state.cart  ?
                        <div className="c-free-shipping-box__wrapper">
                            <div className="c-free-shipping-box__container">
                                <div className="c-free-shipping-box__caption mt-3">
                                    <h4 className="c-free-shipping-box__caption-title">Seller Requirements</h4>
                                </div>
                                <div className="c-free-shipping-box__description mt-3 mb-3">
                                    <p>This is eligible for free shipping.</p>
                                </div>
                                {this.renderMinLinearBar()}
                            </div> 
                        </div> : 
                            otherVendorShippingAmount  !== 0 ? 
                            <div className="c-free-shipping-box__wrapper">
                                <div className="c-free-shipping-box__container">
                                    <div className="c-free-shipping-box__caption mt-3">
                                        <h4 className="c-free-shipping-box__caption-title">Seller Requirements</h4>
                                    </div>
                                    <div className="c-free-shipping-box__description mt-3 mb-3">
                                        <p>Please note this is not eligible for free shipping.</p>
                                    </div>
                                    {this.renderMinLinearBar()}
                                </div>
                            </div> : 
                        null

                        // this.props.availableShippingMethods.map(item => (
                        //     item.carrier_title === business_name &&  item.amount === 0 && this.state.cart  ?
                        //     <div className="c-free-shipping-box__wrapper">
                        //         <div className="c-free-shipping-box__container">
                        //             <div className="c-free-shipping-box__caption mt-3">
                        //                 <h4 className="c-free-shipping-box__caption-title">Seller Requirements</h4>
                        //             </div>
                        //             <div className="c-free-shipping-box__description mt-3 mb-3">
                        //                 <p>This is eligible for free shipping.</p>
                        //             </div>
                        //             {this.renderMinLinearBar()}
                        //         </div> 
                        //     </div> : 
                        //     item.carrier_title === business_name &&  item.amount !== 0 ? 
                        //         <div className="c-free-shipping-box__wrapper">
                        //             <div className="c-free-shipping-box__container">
                        //                 <div className="c-free-shipping-box__caption mt-3">
                        //                     <h4 className="c-free-shipping-box__caption-title">Seller Requirements</h4>
                        //                 </div>
                        //                 <div className="c-free-shipping-box__description mt-3 mb-3">
                        //                     <p>Please note this is not eligible for free shipping.</p>
                        //                 </div>
                        //                 {this.renderMinLinearBar()}
                        //             </div>
                        //         </div> : 
                        //     null
                        // )
                }
            </div>
        );
    }
}