import { DomComponent } from "../core/dom.component";
import { LoadingController } from "../controllers/loading.controller";
import classNames from "classnames";

export default class ProductStickyShopBar extends DomComponent {
    constructor(app, element, props) {
        super(app, element, 'c-product-shop-sticky-bar');

        this.cartService = this.app.cartService;
        this.loadingController = this.app.getController('loading');
        this.props = props || {};
        this.product = props.product;
        this.isOutOfStock = false;
        this.addButton = null;
        this.quantityStepper = null;

        this.quantity = 1;

        this.init();
    }

    init(){
        this.findChild(this.getSelector(' .c-selectbox select')).then( element => {
            this.quantityStepper = element;
            element.onchange = this.quantityChangeHandler.bind(this);
        });

        this.findChild(this.getSelector(' [href="#add-to-cart"]')).then( element => {
            this.addButton = element;
            element.onclick = this.addToCartClickHandler.bind(this);
        });

        this.loadingController.addEventListener(LoadingController.EVENT_CHANGE, this.loadingChangeHandler.bind(this));
        this.setComponentClass();
    }

    quantityChangeHandler(e){
        this.quantity = this.quantityStepper.value;
    }

    loadingChangeHandler() {
        this.setComponentClass();
    }

    setComponentClass(){
        this.isOutOfStock = this.product ? this.product.in_stock === false : false;

        let className = classNames(
            this.getComponentClass(),
            {
                [this.getSelector('--disabled', '')]: this.loadingController.getLoadingStatus('cart'),
                [this.getSelector('--out-of-stock', '')]: this.isOutOfStock,
            }
        )
        this.setClass(className)
    }

    addToCartClickHandler(e){
        if(!this.product) return;
        e.preventDefault();
        e.stopPropagation();
        this.cartService.addToCart(this.product, this.quantity);
        if(this.quantityStepper) this.quantityStepper.selectedIndex = 0;
    }
}