import { BaseApiService } from "./base-api.service";
import validate from "validate.js";

export default class ValidateService extends BaseApiService {
    
    constructor(app) {
        super(app);   
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;

        this.setCustomValidations();
    }

    setCustomValidations() {
        validate.validators.zipCode = function(value, options, key, attributes) {
            console.log()
            if(!value) return this.i18nService.translate("VALIDATE.REGION_CODE_NOT_MATCH");
            if(options) {
                let regionCode = options.regionCode;
                if(!regionCode && attributes && options.attributeName && attributes[options.attributeName]) regionCode = attributes[options.attributeName]
                let valid = this.isZipCode(value, regionCode);
                if(!valid) return this.i18nService.translate("VALIDATE.REGION_CODE_NOT_MATCH");
            }
            return null;
        }.bind(this);
    }

    isZipCode(value, regionCode) {
        if(!regionCode) return true;
        let condition = this.getZipCodeValidationRules(regionCode.toUpperCase());
        let firstThree = parseInt(value.slice(0,3))
        let valid = eval(condition);
        return valid;
    }

    getZipCodeValidationRules(zipCode) {
        if (!zipCode) return null;
        switch(zipCode){
            case "AL": return ('firstThree >= 350 && firstThree <= 369');
            break;
            case "AK": return ('firstThree >= 995 && firstThree <= 999');
            break;
            case "AZ": return ('firstThree >= 850 && firstThree <= 865');
            break;
            case "AR": return ('(firstThree >= 716 && firstThree <= 729) || (firstThree == 755)');
            break;
            case "AS": return ('firstThree == 967');
            break;
            case "CA": return ('firstThree >= 900 && firstThree <= 966');
            break;
            case "CO": return ('firstThree >= 800 && firstThree <= 816');
            break;
            case "CT": return ('firstThree >= 60 && firstThree <= 69');
            break;
            case "DC": return ('firstThree >= 200 && firstThree <= 205');
            break;
            case "DE": return ('firstThree >= 197 && firstThree <= 199');
            break;
            case "FL": return ('(firstThree >= 320 && firstThree <= 349) && (firstThree != 343 && firstThree !=345 && firstThree!=348)');
            break;
            case "GA": return ('(firstThree >= 300 && firstThree <= 319) || firstThree == 398 || firstThree == 399');
            break;
            case "GU": return ('firstThree == 969');
            break;
            case "HI": return ('firstThree >= 967 && firstThree <= 968');
            break;
            case "ID": return ('firstThree >= 832 && firstThree <= 838');
            break;
            case "IL": return ('firstThree >= 600 && firstThree <= 629');
            break;
            case "IN": return ('firstThree >= 460 && firstThree <= 479');
            break;
            case "IA": return ('firstThree >= 500 && firstThree <= 528');
            break;
            case "KS": return ('firstThree >= 660 && firstThree <= 679');
            break;
            case "KY": return ('firstThree >= 400 && firstThree <= 427');
            break;
            case "LA": return ('firstThree >= 700 && firstThree <= 714');
            break;
            case "ME": return ('firstThree >= 39 && firstThree <= 49');
            break;
            case "MH": return ('firstThree == 969');
            break;
            case "MD": return ('firstThree >= 206 && firstThree <= 219');
            break;
            case "MA": return ('(firstThree >= 10 && firstThree <= 27) || (firstThree == 55)');
            break;
            case "MI": return ('firstThree >= 480 && firstThree <= 499');
            break;
            case "MN": return ('firstThree >= 550 && firstThree <= 567');
            break;
            case "MS": return ('firstThree >= 386 && firstThree <= 397');
            break;
            case "MO": return ('firstThree >= 630 && firstThree <= 658');
            break;
            case "MT": return ('firstThree >= 590 && firstThree <= 599');
            break;
            case "NE": return ('firstThree >= 680 && firstThree <= 693');
            break;
            case "NV": return ('firstThree >= 889 && firstThree <= 898');
            break;
            case "NH": return ('firstThree >= 30 && firstThree <= 38');
            break;
            case "NJ": return ('firstThree >= 70 && firstThree <= 89');
            break;
            case "NM": return ('firstThree >= 870 && firstThree <= 884');
            break;
            case "NY": return ('(firstThree >= 90 && firstThree <= 149) || (firstThree == 4) || (firstThree == 63)');
            break;
            case "NC": return ('firstThree >= 269 && firstThree <= 289');
            break;
            case "ND": return ('firstThree >= 580 && firstThree <= 588');
            break;
            case "MP": return ('firstThree == 969');
            break;
            case "OH": return ('firstThree >= 430 && firstThree <= 458');
            break;
            case "OK": return ('firstThree >= 730 && firstThree <= 749');
            break;
            case "OR": return ('firstThree >= 970 && firstThree <= 979');
            break;
            case "PA": return ('firstThree >= 150 && firstThree <= 196');
            break;
            case "PR": return ('firstThree >= 6 && firstThree <= 9');
            break;
            case "RI": return ('firstThree >= 28 && firstThree <= 29');
            break;
            case "SC": return ('firstThree >= 290 && firstThree <= 299');
            break;
            case "SD": return ('firstThree >= 570 && firstThree <= 577');
            break;
            case "TN": return ('firstThree >= 370 && firstThree <= 385');
            break;
            case "TX": return ('(firstThree >= 750 && firstThree <= 799) || (firstThree == 885)');
            break;
            case "UT": return ('firstThree >= 840 && firstThree <= 847');
            break;
            case "VT": return ('firstThree >= 50 && firstThree <= 59');
            break;
            case "VA": return ('(firstThree >= 220 && firstThree <= 246) || (firstThree == 201)');
            break;
            case "VI": return ('firstThree == 8');
            break;
            case "WA": return ('firstThree >= 980 && firstThree <= 994');
            break;
            case "WI": return ('firstThree >= 530 && firstThree <= 549');
            break;
            case "WV": return ('firstThree >= 247 && firstThree <= 268');
            break;
            case "WY": return ('firstThree >= 820 && firstThree <= 831');
            break;
            case "AE": return ('firstThree >= 90 && firstThree <= 98');
            break;
            case "AA": return ('firstThree == 340');
            break;
            case "AP": return ('firstThree >= 962 && firstThree <= 966');
            break;
        }
    }

}