import { DomComponent } from "../core/dom.component";
import Drift from 'drift-zoom';
import classNames from "classnames";
import NumberStepperComponent from "./number-stepper.component";
import { ViewportController } from '../controllers/viewport.controller';
import { LoadingController } from '../controllers/loading.controller';
import GAService from "../services/ga.service";
import Swiper from "swiper";

export default class ProductViewerComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-product-viewer');

        this.isAvailableForCart = true;
        this.isAvailableForShipping = true;
        this.isAddedToCart = false;
        this.isAddedToList = false;
        this.product = null;
        this.settings = null;
        this.relatedCartItem = null;
        this.addButton = null;
        this.quantityStepper;
        this.quantity = 1;
        this.driftZoom = null;
        this.slides = [];
        this.isProcessing;

        this.productController = this.app.getController('product');
        this.viewportController = this.app.getController('viewport');
        this.loadingController = this.app.getController('loading');
        this.cartService = this.app.cartService;

        this.init();
    }

    init() {
        this.product = this.getJSON('product');
        this.settings = this.getJSON('settings');
        this.gaService = this.app.gaService;
        this.gaService.addEventListener(GAService.EVENT_READY, this.gaServiceReadyHandler.bind(this));

        this.findChildren(this.getSelector('__image-slide')).then( slides => {
            this.slides = slides;
            this.setComponentClass();
            if(this.slides.length > 1) {
                this.findChild(this.getSelector('__image-container')).then( element => {
                    this.imageSwiper = new Swiper(element, {
                        init: false,
                        spaceBetween: 0,
                        pagination: {
                            el: this.getSelector('__pagination'),
                            clickable: true,
                            dynamicBullets: true,
                            dynamicMainBullets: 10
                        },
                        breakpoints: {
                            540: {
                                pagination: {
                                    dynamicMainBullets: 3
                                }
                            },
                            720: {
                                pagination: {
                                    dynamicMainBullets: 5
                                }
                            },
                            960: {
                                pagination: {
                                    dynamicMainBullets: 7
                                }
                            }
                        }
                    });

                    // this.imageSwiper.on('init', () => this.setActive());
                    this.imageSwiper.on('slideChange', () => this.setActive());
                    this.imageSwiper.init();
                });

                this.findChild(this.getSelector('__thumb-container')).then( element => {
                    this.thumbSwiper = new Swiper(element, {
                        init: false,
                        spaceBetween: 20,
                        slidesPerView: 3,
                        touchRatio: 0.2,
                        // slideToClickedSlide: true,
                        // slideActiveClass: this.getSelector('__thumb-slide--active').substr(1),
                        navigation: {
                            nextEl: this.querySelector(this.getSelector('__thumb-nav-next')),
                            prevEl: this.querySelector(this.getSelector('__thumb-nav-prev')),
                        },
                    });

                    // this.thumbSwiper.on('init', () => this.setActive());
                    this.thumbSwiper.init();
        
                    this.findChildren(this.getSelector('__thumb-slide')).then( elements => elements.forEach( element => {
                        element.onclick = this.thumbClickHandler.bind(this);
                    }));
                });
            }
    
            this.findChildren(this.getSelector(' [href]')).then( elements => {
                elements.forEach(element => {
                    element.onclick = this.actionClickHandler.bind(this, element);
                })
            }).catch( reason => this.handleSelectorError(reason) );;

            this.findChild(this.getSelector(' .c-number-stepper')).then( element => {
                this.quantityStepper = new NumberStepperComponent(this.app, element);
                this.quantityStepper.addEventListener(NumberStepperComponent.EVENT_CHANGE, this.quantityChangeHandler.bind(this));
            }).catch( reason => this.handleSelectorError(reason) );;

            this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(ViewportController.EVENT_BREAKPOINT_CHANGE, this.viewportChangeHandler.bind(this));
            this.loadingController.addEventListener(LoadingController.EVENT_CHANGE, this.loadingChangeHandler.bind(this));

            setTimeout(()=>{
                const images = Array.from(slides).map( slide => {
                    return slide.getAttribute('data-zoom');
                });
                this.preloadImages(images);
            }, 300);
        }); 
        
        this.findChild(this.getSelector(' .c-product-shop-box__free-shipping')).then( element => {
            // this.freeShippingBox = ReactDOM.render(<FreeShippingBoxComponent app={this.app}/>, element);
        }).catch( reason => this.handleSelectorError(reason) );
    }

    gaServiceReadyHandler() {
        if(this.product) {
            this.gaService.detail({
                'id': this.product.sku,
                'name': this.product.name,
                'category': this.product.category,
                'brand': this.product.brand,
                'position': 0,
                'price': this.product.price,
            });
        }
    }

    setComponentClass(){
        let className = classNames(
            this.getComponentClass(),
            {
                [this.getSelector('--shrink', '')]: this.viewportController.isMobile,
                [this.getSelector('--has-video', '')]: this.settings && this.settings.hasVideo,
                [this.getSelector('--has-thumb', '')]: this.slides && this.slides.length > 1,
                [this.getSelector('--has-more', '')]: this.slides && this.slides.length > 3,
                [this.getSelector('--processing-cart', '')]: this.loadingController.getLoadingStatus('add-to-cart'),
            }
        )
        this.setClass(className)
    }

    viewportChangeHandler() {
        this.setActive();
    }

    loadingChangeHandler() {
        this.setComponentClass();
    }

    setRelatedCartItem(cartItem) {
        if(!this.product) return;
        this.relatedCartItem = cartItem;
        this.isAddedToCart = this.relatedCartItem && this.relatedCartItem['sku'] == this.product.sku;
        this.loadingController.removeLoading('add-to-cart');
        this.setComponentClass();
    }
    
    quantityChangeHandler(e) {
        this.quantity = this.quantityStepper.value;
    }

    actionClickHandler(target, e) {
        if(!this.product) return;
        switch(target.getAttribute('href')) {
            case "#product-video":
                // window.location.href = '#product-video';
            break;
            case '#zoom':
                
            break;
            case '#add-to-list':

            break;
            case '#share':

            break;
            case '#add-to-cart': 
                e.preventDefault();
                e.stopPropagation();           
                this.loadingController.addLoading('add-to-cart');
                this.cartService.addToCart(this.product, this.quantity);
            break;
            default:

            break;
        }
    }

    thumbClickHandler(e) {
        this.imageSwiper.slideTo(this.thumbSwiper.clickedIndex);
    }

    setActive() {
        this.setDriftZoom(null);
        if(this.imageSwiper) {
            if(this.thumbSwiper) {
                for(var i = 0; i < this.thumbSwiper.slides.length; i++) {
                    if(i == this.imageSwiper.activeIndex) {
                        this.addClass(this.getSelector('__thumb-slide--active', ""), this.thumbSwiper.slides[i]);
                    }else{
                        this.removeClass(this.getSelector('__thumb-slide--active', ""), this.thumbSwiper.slides[i]);
                    }
                }
            }
            this.viewportController.isMobile ? this.setDriftZoom(null) : this.setDriftZoom(this.imageSwiper.slides[this.imageSwiper.activeIndex]);
        }else{
            this.viewportController.isMobile ? this.setDriftZoom(null) : this.setDriftZoom(this.slides[0]);
        }
        this.setComponentClass();
    }

    setDriftZoom(triggerElement) {
        if(triggerElement && triggerElement.hasAttribute('data-zoom')) {
            this.findChild(this.getSelector('__image-pane')).then( container => {
                this.driftZoom = new Drift(triggerElement, {
                    paneContainer: container,
                    onHide: this.drifZoomHideHandler.bind(this),
                    onShow: this.driftZoomShowHandler.bind(this),
                    hoverBoundingBox: true,
                    hoverDelay: 300
                });
            });
        }else{
            if(this.driftZoom) this.driftZoom.destroy();
            this.driftZoom = null;
        }
    }

    driftZoomShowHandler(e) {
        this.findChild(this.getSelector('__image-pane')).then( container => this.addClass(this.getSelector('__image-pane--active', ''), container));
    }

    drifZoomHideHandler(e) {
        this.findChild(this.getSelector('__image-pane')).then( container => this.removeClass(this.getSelector('__image-pane--active', ''), container));
    }

    destroy() {
        this.imageSwiper.destroy(true);
        this.thumbSwiper.destroy(true);
        this.driftZoom.disable();
    }

    preloadImages(images) {
        images.forEach( image => {
            new Image().src = image;
        });
    }

}