import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';
import DrawerMenuComponent from './drawer-menu.component';
import MenuController from '../controllers/menu.controller';
import Swiper from "swiper";

export default class DrawerPanelComponent extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);

        this.i18nScopeKey = 'DRAWER_PANEL';
        this.refId = _.uniqueId();
        this.swiper = null;
        this.swiperElement;

        this.renderStore = {
            componentClassName: null,
            sections: null
        }

        this.state = {
            sections: props.sections || []
        }
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();        
    }

    updateSwiper() {
        if(this.swiper) this.swiper.update();
    }
    
    componentDidMount() {
        if(!this.swiperElement) return;
        this.swiper = new Swiper(this.swiperElement, {
            containerModifierClass: 'c-drawer-panel__container',
            wrapperClass: 'c-drawer-panel__sections',
            slideClass: 'c-drawer-panel__section',
            slideActiveClass: 'c-drawer-panel__section--active',
            resistanceRatio: 0.3
        });

        this.swiper.on('slidePrevTransitionEnd', this.historyBackHandler.bind(this));
    }

    componentDidUpdate() {
        this.updateSwiper();
    }
  
    componentWillUnmount() {
        
    }

    render(){
        this.renderStore.sections = this.state.sections.map( (section, index) => {
            return  <div key={'section-'+index} className="c-drawer-panel__section">
                        {index > 0 && <div className="c-drawer-panel__section-caption">
                            <button onClick={this.historyBackClickHandler.bind(this)} className="c-drawer-panel__section-caption-history-back c-button c-button--full-width"><i className="icon__fsd icon__fsd--arrow-left-primary"></i><span>Back</span></button>
                        </div>}
                        <div className="c-drawer-panel__section-content">
                            {section.content && section.content}
                            {!section.content && section.items && <DrawerMenuComponent app={this.app} theme="thumbnail" title={section.title} showAllLink={section.showAllLink} onAction={this.menuActionHandler.bind(this)} items={section.items}/>}
                            {section.actions && <DrawerMenuComponent app={this.app} theme="outlined" onAction={this.menuActionHandler.bind(this)} items={section.actions}/>}
                        </div>
                    </div>
        });

        return  <div ref={ e => this.wrapperRef = e} className="c-drawer-panel">
                    <div ref={r => this.swiperElement = r} className="c-drawer-panel__container">
                        <div className="c-drawer-panel__sections">
                            {this.renderStore.sections}
                        </div>
                    </div>
                </div>
    }

    menuActionHandler(action, params) {
        // switch(action) {
        //     case "new-section":
        //         if(params) this.addSection(params);
        //         if(this.swiper) {
        //             setTimeout(()=>{
        //                 this.swiper.slideNext();
        //             },30);
        //         }
        //     break;
        // }
        if(this.props.onAction) this.props.onAction(action, params);
    }

    addSection(section, index, gotoLastAdded) {
        if(index === null || isNaN(index)){
            index = this.state.sections.length;
        }else{
            index = Math.min(this.state.sections.length, Math.max(0, index));
        }

        let sections = [...this.state.sections];
        sections.splice(index, 0, section);
        this.setState({
            sections: sections
        });

        this.updateSwiper();

        if(gotoLastAdded && this.swiper) {
            setTimeout(()=>{
                this.swiper.slideNext();
            },30);
        }
    }

    removeSection() {

    }

    gotoSection(index, time) {
        if(this.swiper) return;
        this.swiper.slideTo(index, time);
    }

    gotoHome() {
        if(this.swiper) {
            if(!this.state.sections || this.state.sections.length == 1) return;
            let sections = [this.state.sections.shift()];
            this.setState({sections: sections});
            this.updateSwiper();
        }
    }

    historyBackClickHandler() {
        if(this.swiper) this.swiper.slidePrev();
    }

    historyBackHandler() {
        if(this.swiper) {
            let sections = [...this.state.sections];
            sections.splice(this.swiper.activeIndex + 1);
            this.setState({sections: sections});
            this.updateSwiper();
        }
    }

    handleClickOutside(event) {
        if (event && event.target && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.menuController.closeMobileMenu();
        }          
    }
}