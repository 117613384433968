import React, { Component } from 'react';
import BaseReactComponent from '../core/base-react.component';
import { ActionListComponent } from './action-list.component';

export class ContextMenuComponent extends BaseReactComponent {
  constructor(props, context) {
    super(props, context);

    this.title = props.title;
    this.primaryAction = props.primaryAction;
    this.customRender = props.render;
    this.content = null;

    this.renderStore = {
      content: null
    }

    this.state = {
      items: props.items,
      render: props.render
    };
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.dialogController = this.app.getController('dialog');
  }

  componentDidMount()  {
    if (this.state.render && typeof this.state.render === 'function') {
      this.state.render(this.content);
    }
  }

  render() {

    if (this.state.render) {
      if (typeof this.state.render !== 'function') {
        this.renderStore.content = this.state.render;
      }
    } else if (this.state.items) {
      this.renderStore.content = <ActionListComponent
        listItems={this.state.items}
        theme={'flat'}
        app={this.app} />
    }

    return (
      <div className="c-context-menu" ref={e => this.domElement = e}>
        <div className="c-context-menu__container">
          <div className="c-context-menu__caption">
            <h3 className="c-context-menu__title">{this.title}</h3>
            <div onClick={this.close.bind(this)}  className="c-context-menu__close"></div>
          </div>
          <div ref={r => this.content = r} className="c-context-menu__content">
            {this.renderStore.content}
          </div>
          {this.primaryAction && this.primaryAction.href &&
            <div className="c-context-menu__action">
              <a href={this.primaryAction.href} className="c-button c-button--full-width c-button--outline">{this.primaryAction.label}</a>
            </div>
          }
        </div>
      </div>
    )
  }

  close() {
    this.app.closest('.c-dialog-box', this.domElement).then(dialogBoxElement => {
      this.dialogController.dismiss(null, dialogBoxElement.getAttribute('dialog-ref-id'));
    })
  }

}