import { DomComponent } from "../core/dom.component";
import { ViewportController } from '../controllers/viewport.controller';
import classNames from 'classnames';
import Swiper from 'swiper';

export default class BrandSliderComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-brand-slider');
        this.swiper = null;

        this.init();
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        // this.viewportController = this.app.getController('viewport');

        // this.viewportBreakpointChangeHandler = this.viewportBreakpointChangeHandler.bind(this);
        // this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(ViewportController.EVENT_BREAKPOINT_CHANGE, this.viewportBreakpointChangeHandler);
        // this.viewportBreakpointChangeHandler();
    }

    init() {
        this.setSwiper();
    }

    setSwiper() {
        if(this.swiper) return;
        this.findChild(this.getSelector('__container')).then( element => {
            this.findChildren(this.getSelector('__item')).then( slides => {

                this.swiper = new Swiper(element, {
                    slidesPerView: '8',
                    spaceBetween: 20,
                    slidesPerGroup: 1,
                    centerInsufficientSlides: true,
                    watchOverflow: true,
                    touchRatio: 0.2,
                    mousewheel: {
                        invert: true,
                        forceToAxis: true
                    },
                    slideToClickedSlide: false,
                    breakpoints: {
                        [this.app.settings.breakpoints.xxl.min]: {
                            slidesPerView: 7,
                            spaceBetween: 20,
                        },
                        [this.app.settings.breakpoints.xl.min]: {
                             slidesPerView: 6,
                            spaceBetween: 20,
                        },
                        [this.app.settings.breakpoints.lg.min]: {
                            slidesPerView: 5,
                            spaceBetween: 20
                        },
                        [this.app.settings.breakpoints.md.min]: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        [this.app.settings.breakpoints.sm.min]: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        }
                    },
                    navigation: {
                        nextEl: this.querySelector(this.getSelector('__nav-next')),
                        prevEl: this.querySelector(this.getSelector('__nav-prev')),
                        disabledClass: this.getSelector('__nav-item--disabled', ''),
                        hiddenClass: this.getSelector('__nav-item--hidden', '')
                    }
                });
    
                // setTimeout(()=> this.app.trigger('resize'), 50);
            });
        });
    }

    // viewportBreakpointChangeHandler() {
    //     if(!this.viewportController) return;
    //     if(!this.swiper) {
    //         this.setSwiper();
    //     }else{
    //         if(this.swiper) {
    //             this.swiper.destroy();
    //             this.swiper = null;
    //         }
    //     }
    // }
}