import React, {Component} from "react";
import ReactDOM from "react-dom";
import { DomComponent } from "../core/dom.component";
import BaseReactComponent from "../core/base-react.component";
import { TextField as ReactTextField, MenuItem } from "@material-ui/core";
import classNames from "classnames";
import FormControl from "./form-control";
import { InputLabel } from "@material-ui/core";

export class SelectComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        this.component = ReactDOM.render(<Select app={this.app} currentProps={this.getAttributes()} />, this.domElement);
    }
}

SelectComponent.decorator = {
    selector: 'fsd-select'
}

export class Select extends FormControl {
    constructor(prop, context) {
        super(prop, context, {
            defaultProps: {
                form: null,
                variant: 'outlined', //standard, filled
                type: 'text',
                isNative: true,
                isShrink: false,
                options: undefined,
                defaultValue: undefined,
                value: undefined,
                label: undefined,
                placeholder: undefined,
                helperText: undefined,
                errorText: undefined,
                name: undefined,
                disabled: false,
                error: false,
                required: false,
                fullWidth: false,
                multiline: false,
                rows: undefined,
                rowsMax: undefined,
                theme: 'default',
                autoFocus: false,
                defaultValue: '',
                margin: 'none', //dense, normal
                startIcon: null,
                endIcon: null,
                startButton: null,
                endButton: null,
                validate: null,
                validateOnBlur: false
            }
        });

        this.renderStore = {
            componentClassName: undefined,
            options: undefined,
            helperClass: undefined,
            inputPropsClasses: undefined
        }
        
        this.state = {
            form: null,
            isShrink: this.currentProps.isShrink || this.currentProps.placeholder != null || (this.currentProps.options && this.currentProps.options[0] && this.currentProps.options[0].value === ''),
            value: this.currentProps.value,
            defaultValue: this.currentProps.defaultValue,
            error: this.currentProps.error,
            errorText: this.currentProps.errorText,
            isFocused: this.currentProps.autoFocus,
            validateOnBlur: this.currentProps.validateOnBlur
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.inputBlurHandler = this.inputBlurHandler.bind(this);
        this.inputFocusHandler = this.inputFocusHandler.bind(this);
    }

    get formattedOptions() {
        let options = Array.isArray(this.currentProps.options) ? [...this.currentProps.options] : [];
        if(this.currentProps.placeholder && options && (!options[0] || options[0] && options[0].value !== '')) options.unshift({label: this.currentProps.placeholder, value: ''});
        return options;
    }

    get value() {
        let value = this.state.value;
        return value;
    }

    componentDidMount() {
        this.formControlComponentDidMount();

        let options = this.formattedOptions;
        let value = this.state.value;
        if(!value) value = this.state.defaultValue;
        if(!value && options && options.length > 0) value = options[0].value;
        this.setState({
            options: options,
            value: value,
            hasSelection: value != ''
        });
    }

    componentWillReceiveProps(props) {
        let changes = _.pick(props, 'isShrink,validateOnBlur'.split(','));
        this.setState(changes);
    }

    render() {
        if(!this.state.options) return null;

        this.renderStore.componentClassName = classNames(
            'c-select',
            { 
                'c-select--disabled': this.currentProps.disabled,
                'c-select--error': this.props.error,
                'c-select--focused': this.state.isFocused,
                'c-select--required': this.currentProps.required,
                'c-select--no-selection': !this.state.hasSelection,
                ['c-select--margin-'+this.currentProps.margin]: this.currentProps.margin,
                ['c-select--'+this.currentProps.variant]: this.currentProps.variant,
                ['c-select--'+this.currentProps.theme]: this.currentProps.theme,
                ['c-select--'+this.currentProps.align]: this.currentProps.align,
            }
        );
        
        this.renderStore.inputPropsClasses = {
            root: 'c-select__input',
            input: 'c-select__input-select',
            notchedOutline: 'c-select__input--outline'
        };
        if(this.currentProps.variant == 'standard') delete this.renderStore.inputPropsClasses.notchedOutline;

        let inputLabelProps = {
            classes: {
                root: 'c-select__label'
            },
            shrink: this.state.isShrink
        };
        if(!this.state.isShrink) delete inputLabelProps.shrink;

        this.renderStore.helperClass = classNames(
            'c-select__helper',
            'c-react-form__group-helper',
            {
                'c-react-form__group-helper--error': this.props.error,
                'c-react-form__group-helper--disabled': this.state.error,
            }
        );
        
        this.renderStore.options = this.state.options.map( (option,index) => {
            if(this.currentProps.isNative) {
                return <option data-test-id={this.props.dataTestId+"-option"+index} key={option.value} value={option.value}>{option.label}</option>
            }else{
                return <MenuItem data-test-id={this.props.dataTestId+"-option"+index} key={option.value} value={option.value}>{option.label}</MenuItem>
            }
        });
        
        this.input = <><InputLabel className = "c-react-form__group-label-text">{this.currentProps.label}</InputLabel>
                    <ReactTextField
                        select
                        classes= {{
                            root: 'c-select__control'
                        }}
                        InputLabelProps={inputLabelProps}
                        InputProps={{
                            classes: this.renderStore.inputPropsClasses,
                        }}
                        FormHelperTextProps={{
                            classes: {
                                root: this.renderStore.helperClass
                            }
                        }}
                        style={this.props.style}
                        SelectProps={{
                            native: this.currentProps.isNative,
                            classes: {
                                root: 'c-select__input-root',
                                select: 'c-select__input-select',
                                selectMenu: 'c-select__menu',
                                icon: 'c-select__input-icon'
                            },
                           inputProps: {
                            "data-test-id":this.props.dataTestId,
                           }
                        }}
                        name={this.currentProps.name}
                        id={this.props.id}
                        variant={this.currentProps.variant}
                        placeholder={this.currentProps.placeholder}
                        // label={this.currentProps.label}
                        type={this.currentProps.type}
                        value={this.state.value}
                        helperText = {this.props.error ? this.props.errorText : this.currentProps.helperText}
                        required={this.currentProps.required}
                        error={this.props.error}
                        fullWidth={this.currentProps.fullWidth}
                        onChange={this.inputChangeHandler}
                        onBlur={this.inputBlurHandler}
                        onFocus={this.inputFocusHandler}
                        >{this.renderStore.options}</ReactTextField></>

        return <div className={this.renderStore.componentClassName}>
            {this.input}
        </div>
    }

    inputChangeHandler(e) {
        this.setState({value: e.target.value}, () => {
            this.validate();
            this.setState({hasSelection: this.state.value != ''});
            if(this.props.onChange) this.props.onChange({state: this.state, target: this, defaultEvent: e});
        });
    }

    inputBlurHandler(e) {
        if(this.state.validateOnBlur) this.validate();
        this.setState({isFocused: false});
        if(this.props.onBlur) this.props.onBlur({state: this.state, target: this, defaultEvent: e},true);
    }

    inputFocusHandler() {
        this.setState({isFocused: true});
    }
}