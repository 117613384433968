import { DomComponent } from "../core/dom.component";
import HeaderComponent from "./header.component";
import NumberStepperComponent from "./number-stepper.component";
import classNames from "classnames";
import { LoadingController } from '../controllers/loading.controller';

export default class ProductShopBoxComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-product-shop-box');
        this.isAvailableForCart = true;
        this.isAvailableForShipping = true;
        this.isAddedToCart = false;
        this.isAddedToList = false;
        this.isOutOfStock = false;
        this.product = null;
        this.relatedCartItem = null;
        this.addButton = null;
        this.quantityStepper;
        this.quantity = 1;

        this.cartService = this.app.cartService;
        this.loadingController = this.app.getController('loading');
        
        this.init();
    }

    init() {
        let self = this;
        // this.sticky = new hcSticky(this.domElement, {
        //     stickTo: '.p-product-detail__primary-sections',
        //     stickyClass: this.getSelector('--sticky', ''),
        //     followScroll: false,
        //     top: 25,
        //     bottomEnd: 0
        // });
        this.app.getController('layout').header.addEventListener(HeaderComponent.EVENT_SHRINK, (event)=>{
            if(event.target) {
                this.updateTopOffset(event.target.isShrink);
            }
        });
        
        this.product = this.getJSON('product');

        this.findChild(this.getSelector(' .c-number-stepper')).then( element => {
            this.quantityStepper = new NumberStepperComponent(this.app, element,this.app.settings.numberstepper);
            this.quantityStepper.addEventListener(NumberStepperComponent.EVENT_CHANGE, this.quantityChangeHandler.bind(this));
        }).catch(reason => this.handleSelectorError(reason));
        this.findChild(this.getSelector(' [href="#add-to-cart"]')).then( element => {
            this.addButton = element;
            element.onclick = this.addToCartClickHandler.bind(this);
        }).catch( reason => this.handleSelectorError(reason) );

        this.findChild(this.getSelector(' .c-product-shop-box__free-shipping')).then( element => {
            // this.freeShippingBox = ReactDOM.render(<FreeShippingBoxComponent app={this.app}/>, element);
        }).catch( reason => this.handleSelectorError(reason) );

        this.loadingController.addEventListener(LoadingController.EVENT_CHANGE, this.loadingChangeHandler.bind(this));
        this.setComponentClass();
    }

    loadingChangeHandler() {
        this.setComponentClass();
    }

    setComponentClass(){
        this.isAvailableForCart = this.product.is_salable != false;
        this.isAvailableForShipping = this.product.is_available_for_shipping != false;
        this.isOutOfStock = this.product.in_stock === false;

        let className = classNames(
            this.getComponentClass(),
            {
                [this.getSelector('--not-available-for-shipping', '')]: !this.isAvailableForShipping,
                [this.getSelector('--added-to-list', '')]: this.isAddedToList,
                [this.getSelector('--added-to-cart', '')]: this.isAddedToCart,
                [this.getSelector('--not-available-for-cart', '')]: !this.isAvailableForCart,
                [this.getSelector('--processing-cart', '')]: this.loadingController.getLoadingStatus('add-to-cart'),
                [this.getSelector('--out-of-stock', '')]: this.isOutOfStock,
            }
        )
        // if(this.addButton) this.isProcessing ? this.addClass('c-button--processing', this.addButton) : this.removeClass('c-button--processing', this.addButton);
        this.setClass(className)
    }

    updateTopOffset(byHeader = false) {
        // let offsetTop = 25;
        // if(byHeader) {
        //     this.app.findChild('.c-header__content-bar').then( element => {
        //         offsetTop = element.offsetHeight + (this.app.getController('layout').header.searchBarEnabled ? 85 : 25);
        //         this.sticky.update({
        //             top: offsetTop
        //         });
        //     });
        // }else{
        //     this.sticky.update({
        //         top: offsetTop
        //     });
        // }
    }

    setRelatedCartItem(cartItem) {
        if(!this.product) return;
        this.relatedCartItem = cartItem;
        this.isAddedToCart = this.relatedCartItem && this.relatedCartItem['sku'] == this.product.sku;
        this.loadingController.removeLoading('add-to-cart');
        this.setComponentClass();
    }
    
    quantityChangeHandler(e) {
        this.quantity = this.quantityStepper.value;
    }

    addToCartClickHandler(e) {
        if(!this.product) return;
        e.preventDefault();
        e.stopPropagation();
        this.loadingController.addLoading('add-to-cart');
        this.cartService.addToCart(this.product, this.quantity);
        if(this.quantityStepper) this.quantityStepper.setValue(1);
    }

    destroy() {
        
    }

}