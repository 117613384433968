import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';

export default class FileUploadComponent extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);

        this.i18nScopeKey = 'FILE_UPLOAD';
        this.dropzone = null;

        this.renderStore = {
            componentClassName: null
        }

        this.state = {
            files: null,
            value: null,
            settings: props.settings || {}
        }   
    }

    get hasFile() { return this.state.files && this.state.files.length > 0 }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
    }
    
    componentDidMount() {
        if(!this.state.settings.uploadFileLabel) this.setState({
            settings: {...this.state.settings, uploadFileLabel: this.i18nService.translate([this.i18nScopeKey, 'UPLOAD_FILE_LABEL'])}
        });
    }
  
    componentWillUnmount() {

    }

    render(){
        let file = this.state.files ? this.state.files[0] : null;
        let extension = file ? this.getFileExtension(file.name) : null;
        this.renderStore.componentClassName = classNames(
            'c-file-upload',
            {
                'c-file-upload--has-file': this.state.value && this.state.value.length  > 0
            }
        );
        return (
            <div className={this.renderStore.componentClassName}>
                <div className="c-file-upload__container">
                    {file && <div className="c-file-upload__preview">
                        <div className="c-file-upload__file">
                            {extension && 
                                <span className="c-file-upload__file-type">
                                    {!this.state.isProcessing && <i className="icon__fsd icon__fsd--file-gray"></i>}
                                    {this.state.isProcessing && <i className="icon__fsd icon__fsd--loading-dark"></i>}
                                </span>}
                            <span className="c-file-upload__file-name">{this.state.settings.fileName || file.name}</span>
                        </div>
                        {!this.state.isProcessing && <div className="c-file-upload__action">
                            <a onClick={this.removeButtonClickHandler.bind(this)} className="c-button c-button--danger c-button--anchor c-button--sm">
                                <i className="icon__fsd icon__fsd--trash-danger"></i>
                                <span>{this.i18nService.translate([this.i18nScopeKey, 'DELETE'])}</span>
                            </a>
                        </div>}
                    </div>}
                    <Dropzone ref={r => this.dropzone = r} className="c-file-upload__dropzone" onDrop={this.onChange.bind(this)}>
                        <i className="icon__fsd icon__fsd--plus-thin-primary"></i>
                        <span>{this.state.settings.uploadFileLabel}</span>
                    </Dropzone>
                </div>
            </div>
        )
    }

    reset() {
        if(this.state.files && this.dropzone) {
            this.dropzone.fileInputEl.value = null;
            this.app.trigger('change', null, this.dropzone.fileInputEl);    
            this.dropzone.setState({
                acceptedFiles: null,
                rejectedFiles: null
            });
        }
        this.setState({
            files: null,
            value: null,
            isProcessing: false
        });
    }

    onChange(files) {
        let value = files && files.length ? files[0].name : null;
        this.setState({
            files,
            value
        }, this.props.onChange ? this.props.onChange.bind(null, files, value) : null)
    }

    removeButtonClickHandler() {
        if(this.props.onRemove) this.props.onRemove(this.state.value, this.state.files);
    }

    getFileExtension(filename) {
        var ext = /^.+\.([^.]+)$/.exec(filename);
        return ext == null ? "" : ext[1];    
    }
}