
import { DomComponent } from "../core/dom.component";
import classNames from "classnames";

export default class OrderDetailComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-order-detail');
        this.items = [];
        this.init();
    }

    init() {
        let self = this;
        this.findChildren(this.getSelector('__item')).then( itemElements => {
            let itemComponent;
            this.items = [];
            itemElements.forEach( itemElement => {
                itemComponent = new DomComponent(this.app, itemElement, this.getSelector('__item', ''));
                itemComponent.findChild('.c-responsive-line').then( responsiveLineElement => itemComponent.responsiveLine = responsiveLineElement);
                
                itemComponent.init = function() {
                    this.expanded = false;
                    this.findChild(this.getSelector('-detail-btn')).then( detailBtn => {
                        this.detailBtn = detailBtn;
                        this.detailBtn.onclick = self.detailBtnClickHandler.bind(self, this);
                    });
                }

                itemComponent.setComponentClass = function() {
                    let className = classNames(
                        this.getSelector('', ""),
                        {
                            [this.getSelector('--expanded', '')]: this.expanded
                        }
                    );
                    this.setClass(className);
                }
                itemComponent.expand = function() {
                    this.expanded = true;
                    this.setComponentClass();
                };
                itemComponent.collapse = function() {
                    this.expanded = false;
                    this.setComponentClass();
                };
                itemComponent.init();
                this.items.push(itemComponent);
            });
        });
    }

    setItemsClass() {
        let className = classNames(
            this.getSelector('', "")
        );
        this.setClass(className);
    }

    detailBtnClickHandler(itemComponent, e) {
        itemComponent.expanded ? itemComponent.collapse() : itemComponent.expand();
    }
}