import BaseReactComponent from "../core/base-react.component";
import React, { Component } from "react";
import UnbxdService from "../services/unbxd.service";
import MageService from "../services/mage.service";
import classNames from "classnames";
import { SearchSideBarComponent } from "./search-side-bar.component";
import { ViewportController } from "../controllers/viewport.controller";
import PatnerService from "../services/partner.store.service";
import Rating from "./rating.react";
import Cookies from "universal-cookie";

let cookies = new Cookies();
export default class SearchResult extends BaseReactComponent {
  constructor(props, context) {
    super(props, context);

    this.renderStore = {
      products: null,
      settings: null,
      pagination: null,
      sortOptions: null,
      sidebar: null,
    };

    this.state = {
      isMobile: false,
      response: null,
      isLoading: true,
      page: 0,
      pageSize: 20,
    };

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.i18nScopeKey = "SEARCH_RESULT_PAGE";
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.unbxdSearchService = this.app.unbxdSearchService;
    this.unbxdService = this.app.unbxdService;
    this.queryString = this.props.queryString;
    this.noResultImage = this.props.noResultImage;
    this.searchController = this.app.getController("search");
    this.viewportController = this.app.getController("viewport");
    this.routerController = this.app.getController("router");
    this.searchService = this.searchController.searchService;
    this.categoryService = this.app.categoryService;
    this.unbxdSearchService = this.app.unbxdSearchService; // step # 1
    // this.cartService = this.app.cartService;
    this.pageNumbers = [];
    this.paginationVisiblePageCount = 7;
    this.sortValues = [];
    this.selectedCategories = [];
    this.selectedFilters = [];
    this.queryParams = {
      p_size: 20,
    };
    this.isFirstTime = true;
    this.todayDate = new Date();

    this.setSortValues();

    this.viewportBreakpointChangeProxyListener =
      this.viewportController.addEventListener(
        ViewportController.EVENT_BREAKPOINT_CHANGE,
        this.viewportBreakpointChangeHandler.bind(this)
      );
  }

  componentDidMount() {
    this.mageService = this.app.mageService;
    this.partnerService = this.app.partnerService;

    this.partnerService.addEventListener(
      PatnerService.EVENT_STORE_CHANGE,
      this.callSearch.bind(this)
    );
    console.log("search query url", this.queryString);

    if (cookies.get("storesearchstring") === this.queryString) {
      if (cookies.get("storesearchpagination")) {
        this.setState(
          {
            page: cookies.get("storesearchpagination"),
          },
          () => {
            this.goToPage(this.state.page);
          }
        );
      }
    } else {
      if (cookies.get("storesearchpagination")) {
        this.setState(
          {
            page: 0,
          },
          () => {
            this.goToPage(this.state.page);
          }
        );
      }
    }

    if (this.mageService.setData) this.mageConfigReadyHandler();
    else
      this.mageService.addEventListener(
        MageService.EVENT_READY,
        this.mageConfigReadyHandler.bind(this)
      );
  }

  mageConfigReadyHandler() {
    this.sendRequestForSearch(this.queryParams);
  }
  callSearch() {
    this.isFirstTime = false;
    this.sendRequestForSearch(this.queryParams);
  }
  render() {
    if (this.sortValues && this.sortValues.length > 0) {
      let sortOptions = null;
      this.renderStore.sortOptions = this.sortValues.map((sortValue, index) => {
        return (
          <option
            key={index}
            value={sortValue.value}
            selected={!sortValue.value}
          >
            {this.i18nService.translate([this.i18nScopeKey, sortValue.title])}
          </option>
        );
      });
    }

    if (
      this.state.response &&
      this.state.response.response &&
      this.state.response.response.products &&
      this.state.response.response.products.length > 0
    ) {
      this.renderStore.products = this.state.response.response.products.map(
        (product) => {
          const {special_price,special_from_date,special_to_date,price , liquidation_expiry_date,  new_arrivals_expiry_date , liquidation , new_arrivals , ships_in_days , product_review , flags}= product;
          const getDateArrivals = new_arrivals_expiry_date == null ? null : new_arrivals_expiry_date.split(' ')[0];
          const getDateClearance = liquidation_expiry_date == null ? null : liquidation_expiry_date.split(' ')[0];
          const currentPrice  =  this.unbxdSearchService.getSearchCurrentPrice(special_price,special_from_date,special_to_date,price);
          const oldPrice  =  this.unbxdSearchService.getSearchOldPrice(special_price,special_from_date,special_to_date,price);
          const showClearance = this.unbxdSearchService.isShowClearanceTagSearch(flags , getDateClearance)
          const showArrival = this.unbxdSearchService.isShowArrivalTagSearch(flags , getDateArrivals)
          const shipInDays = this.unbxdSearchService.getShipInDays(ships_in_days)
          return (
            <div
              key={product.uniqueId}
              className="p-products__list-item partner-products__list-item col-xl-5 col-md-10"
            >
              <div
                className={classNames("c-product-card", {
                  "c-product-card--shrink": this.state.isMobile,
                })}
                json-product={`{'sku': '${product.sku}','vendor_id': 4 }`}
              >
                <div className="c-product-card__container">
                  <div className="c-product-card__preview">
                    <div
                      href={product.slug}
                      className="c-product-card__preview-slider"
                    >
                      <div className="c-preview-image-slider">
                        <div className="c-preview-image-slider__container swiper-container">
                          <div className="c-preview-image-slider__content swiper-wrapper">
                            <div className="c-preview-image-slider__slide swiper-slide">
                              <img
                                alt={product.title}
                                onError={this.addDefaultProductImage}
                                src={product["gallery.thumbnail"]?.[0] || product?.gallery?.[0]?.thumbnail}
                              />
                            </div>
                          </div>
                          <div className="c-preview-image-slider__pagination swiper-pagination" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="c-product-card__content">
                    <div class="c-product-card_additional-flags">
                      {showClearance && (
                        <div class="c-product-card_liquidation-flag">
                          <span>CLEARANCE</span>
                        </div>
                      )}
                      {showArrival && (
                        <div class="c-product-card_newarrivals-flag">
                          <span>NEW ARRIVALS</span>
                        </div>
                      )}
                      {product.shipping_type === "Ships Free" ? (
                        <div class="c-product-card_free-shipping-flag">
                          {" "}
                          <span>FREE SHIPPING</span>{" "}
                        </div>
                      ) : null}
                        {!product.is_in_stock  && (
                        <div class="c-product-card_out-of-stock-flag">
                          {" "}
                          <span>OUT OF STOCK</span>{" "}
                        </div>
                      )}

                      {flags.includes("rebate_eligible") &&
                        <div class="c-product-card_out-of-stock-flag bg-reb">
                              <span>REBATE-ELIGIBLE</span>
                        </div>
                      }
                      
                    </div>
                    <div className="c-product-card__title">
                      <a href={product.slug}>{product.name}</a>
                    </div>
                    <div className="c-product-card__ship-info">
                    { product.stock_type === "Special Order" ? 
                      <span className="c-product-card__ship-info-text">
                        Ready to ship within{" "}
                      <span className="c-product-card__special-order-ship-info-text">
                     {shipInDays}
                     </span>
                    </span> : <span className="c-product-card__ship-info-text">
                    Ready to ship within{" "}
                     <span className="c-product-card__ship-info-day">
                    {shipInDays}
                    </span>
                    </span>  }
                    </div>
                    {product.attributes?.product_review
                      ?.rating_avg &&
                      product.attributes?.product_review
                        ?.review_count && (
                        <div className="d-flex">
                          <Rating
                            size={"medium"}
                            rating={
                              // product["attributes.product_review.rating_avg"]
                              product.attributes?.product_review?.rating_avg
                              // this.state.product_reviews[product.uniqueId]
                              //   .rating_avg
                            }
                          />
                          <span className="c-product-shop-box__review-count">
                            (
                            {
                              // this.state.product_reviews[product.uniqueId]
                              //   .review_count
                              // product["attributes.product_review.review_count"]
                              product.attributes?.product_review?.review_count
                            }
                            )
                          </span>
                        </div>
                      )}
                    <div className="c-product-card__footer">
                      <div className="c-product-card__footer-left">
                        <div className="c-product-card__price">
                        {oldPrice ? <span className="c-product-card__price-old">
                              {this.i18nService.format(oldPrice, [
                                "number",
                                "currency",
                              ])}
                            </span> : null} 
                        <span className="c-product-card__price-current">
                          {this.i18nService.format(currentPrice, [
                            "number",
                            "currency",
                          ])}
                          <span class="c-product-card__sold-as">/{product.sold_as}</span>
                        </span> 
                        </div>
                      </div>
                      <div className="c-product-card__footer-right">
                        <button className="c-product-card__add-button c-button c-button--icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      );

      if (
        this.state.response &&
        this.state.response.response &&
        this.state.response.response.didYouMean &&
        this.state.response.response.didYouMean.length > 0
      ) {
        this.renderStore.didYouMean = (
          <div className="c-list-view-settings__did-you-mean">
            <span
              dangerouslySetInnerHTML={{
                __html: this.i18nService.translate(
                  [this.i18nScopeKey, "DID_YOU_MEAN_LABEL"],
                  {
                    didYouMeanString:
                      this.state.response.response.didYouMean[0].suggestion,
                    didYouMeanUrl: this.didYouMeanUrl(
                      this.state.response.response.didYouMean[0].suggestion
                    ),
                  }
                ),
              }}
            ></span>
          </div>
        );
      } else if (
        this.state.response &&
        this.state.response.searchMetaData &&
        this.state.response.searchMetaData.fallback
      ) {
        this.renderStore.didYouMean = (
          <div className="c-list-view-settings__did-you-mean">
            <span
              dangerouslySetInnerHTML={{
                __html: this.i18nService.translate(
                  [this.i18nScopeKey, "DID_YOU_MEAN_LABEL"],
                  {
                    didYouMeanString:
                      this.state.response.searchMetaData.fallback.q,
                    didYouMeanUrl: this.didYouMeanUrl(
                      this.state.response.searchMetaData.fallback.q
                    ),
                  }
                ),
              }}
            ></span>
          </div>
        );
      }

      this.renderStore.settings = (
        <div className="p-products__list-settings">
          <div className="c-list-view-settings">
            <div className="c-list-view-settings__container">
              <div className="c-list-view-settings__container-left">
                <div className="c-list-view-settings__filter-button">
                  <a href="#show-search-filters">
                    <i className="icon__fsd icon__fsd--filter-variant-primary" />
                    {this.i18nService.translate([
                      this.i18nScopeKey,
                      "SHOW_FILTERS_LABEL",
                    ])}
                  </a>
                </div>
                {this.renderStore.didYouMean && this.renderStore.didYouMean}
                <div className="c-list-view-settings__list-info">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.i18nService.translate(
                        [this.i18nScopeKey, "SHOWING_LABEL"],
                        {
                          currentPage:
                            this.state.page * this.state.pageSize + 1,
                          pageSize:
                            (this.state.page + 1) * this.state.pageSize <=
                            this.state.response.response.numberOfProducts
                              ? (this.state.page + 1) * this.state.pageSize
                              : this.state.response.response.numberOfProducts,
                          numberOfProducts:
                            this.state.response.response.numberOfProducts,
                          queryString: this.queryString,
                        }
                      ),
                    }}
                  ></span>
                </div>
              </div>
              <div className="c-list-view-settings__container-right">
                <div className="c-list-view-settings__sort">
                  <span className="c-list-view-settings__sort-title">
                    <b>
                      {this.i18nService.translate([
                        this.i18nScopeKey,
                        "SORT_BY_LABEL",
                      ])}
                    </b>
                  </span>
                  <div className="c-list-view-settings__sort-selectbox c-list-view-settings__sort-selectSearchbox ">
                    <select
                      dir="rtl"
                      onChange={this.inputChangeHandler}
                      ref={(r) => {
                        this.sortSelect = r;
                      }}
                      value={this.queryParams.sort}
                    >
                      {this.renderStore.sortOptions}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      let numberOfProducts = this.state.response.response.numberOfProducts;
      let lastPage = Math.floor(
        numberOfProducts / this.state.pageSize
      );
      if(this.state.page + Math.floor(this.paginationVisiblePageCount / 2) > Math.floor(numberOfProducts / this.state.pageSize) && !(numberOfProducts % this.state.pageSize) ) {
        lastPage = lastPage-1;
      }

      this.renderStore.pagination = (
        <div className="c-pagination">
          <div className="c-pagination__container">
            {this.state.page > 0 && (
              <div
                className="c-pagination__left"
                onClick={this.goToPage.bind(this, this.state.page - 1)}
              >
                <span className="c-pagination__navigate">
                  <i className="icon__fsd icon__fsd--arrow-left-primary" />
                </span>
              </div>
            )}
            <div className="c-pagination__middle">
              <ul className="c-pagination__numbers">
                {this.pageNumbers.map((pageNo) => {
                  return (
                    <li
                      key={pageNo}
                      onClick={this.goToPage.bind(this, pageNo)}
                      className={classNames("c-pagination__number", {
                        "c-pagination__number--active":
                          this.state.page == pageNo,
                      })}
                    >
                      <a href="javascript:void(0)">{pageNo + 1}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
            {this.state.page < lastPage && (
              <div
                className="c-pagination__right"
                onClick={this.goToPage.bind(this, this.state.page + 1)}
              >
                <span className="c-pagination__navigate">
                  <i className="icon__fsd icon__fsd--arrow-right-primary" />
                </span>
              </div>
            )}
          </div>
        </div>
      );

      this.renderStore.sidebar = (
        <SearchSideBarComponent
          ref={(el) => (this.searchSideBar = el)}
          app={this.app}
          onClick={this.categoryClickHandler.bind(this)}
          filterOnClick={this.filterOnClick.bind(this)}
          isAccordion={this.state.isMobile}
          contentJson={this.state.response}
        ></SearchSideBarComponent>
      );
    } else {
      // no product
      this.renderStore.products = (
        <div className="p-products__columns row justify-content-center">
          <div className="p-products__col p-products__col--main col-lg-14 col-md-20">
            <div className="page__sections">
              <div className="page__section p-products__no-result">
                <div className="p-products__section-caption p-products__section-caption--no-result">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.i18nService.translate([
                        this.i18nScopeKey,
                        "SHOWING_NO_PRODUCT_LABEL",
                      ]),
                    }}
                  ></span>
                </div>
                <div className="page__section-content">
                  <div className="p-products__no-result-container">
                    <div className="p-products__no-result-content">
                      <h3 className="p-products__no-result-title">
                        {this.i18nService.translate(
                          [this.i18nScopeKey, "NO_RESULT_LABEL"],
                          { queryString: this.queryString }
                        )}
                      </h3>
                      <img
                        className="p-products__no-result-image"
                        src={this.noResultImage.url}
                        alt="no-result"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    this.renderStore.loading = (
      <div className="c-loading-spinner c-loading-spinner--large"></div>
    );

    return (
      <div className="p-products" style={{ width: 100 + "%" }}>
        {this.state.response &&
          this.state.response.response &&
          this.state.response.response.products &&
          this.state.response.response.products.length > 0 && (
            <div className="p-products__columns row">
              <div className="p-products__col p-products__col--main col-lg-15 col-md-20">
                <div className="page__sections">
                  <div className="page__section p-products__list">
                    <div className="page__section-caption">
                      <div className="page__section-caption-title">
                        {this.i18nService.translate([
                          this.i18nScopeKey,
                          "SEARCH_RESULTS_LABEL",
                        ])}
                      </div>
                    </div>
                    <div className="page__section-content">
                      {this.renderStore.settings && this.renderStore.settings}
                      {this.renderStore.products && (
                        <div className="p-products__list-items row">
                          {this.renderStore.products}
                        </div>
                      )}
                      {this.renderStore.pagination && (
                        <div className="p-products__list-view-more">
                          {this.renderStore.pagination}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-products__col p-products__col--aside col-lg-5 col-md-20 pr-5">
                {this.renderStore.sidebar &&
                  !this.state.isMobile &&
                  this.renderStore.sidebar}
              </div>
            </div>
          )}
        {this.state.response &&
          this.state.response.response &&
          this.state.response.response.products &&
          this.state.response.response.products.length == 0 &&
          this.renderStore.products}
        {(!this.state.response ||
          !this.state.response.response ||
          !this.state.response.response.products) &&
          this.renderStore.loading}
      </div>
    );
  }

  calculatePageNumbers(numberOfProducts) {
    let additionalPages = Math.floor(this.paginationVisiblePageCount / 2);
    let pageStartIndex = 0;
    this.pageNumbers = [];
    let i =
      this.state.page == 0 || this.state.page - additionalPages < 0
        ? pageStartIndex
        : this.state.page - additionalPages;
    let limit =
      this.state.page + additionalPages <
      Math.floor(numberOfProducts / this.state.pageSize)
        ? this.state.page + additionalPages
        : Math.floor(numberOfProducts / this.state.pageSize);
        
    if(this.state.page + additionalPages > Math.floor(numberOfProducts / this.state.pageSize) && !(numberOfProducts % this.state.pageSize) ) {
      limit = limit-1;
    }
    for (i; i <= limit; i++) {
      this.pageNumbers.push(i);
    }
  }

  addDefaultProductImage(ev) {
    ev.target.src =
      "https://drryor7280ntb.cloudfront.net/media/catalog/product/placeholder/default/foodservicedirect-product-th-placelolder_1.png";
  }

  resetQueryParams() {
    this.setState({
      p_size: 20,
    });
  }

  sendRequestForSearch(queryParams = {}) {
    if (!this.queryString) return;
    // if (this.unbxdService.isReady) {
      if (!this.app.settings.isPartnerStore || !this.isFirstTime) {
        this.search(queryParams);
      }
    // } 
    // else {
    //   this.unbxdService.addEventListener(
    //     UnbxdService.EVENT_READY,
    //     this.search.bind(this, queryParams)
    //   );
    // }
  }

  search(queryParams) {
    cookies.set("storesearchstring", this.queryString, {
      path: "/",
    });

    this.queryParams = { ...this.queryParams, ...queryParams };
    this.unbxdSearchService
      .search(this.queryString, this.queryParams)
      .then((response) => {
        if (this.unbxdRedirection(response)) {
          return;
        }
        if (
          response.response &&
          response.response.products &&
          response.response.products.length == 0
        ) {
          this.redirectToNoResult();
          return;
        }
        this.calculatePageNumbers(response.response.numberOfProducts);
        this.setState(
          {
            isLoading: false,
            response: response,
          },
          () => {
            this.app.getController("products").update();
            // let sku = "";
            // this.state.response.response &&
            //   this.state.response.response.products &&
            //   this.state.response.response.products.map((product) => {
            //     sku = (!sku ? sku : sku + ",") + product.uniqueId;
            //     return product.uniqueId;
            //   });
            // if (sku) {
            //   this.categoryService.getProductImages(sku).then((products) => {
            //     let images = {};
            //     let product_reviews = {};
            //     products.map(({ sku, image, product_review }) => {
            //       images[sku] = image;
            //       product_reviews[sku] = product_review;
            //     });
            //     this.setState({ images, product_reviews });
            //   });
            // }
            if (this.searchSideBar) {
              this.searchSideBar.setState({
                contentJson: response,
              });
            }
          }
        );
      });
  }

  goToPage(page) {
    console.log("search page no", page);
    cookies.set("storesearchpagination", page, {
      path: "/",
    });
    this.sendRequestForSearch({
      ...this.queryParams,
      p: parseInt(page) + 1,
    });
    this.setState(
      {
        page: parseInt(page),
      },
      () => {
        window.scrollTo(0, 0);
      }
    );
  }

  inputChangeHandler(el) {
    let value = this.sortSelect.value;
    this.queryParams = { ...this.queryParams, page: 0, sort: value };
    this.sendRequestForSearch(this.queryParams);
  }

  setSortValues() {
    this.sortValues = [
      // {
      //   title: "PRODUCT_NAME_ASC",
      //   value: "name:asc",
      // },
      // {
      //   title: "PRODUCT_NAME_DESC",
      //   value: "name:desc",
      // },
      {
        title: "PRICE_DESC",
        value: "price:desc",
      },
      {
        title: "PRICE_ASC",
        value: "price:asc",
      },
      {
        title: "SHIPS_IN_ASC",
        value: "ships_in:asc",
      },
      {
        title: "SHIPS_IN_DESC",
        value: "ships_in:desc",
      },
      {
        title: "RELEVANCE",
        value: "",
      },
    ];
  }

  didYouMeanUrl(didYouMeanString) {
    this.queryString = didYouMeanString;
    return this.searchService.resultUrl + "?q=" + this.queryString;
  }

  categoryClickHandler(category) {
    let queryParams = this.unbxdSearchService.categoryClickHandler(category);
    // queryParams.start = 0;
    cookies.set("storesearchpagination", 0, {
      path: "/",
    });
    this.setState(
      {
        page: 0,
      },
      () => {
        this.sendRequestForSearch(queryParams);
      }
    );
  }

  filterOnClick(filter) {
    let queryParams = this.unbxdSearchService.filterOnClick(filter);
    // queryParams.start = 0;
    cookies.set("storesearchpagination", 0, {
      path: "/",
    });
    this.setState(
      {
        page: 0,
      },
      () => {
        this.sendRequestForSearch({
          ...queryParams,
          p: parseInt(this.state.page) + 1,
        });
      }
    );
  }

  viewportBreakpointChangeHandler() {
    this.setState({
      isMobile: this.viewportController.isMobile,
    });
  }

  changeContentFromAccordion(response) {
    if (!this.state.isMobile) return;
    this.setState({
      response: response,
    },()=>{
      this.app.getController("products").update();
    });
  }

  unbxdRedirection(response) {
    if (response.redirect) {
      const redirect = response.redirect;
      switch (redirect.type) {
        case "url":
          if (redirect.value) {
            window.location.href = redirect.value;
          }
          return true;
          break;
        default:
          return false;
          break;
      }
    }
  }

  redirectToNoResult() {
    let noResult = this.routerController.queryParams.get("no-result");
    if (noResult) {
      return;
    }

    let params = { "no-result": "1" };
    this.routerController.navigate(null, {
      queryParams: params,
      preserveQueryParams: true,
    });
  }
}
