import { BaseController } from "../core/base.controller";
import Navigo from "navigo";

export class RouterController extends BaseController {

    constructor(app) {
        super(app);
        this.router = new Navigo('');
        this.router.hooks({
            before: function(done, params) { 
              
            },
            after: function(params) { 
                
            }
        });
        this.queryParams = new URLSearchParams(window.location.search);
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
    }

    navigate(path, naviagationParams) {
        if(!naviagationParams) naviagationParams = {};
        if(naviagationParams.absolute === undefined) naviagationParams.absolute = true;
        if(naviagationParams.replaceUrl === undefined) naviagationParams.replaceUrl = true;
        if(naviagationParams.reload === undefined) naviagationParams.reload = true;
        let queryParams = new URLSearchParams();
        
        if(naviagationParams.queryParams) {
            if(naviagationParams.preserveQueryParams && this.queryParams) {
                for(let item of this.queryParams) {
                    this.queryParams.has(item[0]) ? queryParams.set(item[0], item[1]) : queryParams.append(item[0], item[1]);
                }
            }
            for(let key in naviagationParams.queryParams) {
                if(naviagationParams.queryParams[key] && naviagationParams.queryParams[key].length) {
                    queryParams.has(key) ? queryParams.set(key, naviagationParams.queryParams[key]) : queryParams.append(key, naviagationParams.queryParams[key]);
                }else{
                    if(queryParams.has(key)) queryParams.delete(key, naviagationParams.queryParams[key]);
                }
            }
        }
        let url = window.location.origin;
        if(path) {
            if(Array.isArray(path)) path = path.join('/');
            if(path.substr(0, 1) != '/') path = '/' + path;
            url += path;
        }else{
            url += window.location.pathname;
        }
        let queryString = queryParams.toString();
        if(queryString && queryString.length) {
            url += "?" + queryString;
            this.setQueryParams(queryParams);
        }
        if(naviagationParams.replaceUrl) this.router.navigate(url, naviagationParams.absolute);
        if(naviagationParams.reload) window.location.reload();
    }

    setQueryParams(urlSearchParams) {
        this.queryParams = urlSearchParams;
        this.dispatchEvent(RouterController.EVENT_QUERY_PARAMS_CHANGE);
    }

    href(path, absolute) {
        // this.router.navigate(path, absolute);
        window.location.href = path;
        // window.location.reload();
    }
}

RouterController.EVENT_QUERY_PARAMS_CHANGE = "query-params-change";