import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';
import CategoryService from '../services/category.service';
import { ViewportController } from '../controllers/viewport.controller';

export default class SearchBarComponent extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);
        
        this.i18nScopeKey = 'CATALOG_SEARCH_BAR';
        
        this.searchInput = null;
        this.categorySelect = null;
        this.wrapperRef = null;
        this.initialValue = props.value;

        this.renderStore = {
            componentClass: null,
            searchResultListLeft: null,
            searchResultListRight: null,
            noResult: null,
            history: null
        }

        this.clearSearch = {
            componentClass: null
        }

        let defaultSettings = {
            isWide: false
        }
        let searchFilterValue,searchFilterCategory  ;
        console.log("searchFilterCategoryyyy", sessionStorage.getItem('searchFilterCategory'))
        if(this.app.settings.isPartnerStore){
            sessionStorage.setItem('searchFilterValue',sessionStorage.getItem('searchFilterValue') || 'fsd');
            searchFilterValue = sessionStorage.getItem('searchFilterValue');
        }else{
            sessionStorage.setItem('searchFilterCategory',sessionStorage.getItem('searchFilterCategory') || '');
            searchFilterCategory = sessionStorage.getItem('searchFilterCategory');
        }
        this.state = {
            isMobile: false,
            isFocused: false,
            isProcessing: false,
            settings: props.settings || defaultSettings,
            searchResult:  null,
            searchValue: null,
            selectedCategory: 0,
            clearButtonIsActive: false,
            isSuggestionOpen: false,
            hasValue: false,
            histories: [],
            searchBarValue: '',
            searchFilterValue,
            searchFilterCategory
        }
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
        this.layoutController = this.app.getController('layout');
        this.viewportController = this.app.getController('viewport');
        this.searchController = this.app.getController('search');
        this.searchService = this.searchController.searchService;
        this.categoryService = this.app.categoryService;
        this.routerController = this.app.getController('router');
        this.unbxdSearchService = this.app.unbxdSearchService;
        this.unbxdService = this.app.unbxdService;

        this.categoryService.addEventListener(CategoryService.EVENT_CATEGORIES_CHANGE, this.categoriesChangeHandler.bind(this));
        this.viewportBreakpointChangeProxyListener = this.viewportController.addEventListener(ViewportController.EVENT_BREAKPOINT_CHANGE, this.viewportBreakpointChangeHandler.bind(this));
        this.clickOutside = this.handleClickOutside.bind(this);
        
    }

    componentDidMount() {
        this.searchInputDebounced = _.debounce(this.searchInputChangeHandler.bind(this), 400);
        let searchQuery = this.routerController.queryParams.get('q');
        if(searchQuery && !this.initialValue) this.initialValue = searchQuery;
        if(this.initialValue) this.searchInput.value = this.initialValue;
        this.setCategoryById(null, true);
 
        // if(this.searchDrop && this.app.settings.isPartnerStore){
        //     this.setState({
        //         searchFilterValue: this.searchDrop.current.value
        //     }, () => {
        //         this.unbxdSearchService.selectedStore(this.state.searchFilterValue)
        //     })
        // }
     
    }
    chnageSearchFilter(e) {
        let searchFilterValue = e.target.value ;
       
        if(this.app.settings.isPartnerStore){
            if(sessionStorage.getItem('searchFilterValue')){
                this.setState({
                    searchFilterValue
                }, () => {
                    this.unbxdSearchService.selectedStore(this.state.searchFilterValue);
                    this.getUnbxdAutosuggest(this.state.searchBarValue);
                })
            }
        }else{
            this.setState({
                searchFilterCategory:searchFilterValue
            }, () => {
                sessionStorage.setItem('searchFilterCategory',searchFilterValue);
                this.unbxdSearchService.searchFilterCategory = this.state.searchFilterCategory;
                this.getUnbxdAutosuggest(this.state.searchBarValue);
            })
        }
        // console.log("select value ", e.target.value )
        
    }
    render() {
        if(!this.appIsReady) return null;
        // let hasResult = this.state.searchResult && this.state.searchResult.autosuggestRight.length > 0;

        this.renderStore.searchResultListLeft = null;
        this.renderStore.searchResultListRight = null;
        this.renderStore.history = null;
        if(this.state.searchResult) {
            this.open();          

            if(this.state.searchResult.autosuggestLeft){
                this.renderStore.searchResultListLeft = this.state.searchResult.autosuggestLeft.map( (searchKey, index) => {
                        return <li onClick={this.submitButtonClickHandler.bind(this, searchKey)} 
                        key={`suggestion-${index}`} className="c-header__autocomplete-list-item" 
                        data-test-id={`home-searchBar-link-autocompleteLeftItem${index}`}
                        >
                            {this.makeTextBold(searchKey)}
                        </li>
                });
                // this.renderStore.searchResultListLeft = this.state.searchResult.autosuggestLeft.map( (results, index) => {
                //     if(!results) return null;
                //     return results.map((result, rIndex) => {
                //         result.typeLabel = this.i18nService.translate([this.i18nScopeKey, 'UNBXD_SUGGEST_TYPE', result.typeId], null, null, true);          
                //         result.label = rIndex == 0 ? result.typeLabel : result.autosuggest;
                //         return <li onClick={rIndex != 0 ? this.submitButtonClickHandler.bind(this, result.autosuggest) : null} key={`suggestion-${rIndex}`} className={classNames('c-header__autocomplete-list-item', {'c-header__autocomplete-list-item--title': rIndex == 0})}>
                //             { rIndex == 0 ? result.label : this.makeTextBold(result.label)}
                //         </li>
                //     });                
                // });
            }     

            if(this.state.searchResult.autosuggestRight){
                this.renderStore.searchResultListRight = this.state.searchResult.autosuggestRight.slice(0,10).map( (resultItem, index) => {
                    const {special_price,special_from_date,special_to_date, price , status , flags }  = resultItem;
                    console.error("this is dropdown item", resultItem["gallery.thumbnail"]?.[0])
                    const currentPrice  =  this.unbxdSearchService.getSearchCurrentPrice(special_price,special_from_date,special_to_date,price);
                    const oldPrice  =  this.unbxdSearchService.getSearchOldPrice(special_price,special_from_date,special_to_date,price);
                    if(!resultItem) return null;
                    if(this.state.isMobile && index > 5) return null;
                    return  <li 
                    onClick={this.handleProductClick.bind(this,resultItem.slug)} 
                    key={index} className="c-header__autocomplete-product-item">
                                <div class="c-header__autocomplete-additional-flags">
                                {status !== "In Stock" &&  <div class="c-header__autocomplete-out-of-stock-flag"><span>OUT OF STOCK</span></div>  }
                                {flags.includes("rebate_eligible") &&  <div class="c-header__autocomplete-out-of-stock-flag bg-reb"><span>REBATE-ELIGIBLE</span></div>  }     
                                </div>
                                <div className="c-header__autocomplete-product-item-avatar">
                                    <img onError={this.addDefaultProductImage} src={resultItem["gallery.thumbnail"]?.[0] || resultItem?.gallery?.[0]?.thumbnail} alt={resultItem.name}/>
                                </div>
                                <div className="c-header__autocomplete-product-item-info">
                                    <h5 className="c-header__autocomplete-product-item-title" 
                                    data-test-id={`home-searchBar-text-autocompleteProductName${index}`}
                                    >{this.makeTextBold(resultItem.name)}</h5>
                                    <div className="c-header__autocomplete-product-item-price">
                                    { <span className="c-header__autocomplete-product-item-price-current">{this.i18nService.format(currentPrice, ['number', 'currency'])}</span> }
                                    { oldPrice ? <span className="c-header__autocomplete-product-item-price-old">{this.i18nService.format(oldPrice, ['number', 'currency'])}</span> : null}
                                    </div>
                                </div>
                            </li>
                });
            }

            let leftCount = 0;
            this.renderStore.searchResultListLeft.forEach( leftItem => {
                if(leftItem.length == 0){
                    leftCount++;
                }
            })
            this.renderStore.searchResultListLeft = leftCount == this.renderStore.searchResultListLeft.length ? null : this.renderStore.searchResultListLeft;
            this.renderStore.searchResultListRight = this.renderStore.searchResultListRight.length == 0 ? null : this.renderStore.searchResultListRight;

            if(!this.renderStore.searchResultListLeft && !this.renderStore.searchResultListRight) {
                this.renderStore.noResult = <div className="c-header__autocomplete-no-result" data-test-id="home-searchBar-text-noResult">
                    <a href="javascript:void(0)" onClick={this.gotoList.bind(this)}>{ this.i18nService.translate([this.i18nScopeKey, "NO_RESULT_LABEL"], { query: this.state.searchValue }) }</a>
                </div>
            }

        }else {     

            if(this.state.histories && this.state.histories.length > 0) {
                this.open(true);
                this.renderStore.history = <div className="c-header__autocomplete-history">
                    <ul className="c-header__autocomplete-list-items">
                        <li className="c-header__autocomplete-list-item c-header__autocomplete-list-item--title"
                        data-test-id="home-searchBar-text-historyTitle"
                        >
                            { this.i18nService.translate([this.i18nScopeKey, "HISTORY_LABEL"]) }
                        </li>
                        {   this.state.histories.map( (history,index) => {
                                return <li key={history.query} onClick={this.submitButtonClickHandler.bind(this, history.query)} 
                                data-test-id={`home-searchBar-link-historyItem${index}`}
                                className="c-header__autocomplete-list-item c-header__autocomplete-list-item--active">
                                    { history.query }
                                </li>
                            }) 
                        }
                    </ul>
                </div>
            }                
            
            this.renderStore.searchResultListLeft = null;        
            this.renderStore.searchResultListRight = null;        
        }

        return  <span ref={ wrapper => this.wrapperRef = wrapper}>
                    <div className="c-header__input-bar">
                        <div className="c-header__input-bar-input">
                                <input  ref={ r => this.searchInput = r } 
                                    type="text" 
                                    onFocus={this.searchIntputFocusHandler.bind(this)} 
                                    onBlur={this.searchIntputBlurHandler.bind(this)} 
                                    onKeyDown={this.searchInputKeyDownHandler.bind(this)} 
                                    onChange={this.searchInputDebounced}
                                    maxLength = {100}
                                    placeholder={this.i18nService.translate([this.i18nScopeKey, 'SEARCH_INPUT_PLACEHOLDER'])}
                                    data-test-id="home-searchBar-input-searchInput"
                                />
                           {this.state.clearButtonIsActive ? <i onClick={(e)=>this.clearSearchInput(e)} 
                            data-test-id="home-searchBar-btn-clearInput"
                           className="icon__fsd icon__fsd--close-primary c-header__input-bar-input__clear"></i> : "" }
                        </div>
                        {/* {this.app.settings.isPartnerStore && window.mageConfig.settings.unbxd && window.mageConfig.settings.unbxd.isEnabled ? <div className="c-header__input-bar-search-filter">
                            <select
                                ref={this.searchDrop}
                                value={this.state.searchFilterValue} 
                                defaultValue="fsd"
                                onChange={this.chnageSearchFilter.bind(this)}
                            >
                                <option selected value="focus">Focus Brands Store</option>
                                <option value="fsd">All Available Items</option>
                            </select>
                        </div> : null} */}
                        {/* {!this.state.isMobile && !this.app.settings.isPartnerStore && this.state.categories && this.state.categories.length ? <div className="c-header__input-bar-search-filter">
                            {
                                console.log("defaultValue", this.state.searchFilterCategory)
                            }
                            <select
                                //ref={this.searchDrop}
                                value={this.state.searchFilterCategory} 
                                defaultValue={this.state.searchFilterCategory}
                                onChange={this.chnageSearchFilter.bind(this)}
                            >
                                <option selected value="focus">Focus Brands Store</option>
                                <option value="">All Departments</option>
                                {this.state.categories.map(({name})=>
                                    <option value={name}>{name}</option>
                                )} 
                            </select>
                        </div> : null} */}
                        <div className="c-header__input-bar-search-button">
                            <button onClick={this.submitButtonClickHandler.bind(this, null)} 
                            data-test-id="home-searchBar-btn-submitSearch"
                            className={classNames('c-button c-button--primary c-button--icon', {'c-button--processing': this.state.isProcessing})}><i className="icon__fsd icon__fsd--search-gray"></i></button>
                        </div>
                        {this.state.isMobile && <div className="c-header__autocomplete">
                            <div className="c-header__autocomplete-container">
                                { this.renderStore.history && this.renderStore.history }
                                { this.renderStore.noResult && this.renderStore.noResult }
                                { this.renderStore.searchResultListLeft && <div className="c-header__autocomplete-left">
                                        <ul className="c-header__autocomplete-product-items">
                                            {/* {this.renderStore.searchResultListRight} */}
                                            {this.renderStore.searchResultListLeft}
                                        </ul>
                                        {/* <div className="c-header__autocomplete-right-action">
                                            <a onClick={this.submitButtonClickHandler.bind(this, null)} href="javascript:void(0)">{this.i18nService.translate([this.i18nScopeKey, 'VIEW_ALL_LABEL'])}</a>
                                        </div> */}
                                    </div>
                                }                                
                            </div>
                        </div>}
                    </div>
                    <div className="c-header__autocomplete">
                        {!this.state.isMobile && <div className="c-header__autocomplete-container">
                            { this.renderStore.history && this.renderStore.history }
                            { this.renderStore.noResult && this.renderStore.noResult }
                            { this.renderStore.searchResultListLeft && <div className="c-header__autocomplete-left">
                                    <ul className="c-header__autocomplete-list-items">
                                        {this.renderStore.searchResultListLeft}
                                    </ul>
                                </div>
                            }     
                            { this.renderStore.searchResultListRight && <div className="c-header__autocomplete-right">
                                    <ul className="c-header__autocomplete-product-items">
                                        {this.renderStore.searchResultListRight}
                                    </ul>
                                    <div className="c-header__autocomplete-right-action">
                                        <a onClick={this.submitButtonClickHandler.bind(this, null)}
                                        data-test-id="home-searchBar-link-autoCompleteViewlAll"
                                         href="javascript:void(0)">{this.i18nService.translate([this.i18nScopeKey, 'VIEW_ALL_LABEL'])}</a>
                                    </div>
                                </div>
                            }                       
                        </div>}
                    </div>                    
                </span>        
    }

    setCategoryById(id, forceGetFromQueryParams, forceToChange) {
        if(!this.state.categories) return;
        if(!id && forceGetFromQueryParams) id = this.routerController.queryParams.get('cat');
        let existCategory = this.state.categories.find( item => item.id == id);
        if(existCategory && this.categorySelect) this.categorySelect.value = existCategory.id;
        if(forceToChange) this.categorySelectChangeHandler();
    }

    open(isHistory = false){
        if(this.searchService.searchType != 'readonly') return;
        this.layoutController.header.isAutosuggested = !isHistory;
        this.layoutController.header.shouldShowHistory = (isHistory && this.state.histories && this.state.histories.length > 0);
        this.layoutController.header.setComponentClass();

        if(this.state.isSuggestionOpen){ return }

        this.setState({
            isSuggestionOpen: true
        });

        this.viewportController.addGestureEvent('tap', this.clickOutside);
    }

    close(){       
        if(!this.state.isSuggestionOpen){ return }   

        this.setState({
            searchResult: null,
            isSuggestionOpen: false,
            histories: null
        });
        this.viewportController.removeGestureEvent('tap', this.clickOutside);
        this.removeAllClassesFromHeader();
    }

    removeAllClassesFromHeader() {
        this.layoutController.header.isAutosuggested = false;
        this.layoutController.header.isNoResult = false;
        this.layoutController.header.isBrandHidden = false;
        this.layoutController.header.isProductsHidden = false;
        this.layoutController.header.shouldShowHistory = false;
        this.layoutController.header.setComponentClass();
    }

    clearSearchInput(e) {
        this.searchInput.value = "";
        this.setValue('',null,true);
    }

    setValue(value, callback,clear){
        this.setState({
            searchValue: value,
            hasValue: (value && value.length > this.app.settings.minSearchCharCount),
            clearButtonIsActive: (value && value.length > 0),
            isProcessing: (value && value.length > 0 && this.searchService.searchType == 'readonly')
        }, () => {
            if(!this.state.hasValue && (!this.state.histories || this.state.histories.length == 0)) this.close();
            if(callback){
                callback(this.state);
            }
            if(clear){
                this.setState({
                    searchResult: null,
                    isSuggestionOpen: false,
                    histories: null
                });
            }
        });                      
    }

    categoriesChangeHandler() {
      //  if(this.searchService.searchType == "readonly") return;
        let mainCategories = [];
        if(this.categoryService.categories && this.categoryService.categories.length) {
            this.setState({
                categories: this.categoryService.categories,
                // isProcessing: false
            }, ()=>{
                //this.setCategoryById(null, true);
            });
        }
    }

    viewportBreakpointChangeHandler() {
        // if(this.categorySelect) {
        //     this.categorySelect.selectedIndex = 0;
        //     this.categoriesChangeHandler();
        // }
        this.setState({
            isMobile: this.viewportController.isMobile
        });
    }

    categorySelectChangeHandler(e) {
        if(!this.categorySelect) return;
        this.setState({
            selectedCategory: this.categorySelect.value
        });
        this.searchInputChangeHandler();
    }

    searchInputChangeHandler(e) {       
        this.setValue(this.searchInput.value, () => {
            if(this.state.hasValue){
                this.layoutController.header.shouldShowHistory = false;
                this.layoutController.header.setComponentClass();
                this.search(this.searchInput.value);
            }else {
                // this.layoutController.header.isNoResult = false;
                // this.layoutController.header.isBrandHidden = false;
                // this.layoutController.header.isProductsHidden = false;
                // this.layoutController.header.setComponentClass();
                this.close();
            }         
        }); 
      
    }

    searchIntputFocusHandler(e) {
        let history = [];        
        if(this.state.hasValue == null || !this.state.hasValue){
            this.removeAllClassesFromHeader();      
            history = this.unbxdSearchService.getHistory();
            // this.open(true);
        }                
        this.setState({ isFocused: true , histories: (this.state.hasValue == null || !this.state.hasValue) ? history : null}, () => {
            if(this.state.hasValue) this.searchInputChangeHandler();
            (this.state.hasValue == null || !this.state.hasValue) ? this.open(true) : null;
        });                        
    }

    searchIntputBlurHandler(e) {        
        this.setState({isFocused: false});
    }

    submitButtonClickHandler(value) {
        // let value = e.currentTarget.dataset.id;
        if(value){
            this.searchInput.value = value;
        }
        this.gotoList();
    }

    searchInputKeyDownHandler(event) {
        switch(event.keyCode) {
            case 9: //TAB
              if(this.state.isFocused) {
                // event.preventDefault();
                // this.searchInput.blur();
              }
            //   this.selectItem(this.state.items[0]);
            break;
            case 13: //Enter
              if(this.state.isFocused){
                this.gotoList();
              }else{
                if(this.state.selectedItem) {
                  
                }
              }
            break;
            case 27: //ESC
              if(this.state.selectedItem) {
                this.searchInput.focus();
                // this.selectItem(null);
              }else {
                if(this.state.isFocused) this.clearSearchInput();
              }
            break;
            //case 37: //Left
            case 38: //Top
            //   event.preventDefault();
            //   if(!this.state.isFocused) this.selectPrev();
            break;
            //case 39: //Right
            case 40: //Down
              event.preventDefault();
              if(!this.state.isFocused) {
                // this.selectNext();
              }else{
                if(this.isFocused) {
                  event.preventDefault();
                  this.searchInput.nativeElement.blur();
                }
                //this.selectItem(this.state.items[0]);
              }
            break;
        }
    }

    gotoList() {
        // if(this.searchService.searchType == 'readonly') return;
        if(this.searchInput.value && _.trim(this.searchInput.value).length > 0) {
            const searchValue = this.searchInput.value; 
            const products = this.state.searchResult.products; 
            
            if(this.state.isMobile) {
            for (let i = 0; i < products.length; i++) {
                if (products[i].name === searchValue) {
                    const baseUrl = window.location.origin;
                    window.location.href = `${baseUrl}/${products[i].slug}`;
                    return;
                }
            }
        }
    
            window.location.href = this.searchService.resultUrl + "?q=" + searchValue + (this.state.selectedCategory ? '&cat=' + this.state.selectedCategory : '');
    
        }
    }
    search(value) {                
        if(value && value.length){
            this.getUnbxdAutosuggest(value);
        }  
    }

    handleClickOutside(event) {
        if (event && event.target && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.close();
        }          
    }

    getUnbxdAutosuggest(value) {
        if(this.searchService.searchType != 'readonly') return;
        let searchType = this.state.isMobile ? "autocomplete_us" : "search_us";
        this.unbxdSearchService.getAutosuggest(value,searchType).then( result => {
            this.setState({
                searchResult: result,
                isProcessing: false,
                searchBarValue: value
            }, () => {
                if(!this.renderStore.searchResultListLeft && !this.renderStore.searchResultListRight){                    
                    this.layoutController.header.isBrandHidden = false;
                    this.layoutController.header.isProductsHidden = false;
                    this.layoutController.header.isNoResult = true;
                }else if(!this.renderStore.searchResultListLeft && this.renderStore.searchResultListRight) {
                    this.layoutController.header.isBrandHidden = true;
                    this.layoutController.header.isProductsHidden = false;
                    this.layoutController.header.isNoResult = false;                    
                }else if(this.renderStore.searchResultListLeft && !this.renderStore.searchResultListRight){
                    this.layoutController.header.isBrandHidden = false;
                    this.layoutController.header.isProductsHidden = true;
                    this.layoutController.header.isNoResult = false;
                }else {
                    this.layoutController.header.isBrandHidden = false;
                    this.layoutController.header.isProductsHidden = false;
                    this.layoutController.header.isNoResult = false;
                }
                this.layoutController.header.setComponentClass();
            });
        }).catch(error => {
            this.setState({
                searchResult: null,
                isProcessing: false
            })
        });
    }

    addDefaultProductImage(ev) {
        ev.target.src = "https://drryor7280ntb.cloudfront.net/media/catalog/product/placeholder/default/foodservicedirect-product-th-placelolder_1.png";
    }

    handleProductClick(productUrl) {
        if(this.unbxdService.isReady) {
            this.unbxdService.fireSearch(this.state.searchValue);
        }
        productUrl = window.location.protocol + "//" + window.location.hostname + "/" + productUrl;
        window.location.href = productUrl;        
    }

    makeTextBold(text) {
        if(!text) return;

        let startIndex = text.toLowerCase().indexOf(this.state.searchValue.toLowerCase());
        let wordLength = this.state.searchValue.length;
        let beforeSubstr = startIndex == 0 ? "" : text.substring(0, startIndex);
        let boldSubstr = text.substring(startIndex, startIndex + wordLength);
        let afterSubstr = text.substring(startIndex + wordLength, text.length);

        return <span>{beforeSubstr}<strong>{boldSubstr}</strong>{afterSubstr}</span>

    }

}