import { DomComponent } from "../core/dom.component";

export default class NumberStepperComponent extends DomComponent {
    constructor(app, element, settings) {
        super(app, element, 'c-number-stepper');
        this.decreseBtn;
        this.increaseBtn;
        this.numberInput;
        this.increaseClick = this.increaseClickHandler.bind(this);
        this.decreaseClick = this.decreaseClickHandler.bind(this);
        this.inputChange = this.inputChangeHandler.bind(this);
        this.keypress = this.inputKeyPressHandler.bind(this);
        this.value = 1;
        this.settings = settings || {};
        this.init();
    }

    init() {
        this.findChild(this.getSelector('__decrease')).then( element => {
            this.decreseBtn = element;
            this.decreseBtn.onclick = this.decreaseClick;
        });
        this.findChild(this.getSelector('__increase')).then( element => {
            this.increaseBtn = element;
            this.increaseBtn.onclick = this.increaseClick;
        });
        this.findChild(this.getSelector('__input input')).then( element => {
            this.numberInput = element;
            this.numberInput.onkeyup   = this.inputChange;
           
            this.numberInput.onkeypress=this.keypress;
            let min = this.settings.min || this.numberInput.getAttribute('min');
            let max = this.settings.max || this.numberInput.getAttribute('max');
            let value = this.numberInput.getAttribute('value');
            this.settings.min = parseInt(min) || 1;
            this.numberInput.setAttribute('min', this.settings.min);
            if(max) this.settings.max = parseInt(max);
            if(!isNaN(this.settings.max)) this.numberInput.setAttribute('max', this.settings.max);
            this.setValue(value || 1);
        });
    }

    decreaseClickHandler() {
        let value = parseInt(this.numberInput.value);
        this.setValue(--value);
    }
    inputKeyPressHandler(event){
        if(event.target.value.length===3 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 96 && event.charCode <= 105))){
            this.setValue(this.settings.max);
            return false;
        }
        return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57
    }
    increaseClickHandler() {
        let value = parseInt(this.numberInput.value);
        this.setValue(++value);
    }

    inputChangeHandler(event) {
        this.setValue(event.target.value);
    }

    setValue(value) {
        this.value = value;
        if(this.settings.min) this.value = Math.max(this.value, this.settings.min);
        if(this.settings.max) this.value = Math.min(this.value, this.settings.max);
        this.numberInput.value = this.value;
        this.numberInput.setAttribute('value', this.numberInput.value);
        this.dispatchEvent(NumberStepperComponent.EVENT_CHANGE);
    }
}

NumberStepperComponent.EVENT_CHANGE = "change";