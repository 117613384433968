import { BaseController } from "../core/base.controller";
import BrandNavigationComponent from "../components/brand-navigation.component";
import { LayoutController } from "./layout.controller";

export class ManufacturerController extends BaseController {

    constructor(app) {
        super(app);
        this.brandMenu = null;
        this.pageHeader = null;
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();
        this.layoutController = this.app.getController('layout');

        this.app.findChild('.p-manufacturer .p-manufacturer__header').then( element => {
            this.pageHeader = element;
        }).catch( reason => this.handleSelectorError(reason));

        this.app.findChild('.p-manufacturer .c-brand-navigation').then( element => {
            this.brandMenu = new BrandNavigationComponent(this.app, element);
            this.scrollHanlder();
        }).catch( reason => this.handleSelectorError(reason));

        this.layoutController.addEventListener(LayoutController.EVENT_SCROLL, this.scrollHandler.bind(this));
    }

    scrollHandler() {
        if(!this.brandMenu || !this.layoutController.header || !this.pageHeader) return;
        console.log(this.layoutController.header.container.offsetHeight, this.pageHeader.offsetHeight);
        let offsetTop = this.layoutController.header.container.offsetHeight + this.pageHeader.offsetHeight;
        if(offsetTop < this.layoutController.getScrollTop()) {
            this.brandMenu.shrink();
            if(this.layoutController.header.searchBarActiveOnScroll) {
                this.layoutController.header.searchBarActiveOnScroll = false;
                this.layoutController.header.layoutScrollDirectionChangeHandler();
            }
        }else{
            this.brandMenu.grow();
            if(!this.layoutController.header.searchBarActiveOnScroll) {
                this.layoutController.header.searchBarActiveOnScroll = true;
                this.layoutController.header.layoutScrollDirectionChangeHandler();
            }
        }
    }
}