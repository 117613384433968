import BaseReactComponent from "../core/base-react.component";
import React, { Component, Fragment } from "react";
import UnbxdService from "../services/unbxd.service";
import RecSys from "../services/recys.service";
import Rating from "./rating.react";
import CartService from "../services/cart.service";

export default class CartRecommendationsComponent extends BaseReactComponent {
  constructor(props, context) {
    super(props, context,{
      defaultProps: {
        hasCart: true
      }
    });

    this.state = {
      limit: props.limit,
      response: null,
      isLoading: this.app.settings.isPartnerStore ? false : true,
      haveEmptyResponse: this.app.settings.isPartnerStore ? true : false,
      images: {},
      loading:null,
      loadMore:false,
      defaultImage:
        "https://www.foodservicedirect.com/media/catalog/product/placeholder/default/foodservicedirect-product-th-placelolder_1.png",
    };

    this.i18nScopeKey = "CART_RECOMMENDATIONS";
    this.addToCartHandler = this.addToCartHandler.bind(this);
    this.removeFromCartHandler = this.removeFromCartHandler.bind(this);
    this.onScrollHandler = this.onScrollHandler.bind(this);
    
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.unbxdRecommendationService = this.app.unbxdRecommendationService;
    this.page = "CART";
    this.label = `CustomerAlsoBought - ${this.props.isSlider ? 'Slider':'Cart'}`;
    this.mageService = this.app.mageService;
    this.categoryService = this.app.categoryService;
    this.unbxdService = this.app.unbxdService;
    this.cartService = this.app.cartService;
    this.recSysService = this.app.recSysService;
    this.gaService = this.app.gaService;
    this.recSysService.addEventListener(RecSys.EVENT_CHANGE, () =>
      this.getProducts()
    );
  }
  componentWillReceiveProps(nextprops) {
    if (this.props.isUnbxd === false && nextprops.isUnbxd === true) {
      this.sendRequestForRecommendations();
    }
  }
  componentDidMount() {
    this.cartChangeHandlerProxyListener = this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartChangeHandler.bind(this));
    this.sendRequestForRecommendations();
  }

  cartChangeHandler() {
    this.setState({
      cart: this.currentProps.hasCart ? this.cartService.cart : null    
    })
  }

  getProducts() {
    if (!this.props.isUnbxd) {
      if (this.recSysService.faqData === false) {
        this.setState({ isLoading: false, haveEmptyResponse: true });
        return true;
      }
      const {cartPageFaqData,faqData} = this.recSysService;
      this.setState(
        {
          faq: true,
          isLoading: false,
          loadMore:false,
          response: { Recommendations: this.props?.limit ? cartPageFaqData.products : faqData.products},
        },
        () => {
          this.app.getController("products").update();
        }
      );
    }
  }
  onScrollHandler(event){
      if(event.currentTarget.scrollTop > 70 && this.state.limit < 7 ){
        this.recSysService.getProducts(null,18,this.props?.limit ? true :false);
        this.setState({limit:18,loadMore:true})
      }
  };

  render() {
    let products = null;
    if (
      this.state.response &&
      this.state.response.Recommendations &&
      this.state.response.Recommendations.length > 0
    ) {
      products = this.state.response.Recommendations.map((product, index) => {
        if (this.state.limit && index > this.state.limit) return null;
        if(this.state.cart && this.state.cart.items.length ){
          var currentItem = this.state.cart.items?.find(({ sku }) => sku === product.sku);
          var isAdded = currentItem?.sku;
        }   
        return (
          <div key={product.uniqueId} className={`${index === 0 && this.state.response.Recommendations.length === 1 ? 'c-cart-recommendation_first_item' : ''}`}>
            <div
              className="c-product-card c-product-card--shrink p-3"
              // json-product={`{'sku': '${product.uniqueId}','vendor_id':'${product.default_vendor_data || product.vendor_id}','label':'CustomerAlsoBought - ${this.props.isSlider ? 'Slider':'Cart'}'}`}
              // json-product={`{'sku': '21380009-1-1-1'}`}
            >
              <div className="c-product-card__container">
                <div className="c-product-card__preview">
                  <div
                    href={
                      this.state.faq
                        ? product.productUrl
                        : `/${product.productUrl}`
                    }
                    onClick={this.productClickHandler.bind(
                      this,
                      product,
                      index
                    )}
                    className="c-product-card__preview-slider"
                  >
                    <div className="c-preview-image-slider">
                      <div className="c-preview-image-slider__container swiper-container">
                        <div className="c-preview-image-slider__content swiper-wrapper">
                          <div className="c-preview-image-slider__slide swiper-slide">
                            {/* <img
                              onError={this.addDefaultProductImage}
                              src={
                                this.state.images[product.uniqueId] ||
                                this.state.defaultImage
                              }
                            /> */}
                            <img
                              onError={this.addDefaultProductImage}
                              src={
                                !this.props.isUnbxd
                                  ? product.image
                                  : this.state.images[product.uniqueId] ||
                                    this.state.defaultImage
                              }
                              alt={product.title}
                            />
                          </div>
                        </div>
                        <div className="c-preview-image-slider__pagination swiper-pagination" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-product-card__content">
                  

                  <div className="c-product-card__title cust-bought-title">
                    <a
                      href={
                        this.state.faq
                          ? product.productUrl
                          : `/${product.productUrl}`
                      }
                      onClick={this.productClickHandler.bind(
                        this,
                        product,
                        index
                      )}
                    >
                      {product.title}
                    </a>
                  </div>

                  <div class="c-product-card_additional-flags">
                    {product.liquidation && product.liquidation !== "No" ? (
                      <div class="c-product-card_liquidation-flag">
                        {" "}
                        <span>CLEARANCE</span>{" "}
                      </div>
                    ) : null}
                    {product.shipping_type === "Ships Free" ? (
                      <div class="c-product-card_free-shipping-flag">
                        {" "}
                        <span>FREE SHIPPING</span>{" "}
                      </div>
                    ) : null}
                    {product?.rebate_eligibility === "yes" &&
                        <div class="c-product-card_free-shipping-flag bg-reb">
                              <span>REBATE-ELIGIBLE</span>
                        </div>
                    }
                  </div>

                  <div className="c-product-card__ship-info">
                    {product.ships_in && (
                      <span style={{display:"unset"}} className="c-product-card__ship-info-text">
                        {product["stock_type"] &&
                          product["stock_type"] ==
                            this.unbxdRecommendationService.specialStockType &&
                          <>
                            Ready to ship within <span className="ship-t">3-4 weeks</span>
                          </>      
                          // `Ready to ship within 3-4 weeks`
                        }
                        {(!product["stock_type"] ||
                          (product["stock_type"] &&
                            product["stock_type"] !=
                              this.unbxdRecommendationService
                                .specialStockType)) &&
                              <>
                                Ready to ship within <span className="ship-t">{ 
                                  product.ships_in == 21 || product.ships_in == 28
                                  ? "3-4 weeks"
                                  : `${product.ships_in} day(s)`
                                }
                                </span>
                              </>
                              // `Ready to ship within ${
                              //   product.ships_in == 21 || product.ships_in == 28
                              //     ? "3-4 weeks"
                              //     : `${product.ships_in} day(s)`
                              // }`
                        }
                      </span>
                    )}
                    {!product.ships_in && (
                      <span className="c-product-card__ship-info-no-shipping-badge">
                        {this.i18nService.translate([
                          "HOME_PAGE_RECOMMENDATIONS",
                          "TITLE",
                        ])}
                      </span>
                    )}
                  </div>
                  {product?.product_review?.review_count && (
                      <div className="d-flex">
                        <Rating
                          size={"medium"}
                          rating={
                            product?.product_review?.rating_avg
                          }
                          customStyle={this.props?.limit ? {
                            div:{
                              width: '75px',height: '15px',  backgroundSize: '15px',
                            },
                            span:{
                              height: '15px',  backgroundSize: '15px'
                            }
                          }:null}
                        />
                        <span className="c-product-shop-box__review-count" style={this.props.limit ? {marginTop:'-3px'}:null}>
                          (
                          {
                            product?.product_review?.review_count 
                          }
                          )
                        </span>
                      </div>
                    )}
                  <div className="c-product-card__footer">
                    <div className="c-product-card__footer-left">
                      <div className="c-product-card__price">
                        {(!product.special_price ||
                          !product.isSpecialPriceAvailable) && (
                          <span className="c-product-card__price-current cust-bought-current">
                            {this.i18nService.format(product.price, [
                              "number",
                              "currency",
                            ])}
                            <span class="c-product-card__sold-as">/{product.sold_as}</span>
                          </span>
                        )}
                        {product?.special_price !== 0 &&
                          product.isSpecialPriceAvailable && (
                            <span className="c-product-card__price-current">
                              {this.i18nService.format(product.special_price, [
                                "number",
                                "currency",
                              ])} 
                              <span class="c-product-card__sold-as">/{product.sold_as}</span>
                            </span>
                          )}
                        {product?.special_price !== 0 &&
                          parseFloat(product.special_price) !==
                            parseFloat(product.price) &&
                          product.isSpecialPriceAvailable && (
                            <span className="c-product-card__price-old">
                              {this.i18nService.format(product.price, [
                                "number",
                                "currency",
                              ])}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="c-product-card__footer-right">
                      {/* <button className="c-product-card__add-button c-button c-button--icon" /> */}
                      { isAdded ?
                          <div className={`c-product-card--added-to-cart ${this.state.loading === product.sku && 'c-product-card--disabled c-product-card--process'}`}>
                              <button onClick={() => this.removeFromCartHandler(currentItem)} 
                              class={`c-product-card__add-button c-button c-button--icon ${this.state.loading === product.sku && 'c-button--processing'}`}></button>
                          </div>
                          :
                          <div className={`${this.state.loading === product.sku && 'c-product-card--disabled c-product-card--process'}`}>
                              <button onClick={() => this.addToCartHandler(product)} class={`c-product-card__add-button c-button c-button--icon ${this.state.loading === product.sku && 'c-button--processing'} `}></button>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <span
        id={this.props.elementId}
        class="page__section p-cart-detail__may-like-products p-cart-detail__cutomer_bought  "
      >
        {!this.state.haveEmptyResponse ? (
          <div className="page__section-caption pt-4">
            <div className="page__section-caption-title page__section-caption-title--fsd-title">
              <h2 className="cust-bought-h2" >
                {this.props.isUnbxd
                  ? this.i18nService.translate([this.i18nScopeKey, "TITLE"])
                  : "Customers also Bought"}
              </h2>
              {!this.state.response && (
                <div className="c-loading-spinner ml-1"></div>
              )}
            </div>
          </div>
        ) :  ( 
          <div className="page__section-caption pt-4">
          <div className="page__section-caption-title page__section-caption-title--fsd-title">
            <h2 className="cust-bought-h2" >
              {this.props.isUnbxd
                ? this.i18nService.translate([this.i18nScopeKey, "TITLE"])
                : "Customers also Bought"}
            </h2>
            {!this.state.response && (
              <div className="c-loading-spinner ml-1"></div>
            )}
          </div>
        </div>
        )
        }
        {this.state.response &&
          this.state.response.Recommendations &&
          this.state.response.Recommendations.length > 0 && (
            <div className="page__section-content fsd-recommendations-recsys-for-customer-group">
              <div
              style={{paddingBottom:'0'}}
                className={`c-cart-recommendation ${
                  this.props.nocartitems ? "nocartitems" : ""
                }`}
              >
                <div className="c-multi-product-slider__container"
                  onScroll={this.onScrollHandler}
                >
                  <div className="c-multi-product-slider__wrapper">
                    {products}
                  </div>
                  {this.state.loadMore && <h1 class="text-center mt-5 text-primary h4">Loading...</h1>}

                  <div className="c-multi-product-slider__scrollbar swiper-scrollbar d-sm-none" />
                </div>
              </div>
            </div>
          )}
      </span>
    );
  }

  addDefaultProductImage(ev) {
    ev.target.src =
      "https://www.foodservicedirect.com/media/catalog/product/placeholder/default/foodservicedirect-product-th-placelolder_1.png";
  }

  sendRequestForRecommendations() {
    // if (this.props.isUnbxd) {
    //   if (this.unbxdService.isReady) {
    //     this.getRecommendations();
    //   } else {
    //     this.unbxdService.addEventListener(
    //       UnbxdService.EVENT_READY,
    //       this.getRecommendations.bind(this)
    //     );
    //   }
    // } else {
      // if(this.mageService.settings && this.mageService.settings.isRecSysAPIEnabled){
    this.recSysService.getProducts(null,this.state.limit,this.props?.limit ? true :false);
    this.recSysService.addCartListner(this.state.limit);
      // }
    // }
  }

  productClickHandler(product, index, e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.unbxdService.isReady) {
      let pIdList = this.state.response.Recommendations.map((product) => {
        return product.sku;
      });
      // if(index !== undefined)
      this.gaService.clickOnWidgets(this.label);
      this.unbxdService.fireProductClick(product, index, this.page);
      //  this.unbxdService.fireRecommendationImpressions(pIdList, 'CART_RECOMMEND');
    }
    window.location.href = this.state.faq
      ? product.productUrl
      : `/${product.productUrl}`;
  }

  // getRecommendations() {
  //   if (!this.app.settings.isPartnerStore) {
  //     this.unbxdRecommendationService
  //       .getRecommendations(this.page, null, this.props.cartItems)
  //       .then((response) => {
  //         this.setState(
  //           {
  //             isLoading: false,
  //             response: response,
  //             haveEmptyResponse: !(
  //               response.Recommendations && response.Recommendations.length > 0
  //             ),
  //           },
  //           () => {
  //             this.app.getController("products").update();
  //             let sku = "";
  //             let pIds =
  //               this.state.response &&
  //               this.state.response.Recommendations &&
  //               this.state.response.Recommendations.map((product) => {
  //                 sku = (!sku ? sku : sku + ",") + product.uniqueId;
  //                 return product.uniqueId;
  //               });
  //             if (sku) {
  //               this.categoryService.getProductImages(sku).then((products) => {
  //                 let images = {};
  //                 let product_reviews = {};
  //                 products.map(({ sku, image, product_review }) => {
  //                   images[sku] = image;
  //                   product_reviews[sku] = product_review;
  //                 });
  //                 this.setState({ images, product_reviews });
  //               });
  //             }
  //             if (this.unbxdService.isReady) {
  //               this.unbxdService.fireRecommendationWidgetImpressions(
  //                 this.page,
  //                 pIds
  //               );
  //             }
  //           }
  //         );
  //       });
  //   }
  // }

  addToCartHandler(item) {
    this.setState({ loading: item.sku });
    this.cartService.addToCart(item, 1).then( (res) => {
        console.log("Item Added",err)
        this.setState({ loading: null });
    }).catch( err => {
        console.log("EROOR",err)
        this.setState({ loading: null });
    })
  }
  removeFromCartHandler(item) {
    this.setState({ loading: item.sku });
    this.cartService.removeFromCart(item.item_id).then( (res) => {
        console.log("Item removed",err)
        this.setState({ loading: null });
    }).catch( err => {
        this.setState({ loading: null });
        console.log("EROOR",err)
    })
  }

}
