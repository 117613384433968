import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DomComponent } from '../core/dom.component';
import { Suspense,lazy } from 'react';
const  OnePageCheckout = React.lazy(()=>import('./one.page.checkout'));

export class CheckoutStepsComponent extends DomComponent {
    constructor(app, element) {
        super(app, element);
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        console.warn("this.getAttributes",this.getAttributes,this.app)
        this.component = ReactDOM.render(
        <Suspense fallback={null}>
            <OnePageCheckout currentProps={this.getAttributes()}  app={this.app} />
        </Suspense>
        , this.domElement);
    }
}

CheckoutStepsComponent.decorator = {
    selector: "fsd-checkout-steps"
}

