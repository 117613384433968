import BaseReactComponent from '../core/base-react.component';
import React, { Component } from 'react';
import classNames from 'classnames';
import I18nService from '../services/i18n.service';
import {ApplyInput} from './apply-input.component';
import CartService from '../services/cart.service';

export default class PromotionCodeList extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);
        this.i18nScopeKey = 'PROMOTION_CODE_LIST';

        this.applyInput = null;
        this.hasMultipleCode = false;

        this.renderStore = {
            componentClassName: 'c-promotion-code-list'
        }

        this.state = {
            isEditMode: false,
            coupons: props.coupons || null,
            couponProcessing: false
        }

        this.cartChangeHandler = this.cartChangeHandler.bind(this);
    }

    appOnInit(){
        if(super.appOnInit) super.appOnInit();

        this.i18nService = this.app.i18nService;
        this.cartService = this.app.cartService;
        this.cartChangeHandlerProxyListener = this.cartService.addEventListener(CartService.EVENT_CHANGE, this.cartChangeHandler, this);
    }

    componentDidMount() {
        this.cartChangeHandler();
    }

    componentWillUnmount() {
        this.cartService.removeEventListener(CartService.EVENT_CHANGE, this.cartChangeHandlerProxyListener);
    }

    cartChangeHandler(e){
        this.setState({
            coupons: this.cartService.coupons,
        });
    }

    render() {
        this.renderStore.componentClassName = classNames(
            'c-promotion-code-list',
            {
                'c-promotion-code-list--processing': this.state.couponProcessing
            }
        )
        let couponList;
        if(this.state.coupons){
            couponList = this.state.coupons.map( coupon => {
                return  <li key={'promotion-code'+coupon.code} className="c-promotion-code-list__item">
                            <div className="c-promotion-code-list__item-container">
                                <div className="c-promotion-code-list__item-left">
                                    <div className="c-promotion-code-list__item-title">
                                        <i className="icon__fsd icon__fsd--coupon-icon"></i>
                                        <span className={`coupon-span ${coupon.title.length > 10 ? 'coupon-span2' : ''}`}>
                                            <span data-test-id="checkout-promo-text-appliedpromocode">{coupon.title}</span>
                                            <span>is applied</span>
                                        </span>
                                    
                                    </div>
                                </div>
                                <div className="c-promotion-code-list__item-right">
                                    {/* <div className="c-promotion-code-list__item-discount">
                                        <span>{this.i18nService.format(coupon.value < 0 ? coupon.value*-1 : coupon.value, ['number', 'currency'])}</span>
                                    </div> */}
                                    <div className="c-promotion-code-list__item-action">
                                        <span onClick={() => {
                                            this.removeCoupon(coupon);
                                        }} className="c-promotion-code-list__item-action-remove">
                                            {/* {!this.state.couponProcessing && <i className="icon__fsd icon__fsd--trash-gray"></i>} */}
                                            {!this.state.couponProcessing && <span className="coupon-remove" data-test-id="checkout-promo-btn-removecode">Remove</span>}
                                            {this.state.couponProcessing && <i className="icon__fsd icon__fsd--processing-dark"></i>}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
            })
        }

        return <div className={this.renderStore.componentClassName}>
            <div className="c-promotion-code-list__container">
                {this.state.coupons && <ul className="c-promotion-code-list__items">
                    {couponList}       
                </ul>}
                {this.hasMultipleCode || (!this.hasMultipleCode && (!this.state.coupons || this.state.coupons.length === 0)) &&
                  <div className="c-promotion-code-list__apply-input" data-test-id="checkout-promo-btn-openform">
                    { !this.state.isEditMode && <div onClick={() => {
                        this.setEditMode(true);
                    }} className="c-promotion-code-list__apply-input-action">
                        <div className="c-promotion-code-list__apply-input-action-container">
                            {/* <i className="c-promotion-code-list__apply-input-action-icon icon__fsd icon__fsd--plus-thin-primary"></i> */}
                            <span className="c-promotion-code-list__apply-input-action-title">
                                { this.i18nService.translate([this.i18nScopeKey, 'ACTION_TITLE']) }
                            </span>
                            <i className="c-promotion-code-list__apply-input-action-icon icon__fsd icon__fsd--plus-thin-primary"></i>
                        </div>
                    </div> }
                    {this.state.isEditMode && <div className="c-promotion-code-list__apply-input-field">
                        <ApplyInput 
                            ref={r => this.applyInput = r} 
                            app={this.app} 
                            placeholder={ this.i18nService.translate([this.i18nScopeKey, 'APPLY_INPUT_PLACEHOLDER']) } 
                            onSubmit={this.inputSubmitHandler.bind(this)}
                            onBlur={this.inputBlurHandler.bind(this)}
                            isProcessing={this.state.couponProcessing}/>
                    </div>}
                  </div>
                }
                {/* <div className = "c-promotion-code-list__continue-shopping">
                        shopping-cart
                            <i className="c-promotion-code-list__shop-icon"></i>
                    <a href = "/" className = "c-promotion-code-list__continue-shopping-icon"><p className = "c-promotion-code-list__continue-shopping-label">Continue Shopping</p></a>
                </div> */}
            </div>
        </div>
    }

    inputSubmitHandler(data) {
        if(!data) return;
        this.setState({couponProcessing: true});
        if(this.applyInput) this.applyInput.setState({isProcessing: true});
        this.cartService.addCoupon(data).then( result => {
            if(result) {
                this.setEditMode(false);
            }else{
                this.dialogController.notify({
                    theme: 'danger',
                    text: 'Unknown Error'
                });
            }
            this.setEditMode(false);
        }).catch( error => {
            this.setEditMode(false);
         });        
    }

    inputBlurHandler() {
        if(this.applyInput && !this.applyInput.state.value) this.setEditMode(false);
    }

    setEditMode(isEditMode){
        this.setState({couponProcessing: false});
        if(this.applyInput && this.state.isEditMode && !isEditMode) this.applyInput.reset();
        this.setState({
            isEditMode: isEditMode
        }, () => {
            if(this.applyInput && this.state.isEditMode) this.applyInput.activate();
        });
    }

    removeCoupon(coupon){
        let coupons = this.state.coupons;
        let indexOfCoupon = coupons.indexOf(coupon);
        this.setState({couponProcessing: true});
        this.cartService.removeCoupon(coupon.code).then(result => {
            coupons.splice(indexOfCoupon, 1);
            this.setState({
                coupons: coupons
            });
            this.setState({couponProcessing: false});
        }).catch(error => {
            this.setState({couponProcessing: false});
        });
    }
}