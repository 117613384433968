import React, { Component } from "react";
import ReactDOM from "react-dom";
import { DomComponent } from "../core/dom.component";
import classNames from "classnames";
import BaseReactComponent from "../core/base-react.component";
import CheckoutService from "../services/checkout.service";
import BraintreeService from "../services/braintree.service";
import braintree from "braintree-web";

export class PaypalBraintreeComponent extends DomComponent {
  constructor(app, element) {
    super(app, element);
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.component = ReactDOM.render(
      <PaypalBraintreeButton
        app={this.app}
        currentProps={this.getAttributes()}
      />,
      this.domElement
    );
  }
}

PaypalBraintreeComponent.decorator = {
  selector: "fsd-paypal-button",
};

export class PaypalBraintreeButton extends BaseReactComponent {
  constructor(prop, context) {
    super(prop, context, {
      defaultProps: {},
    });

    this.renderStore = {
      componentClassName: undefined,
      // paypalButton: paypal.Buttons.driver('react', { React, ReactDOM })
    };

    this.state = {
      paypalButton: null,
    };

    this.buttonRef = React.createRef();

    this.paymentHandler = this.paymentHandler.bind(this);
    this.authorizeHandler = this.authorizeHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.checkoutController = this.app.getController("checkout");
    this.loadingController = this.app.getController("loading");
    this.dialogController = this.app.getController("dialog");
    this.routerController = this.app.getController("router");
    this.checkoutService = this.checkoutController.checkoutService;
    this.paypalService = this.checkoutService.paypalService;
    this.accountService = this.app.accountService;
    this.braintreeService = this.app.braintreeService;
    this.cartService = this.app.cartService;
    this.mageService = this.app.mageService;
    var event = document.createEvent("Event");
    event.initEvent("paypal-load", false, true);
    document.dispatchEvent(event);
    document.addEventListener(
      "paypal-load-complete",
      this.paypalButton.bind(this)
    );
  }

  componentDidMount() {
    if (this.braintreeService.load) {
      this.paypalButton();
    }
  }
  paypalButton() {
    this.braintreeService.load = true;
    const { checkout, color } = this.props;
    if (this.buttonRef.current) {
      this.setState(
        {
          paypalButton: paypal.Button.render(
            {
              env: this.braintreeService.environment,
              style: {
                color: color,
                size: "responsive",
                height: 40,
                shape: "rect",
                label: "checkout",
                fundingicons: false,
                layout: "horizontal",
                tagline: "false",
              },
              funding: {
                // allowed:[paypal.FUNDING.CARD],
                disallowed: [paypal.FUNDING.VENMO, paypal.FUNDING.CREDIT],
              },
              commit: false,
              payment: this.paymentHandler,
              onAuthorize: this.authorizeHandler,
              onCancel: this.cancelHandler,
              onError: this.errorHandler,
              onShippingChange: function (data, actions) {
                console.log(data, "shipping-address");

                //  localStorage.setItem('shipping-address',JSON.stringify(data.shipping_address));
              },
            },
            this.buttonRef.current
          ),
        },
        () => {
          console.error("braintree- paypalButton = ", this.state.paypalButton);
        }
      );
    }
  }

  async paymentHandler(data, actions) {
    if (this.checkoutService.addshipping && this.props.checkout) {
      if (!this.checkoutService.addshipping.isValid) {
        this.dialogController.notify({
          theme: "danger",
          text: "Invalid Address",
        });
        return null;
      }
    }
    let response = await this.braintreeService.generatePaypalPaymentToken();
    return response.token;
  }

  async authorizeHandler(data, actions) {
    if (data.returnUrl) {
      const { paymentToken, billingToken, payerID } = data;
      
      let response =
      await this.braintreeService.generatePaypalPaymentNonceToken({
        paymentToken,
        correlationId: billingToken, // to be discussed
        payerId: payerID,
        cartId: this.checkoutService.cartId, 
        isExpressCheckout: !this.props.checkout,
      });

      if(!this.props.checkout) { 
          this.checkoutService.getPaypalAuth({
            token:paymentToken,
            quoteId:this.checkoutService.cartId,
            payerIdentifier:payerID,
            nonce:response.createBraintreePaypalNonceTokenByMethodToken.paymentMethodNonceToken
          }).then(result=>{
                this.cartService.clearApplePayCookie();
                // if(result){
                  this.checkoutService.getShippingAddressFromQuote().then(shippingAddress=>{
                    localStorage.setItem('shipping-address',JSON.stringify(shippingAddress));
                    window.location.href = this.app.environment.route.checkout
                  })
                // }
          }).catch((err)=>{
              console.error("getPaypalAuth err :",err);
          })

      }else{
        if (response) {
          this.checkoutService.selectCurrentPaymentMethodByCode(
            "braintree_paypal",
            {
              braintreePayapalNonceToken:
                response.createBraintreePaypalNonceTokenByMethodToken
                  .paymentMethodNonceToken,
            }
          );
        }
      }      
    }
  }

  errorHandler(err) {
    console.log("errorHandler = ", err);
  }

  cancelHandler() {
    this.paypalService.cancel();
  }

  render() {
    return (
      <div
        ref={this.buttonRef}
        data-test-id="checkout-payment-btn-paypalopenpayment"
        className={`c-paypal-button ${
          this.props.checkout ? "" : "not-checkout"
        }`}
      ></div>
    );
  }
}
