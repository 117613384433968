import { DomComponent } from "../core/dom.component";
import classNames from "classnames";

export default class MainMenuComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-main-menu');
        this.items = [];
        
        this.findChildren(this.getSelector('__item')).then ( elements => {
            this.items = Array.from(elements).map( element => {
                const key = element.querySelector(this.getSelector('__item-button')).getAttribute('href');
                return {
                    element: element,
                    key: key
                }
            });
            this.init();
        });
        this.keyDownProxy = this.keydDownHandler.bind(this);
    }
    init() {
        this.setItems();
    }

    setComponentClass() {
        let className = classNames(
            this.getSelector("", ""),
            {
                [this.getSelector("--open", "")]: this.selectedItem != null
            }
        )
        this.setClass(className);
    }

    setItems() {
        let self = this;
        let href;
        this.items.map( item => {
            href = item.element.querySelector('a').getAttribute('href');
            item.label = item.element.querySelector(this.getSelector('__item-label')).innerText;
            if(href.substr(0, 1) == "#") {
                switch(href) {
                    default: 
                        item.hasMore = true;
                    break;
                }
                item.action = href.substr(1);
            }
            item.isSelected = false;
            item.isHighlighted = item.element.hasAttribute('highlighted');
            item.element.data = item;
            if(item.action) item.element.onclick = function(e) {
                self.itemClickHandler(e);
                return false;
            };
        });
        this.setItemClassList();
    }

    setItemClassList() {
        let classList;
        let itemClassName;

        this.items.map( item => {
            itemClassName = classNames(
                this.getComponentClass()+'__item',
                {
                    [this.getComponentClass()+'__item--has-more']: item.hasMore,
                    [this.getComponentClass()+'__item--selected']: item.isSelected,
                    [this.getComponentClass()+'__item--highlighted']: item.isHighlighted
                }
            );

            this.setClass(itemClassName, item.element);
        });
    }

    itemClickHandler(e) {
        e.preventDefault();
        this.setSelectedItem(this.items.find( item => {
            const key = e.currentTarget.querySelector(this.getSelector('__item-button')).getAttribute('href');
            return item.key == key;
        }));
    }

    setSelectedItem(item) {
        if(item) {
            if(this.selectedItem && this.selectedItem.key == item.key) {
                this.selectedItem = null;
            }else{
                this.selectedItem = item;
            }
        }else{
            this.selectedItem = null;
        }

        if(this.selectedItem) {
            document.addEventListener('keydown', this.keyDownProxy)
        }else{
            document.removeEventListener('keydown', this.keyDownProxy);
        }

        this.items = this.items.map( existItem => {
            existItem.isSelected = this.selectedItem ? existItem.key == this.selectedItem.key : false
            return existItem;
        } );

        this.setComponentClass();
        this.setItemClassList();
        this.dispatchEvent(MainMenuComponent.EVENT_SELECT);
    }

    keydDownHandler(event) {
        switch(event.keyCode) {
            case 27:
                if(this.selectedItem) this.setSelectedItem(null);
            break;
        }
    }
}

MainMenuComponent.EVENT_SELECT = "select";